import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {HeaderComponent} from '../header/header.component';
import {FormGroup, FormControl, Validators, NgForm, FormBuilder} from '@angular/forms';
import {AppService} from '../app.service';
import {HttpClient} from '@angular/common/http';
import {MethodsService} from '../methods.service';
import {Router} from '@angular/router';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {Parameters} from '../parameters';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {AppComponent} from '../app.component';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @ViewChild('captchaElem') captchaElem: any;
    formValues: FormGroup = new FormGroup({});
    app: any;
    socialUser: SocialUser = new SocialUser();
    loggedIn: any = false;
    siteKey = '6Lcc-W0UAAAAAAdcFGL0d47jvGH5Eemf2DqBiOwK';
    appsettingsUrl = '../assets/json/app.json';
    constructor(
        private router: Router,
        private socialAuthService: SocialAuthService,
        private _formBuilder: FormBuilder,
        public  generalMethods: MethodsService,
        private appComponent: AppComponent,
        public httpClient: HttpClient,
        public formBuilder: FormBuilder,
        public service: AppService ) {
        this.captchaElem = InvisibleReCaptchaComponent;
    }

    ngOnInit(): void {
        document.getElementsByTagName( 'body')[0].style.backgroundColor = '#ffff';
        this.app = this.service.app;
        this.formValues = this.formBuilder.group({
            email: ['', Validators.compose([
                Validators.required,
            ])],
            password: ['', Validators.compose([
                Validators.required,
                Validators.minLength(6)
            ])],
            // grecaptcharesponse: ['', Validators.compose([
            //   Validators.required,
            // ])],
        });
        this.socialAuthService.authState.subscribe((socialUser) => {
            this.socialUser = socialUser;
            const incomingSocialUser: Parameters = new Parameters();
            incomingSocialUser.setAttributes(socialUser);
            this.router.navigateByUrl('/app/services/add');
        });
    }

    onSubmit(loginForm: NgForm){
        this.service.httpService(
            'post',
            this.app.settings.routes.authenticate,
            loginForm, {},
            (response: any) => {
                localStorage.setItem(JSON.parse(JSON.stringify(this.service.app)).settings.tokenName, response.token);
                this.service.setToken(response.token);
                this.service.httpService( 'get', this.appsettingsUrl, {}, {},
                    (result: any) => {
                        this.app.settings = result;
                        this.service.app = Object.assign(this.service.app, {settings: result});
                        if(this.service.app.hasOwnProperty('data')) delete this.service.app.data;
                        this.service.setNavigatedUrl(window.location.href);
                        window.localStorage.setItem('routeName',  'null');
                        window.localStorage.setItem('formId'   ,  'null');
                        // this.appComponent.main();
                    },  (error: any) => {
                    });
            }, (error: any) =>  {
            });
    }

    handleReset(){
    }

    handleExpire(){
      this.captchaElem.reloadCaptcha();
    }

    handleLoad(){}

    handleSuccess(event: any){
    }

    signInWithGoogle(): void {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    signInWithFB(): void {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    signOut() {
        this.socialAuthService.signOut();
    }
}
