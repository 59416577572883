import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {MatDialog} from "@angular/material/dialog";
import {DialogCampaniesComponent} from "../campanies/dialog-campanies.component";
import {AppService} from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class CampanyService {

  report: any;

  data: any = [];

  /**
   * @param mixed Data
   */


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }


  constructor(public service: AppService,
              private dialog: MatDialog) {

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);
  }


   getCampanyByCampany(campany: any){
      var campanies: any = [] = this.service.app.data.campanies.data.filter((item: any) => {
        return parseFloat(item.id) === parseFloat(campany.id); });
      return campanies.length === 0 ? this.addCampany(campany) : campanies[0];
   }

    addCampany(campany: any){
      this.service.app.data.campanies.data.unshift(campany);
      return campany;
    }

    getCampanyById(campanyId: any){
      var campanies: any = [] = this.service.app.data.campanies.data.filter((campany: any) => {
        return parseFloat(campany.id) === parseFloat(campanyId);});
      return campanies[0];
    }


    search(searchText: any, callback: any){
      this.service.httpService(
          'post',
          '/campanies/search',
          {searchText: searchText}, {ignoreLoadingBar : 'true', notify: 'false'},
          (response: any) => {
            setTimeout((result: any) =>{
              response.map((item: any) => {
                result = [];
                result = this.service.app.data.companies.data.filter((itm: any) => {
                  return parseFloat(item.id) === parseFloat(itm.id);
                }); if(result.length === 0){
                  this.service.app.data.companies.data.push(item);
                  callback(null);
                }
              });
            });
          }, (error: any) => {
          });
    }

    openDialog(params: any) {
      setTimeout(() => {
        params = new Parameters();
        params.setAttributes({});
        const dialogRef = this.dialog.open(DialogCampaniesComponent, {
          data  : {params: params.getAttributes()},
          width : '55%',
          height: '100%',
          disableClose: true,
        });
      });

    }

}
