<div layout="row">
    <div class="panel panel-default normal" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <mat-tab-group mat-align-tabs="start">
                <mat-tab label="Images">
                    <a class="cursor defaultColor pull-right"
                       mat-mini-fab
                       (click)="incomingImgSelected.setAttributes({});img.click()"
                       [ngStyle]="{'margin-top':'10px'}"
                    ><i class="material-icons">add</i></a>
                    <section class="post-section blog-post">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 col-sm-6"
                                     *ngFor="let image of (incomingQuestion.attributes.settings.hasOwnProperty('images') ? incomingQuestion.attributes.settings.images : [])">
                                    <div class="post-box"
                                         (contextmenu)="onContextMenu($event, image)"
                                         (click)="onContextMenu($event, image)"
                                         (mousedown)="incomingImgSelected.setAttributes(image)"
                                    >
                                        <div class="frame" style="width:360px;height: 200px">
                                            <a>
                                                <img src="{{image.src}}" alt="img" [ngStyle]="{'max-height':'199px'}">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </mat-tab>
                <mat-tab label="Video">

                </mat-tab>
            </mat-tab-group>
            <div>
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
<input type="file" (change)="getImg($event)" [ngStyle]="{'display':'none'}" accept="image/*" #img>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item (click)="setCaption()">Caption</button>
        <button mat-menu-item (click)="img.click()">Replace photo</button>
        <button mat-menu-item (click)="setActionCaption()">Set Action Caption</button>
        <button mat-menu-item (click)="setSubmitCaption()">Set Submit Caption</button>
        <button mat-menu-item (click)="setActionButton1()">Action Button 1</button>
        <button mat-menu-item (click)="setActionButton2()">Action Button 2</button>
    </ng-template>
</mat-menu>