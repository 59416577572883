import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent implements OnInit {
    @Input() app: any;
    @Output() onOpenDialog = new EventEmitter();
    @Output() extractEventEmitter = new EventEmitter();
    constructor(public  dialog: MatDialog,
                public emitter: BroadcastChannelService,
                public broadcastChannel: BroadcastChannelService) {
    }

    ngOnInit(): void {
    }

    openDialog(){
      this.onOpenDialog.emit();
      this.broadcastChannel.emitNavChangeEvent('add');
    }

    handledaterange() {

    }

    openDateMenu(){this.broadcastChannel.emitNavChangeEvent('openDateMenu');}

    extract(){
      this.broadcastChannel.emitNavChangeEvent('extract');
    }



}

