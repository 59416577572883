import { Component, OnInit } from '@angular/core';
import {AppService} from '../../../app.service';
@Component({
  selector: 'app-dialog-invitation-card',
  templateUrl: './dialog-invitation-card.component.html',
  styleUrls: ['./dialog-invitation-card.component.scss']
})
export class DialogInvitationCardComponent implements OnInit {

  constructor(public  service: AppService) { }

  ngOnInit(): void {

//    QrCodeWithLogo.toCanvas({
//                           canvas : qrcodeElement, // 换成你的canvas节点
//                           content: JSON.stringify(qrcodeSample.getAttributes()),
//                           width  : 140,
//                           height : 160,
//                           logo: {
//                               src: ('/app/assets/images/coat.png'),
//                               width: 100,
//                               height:100,
//                           },
//                       });
  }

}
