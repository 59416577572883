import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {AppService} from '../../../../app.service';
import * as Chart from 'chart.js';
import {Parameters} from '../../../../parameters';

@Component({
  selector: 'app-mitiple-user-activity',
  templateUrl: './mitiple-user-activity.component.html',
  styleUrls: ['./mitiple-user-activity.component.scss']
})
export class MitipleUserActivityComponent implements OnInit,AfterViewInit {
  @Input() element: any;
  @Input() color: any;
  @Input() legends: any;
  @Input() data: any;
  @Input() label: any;
  @Input() type: any;
  @Input() limit: any;
  @Input() height: any;
  @Input() grouped: any;
  @Input() flag: any;
  @Input() fill: any;
  @Input() xGridlines: any;
  @Input() options: any;
  @Input() barThickness: any;
  transparentColor: any;
  datasets: any = [];
  incomingOption: any;
  incomingPrimary: any;
  incomingPrimaryOption: any;
  incomingDataset: any;
  public canvas: any;
  public ctx;
  constructor(private service: AppService) {
    this.incomingOption = new Parameters();
    this.incomingPrimary = new Parameters();
    this.incomingDataset = new Parameters();
    this.incomingPrimaryOption = new Parameters();
  }

  ngOnInit(): void {
   this.xGridlines = this.xGridlines === undefined ? true : this.xGridlines;
   this.barThickness === undefined ? 1 : this.barThickness;
  }

  getColor(){
    if(this.color.toString() === 'red') this.color = '#ec250d';
    if(this.color.toString() === 'blue') this.color = 'rgb(82, 136, 255)';
    this.color = this.color.toString() === 'green' ? '#00d6b4' : this.color;
    return this.color;
  }
  // '#1f8ef1'
  getTransparentColor(){
    if(this.color.toString() === 'red')  this.transparentColor = 'rgba(233,32,16,0.2)';
    if(this.color.toString() === 'blue') this.transparentColor = this.type === 'line' ? 'rgba(29,140,248,0.2)' : 'rgb(82, 136, 255)';
    this.transparentColor = this.color.toString() === 'green' ? '#96CA59' : this.transparentColor;
    return this.transparentColor;
  }

  getLabels(){
    this.legends = this.legends.map((label: any) => {
      return (this.limit === undefined ?
          this.service.limitTo(label.toString(), 3).toUpperCase().replace('..', '') :
          this.service.limitTo(label.toString().toUpperCase(), this.limit)); });
    return this.legends;
  }


  ngAfterViewInit(): void {
    setTimeout(() =>{
      const gradientBarChartConfiguration: any = {
        maintainAspectRatio: false,
        legend: {
          display: false
        },

        tooltips: {
          backgroundColor: '#f5f5f5',
          titleFontColor: '#333',
          bodyFontColor: '#666',
          bodySpacing: 4,
          xPadding: 12,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest',
        },
        responsive: true,
        options: {
              legend: {
                display  : false,
                position : 'right',
                align    : 'start',
                fontColor: '#5f6368',
                font     : 'Google Sans',
              },
              plugins : {
                labels: [
                  {
                    render: function (args) {
                      alert(args.label);
                      return args.label + ' \n' + args.percentage + ' %';
                    },
                    display   : false,
                    position  : 'inside',
                    fontColor : '#fff',
                    fontFamily: 'Google Sans',
                    segment   : false,
                  }
                ]
              },
        },
        scales: {
          yAxes: [{
            display: false,
            gridLines: {
              drawBorder: true,
              color: this.getTransparentColor(),
              zeroLineColor: 'transparent',
            },
            ticks: {
              // suggestedMin: 0,
              // suggestedMax: 120,
              padding: 20,
              fontColor: '#9e9e9e',
            }
          }],

          xAxes: [{
            maxBarThickness: this.barThickness, // number (pixels),
            display: true,
            gridLines: {
              display: this.xGridlines,
              drawBorder: this.xGridlines,
              color: this.getTransparentColor(),
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#5f6368',
              fontFamily: 'Google Sans',
              fontSize: 10,
            }
          }]
        }
      };
      if(this.barThickness !== undefined)
       gradientBarChartConfiguration.scales.xAxes[0].maxBarThickness = this.barThickness;
      this.canvas = document.getElementById(this.element);
      this.ctx  = this.canvas.getContext('2d');
      const gradientStroke: any = this.ctx.createLinearGradient(0, 230, 0, 50);


      gradientStroke.addColorStop(1, this.getTransparentColor());
      gradientStroke.addColorStop(0.4, this.getTransparentColor());
      gradientStroke.addColorStop(0, this.getTransparentColor()); //blue colors
      const opt: any = {
        type: this.type,
        responsive: true,
        legend: {
          display: false,
          show: false
        },
        data: {
          labels: this.getLabels(),
          datasets: this.getDatasets(gradientStroke)
        },
        options: gradientBarChartConfiguration
      };

      const myChart: any = new Chart(this.ctx, opt);
    });
  }

  getDatasets(gradientStroke: any){
    if(this.flag === undefined){
        this.options.map((legend: any, legendIncremental: any) =>{
          this.incomingDataset.setAttributes({});
          this.incomingDataset.setUnknown('backgroundColor', gradientStroke);
          this.incomingDataset.setUnknown('hoverckgroundColor', gradientStroke);
          this.incomingDataset.setUnknown('borderColor', gradientStroke);
          this.incomingDataset.setUnknown('borderWidth', 2);
          this.incomingDataset.setUnknown('fill', this.fill !== undefined);
          this.incomingDataset.setUnknown('borderDash', []);
          this.incomingDataset.setUnknown('borderDashOffset', 0.0);
          this.incomingDataset.setUnknown('lineTension', 0);
          this.incomingDataset.setUnknown('label', legend);
          this.incomingDataset.setUnknown('data', []);
          this.data.map((option: any, optionIncremental: any) =>{
            this.incomingOption.setAttributes(option);
            this.incomingDataset.getUnknown('data').push(parseFloat(this.incomingOption.getUnknown(legend)));
          }); this.datasets.push(this.incomingDataset.getAttributes());
        });
    }else{
        this.options.map((option: any, optionIncremental: any) =>{
          this.incomingOption.setAttributes(Object.assign({}, option));
          this.incomingDataset.setAttributes({});
          this.incomingDataset.setUnknown('backgroundColor', this.service.pieChartColors()[optionIncremental]);
          this.incomingDataset.setUnknown('hoverckgroundColor', this.service.pieChartColors()[optionIncremental]);
          this.incomingDataset.setUnknown('borderColor', this.service.pieChartColors()[optionIncremental]);
          this.incomingDataset.setUnknown('borderWidth', 2);
          this.incomingDataset.setUnknown('fill', this.fill !== undefined);
          this.incomingDataset.setUnknown('borderDash', []);
          this.incomingDataset.setUnknown('label', this.incomingOption.getLabel());
          this.incomingDataset.setUnknown('borderDashOffset', 0.0);
          this.incomingDataset.setUnknown('lineTension', 0);
          this.incomingDataset.setUnknown('data', []);
          if(this.incomingOption.attributes.hasOwnProperty('chart') &&
           !this.service.empty(this.incomingOption.getChart()))
          this.incomingDataset.setUnknown('type', parseFloat(this.incomingOption.getChart()) === 4 ? 'bar' : 'line');
          if(this.incomingOption.attributes.hasOwnProperty('fill') &&
                     !this.service.empty(this.incomingOption.getFill()))
                    this.incomingDataset.setUnknown('fill', parseFloat(this.incomingOption.getFill()) === 1);
          this.data.map((primary: any, primaryIncremental: any) =>{
            this.incomingPrimary.setAttributes(Object.assign({}, primary));
            this.incomingPrimary.getOptions().map((primaryOption: any) => {
             this.incomingPrimaryOption.setAttributes(Object.assign({}, primaryOption));
             if(this.incomingPrimaryOption.getCntrlNum().toString().includes(this.incomingOption.getCntrlNum())){
               this.incomingDataset.getUnknown('data').push(parseFloat(this.incomingPrimaryOption.getValue()));
             }
            });
         });
         this.datasets.push(this.incomingDataset.getAttributes());
        });
    }

     return this.datasets;

  }


  ngAfterContentInit(): void {
  }

}
