<div id="main">
    <div class="banner-outer banner-2 home">
        <div class="banner-2" [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()/2+'px'}">
            <!--            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
            <!--                 src="assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg" />-->
            <div class="img" [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()/2+'px','z-index':'0'}">
                <carousel [cellsToScroll]="1"
                          [cellsToShow]="1"
                          [height]="service.getHeight()/2"
                          [autoplay]="true"
                          [arrows]="false"
                          [dots]="false"
                          [loop]="true"
                          *ngIf="setup.length!==0">
                    <div class="carousel-cell"
                         [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()/2+'px'}"
                         *ngFor="let s of setup">
                        <img src="{{s}}"
                             class="mdl-shadow--2dp"
                             [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"/>
                    </div>
                </carousel>
            </div>
        </div>
    </div>

    <!--&lt;!&ndash;INNER BANNER START&ndash;&gt;-->
    <!--<section id="inner-banner">-->
    <!--    <div class="container">-->
    <!--        <h4 class="sawasdeeX4" [ngStyle]="{'color':'#fff'}"><span>venue features and solutions</span></h4>-->
    <!--    </div>-->
    <!--</section>-->
    <!--&lt;!&ndash;INNER BANNER END&ndash;&gt;-->

    <!--POST AREA START-->
    <section class="post-section blog-post">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12" style="cursor: pointer">
                    <div class="post-box">
                        <div class="text-box">
                            <h4 class="mitraX2"><a>
                                <i>"venue features and solutions:"</i>
                                The warehouse Arena is a world-class indoor multi-purpose events venue which will drive the growth of the live entertainment, sport and events industry to new levels of achievement in Tanzania.
                            </a>
                            </h4>
                            <p class="sawasdeeX4 font11" [ngStyle]="{'color':'#000'}">
                                {{service.upperStartingCharacter("The warehouse Arena is set to become Tanzania’s leading entertainment venue, while enhancing Tanzanian's position as a global entertainment destination offering the ultimate options for any event and our business clients.",true)}}
                                <i class="mitra14X2">The Arena can host a multitude of events with varying seating capacities from 1,200 to 5,800 persons</i>.
                            </p>
                            <p class="sawasdeeX4 font11" [ngStyle]="{'color':'#000'}">
                                {{service.upperStartingCharacter('Proudly positioned within the 360 Mall entertainment, leisure and shopping destination, and adjacent to the Rafa Nadal Academy and the Grand Hyatt Kuwait, The Arena is destined to become a key event hub and visitor attraction in Tanzania.',true)}}
                            </p>
                            <h4 class="sawasdeeX4">
                                <a [ngStyle]="{'color':'#000'}">
                                    The Arena has a range of key features and attributes which will provide unique benefits for hosting your event within the venue.
                                </a>
                            </h4>
                            <div class="mitra14X2" [ngStyle]="{'padding-top':'50px'}">
                                <mat-tab-group>
                                    <mat-tab mat-align-tabs="center">
                                        <ng-template mat-tab-label>
                                            <div>
                                                Key Features & Attributes
                                            </div>
                                        </ng-template>
                                        <br><br>
                                        <div>
                                            <ul class="sawasdeeX4 font11" [ngStyle]="{'color':'#000'}">
                                                <li>The largest indoor multipurpose venue in Tanzania built to host a wide range of event types,
                                                    while delivering complete live entertainment on an international scale.</li>
                                                <li>Transformable “Blank Canvas” to customize the venue for commercial events or corporate functions such as product launches and brand activations</li>
                                                <li>Flexible setting that can be altered to suit a range of event modes with desired seating configurations for the Arena Bowl and flat floor</li>
                                                <li>Seating capacity between 1,200 to 5,800 guests depending on event configuration</li>
                                                <li>A 20-meter floor to ceiling clearance to make great use of the venue’s 300-ton rigging grid</li>
                                                <li>Well-designed “Back of House” with extensive support facilities and services</li>
                                                <li>We provide a world class range of suppliers to support any event</li>
                                                <li>Exceptional catering and hospitality options</li>
                                                <li>Customer-centric venue management team operating to the highest international standards</li>
                                            </ul>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <div>
                                                Event types we can proudly host
                                            </div>
                                        </ng-template>
                                        <br><br>
                                        <ul class="sawasdeeX4 font11" [ngStyle]="{'color':'#000'}">
                                            <li>Music concerts and festivals</li>
                                            <li>Family Shows</li>
                                            <li>Theatre, Musicals, Ballet & Classical Shows</li>
                                            <li>Comedy Shows</li>
                                            <li>Thrill and Fun activity events</li>
                                            <li>Sports events</li>
                                            <li>Corporate events, functions & product launches</li>
                                            <li>Cultural events</li>
                                            <li>Exhibitions and international Expos</li>
                                            <li>Community Based functions</li>
                                        </ul>
                                        <h4 class="sawasdeeX4 font11"><a [ngStyle]="{'color':'#00'}">With our Business Events Partner – warehouse Arena, we can host:</a></h4>
                                        <ul class="sawasdeeX4 font11" [ngStyle]="{'color':'#00'}">
                                            <li><a [routerLink]="'/app/meetings'">Summits, Conferences, Motivational Speakers etc..</a></li>
                                            <li><a [routerLink]="'/app/weddings'">Weddings</a></li>
                                            <li>Gala Dinners & Banquets</li>
                                        </ul>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <div>
                                                Client Support
                                            </div>
                                        </ng-template>
                                        <br><br>
                                        <div class="sawasdeeX4 font11 textBlack">
                                            <p class="sawasdeeX4 font11 textBlack">
                                                Our highly professional staff can assist with initial planning stages through to the
                                                seamless execution of your event. We are committed to providing the highest
                                                standards of service and to ensuring the best possible experience for both
                                                you and your guests.
                                            </p>
                                            <p class="sawasdeeX4 font11 textBlack">
                                                To support promoters and event organizers we have developed a list of
                                                “frequently asked questions”, Technical specification fact sheet and
                                                numerous other guides to assist every organiser in planning and
                                                organising their event in warehouse Arena.
                                            </p>
                                            <p class="sawasdeeX4 font11 textBlack">
                                                The warehouse Arena team will work with all promoters and authorities
                                                to support the event licensing process and ensure a challenge free process is at place.
                                            </p>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--POST AREA END-->
</div>
