import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from '../../../app.service';
import {Parameters} from '../../../parameters';
import {FormService} from '../../form.service';
import {ResponseService} from '../../responses/data/response.service';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
@Component({
  selector: 'app-address-preview-controls',
  templateUrl: './address-preview-controls.component.html',
  styleUrls: ['./address-preview-controls.component.scss']
})
export class AddressPreviewControlsComponent implements OnChanges, AfterViewInit {
  @ViewChild('addressElement', {static: false})  addressElement: any;
  @Input() question: any;
  @Input() position: any;
  @Input() data: any;
  @Input() status: any;
  addressValue: any;
  incomingQuestion: any;
  incomingFormSettings: any;
  incomingQuestionSettings: any;
  incomingForm: any;
  incomingResponse: any;
  outgoingRequest: any;
  incomingAddress1: any;
  incomingAddress2: any;
  incomingAddress3: any;
  incomingAddress4: any;
  incomingAddress5: any;
  incomingAddress: any;
  constructor(public service: AppService,
              private broadcastChannel: BroadcastChannelService,
              private responseService: ResponseService,
              public formService: FormService) {
    this.addressElement = ElementRef;
    this.incomingQuestion = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingQuestionSettings = new Parameters();
    this.incomingResponse = new Parameters();
    this.outgoingRequest  =  new Parameters();
    this.incomingAddress  =  new Parameters();
    this.incomingAddress1 = new Parameters();
    this.incomingAddress2 = new Parameters();
    this.incomingAddress3 = new Parameters();
    this.incomingAddress4 = new Parameters();
    this.incomingAddress5 = new Parameters();
  }


  onInputTextChange(event: any){
    this.incomingQuestion.setData(event.target.value);
  }

  onGoogleMapAutocompleteSelected(result: any) {
    setTimeout(() => {
    //  this.question.data = result.formatted_address;
        if(!this.service.empty(this.incomingFormSettings.getLocation())){
          this.incomingQuestion.setData(this.addressElement.nativeElement.value);
          this.incomingResponse.setAddress4((result.address_components.length - 4) > 0 ? result.address_components[result.address_components.length - 4].long_name : '');
          this.incomingResponse.setAddress3((result.address_components.length - 3) > 0 ? result.address_components[result.address_components.length - 3].long_name : '');
          this.incomingResponse.setAddress2((result.address_components.length - 2) > 0 ? result.address_components[result.address_components.length - 2].long_name : '');
          this.incomingResponse.setAddress1((result.address_components.length - 1) > 0 ? result.address_components[result.address_components.length - 1].long_name : '');
          this.incomingResponse.setAddress(this.addressElement.nativeElement.value);
          this.incomingResponse.setLat(result.geometry.location.lat());
          this.incomingResponse.setLng(result.geometry.location.lng());
          this.incomingResponse.setAltitude(0);
          this.incomingQuestion.setLat(this.incomingResponse.getLat());
          this.incomingQuestion.setLng(this.incomingResponse.getLng());
          this.outgoingRequest.setAttributes({});
          this.outgoingRequest.setLat(this.incomingResponse.getLat());
          this.outgoingRequest.setLng(this.incomingResponse.getLng());
          this.service.httpService('post', this.service.app.routes.home.address.get,
            this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true}, (result: any) => {
            this.incomingAddress.setAttributes(result);
            this.incomingAddress1.setAttributes(this.incomingAddress.getAddress1());
            this.incomingAddress2.setAttributes(this.incomingAddress.getAddress2());
            this.incomingAddress3.setAttributes(this.incomingAddress.getAddress3());
            this.incomingAddress4.setAttributes(this.incomingAddress.getAddress4());
            this.incomingResponse.setAddress1(this.incomingAddress1.getPlaceId());
            this.incomingResponse.setAddress2(this.incomingAddress2.getPlaceId());
            this.incomingResponse.setAddress3(this.incomingAddress3.getPlaceId());
            this.incomingResponse.setAddress4(this.incomingAddress4.getPlaceId());
            this.incomingResponse.setAddress(this.incomingAddress.getAddress());
            this.broadcastChannel.emitNavChangeEvent({action: 'nextQuestion'});
          }, (error: any) => {
          });
        }else this.incomingQuestion.setData(this.addressElement.nativeElement.value);
    });
  }

  onLocationSelected(location: any) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.addressElement.nativeElement){
        this.addressElement.nativeElement.focus();
      }
      // this.addressValue = this.service.empty(this.question.data) ? '' : this.question.data;
      // this.addressElement.nativeElement.value = this.service.empty(this.question.data) ? '' : this.question.data;
    });
  }

  ngOnChanges(changes: any): void {
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') ? this.incomingQuestion.getResult() : {});
    this.incomingForm.setAttributes(this.formService.selected.hasOwnProperty('settings') ? this.formService.selected : this.formService.getFormById(this.formService.formSelectedId));
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingFormSettings.setLocation(this.incomingFormSettings.attributes.hasOwnProperty('location') ?
        this.incomingFormSettings.getLocation() : '');
    this.incomingResponse.setAttributes(this.responseService.selected);
    this.ngAfterViewInit();
  }

}
