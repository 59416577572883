<!--CONTACT US START-->
<section class="contact-section">
    <div class="map-box">
        <div id="map_contact_4x" class="map_canvas active">
            <agm-map
                #gm
                [latitude]="lat"
                [longitude]="lng"
                [fitBounds]="true"
                [zoom]="15"
                [disableDefaultUI]="false"
                [styles]="service.app.settings.map.styles.style1">
                <agm-marker
                        [latitude]="lat"
                        [longitude]="lng"
                        [agmFitBounds]="true"
                        [openInfoWindow]="true"
                >
                </agm-marker>
            </agm-map>
        </div>
        <div class="contact-form padd-tb">
            <div class="container">
                <div class="row">
                    <div class="col-md-9 col-sm-8">
                        <h2 class="">Get in touch</h2>
                        <form >
                            <div class="row">
                                <div class="col-md-4">
                                    <input name="name" required pattern="[a-zA-Z ]+" type="text" placeholder="Name">
                                </div>
                                <div class="col-md-4">
                                    <input name="email" required pattern="^[a-zA-Z0-9-\_.]+@[a-zA-Z0-9-\_.]+\.[a-zA-Z0-9.]{2,5}$" type="text" placeholder="Email">
                                </div>
                                <div class="col-md-4">
                                    <input name="subject" required type="text" placeholder="Subject">
                                </div>
                                <div class="col-md-12">
                                    <textarea name="comments" required cols="10" rows="10" placeholder="Comments"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <input name="comments" type="submit" value="Submit">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-3 col-sm-4">
                        <div class="address-box">
                            <address>
                                <ul class="">
                                    <li> <i class="fa fa-phone"></i> <strong>+440 875 369 200</strong> <strong>+440 875 123 697</strong> </li>
                                    <li> <i class="fa fa-envelope-o"></i> <a href="mailto:">info@jobinn.com</a> </li>
                                    <li> <i class="fa fa-globe"></i> <a href="#">www.jobinn.com</a> </li>
                                </ul>
                            </address>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--CONTACT US END-->
