<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <center>
            <app-label-create-control [question]="question"></app-label-create-control>
        </center>
        <div class="panell panel-defaultt cSlider" [ngStyle]="{'width':'100%','border-top-size':'50px','margin-top':'30px'}">
            <div class="panel-bodyy">
                {{incomingSettings.getRateOptions() | json}}
                <div>
<!--                    <mat-slider-->
<!--                            class = "tp-margin"-->
<!--                            [disabled] = "disabled"-->
<!--                            [invert] = "invert"-->
<!--                            [thumbLabel] = "thumbLabel"-->
<!--                            [(ngModel)] = "value"-->
<!--                            [vertical] = "vertical">-->
<!--                    </mat-slider>-->

                    <table [ngStyle]="{'width':'100%','background-color':'transparent','color':incomingSettings.getColor()}">
                        <tr *ngFor="let rateOption of incomingQuestion.getOptions()"
                            [ngStyle]="{'width':'100%'}">
                            <td class="sawasdee20">{{service.upperStartingCharacter(rateOption.label, true)}}</td>
                            <td [ngStyle]="{'width':'100%'}">
                                <mat-slider
                                        min="0"
                                        max="100"
                                        step="1"
                                        tickInterval="1"
                                        [thumbLabel] = "true"
                                        discrete
                                        showTickMarks
                                        [displayWith]="formatLabel"
                                        [value]='outgoingQuestionResult.getUnknown(rateOption.cntrl_num).percentage'
                                        (valueChange)="onSliderChange($event,rateOption)"
                                        [ngStyle]="{'width':'100%'}">
                                    <input matSliderThumb>
                                </mat-slider>
                            </td>
<!--                            <td>{{incomingResult.getUnknown(rateOption.cntrl_num).percentage}}</td>-->
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
