import { Component, OnInit } from '@angular/core';
import {AppService} from "../app.service";
import {HeaderComponent} from "../header/header.component";

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {

  appmeetingsUrl = '../assets/json/meetings.json';
  meetings: any = [];
  constructor(public service: AppService,
              private headerComponent: HeaderComponent) {
  }

  ngOnInit(): void {
    this.headerComponent.headerClass = 'header-2';
    this.service.httpService( 'get', this.appmeetingsUrl, {}, {}, (meetings: any) => {this.meetings = meetings;},  (error: any) => {});
  }

}
