import {Component, OnInit, Input, AfterContentInit, AfterViewInit, ViewChild, ElementRef,NgZone} from '@angular/core';
import {AppService} from '../app.service';
import * as $ from 'jquery';
import {Location} from '@angular/common';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {BroadcastChannelService} from "../broadcast-channel.service";
import {MatDialog} from "@angular/material/dialog";
import {FormService} from "../forms/form.service";
import {CampaignService} from "../campaigns/campaign.service";
import {ResponseService} from "../forms/responses/data/response.service";
import * as XLSX from 'ts-xlsx';
import readXlsxFile from 'read-excel-file';
import {Parameters} from "../parameters";
import * as moment from "moment";
import * as url from "url";
@Component({
  selector: 'app-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss']
})
export class SidebarHeaderComponent implements OnInit, AfterContentInit, AfterViewInit {
  @ViewChild('file')  fileHtmlElement: any;
  app: any;
  modules: any = [];
  file: any;
  result: any;
  arrayBuffer: any;
  incomingQuestion: any;
  reference1: any = '';
  reference2: any = '';
  outgoingRequest: any;
  url: any;
  formElement: any;
  hiddenInputElement: any;
  fileFlag: any = null;
  incomingForm: any;
  results: any = '';
  incomingSettings: any;
  constructor(private router: Router,
              private location: Location,
              public zone: NgZone,
              private activatedRoute: ActivatedRoute,
              private campaignService: CampaignService,
              private responseServce: ResponseService,
              private dialog: MatDialog,
              private formService: FormService,
              private broadcastChannelService: BroadcastChannelService,
              public service: AppService) {
    this.fileHtmlElement = ElementRef;
    this.formElement = ElementRef;
    this.hiddenInputElement = ElementRef;
    this.incomingQuestion = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingForm   = new Parameters();
    this.incomingSettings = new Parameters();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.app = this.service.app;
      this.incomingForm.setAttributes(this.formService.getFormById(this.formService.formSelectedId));
    });
  }

  getModule(module: any){
    this.results =
        this.incomingForm.getAttributes() &&
        module.title.toString().toLowerCase().includes('responses') ?
        this.incomingForm.getResponseDisplayTitle() : module.title;

    this.results =
        this.incomingForm.getAttributes() &&
        module.title.toString().toLowerCase().includes('respondents') ?
            this.incomingForm.getRespondentAlias() : this.results;
    return this.results;
  }

  onToggleChange(event: any){
       this.incomingForm.setActive(event.checked ? 1 : 0);
       this.formService.update(this.incomingForm, (result: any) => {});
  }

  formNavigation(module: any){
    this.url = module.path.toString().toLowerCase().includes('images')    ? 'images'     : this.url;
    this.url = module.path.toString().toLowerCase().includes('dashboard') ? 'dashboard'  : this.url;
    this.url = module.path.toString().toLowerCase().includes('dashboard1')? 'dashboard1' : this.url;
    this.url = module.path.toString().toLowerCase().includes('dashboard2')? 'dashboard2' : this.url;
    this.url = module.path.toString().toLowerCase().includes('dashboard3')? 'dashboard3' : this.url;
    this.url = module.path.toString().toLowerCase().includes('dashboard4')? 'dashboard4' : this.url;
    this.url = module.path.toString().toLowerCase().includes('map')       ? 'map'        : this.url;
    this.url = module.path.toString().toLowerCase().includes('outlets')   ? 'outlets'    : this.url;
    this.url = module.path.toString().toLowerCase().includes('responses') ? 'responses'  : this.url;
    this.url = module.path.toString().toLowerCase().includes('videos')    ? 'videos'     : this.url;
    this.url = module.path.toString().toLowerCase().includes('files')     ? 'files'      : this.url;
    this.url = module.path.toString().toLowerCase().includes('invites')   ? 'invites'    : this.url;
    this.url = module.path.toString().toLowerCase().includes('routes')    ? 'routes'     : this.url;
    this.url = module.path.toString().toLowerCase().includes('items')     ? 'items'      : this.url;
    this.router.navigateByUrl('/' + (this.url.toString().toLowerCase().includes('map') ? 'app2' : 'app') + '/forms/'+ this.service.encrypt(this.formService.formSelectedId) + '/' + this.url);
  }

  isSwitchOff(){ return parseFloat(this.incomingForm.attributes.hasOwnProperty('active') ? this.incomingForm.getActive() : 0) === 1;}
  
  exportQuestions(){
    setTimeout((requestValues: any) => {
      requestValues = new Parameters();
      requestValues.setAttributes({});
      requestValues.setFormId(this.formService.formSelectedId);
      this.formElement = document.createElement('form');
      this.formElement.action  = this.service.app.settings.routes.server + '/forms/xx/questions/export';
      this.formElement.method  = 'get';
      this.formElement.enctype = 'multipart/form-data';
      this.hiddenInputElement  = document.createElement('input');
      this.hiddenInputElement.name  = 'token';
      this.hiddenInputElement.type  = 'hidden';
      this.hiddenInputElement.value = this.service.getToken();
      this.formElement.appendChild(this.hiddenInputElement);
      for (const key in requestValues.attributes) {
      this.hiddenInputElement       = document.createElement('input');
      this.hiddenInputElement.name  = key;
      this.hiddenInputElement.type  = 'hidden';
      this.hiddenInputElement.value = requestValues.getAttributes()[key];
      this.formElement.appendChild(this.hiddenInputElement);
      }
      $('#form').append(this.formElement);
      this.formElement.submit();
    });
  }


  navigateUrl(module: any, status: any){
    setTimeout(() => {
      if(module.childs.length !== 0){
        if(status === 0) return;
      }else this.redirectUrl(module);
    });
  }

  redirectUrl(module: any){
    setTimeout(() => {
      if (module.title.toString().toLowerCase().includes('open') ||
          module.title.toString().toLowerCase().includes('register') ||
          module.title.toString().toLowerCase().includes('create')) {
        this.broadcastChannelService.emitNavChangeEvent('register');
        this.broadcastChannelService.emitNavChangeEvent('create');
        this.broadcastChannelService.emitNavChangeEvent('add');
      } else if (module.path.toString().toLowerCase().includes('/question')) {
        this.formService.setSelected(this.formService.getFormById(this.formService.formSelectedId));
        if(module.title.toString().toLowerCase() === 'set'){
         this.formService.openDialog({});
        }else if(module.title.toString().toLowerCase().includes('export')) {
         this.exportQuestions();
        }else if(module.title.toString().toLowerCase().includes('import')) {
          this.fileFlag = undefined;
          this.fileHtmlElement.nativeElement.click();
        }
      } else if (module.title.toString().toLowerCase().includes('share')) {
        this.formService.copyUrl();
      } else if (module.title.toString().toLowerCase().includes('set team')) {
        this.formService.setSelected(this.formService.getFormById(this.formService.formSelectedId));
        this.formService.manageRespondents(this.formService.selected);
      } else if (module.title.toString().toLowerCase().includes('settings')) {
       this.formService.openFormSetting();
      }else if (module.title.toString().toLowerCase().includes('import')){
       this.fileFlag = null;
       this.fileHtmlElement.nativeElement.click();
      }else if (module.title.toString().toLowerCase().includes('update')){
          this.fileFlag = 'update';
          this.fileHtmlElement.nativeElement.click();
      }else if (module.title.toString().toLowerCase().includes('+one')){
           this.outgoingRequest.setAttributes({});
           this.outgoingRequest.setFormId(this.incomingForm.getId());
           this.service.httpService( 'post',
            this.service.app.routes.invitations.complaints.requests,
            this.outgoingRequest.attributes, {}, (response: any) => {
            alert(56);
           }, (error: any) => {
           });
      }else if (module.title.toString().toLowerCase().includes('qr code')){
       this.formNavigation(module);
       setTimeout(()=> {
        this.broadcastChannelService.emitNavChangeEvent('qrcode');
        },this.service.timeout()*2);
      }else if (module.title.toString().toLowerCase().includes('send invitation')){
        this.formNavigation(module);
        setTimeout(()=> {this.broadcastChannelService.emitNavChangeEvent('sendInvitation');},this.service.timeout()*2);
      }else if (module.path.toString().toLowerCase().includes('forms')) {
       this.formNavigation(module);
      }else this.router.navigateByUrl(module.path);
    });
  }

  readFile(event: any){
    let files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setFormId(this.incomingForm.getId());
      this.outgoingRequest.setFile(this.service.dataURItoBlob(reader.result));
      this.url = this.fileFlag === null     ? this.service.app.routes.responses.excel.import : this.service.app.routes.questions.excel.import;
      this.url = this.fileFlag === 'update' ? this.service.app.routes.responses.excel.update : this.url;
      if(this.fileFlag === 'update'){
           this.reference1 = prompt('reference1', '');
            if (this.reference1 !== null){
                   this.reference2 = prompt('reference2', '');
                   if (this.reference2 !== null){
                     this.outgoingRequest.setReference1(this.reference1);
                     this.outgoingRequest.setReference2(this.reference2);
                   }
            }
      }
      this.service.httpService( 'post', this.url, this.outgoingRequest.attributes, {}, (response: any) => {
        setTimeout((form: any) => {
          if(this.fileFlag === null) {
            form = this.formService.getFormById(this.formService.formSelectedId);
            form  = Object.assign(form, response);
          }event.target.files = null;
        });
      }, (error: any) => {
        event.target.files = null;
      });

    };
    // const files: FileList = event.target.files;
    // readXlsxFile(files[0]).then((excelSheetRows) => {
    //   setTimeout((headers: any, outgoingQuestions: any = [], posts: any = [], worker: any = undefined) => {
    //     excelSheetRows.map((excelSheetRow: any, key: any) => {
    //       this.formService.onSelectProduct = this.formService.getFormById(this.formService.formSelectedId);
    //       this.formService.questions = this.formService.onSelectProduct.controls;
    //       this.outgoingRequest = new Parameters();
    //       this.outgoingRequest.setAttributes({});
    //       this.outgoingRequest.setStartingAt(moment(new Date()).format('HH:mm:ss'));
    //       this.outgoingRequest.setEndingAt(moment(new Date()).format('HH:mm:ss'));
    //       this.outgoingRequest.setAltitude(0);
    //       this.outgoingRequest.setDeviceUsage(this.service.getBrowserName());
    //       this.outgoingRequest.setDepartmentId(1);
    //       this.outgoingRequest.setAddress('');
    //       this.outgoingRequest.setFormId(this.formService.formSelectedId);
    //       this.outgoingRequest.setLat(0);
    //       this.outgoingRequest.setLng(0);
    //       this.outgoingRequest.setPostId(this.service.random());
    //       this.outgoingRequest.setDate(moment(new Date()).format('MM/DD/YYYY'));
    //       this.outgoingRequest.setStatus(0);
    //       if(key === 0){
    //         headers = excelSheetRow;
    //       }else{
    //         excelSheetRow.map((cellValue: any, l: any) => {
    //             if(this.formService.hasQuestionByLabel(headers[l].toString().trim())){
    //             let question: any = this.formService.getQuestionByLabel(headers[l].toString().trim());
    //             this.questionValues.setAttributes(question);
    //             this.questionValues.setData(cellValue);
    //             this.questionValues.setFormControlId(this.questionValues.attributes.control.id);
    //             this.questionValues.setCntrlNum(this.questionValues.getCntrlNum());
    //             outgoingQuestions.push(this.questionValues.attributes);
    //           }else if(headers[l].toString().trim().toLowerCase().includes('timestamp')){
    //             this.outgoingRequest.setPostId(cellValue);
    //           }
    //         });
    //         this.outgoingRequest.setControls(outgoingQuestions);
    //         posts.push(this.outgoingRequest.attributes);
    //         // if (typeof Worker !== 'undefined') {
    //         //   worker = new Worker('../../assets/js/workers.js');
    //         //   worker.onmessage = ((evt: any) => {
    //         //
    //         //   });
    //         //   worker.postMessage('null');
    //         // }
    //       }
    //     });
    //     this.post(posts.splice(0, 20), () => {
    //       alert(99);
    //     });
    //   });
    // });
  }

  post(postValues: any, callback: any){
    console.log(postValues[postValues.length - 1]);
    this.service.httpService( 'post', '/public/form-posts', {posts: JSON.stringify(postValues)}, {}, (response: any) => {
      callback('successfull');
    }, (error: any) => {});
  }

  timeout(){return 3010;}

  ngAfterContentInit(): void {
  }

  getUrl(){return window.location.href; }

  ngAfterViewInit(): void {
    $.getScript( '../assets/js/sidebar.js', ( data, textStatus, jqxhr ) => {});
  }



}
