import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Parameters} from '../../parameters';
import {FormService} from '../form.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {FlatTreeControl, NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from "@angular/material/tree";

interface QuestionNode {
  name: string;
  children?: QuestionNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-treeview-form',
  templateUrl: './treeview-form.component.html',
  styleUrls: ['./treeview-form.component.scss']
})
export class TreeviewFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() questions: any;
  treedata: any = [];
  subscription: any;

  private _transformer = (node: QuestionNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(public formService: FormService,
              private broadcastChannel: BroadcastChannelService) {
  }

  ngOnInit(): void {
    this.dataSource.data = this.treedata;
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
          }else if(item.toString().includes('treenodes') === true){
            this.nodes();
          }
        });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;


  ngAfterViewInit(): void {}

  nodes(){
    this.treedata = [];
    this.formService.questions.map((question: any) => {
      if(question.hasOwnProperty('options')){
        question.options.map((opt: any) => {
          if(opt.label.toString().includes('null')){
            this.formService.showQuestions(opt);
          }else this.formService.hideOptionalQuestions(opt);
        });
      }
    });
    this.formService.loadHiddenQuestions();
    this.formService.questions.map((question: any) => { this.appendChildrenNode(question, 0); });
    this.dataSource.data = this.treedata;
    return this.dataSource;
  }


  appendChildrenNode(question: any, flag: any){
    const incomingQuestion = new Parameters();
    incomingQuestion.setAttributes(question);
    incomingQuestion.setName(incomingQuestion.getLabel());
    incomingQuestion.setChildren([]);
    if(parseFloat(incomingQuestion.getVisible().toString()) === 1){
      this.treedata.push(incomingQuestion.getAttributes());
    }
    if(incomingQuestion.attributes.hasOwnProperty('options')){
      incomingQuestion.getOptions().map((optional: any) => {
        const incomingOptional = new Parameters();
        incomingOptional.setAttributes(optional);
        incomingOptional.setName(incomingOptional.getLabel());
        incomingOptional.setChildren([]);
        incomingOptional.setNumber(incomingQuestion.getCntrlNum());
      //if(incomingQuestion.getControl().id === 5){
          incomingQuestion.getChildren().push(incomingOptional.getAttributes());
          if(incomingOptional.getAttributes().hasOwnProperty('questions')){
            incomingOptional.getQuestions().map((q: any) => {
              const incomingQ = new Parameters();
              incomingQ.setAttributes(q);
              incomingQ.setChildren([]);
              incomingQ.setName(incomingQ.getLabel());
              incomingOptional.getChildren().push(incomingQ.getAttributes());
              const finalQ = this.formService.questions.filter((questional: any) => {
              return questional.cntrl_num.toString().includes(incomingQ.getCntrlNum()); })[0];
              incomingQ.attributes = Object.assign(incomingQ.attributes, finalQ);
              this.appendChildrenNode(incomingQ.attributes, 1);
            });
          }
      //}
      });
    }
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
}
