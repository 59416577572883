import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {MethodsService} from './methods.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, retry, sample} from 'rxjs/internal/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {fromEvent, merge, of, Subscription, throwError} from 'rxjs';
import {fromJSON} from 'tough-cookie';
import {Parameters} from './parameters';
import {NotifierService} from 'angular-notifier';
import {Options } from 'highcharts';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import {orderBy } from 'lodash';
import { map } from 'rxjs/operators';
// import * as Typewriter from 't-writer.js';

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy{
    private getRestaurantsUrl: any = '../assets/json/restaurants.json';
 // private GlobalMethods = new GlobalMethods();
    public angularEditorConfig: AngularEditorConfig = {};
    public token: any = null;
    public searchText: any = '';
    public routeName: any = null;
    public progressShow: any = false;
    public isWaitScreen: any = false;
    public app: any = {form: {report: null, data: null}};
    public report: any = {time: 'today', type: null, display: 'dashboard', target: 'all',
      startingAt: moment(new Date()).format('MM/DD/YYYY'),
      endingAt: moment(new Date()).format('MM/DD/YYYY')};
    public ngxLoadingBarColor: any = 'orange';
    networkStatus: any = false;
    networkStatus$: Subscription = Subscription.EMPTY;
    public navigatedUrl: any = null;
    private httpHeaders: any = null;
    private incomingResult: any;
    private httpParams: any = null;
    private formData: any = new FormData();
    private ciphertext: any;
    private fieldHidden: any;
    public header: any = '';
    private conversionEncryptOutput: any;
    private conversionDecryptOutput: any;
    outgoingRegionsControl: any;
    outgoingOutletsControl: any;
    outgoingDistrictsControl: any;
    outgoingTeamsControl: any;
    outgoingUsersControl: any;
    addOutlet: any = null;
    outgoingRoutesControl: any;
    form: any;
    modules: any = [{path: '/app/surveys' , title: 'Surveys'}];
    separator: any = 'mime*!67l';
    data: any = [];
    incomingItem: any;
    progress: any = false;
    responses: any = [];
    startingAt: any;
    endingAt: any;
    incomingAddressComponent: any;
    module: any;
    status: any = -1;
    result: any;
    logo: any = 'null';
    separators: any;
    originValue: any = '';
    isAudioPlayed = false;
    encrptUrl: any;
    comma: any = true;
    interval: any = null;
    user: any = {};
    htmlContents: any;
    spinner: any = false;
    outgoingRequest: any;
    render: any = true;
    waitStatus: any = null;
    subHeader: any = '';
    response: any;
    outgoingResult: any;
    incomingAddress1: any;
    incomingAddress2: any;
    incomingAddress3: any;
    incomingAddress4: any;
    incomingAddress5: any;
    incomingGeometry: any;
    incomingLocation: any;
    geomentry: any;
    epochs: any = [
        ['year', 31536000],
        ['month', 2592000],
        ['day', 86400],
        ['hour', 3600],
        ['minute', 60],
        ['second', 1]
    ];
    markers: any = {overpassapi: {railway: {data: {features: [], waypoints: []}, status: true}}};
    constructor(private httpClient: HttpClient,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private notifier: NotifierService,
                private activatedRouter: ActivatedRoute,
                private methods: MethodsService){
        const params: Parameters = new Parameters();
        this.outgoingRegionsControl = new Parameters();
        this.outgoingResult = new Parameters();
        this.incomingAddress1 = new Parameters();
        this.incomingAddress2 = new Parameters();
        this.incomingAddress3 = new Parameters();
        this.incomingAddress4 = new Parameters();
        this.incomingAddress5 = new Parameters();
        this.incomingLocation = new Parameters();
        this.incomingGeometry = new Parameters();
        this.incomingAddressComponent = new Parameters();
        this.incomingResult = new Parameters();
        this.outgoingDistrictsControl = new Parameters();
        this.outgoingOutletsControl = new Parameters();
        this.outgoingTeamsControl = new Parameters();
        this.outgoingUsersControl = new Parameters();
        this.outgoingRoutesControl = new Parameters();
        this.incomingItem = new Parameters();
        this.outgoingRequest = new Parameters();
        params.setAttributes({});
        params.setLastIndexOf(-1);
        params.setType(null);
        params.setTime(null);
        this.app.form.report = params.getAttributes();
        this.app.form.modules = null;
        this.app.form.selected = null;
        this.outgoingRegionsControl.setAttributes({});
        this.outgoingDistrictsControl.setAttributes({});
        this.outgoingTeamsControl.setAttributes({});
        this.outgoingUsersControl.setAttributes({});
        this.outgoingRoutesControl.setAttributes({});
        this.outgoingOutletsControl.setAttributes({});
    }

    ngOnDestroy(): void {
        this.networkStatus$.unsubscribe();
    }

    getDrivingDistanceBetweenTwoLatLong(origin, destination) {
         return new Observable(subscriber => {
              let service = new google.maps.DistanceMatrixService();
              service.getDistanceMatrix({
                  origins: [new google.maps.LatLng(parseFloat(origin.lat), parseFloat(origin.long))],
                  destinations: [new google.maps.LatLng(parseFloat(destination.lat), parseFloat(destination.long))],
                  travelMode: google.maps.TravelMode.WALKING
                }, (response, status) => {
                  if (status !== google.maps.DistanceMatrixStatus.OK) {
//                  console.log('Error:', status);
                    subscriber.error({error: status, status: status});
                  } else {
//                     console.log(response);
                       try {
                          let valueInMeters = response.rows[0].elements[0].distance.value;
                          let valueInKms = valueInMeters / 1000;
                          subscriber.next(valueInKms);
                          subscriber.complete();
                       }catch(error) {
                        subscriber.error({error: error, status: status});
                       }
                  }
                });
         });
    }




    checkNetworkStatus(callback: any) {
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        )
            .pipe(map(() => navigator.onLine))
            .subscribe(status => {
                this.networkStatus = status;
                callback(this.networkStatus);
            });
    }

    getAddressByLatAndLng(results){
                this.outgoingResult.setAttributes({});
                this.outgoingResult.setAddress1('');
                this.outgoingResult.setAddress2('');
                this.outgoingResult.setAddress3('');
                this.outgoingResult.setAddress4('');
                results.map((result)=>{
                     this.incomingResult.setAttributes(result);
                     if(this.incomingResult.getAttributes().hasOwnProperty('address_components')){
                        this.incomingResult.getAddressComponents().map((addressComponent)=>{
                          this.incomingAddressComponent.setAttributes(addressComponent);
                          if(this.incomingAddressComponent.getAttributes().hasOwnProperty('types')){
                           this.incomingAddressComponent.getTypes().map((type)=>{
                            if(type.toString().toLowerCase().includes('country')){
                             if(this.incomingAddressComponent.getAttributes().hasOwnProperty('long_name')){
                              this.incomingAddress1.setAttributes({});
                              this.incomingAddress1.setLongName(this.incomingAddressComponent.getLongName());
                              this.incomingAddress1.setPlaceId(this.incomingResult.getPlaceId());
                              this.geomentry = this.incomingResult.getGeometry();
                              this.incomingGeometry.setAttributes(this.geomentry);
                              this.incomingLocation.setAttributes(this.incomingGeometry.getLocation());
                              this.incomingAddress1.setLat(this.incomingLocation.getLat());
                              this.incomingAddress1.setLng(this.incomingLocation.getLng());
                              this.outgoingResult.setAddress1(this.incomingAddress1.getAttributes());
                             }
                            }
                            if(type.toString().toLowerCase().includes('administrative_area_level_1')){
                             if(this.incomingAddressComponent.getAttributes().hasOwnProperty('long_name')){
                               this.incomingAddress2.setAttributes({});
                               this.incomingAddress2.setLongName(this.incomingAddressComponent.getLongName());
                               this.incomingAddress2.setPlaceId(this.incomingResult.getPlaceId());
                               this.geomentry = this.incomingResult.getGeometry();
                               this.incomingGeometry.setAttributes(this.geomentry);
                               this.incomingLocation.setAttributes(this.incomingGeometry.getLocation());
                               this.incomingAddress2.setLat(this.incomingLocation.getLat());
                               this.incomingAddress2.setLng(this.incomingLocation.getLng());
                               this.outgoingResult.setAddress2(this.incomingAddress2.getAttributes());
                             }
                            }
                            if(type.toString().toLowerCase().includes('administrative_area_level_2')){
                             if(this.incomingAddressComponent.getAttributes().hasOwnProperty('long_name')){
                                this.incomingAddress3.setAttributes({});
                                this.incomingAddress3.setLongName(this.incomingAddressComponent.getLongName());
                                this.incomingAddress3.setPlaceId(this.incomingResult.getPlaceId());
                                this.geomentry = this.incomingResult.getGeometry();
                                this.incomingGeometry.setAttributes(this.geomentry);
                                this.incomingLocation.setAttributes(this.incomingGeometry.getLocation());
                                this.incomingAddress3.setLat(this.incomingLocation.getLat());
                                this.incomingAddress3.setLng(this.incomingLocation.getLng());
                                this.outgoingResult.setAddress3(this.incomingAddress3.getAttributes());
                             }
                            }
                            if(type.toString().toLowerCase().includes('administrative_area_level_3')){
                             if(this.incomingAddressComponent.getAttributes().hasOwnProperty('long_name')){
                                 this.incomingAddress4.setAttributes({});
                                 this.incomingAddress4.setLongName(this.incomingAddressComponent.getLongName());
                                 this.incomingAddress4.setPlaceId(this.incomingResult.getPlaceId());
                                 this.geomentry = this.incomingResult.getGeometry();
                                 this.incomingGeometry.setAttributes(this.geomentry);
                                 this.incomingLocation.setAttributes(this.incomingGeometry.getLocation());
                                 this.incomingAddress4.setLat(this.incomingLocation.getLat());
                                 this.incomingAddress4.setLng(this.incomingLocation.getLng());
                                 this.outgoingResult.setAddress4(this.incomingAddress4.getAttributes());
                              }
                            }
                            if(type.toString().toLowerCase().includes('administrative_area_level_4')){
                             if(this.incomingAddressComponent.getAttributes().hasOwnProperty('long_name')){
                                  this.incomingAddress5.setAttributes({});
                                  this.incomingAddress5.setLongName(this.incomingAddressComponent.getLongName());
                                  this.incomingAddress5.setPlaceId(this.incomingResult.getPlaceId());
                                  this.geomentry = this.incomingResult.getGeometry();
                                  this.incomingGeometry.setAttributes(this.geomentry);
                                  this.incomingLocation.setAttributes(this.incomingGeometry.getLocation());
                                  this.incomingAddress5.setLat(this.incomingLocation.getLat());
                                  this.incomingAddress5.setLng(this.incomingLocation.getLng());
                                  this.outgoingResult.setAddress5(this.incomingAddress5.getAttributes());
                             }
                            }
//                             if(strcmp(strtolower($type),'administrative_area_level_5') === 0){
//                              if($incomingAddressComponent->hasAttribute('long_name')){
//                                   $incomingAddress5 = new SpGenericDto();
//                                   $incomingAddress5->setLongName($incomingAddressComponent->getLongName());
//                                   $incomingAddress5->setPlaceId($incomingResult->getPlaceId());
//                                   $geomentry = $incomingResult->getGeometry();
//                                   $incomingGeometry = new SpGenericDto();
//                                   $incomingGeometry->setAttributes(json_decode(json_encode(collect($geomentry)),true));
//                                   $incomingLocation = new SpGenericDto();
//                                   $incomingLocation->setAttributes(json_decode(json_encode(collect($incomingGeometry->getLocation())),true));
//                                   $incomingAddress5->setLat($incomingLocation->getLat());
//                                   $incomingAddress5->setLng($incomingLocation->getLng());
//                                   $outgoingResult->setAddress5($incomingAddress5->getAttributes());
//                              }
//                             }
                            if(type.toString().toLowerCase().includes('premise') ||
                             type.toString().toLowerCase().includes('street_address') ||
                             type.toString().toLowerCase().includes('route')){
                             if(this.incomingResult.getAttributes().hasOwnProperty('formatted_address'))
                              this.outgoingResult.setAddress(this.incomingResult.getFormattedAddress());
                            }
                           });
                          }
                        });

                     }
                     return this.incomingResult.getAttributes();
                });
//                 $this->country()->addCountryByAddress($outgoingResult->getAttributes());
                return this.outgoingResult.getAttributes();
    }

    getDistanceBetweenTwoLatLong(travelMode: any,incomingOrigin,incomingDestination){
         var origin1 = new google.maps.LatLng(parseFloat(incomingOrigin.getUnknown('lat')), parseFloat(incomingOrigin.getUnknown('lng')));
         var origin2 = incomingOrigin.getUnknown('address');
         var destinationA = incomingDestination.getUnknown('address');
         var destinationB = new google.maps.LatLng(parseFloat(incomingDestination.getUnknown('lat')), parseFloat(incomingDestination.getUnknown('lng')));
         var service = new google.maps.DistanceMatrixService();
         service.getDistanceMatrix({
             origins: [origin1, origin2],
             destinations: [destinationA, destinationB],
             travelMode: google.maps.TravelMode.WALKING,
             unitSystem:google.maps.UnitSystem.IMPERIAL,
             avoidHighways: false,
             avoidTolls: false,
             }, (response, status) =>{
                 if (status === 'OK') {
                   var origins = response.originAddresses;
                   var destinations = response.destinationAddresses;
                   for (var i = 0; i < origins.length; i++) {
                     var results = response.rows[i].elements;
                     for (var j = 0; j < results.length; j++) {
                       var element = results[j];
                       var distance = element.distance.text;
                       var duration = element.duration.text;
                       var from = origins[i];
                       var to = destinations[j];
                       alert(duration);
                     }
                   }
                 }
         });
   }

    getClientImgPart(){return this.app.settings.routes.server.toString().replace('/index.php','').replace('api','images/clients/');}

    encrypt2(ciphertext: any){
        let bytes: any = CryptoJS.AES.encrypt(ciphertext.toString(), this.app.settings.encryptKey);
        bytes = encodeURIComponent(bytes.toString());
        return bytes;
    }

    clientImgUrl(){ return 'https://patten.co.tz/server/uza/public/images/clients/';}
    extract(incomingReport: any){
        this.form = document.createElement('form');
        this.form.action  = this.app.settings.routes.server + '/reports';
        this.form.method  = 'get';
        this.form.enctype = 'multipart/form-data';
        this.fieldHidden  = document.createElement('input');
        this.fieldHidden.name  = 'token';
        this.fieldHidden.type  = 'hidden';
        this.fieldHidden.value = this.getToken();
        this.form.appendChild(this.fieldHidden);
        for (const key in incomingReport.attributes) {
            this.fieldHidden       = document.createElement('input');
            this.fieldHidden.name  = key;
            this.fieldHidden.type  = 'hidden';
            this.fieldHidden.value = incomingReport.getAttributes()[key];
            this.form.appendChild(this.fieldHidden);
        }
        $('#form').append(this.form);
        this.form.submit();
    }


    logout(){
        localStorage.setItem(this.app.settings.tokenName, '');
        this.setToken('');
        this.router.navigateByUrl('login');
    }

    getLogo(){ return this.logo;}
    setLogo(logo: any){return this.logo = logo;}

    easyPieChart(progress: any){
        return `
                <span
                   class="chart"
                   data-percent="` +
                     parseFloat(progress.toString()) + `">
                   <span class="percent">` + parseFloat(progress.toString()) + `</span>
                </span>
                `;
    }

    getAttributesWithoutobject(item: any){
        this.incomingItem.setAttributes({});
        for(var l in item){
          if(typeof item[l] !== 'object')
              this.incomingItem.attributes[l] = item[l];
        }return this.incomingItem.getAttributes();
    }

    notValidCard(){
         const url: any = '../assets/audio/notValidCard.wav';
         const sound: any = new Audio(url);
         sound.volume = 1;
         sound.play();
    }

    exportCustomReport(JSONData: any, ReportTitle: any, ShowLabel: any){
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

        var CSV = '';
        //Set Report title in first row or line
        // CSV += ReportTitle + '\r\n\n';
        //This condition will generate the Label/Header
        if (ShowLabel) {
            var row = '';
            //This loop will extract the label from 1st index of on array
            for (var index in arrData[0]) {
                //Now convert each value to string and comma-seprated
                row += index + ',';
            }
            row = row.slice(0, -1);
            //append Label row with line break
            CSV += row + '\r\n';
        }
        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = '';
            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }

            row.slice(0, row.length - 1);
            //add a line break after each row
            CSV += row + '\r\n';
        }

        if (CSV === '') {
            alert('Invalid data');
            return;
        }

        //Generate a file name
        var fileName = "data-";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g,'_');
        //Initialize file format you want csv or xls
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension
        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;
        //set the visibility hidden so it will not effect on your web-layout
        // link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    cardScannedWithSomeoneelse(){
             const url: any = '../assets/audio/scanedWithSomeoneelse.wav';
             const sound: any = new Audio(url);
             sound.volume = 1;
             sound.play();
    }

    easyPieChartByPercentageAndValue(percentage: any, value: any){
        return `
                <span
                   class="chart"
                   data-percent="` +
            parseFloat(percentage.toString()) + `">
                   <span class="percent">` + value.toString() + `</span>
                </span>
                `;
    }


    timeAgo(input_date: any){
        input_date = input_date + ' UTC';
        // convert times in milliseconds
        const input_time_in_ms = new Date(input_date).getTime();
        const current_time_in_ms = new Date().getTime();
        const elapsed_time = current_time_in_ms - input_time_in_ms;

        function numberEnding(number) {
            return (number > 1) ? 's' : '';
        }

        let temp = Math.floor(elapsed_time / 1000);
        const years = Math.floor(temp / 31536000);
        if (years) {
            return years + ' year' + numberEnding(years);
        }
        // TODO: Months! Maybe weeks?
        const days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            return days + ' day' + numberEnding(days);
        }
        const hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return hours + ' hour' + numberEnding(hours);
        }
        const minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return minutes + ' minute' + numberEnding(minutes);
        }
        const seconds = temp % 60;
        if (seconds) {
            return seconds + ' second' + numberEnding(seconds);
        }
        return 'less than a second'; // 'just now' //or other string you like;
    }

    repeatScan(){
         const url: any = '../assets/audio/repeatScan';
         const sound: any = new Audio(url);
         sound.volume = 1;
         sound.play();
    }

//   audioEncoding: 'LINEAR16',
     voiceSpeek(inputText: any,callback: any){
               var params: any = {
                   input: {
                       text: inputText,
                   },
                   audioConfig: {
                       audioEncoding: 'MP3',
                       pitch: 0,
                       speakingRate: 0.9
                   },
                   voice: {
                       languageCode: 'en-US',
                       name :  "en-US-News-L" ,
                       ssmlGender: "FEMALE"
                   }
               };

               params = JSON.stringify(params);
               var request = new XMLHttpRequest();
               request.open('POST', 'https://content-texttospeech.googleapis.com/v1/text:synthesize?alt=json&key=AIzaSyBvl3YCf1HCaokPiYgcuhdWojTxI1490es',true);
               request.setRequestHeader('Content-type', 'application/json')
               request.responseType = 'json';
               request.onload = () =>{
                   this.incomingResult.setAttributes(request.response);
                   this.incomingResult.setUnknown('audioContent', 'data:audio/mpeg;base64,' + this.incomingResult.getUnknown('audioContent'));
                   let blob: any = this.dataURItoBlob(this.incomingResult.getUnknown('audioContent'));
                   const arrayBuffer: any = Uint8Array.from(this.incomingResult.getUnknown('audioContent')).buffer;
                   this.outgoingRequest.setAttributes({});
                       this.outgoingRequest.setSrc(null);
                       this.outgoingRequest.setFlag(0);
                       this.outgoingRequest.setCaption('');
                       this.outgoingRequest.setOrderBy('');
                       this.outgoingRequest.setStatus(1);
                       this.outgoingRequest.setUrl('');
                       this.outgoingRequest.setPath('');
                       this.outgoingRequest.setAvatar(blob);
                       this.httpService('post', this.app.routes.texttospeech.writeFile,
                         this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'},(result: any) => {
                            this.incomingResult.setAttributes(result);
                            callback(this.incomingResult.getSrc());
                         }, (error: any) => {
                          callback(null);
                         });

               }
               request.send(params);
     }


     setCrypt(ciphertext: any, callback: any){
              try{
                 var result: any = this.encrypt(ciphertext);
                 if(this.empty(this.decrypt(result)))
                  this.setCrypt(ciphertext,callback);
                 else callback(result);
              }catch(e){ this.setCrypt(ciphertext,callback);
              }
    }



    getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
          case agent.indexOf('edge') > -1:
            return 'edge';
          case agent.indexOf('opr') > -1 && !!(window as any).opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other Browser';
        }
    }

    renderOptions = {
        suppressMarkers: true
    };

    getMarkers(){
        setTimeout(() => {
            this.httpService( 'get', '../assets/json/overpassapi_railway.json', {}, {ignoreLoadingBar: 'true'},
                (response: any) => {
                    this.markers.overpassapi.railway.data.features = response.features;
                    // this.markers.overpassapi.railway.data.features = response.features.map((feature: any) => {
                    //     feature = Object.assign(feature, {lat: parseFloat(feature.geometry.coordinates[1]), lng: parseFloat(feature.geometry.coordinates[0])});
                    //     feature = Object.assign(feature, {location: {lat: parseFloat(feature.geometry.coordinates[1]), lng: parseFloat(feature.geometry.coordinates[0])}});
                    //     return feature;
                    // });
                    // this.markers.overpassapi.railway.data.features.map((feature: any) => {
                    //     this.markers.overpassapi.railway.data.waypoints.push({location: feature.location});
                    // });
                },  (error: any) => {
                });
        }, this.timeout());
    }


     timeSince(input) {
         const date = (input instanceof Date) ? input : new Date(input);
         // @ts-ignore
         const formatter = new Intl.RelativeTimeFormat('en');
         const ranges = {
             years: 3600 * 24 * 365,
             months: 3600 * 24 * 30,
             weeks: 3600 * 24 * 7,
             days: 3600 * 24,
             hours: 3600,
             minutes: 60,
             seconds: 1
         };
         const secondsElapsed = (date.getTime() - Date.now()) / 1000;
         for (let key in ranges) {
             if (ranges[key] < Math.abs(secondsElapsed)) {
                 const delta = secondsElapsed / ranges[key];
                 return formatter.format(Math.round(delta), key);
             }
         }
    }


    timeout(){return 1112; }
    decrypt(ciphertext: any){
        ciphertext = ciphertext.toString().replace(/mime2F/g, '/');
        let bytes: any = CryptoJS.AES.decrypt(ciphertext.toString(),this.app.settings.encryptKey);
        bytes = decodeURIComponent(bytes.toString(CryptoJS.enc.Utf8));
        return bytes;
    }


    hasToken(){
        return !(localStorage.getItem(this.app.settings.tokenName) === null ||
            this.empty(window.localStorage.getItem(this.app.settings.tokenName)));
    }

    playSound(){
        setTimeout(() => {
            const url: any = '../assets/audio/never.mp3';
            const sound: any = new Audio(url);
            sound.volume = 0.01;
            sound.play();
            this.isAudioPlayed = true;
        });
    }

    validateEmail(email) {
        const validator = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return validator.test(email);
    }

    random(){
      return (Math.random() + '' + window.crypto.getRandomValues(new Uint32Array(1))[0].toString(23)).toString().substr(2, 24);
      // return Date.now() + '.' +new Date().getMilliseconds();
    }

    encrypt(ciphertext: any){
        let bytes: any = CryptoJS.AES.encrypt(ciphertext.toString(), this.app.settings.encryptKey);
        bytes = encodeURIComponent(bytes.toString()).replace(/%2F/g, 'mime2F');
        return bytes;
    }

    dataURItoBlob(dataURI: any){
        const binary = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const array = new Array();
        for (let i = 0; i < binary.length; i++) { array.push(binary.charCodeAt(i)); }
        return new Blob([new Uint8Array(array)], { type: mimeString });
    }



  Arrayfrom(source: any){ return source.map((ob: any) => ob); }

  only(source: any){
      let result: any = {};
      for (const key in source) {
        if (source[key] instanceof Array){}
        else if (source[key] instanceof Object){}
        else{
          const element: any = {};
          element[key]  = source[key];
          result = Object.assign(result, element);
        }
      }
      return result;
  }

    getValuesByDataAndLable(data: any, label: any){
        return data.map((l: any) => parseFloat(l[label]));
    }





    getNewsApiKey(){
        return '41e139388fff4d498a2c69181b638107';
    }

    // getRestaurants(http:HttpClient){
    //   this.GlobalMethods.httpService(http,this.getRestaurantsUrl,'get',{},{},
    //       (response: any) => {
    //   },(error: any) => {
    //   });
    // }
    groupBy(xs: any, f: any) { return xs.reduce((r: any, v: any, i: any, a: any, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {}); }
    sparkline( type: any, data: Array<number>, legends: Array<string>){
        const  options: Options = {
            chart: {
                backgroundColor: 'transparent',
                borderWidth: 0,
                type,
                margin: [2, 0, 2, 0],
                width: 120,
                height: 20,
                style: {
                    overflow: 'visible'
                }
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                startOnTick: false,
                endOnTick: false,
                tickPositions: []
            },
            yAxis: {
                endOnTick: false,
                startOnTick: false,
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                tickPositions: [0]
            },
            legend: {
                enabled: false
            },
            tooltip: {
                hideDelay: 0,
                outside: true,
                shared: true,
                formatter(){
                    return '<span style=font-size: "10px"><center>' + legends[this.x] + '</center></span><br/><span><center>' + this.y + '</center></span>';
                }

            },
            plotOptions: {
                series: {
                    animation: false,
                    lineWidth: 1,
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    marker: {
                        radius: 1,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    }
                },
                column: {
                    negativeColor: '#910000',
                    borderColor: 'silver'
                }
            },
            series: [
                {
                    name: '',
                    type,
                    data
                }
            ]
        };
        return options;

    }

    orderBy(value: any[], order: any, column: any){
        return orderBy(value, column, order);
    }

    playSoundByVolume(volume: any){
        const url: any = '../assets/audio/never.mp3';
        const sound: any = new Audio(url);
        sound.volume = parseFloat(volume);
        sound.play();
    }

    jqx(callback: any){
        setTimeout((sampleData: any[],
                    xAxis: any,
                    valueAxis: any,
                    seriesGroups: any[],
                    piechartlegendLayout: any,
                    piechartpadding: any,
                    piechartseriesGroups: any[],
                    padding: any, titlePadding: any) => {

            padding = { left: 15, top: 5, right: 15, bottom: 5 };
            piechartpadding = { left: 5, top: 5, right: 5, bottom: 5 };

            piechartlegendLayout = { left: 700, top: 160, width: 300, height: 200, flow: 'vertical' };

            titlePadding = { left: 0, top: 0, right: 0, bottom: 10 };

            xAxis = {
                    dataField: 'a',
                    logarithmicScale: true,
                    logarithmicScaleBase: 2
                };
            valueAxis =
                {
                    logarithmicScale: false,
                    logarithmicScaleBase: 2,
                    title: { text: 'Value' },
                    labels: {
                        horizontalAlignment: 'right'
                    }
                };
            seriesGroups =
                [
                    {
                        type: 'line',
                        series: [
                            { dataField: 'a', displayText: 'A', symbolType: 'square', symbolSize: 6, dashStyle: '4,4', lineWidth: 1 },
                            { dataField: 'b', displayText: 'B', symbolType: 'circle', symbolSize: 6, lineWidth: 1 }
                        ]
                    }
                ];

            piechartseriesGroups =
                [
                    {
                        type: 'pie',
                        showLabels: true,
                        series:
                            [
                                {
                                    dataField: 'Share',
                                    displayText: 'Browser',
                                    labelRadius: 170,
                                    initialAngle: 15,
                                    radius: 145,
                                    centerOffset: 0,
                                    formatFunction: (value: any) => {
                                        if (isNaN(value)) {
                                            return value;
                                        }
                                        return parseFloat(value) + '%';
                                    },
                                }
                            ]
                    }
                ];

            callback({
                piechartseriesGroups,
                piechartlegendLayout,
                piechartpadding,
                titlePadding
            });
        });
    }

    percentageOf(number: any, over: any){
        const results = parseFloat(over) === 0 ? 0 : parseInt((parseFloat(number) / parseFloat(over) * 100).toString()); return results;
    }

    slice(s: any, start: any, end: any){return s.slice(start, end); }
    handlePiechart(){
        const results =  {
            seriesGroups : [
                {
                    type: 'pie',
                    showLabels: true,
                    series:
                        [
                            {
                                dataField: 'percent',
                                displayText: 'category',
                                labelRadius: 170,
                                initialAngle: 15,
                                radius: 145,
                                centerOffset: 0,
                                formatFunction: (value: any) => {
                                    if (isNaN(value)) {
                                        return value;
                                    }
                                    return parseFloat(value) + '%';
                                },
                            }
                        ]
                }
            ],
            legendLayout : { left: 700, top: 160, width: 300, height: 200, flow: 'vertical' },
            dataAdapter  : null,
            titlePadding : { left: 0, top: 0, right: 0, bottom: 10 },
            padding      : { left: 5, top: 5, right: 5, bottom: 5 },
        };

        return results;
    }

    getWidth(){ return window.innerWidth;}
    getHeight(){ return window.innerHeight;}

    getlegendsByDataAndlabel(data, label){return data.map((l: any) => l[label]); }
    limitTo(str, l){return !this.empty(str) && str.length > l ? str.substring(0, l) + '..' : str; }


    upperStartingCharacter(text: any, status: any){
         text = this.removeHtmlTags(text).toString().toLowerCase().trim();
         this.result = text;
         if(status){
           if(!this.empty(text)){
             if(text.toString().length !== 1){
              let starting: any = text.toString().substring(0, 1);
              let lasting: any = text.toString().substring(1, text.toString().length);
             if(!starting.toString().toUpperCase().includes('M') &&
             !starting.toString().toUpperCase().includes('N1') &&
                     !starting.toString().toUpperCase().includes('P') &&
               !starting.toString().toUpperCase().includes('S1') &&
                     !starting.toString().toUpperCase().includes('Q1') &&
                     !starting.toString().toUpperCase().includes('R') &&
               !starting.toString().toUpperCase().includes('A1') &&
              !starting.toString().toUpperCase().includes('O'))
// //              if(starting.toString().toUpperCase().includes('A') ||
// //                 starting.toString().toUpperCase().includes('D') ||
// //                 starting.toString().toUpperCase().includes('L') ||
// //                 starting.toString().toUpperCase().includes('G'))
              this.result = starting.toString().toUpperCase() + lasting;
             }
           }
         }
         return this.result;
   }

   getScrollY(){ return  window.scrollY;}
   decrypt2(ciphertext: any){
           ciphertext = ciphertext.toString();
           let bytes: any = CryptoJS.AES.decrypt('' + ciphertext, this.app.settings.encryptKey);
           bytes = decodeURIComponent(bytes.toString(CryptoJS.enc.Utf8));
           return bytes;
   }


   playSoundByUrl(url: any, callback: any){
        const sound: any = new Audio(url);
        sound.play();
        sound.onended = () =>{
            callback(null);
        }
   }


    playSoundWait(callback: any){
           const sound: any = new Audio('../assets/audio/wait.wav');
           sound.play();
           sound.onended = () =>{
               callback(null);
           }
    }




    handleLineChartJQx(){
        const results = {
            sampleData: [],
            padding:  { left: 15, top: 5, right: 15, bottom: 5 },
            titlePadding: { left: 0, top: 0, right: 0, bottom: 10 },
            xAxis: {
                    dataField: 'a',
                    logarithmicScale: true,
                    logarithmicScaleBase: 2
                },
            valueAxis: {
                    logarithmicScale: false,
                    logarithmicScaleBase: 2,
                    title: { text: 'Value' },
                    labels: {
                        horizontalAlignment: 'right'
                    }
                },
            seriesGroups: [
                    {
                        type: 'line',
                        series: [
                            { dataField: 'a', displayText: 'AA', symbolType: 'square', symbolSize: 6, dashStyle: '4,4', lineWidth: 1 },
                            { dataField: 'b', displayText: 'BB', symbolType: 'circle', symbolSize: 6, lineWidth: 1 }
                        ]
                    }
                ]
        };
        return results;
    }

    startTyper(text: any, element: any){
//        var app: any = document.getElementById(element);
//         const openTyper = new Typewriter(app, {
//           loop: true,
//           typeColor: 'white'
//         });
//
//         openTyper
//             .strings(
//                 400,
//                 text
//             )
//             .rest(500)
//             .start();
    }

    getJSObjectByArrayAndLabel(lists: any, key: any){
        let incomingObjects: any = new Parameters();
        incomingObjects.setAttributes({});
        lists.map((list: any) => {
            let incominglist = new Parameters();
            incominglist.setAttributes(list);
            if(!this.empty(incominglist.getUnknown(key))){
                if(!incomingObjects.attributes.hasOwnProperty(incominglist.getUnknown(key))){
                    incomingObjects.setUnknown(incominglist.getUnknown(key), incominglist.getAttributes());
                }
            }
        });
        return incomingObjects.getAttributes();
    }

    parseInt(number: any){
        return parseInt(number.toString());
    }

    parseFloat(number: any){
        return parseFloat(!this.empty(number) ? number.toString() : 0);
    }

    onKeyUp(event: any){
        event.target.value = this.numberWithCommans(parseFloat(event.target.value.toString().replace(/,/g, '')));
    }

    getObjectByObjectsAndField(objects: any, field: any){
        let incomingObject: any = new Parameters();
        return objects.filter((object: any) => {
            incomingObject.setAttributes(object);
            return incomingObject.getUnknown(field)[field].toString().trim().includes(field.toString().trim());
        })[0];
    }





    addComma(event: any){
        setTimeout(() => {
            this.result = event.value;
            if(!this.empty(event.value.toString())){
                this.separators = event.value.toString().split('.');
                this.result = this.separators[0].toString().replace(/,/g, '');
                this.result = this.numberWithCommans(parseFloat(this.result));
                event.value = this.separators.length === 2 ? (this.result + '.' + this.separators[1].toString()) : this.result;
            }
        });
    }

    numberValidation(event: any, conditions: any){
        if(!this.empty(event.target.value)){
           this.incomingItem.setAttributes(conditions);
              this.originValue = event.target.value;
              if(this.incomingItem.attributes.hasOwnProperty('maximum_value') &&
                !this.empty(this.incomingItem.getMaximumValue())){
               if(parseFloat(this.incomingItem.getMaximumValue()) < parseFloat(event.target.value.toString().replace(/,/g, '')))
                event.target.value = event.target.value.toString().substring(0, event.target.value.toString().length - 1);
              }else if(this.incomingItem.attributes.hasOwnProperty('minimum_value')){}
              if(this.incomingItem.attributes.hasOwnProperty('digits') &&
               !this.empty(this.incomingItem.getDigits())){
                 if(parseFloat(this.incomingItem.getDigits()) < event.target.value.toString().replace(/,/g, '').toString().length)
                   event.target.value = event.target.value.toString().substring(0, event.target.value.toString().length - 1);
              } event.target.value = this.incomingItem.attributes.hasOwnProperty('integer') &&
                 !this.empty(this.incomingItem.getInteger()) &&
                 parseFloat(this.incomingItem.getInteger()) === 1 ?
                 parseInt(event.target.value.toString().replace(/,/g, '')) : event.target.value;
        }event.target.value = !this.empty(event.target.value) && event.target.value.toString() === 'NaN' ? '' : event.target.value;
    }

    decimalPlaces(event: any, conditions: any){
       if(!this.empty(event.target.value) && event.target.value.toString() !== 'NaN'){
           this.incomingItem.setAttributes(conditions);
           this.originValue = event.target.value;
           event.target.value = this.incomingItem.attributes.hasOwnProperty('decimal_places') &&
              !this.empty(this.incomingItem.getDecimalPlaces()) ?
              Number(parseFloat(event.target.value.toString().replace(/,/g, ''))).toFixed(parseFloat(this.incomingItem.getDecimalPlaces())) : event.target.value;
       }event.target.value = !this.empty(event.target.value) && event.target.value.toString() === 'NaN' ? '' : event.target.value;
    }



    validateNumber(evt) {
        var e = evt || window.event;
        var key = e.keyCode || e.which;
        if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
            // numbers
            key >= 48 && key <= 57 ||
            // Numeric keypad
            key >= 96 && key <= 105 ||
            // Backspace and Tab and Enter
            key === 8 || key === 9 || key === 13 ||
            // Home and End
            key === 190 || key === 188 || key === 109 || key === 110 ||
            // Comma and Decimal
            key === 35 || key === 36 ||
            // left and right arrows
            key === 37 || key === 39 ||
            // Del and Ins
            key === 46 || key === 45) {
            // input is VALID
        }
        else {
            // input is INVALID
            e.returnValue = false;
            if (e.preventDefault)
                e.preventDefault();
        }
    }



    getJSONObjectByJSONArrayAndColumnAndValue(JSONArray: any, column: any, value: any){
        return JSONArray.filter((JSONObject: any) => {
            return JSONObject[column] === value;
        })[0];
    }

    numberWithCommans(s: any){
        const money = new Intl.NumberFormat('de-CH',
            { style: 'currency', currency: 'CHF' });
        // return this.empty(s) ? 0 : money.format(s);
        // return s.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return this.empty(s) ? 0 : s.toLocaleString();
        // return s.toLocaleString('en-US', {
        //     style: 'currency',
        // });
    }

    onlyNumbers(){

    }

    setSmallCharacters(text: any){
     return text.toString().toLowerCase();
    }


    setNavigatedUrl(url: string){this.navigatedUrl = url; }
    getNavigatedUrl(){return this.navigatedUrl; }
    getToken(){return this.token; }
    setToken(token: string){ this.token = token; }
    setAngularEditorConfig(){
        this.angularEditorConfig = {
            editable      : true,
            spellcheck    : true,
            height        : '200px',
            minHeight     : '0',
            maxHeight     : 'auto',
            width         : 'auto',
            minWidth      : '0',
            translate     : 'yes',
            enableToolbar : true,
            showToolbar   : true,
            placeholder   : 'Enter text here...',
            defaultParagraphSeparator: '',
            defaultFontName: 'Arial',
            defaultFontSize: '14px',
            fonts: [
                {class: 'arial'          , name: 'Arial'},
                {class: 'times-new-roman', name: 'Times New Roman'},
                {class: 'calibri'        , name: 'Calibri'},
                {class: 'comic-sans-ms'  , name: 'Comic Sans MS'}
            ],
            customClasses: [
                {
                    name : 'quote',
                    class: 'quote',
                },
                {
                    name : 'redText',
                    class: 'redText'
                },
                {
                    name : 'titleText',
                    class: 'titleText',
                    tag  : 'h1',
                },
            ],
            uploadUrl: 'v1/image',
            uploadWithCredentials: false,
            sanitize: false,
            toolbarPosition: 'top',
            toolbarHiddenButtons: [
                [],
                []
            ]
        };
        return this.angularEditorConfig;
    }

    getRouteName(){return this.activatedRouter.snapshot.data.routeName; }

    getJSONArrayByJSONArrayAndColumnAndValue(JSONArray: any, column: any, value: any){
        return JSONArray.filter((JSONObject: any) => {
            return JSONObject[column] === value;
        });
    }

   JSONParse(string: any){
    return JSON.parse(JSON.stringify(string));
   }


  // findIndex(array: any, key: any, val: any){return array.findIndex((obj: any) => { return obj[key] === val; }); }
    findIndex(array: any, key: any, val: any){ return array.findIndex((obj: any) =>  obj[key] === val ); }
    findIntegerIndex(array: any, key: any, val: any){ return array.findIndex((obj: any) =>  parseFloat(obj[key]) === parseFloat(val) ); }
    authenticateUser(callback: any){
        this.httpService(
            'post',
            JSON.parse(JSON.stringify(this.app)).settings.routes.authenticateUser + '?token=' + this.getToken(),
            {}, {},
            (response: any) => {
                this.app = Object.assign(this.app, {data: response});
              // this.router.navigateByUrl('/app/campaigns');
                callback(response);
            }, (error: any) =>  {
                callback({});
            });
    }

    restore(url: string, data: any, key: any, id: number, model: any){
        this.httpService('post', url, {}, {}, (response: any) => {
            data.trashed.data.splice(this.findIndex(data.trashed.data, key, id), 1);
            data.data.unshift(model);
            this.notifier.notify('success', 'successfull restored');
        }, (error: any) => {});
    }

    pieChartColors(){
        return [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            '#007bff', '#ffc107', '#28a745', '#4c84ff', '#29cc97', '#8061ef', '#fec402', '#26B99A', '#263238',  '#4caf50', '#ff9800', '#f44336', '#1dc7ea', '#172b4d', '#34495E', '#BDC3C7', '#3498DB',
            '#9B59B6', '#8abb6f', '#759c6a', '#bfd3b7'
        ];
    }


    getImage(images: any[], width: any, height: any){
        this.setimageCarouselCells(images);
        return   `
            <carousel
              class="container"
              width="` + width + `"
              height="` + height + `"
              [cellsToShow]="1"
              >
              <div>` + this.htmlContents + `</div>
            </carousel>`;
    }

    setimageCarouselCells(items: any){
        this.htmlContents = '';
        items.map((item: any) => {
            this.incomingItem.setAttributes(item);
            this.htmlContents += (
                `<div class="carousel-cell">
                    <img src="` + this.incomingItem.getData() + `" style="width: auto; max-height: 300px">
                 </div>`
            );
        });
    }

    getStatus(){
        let status: any = this.status === -1 ? 'All' : 'Active';
        status = this.status === 0  ? 'Deleted' : status;
        return status;
    }



    setSuggestion(text: any, callback: any){
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setEntity2(this.random());
        this.outgoingRequest.setSearchText(text);
        if(!this.empty(text)){
            this.httpService( 'post', '/suggestions/addrequest', this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true},
                (response: any) => {
                    this.app.data.suggestions.push(response);
                    callback(text);
                },  (error: any) => {
                });
        }
    }

    sum(source: any, carry: any){
        let result: any = parseFloat('0');
        source.map((response: any) => {
            result += parseFloat(response[carry]);
        }); return result;
    }

    getSuggestions(text: any, callback: any){
        if(!this.empty(text)){
            if(!this.spinner){
                this.spinner = true;
                this.httpService( 'post', '/suggestions/searchrequest', {searchText: text}, {ignoreLoadingBar: 'true', notify: true},
                    (response: any) => {
                        this.app.data.suggestions = response;
                        this.spinner = false;
                        callback();
                    },  (error: any) => {
                        this.spinner = false;
                    });
            }
        }
    }


     upperStartingCharacter2(text: any, status: any){
             text = text.toString().toLowerCase().trim();
             this.result = text;
             if(status){
               if(!this.empty(text)){
                 if(text.toString().length !== 1){
                  let starting: any = text.toString().substring(0, 1);
                  let lasting: any = text.toString().substring(1, text.toString().length);
                 if(!starting.toString().toUpperCase().includes('M') &&
                 !starting.toString().toUpperCase().includes('N1') &&
                         !starting.toString().toUpperCase().includes('P') &&
                   !starting.toString().toUpperCase().includes('S1') &&
                         !starting.toString().toUpperCase().includes('Q1') &&
                         !starting.toString().toUpperCase().includes('R') &&
                   !starting.toString().toUpperCase().includes('A1') &&
                  !starting.toString().toUpperCase().includes('O'))
    // //              if(starting.toString().toUpperCase().includes('A') ||
    // //                 starting.toString().toUpperCase().includes('D') ||
    // //                 starting.toString().toUpperCase().includes('L') ||
    // //                 starting.toString().toUpperCase().includes('G'))
                  this.result = starting.toString().toUpperCase() + lasting;
                 }
               }
             }
             return this.result;
    }



    httpService(method: string, url: string, params: any, options: any, callback: any, errorcallback: any){
        //  headers.ignoreLoadingBar = headers.hasOwnProperty('ignoreLoadingBar') === true ? headers.ignoreLoadingBar : false;
//      params.business_id = 2;
        this.httpHeaders = new HttpHeaders();
        if (this.getToken() !== null) {
        this.httpHeaders = this.httpHeaders.append('gaterway', '' + this.getToken());
        }
        if (options.hasOwnProperty('ignoreLoadingBar')) {
        this.httpHeaders = this.httpHeaders.append('ignoreLoadingBar', '' + options.ignoreLoadingBar);
        }
        this.httpParams = new HttpParams();
        this.formData = new FormData();
        for (const key in params) {
            this.formData.append(key, params[key]);
            this.httpParams = this.httpParams.append(key, params[key]);
        }
        //  this.httpRequestOptions.headers = this.,.headers.set();
        //  this.httpRequestOptions = {headers : new Headers({'Content-Type': 'undefined'}),};
        const hrequestHttp = new HttpRequest(method, url,
            {reportProgress: false, responseType: 'json'});
        //  this.httpClient.get(url).subscribe(
        //  (response: any) => {callback(response.body); },
        //  (error: any) => {errorcallback(error); });
        //  catchError(this.handleError)
        this.httpClient.request<any>(method, method === 'get' ? url : (options.hasOwnProperty('ignoreServerUrl') ? '' : this.app.settings.routes.server) + url, {body: this.formData, headers: this.httpHeaders}).pipe(retry(0)).subscribe(
        (response: any) => {
            callback(response);
        },
        (error: any) => {
            errorcallback(error);
            if (options.hasOwnProperty('notify')){
            } else{
                let notifier = '';
                if (error.hasOwnProperty('error')){
                    if (error.error.hasOwnProperty('error')){
                        if (error.error.error.toString().includes('token_expired') ||
                            error.error.error.toString().includes('token_invalid')){
                            this.notifier.notify('success', notifier);
                            localStorage.setItem(this.app.settings.tokenName, '');
                            this.setToken('');
                            this.router.navigateByUrl('/login');
                            return;
                        }
                    }else if (error.error.hasOwnProperty('security')){
                        this.router.navigateByUrl('/login');
                        return;
                    }else  notifier = this.buildHttpServerErrorAlert(error.error);
                }
                if (! notifier.toString().includes('true')){
                  this.notifier.notify('success', notifier);
                }
            }
        });
    }

    requestMethod(method: string, url: string, formData: FormData){
        if (method.includes('get')) {
            return this.httpClient.get<any>(url);
        } else if (method.includes('post')) {
            return this.httpClient.post<any>(url, this.formData, {headers: this.httpHeaders, responseType: 'json', reportProgress: false});
        }return this.httpClient.request<any>(method, url, this.formData);
    }

    removeHtmlTags(resp: any) {const html = resp; const div  = document.createElement('div'); div.innerHTML = html; resp = div.innerText; return resp; }
F
    buildHttpServerErrorAlert(error: any){
        let output = '';
        if (error instanceof Object){ for (const key of Object.keys(error)){ output =  output + error[key];}
        }return  this.empty(output) ? 'internet disconnected' : output;
    }

    hasSameYearAndMonth(reportValues: Parameters){
        return (moment(new Date(reportValues.attributes.starting_at)).format('YYYY') === moment(new Date(reportValues.attributes.ending_at)).format('YYYY')) &&
            (moment(new Date(reportValues.attributes.starting_at)).format('MM') === moment(new Date(reportValues.attributes.ending_at)).format('MM')) &&
            (moment(new Date(reportValues.attributes.starting_at)).format('D') !== moment(new Date(reportValues.attributes.ending_at)).format('D'));
    }

    setDisplayedDate(reportValues: Parameters){
        return this.hasSameYearAndMonth(reportValues) ?
            moment(new Date(reportValues.attributes.starting_at)).format('MMMM ') +
            moment(new Date(reportValues.attributes.starting_at)).format('D') +
            moment(new Date(reportValues.attributes.ending_at)).format('-D,') +
            moment(new Date(reportValues.attributes.starting_at)).format(' YYYY') :
            moment(new Date(reportValues.attributes.ending_at)).format('MMMM D, YYYY');
    }


    handleError(error: HttpErrorResponse) {
        if (error.hasOwnProperty('error')){
            if (error.error.hasOwnProperty('error')){
                if (error.error.error.includes('token_expired')) {
                    localStorage.setItem(this.app.settings.tokenName, '');
                    this.app.setToken(null);
                    this.router.navigateByUrl('/login');
                }
            }
        }
        let notifications = 'Unknown error!';
        if (error.error instanceof ErrorEvent) { notifications = `Error: ${error.error.message}`; }   // Client-side errors
        else { notifications = `Error Code: ${error.status}\nMessage: ${
            error.message}`;
        }  // Server-side errors
        return throwError(notifications);
    }

    empty(str: any) {
        if (str === undefined || str === null || str.toString().toLowerCase() === 'null') return true;
        else if(str.toString() === '0') return false;
        return !str || !/[^\s]+/.test(str);
    }

    getActivatedRouteId(){
        let id: any = this.activatedRoute.snapshot.params.id;
        id = this.decrypt(id);
        return id;
    }



}


