import { Component, OnInit } from '@angular/core';
import {HeaderComponent} from "../header/header.component";
import {AppService} from "../app.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  lat: any = -6.8643365;
  lng: any = 39.2712821;
  constructor(private headerComponent: HeaderComponent,
              public service: AppService) {
  }

  ngOnInit(): void {
    this.headerComponent.headerClass = 'header-4';
  }

}
