<div style="margin-top: 50px">
    <div>
        <mat-form-field  class="example-full-width" appearance="fill">
            <input matInput
                   #userElement
                   type="text"
                   placeholder="search for member .."
                   [(ngModel)]="searchCntrl"
                   (input)="onUserNameTextChanged($event)"
                   [formControl] = "userControl"
                   [matAutocomplete] = "user">
            <mat-autocomplete #user = "matAutocomplete"
                              (optionSelected)="onUserSelectionChanged($event)"
                              [displayWith]="displayFn">
                <mat-option *ngFor="let member of filteredUsersOptions | async; let memberOf = index" [value]= "member" >
                    <img class='img'
                         src="{{member.avatar ? member.avatar : 'assets/images/avatar.jpg'}}"
                         [ngStyle]="{'width':'40px', 'height':'40px','margin-right':'10px'}" />
                         {{member.name}}
                </mat-option>
            </mat-autocomplete>
            <h4 style="color: red; font-weight: normal"
                *ngIf="service.empty(userElement.value) === false &&
                     ((filteredUsersOptions | async) | json).length === 2">
                **---Not Found *---</h4>
            <i class="material-icons pull-right" [ngStyle]="{'cursor': 'pointer'}" (click)="addUser()">add</i>
        </mat-form-field>
    </div>
    <br><br><br>
    <div class="col-md-12 col-sm-12">
       <mat-list>
            <mat-list-item *ngFor="let member of (getMembersByTeamId(team.id) | jsonFilter : searchCntrl) ; let memberOf = index">
                <span class="cursor" mat-line
                      (mousedown)="selectedUser = member"
                      (contextmenu)="onContextMenu($event, member)"
                      (click)="onContextMenu($event, member)">
                     <img class='img-circle'
                          src="{{member.avatar ? member.avatar : 'assets/images/avatar.jpg'}}"
                          [ngStyle]="{'width':'40px', 'height':'40px','margin-right':'10px'}" />
                         {{getUserByMember(member).name}}
                </span>
                <mat-checkbox #checkboxElement
                              [checked]="onloadChecked(team.id, member,checkboxElement)"
                              (click)="onUserChecked(member,checkboxElement)"
                              class="pull-right"></mat-checkbox>
<!--            <i class="material-icons pull-right" [ngStyle]="{'cursor': 'pointer'}" (click)="removeUser(member)">close</i>-->
            </mat-list-item>
        </mat-list>
    </div>
</div>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item (click)="setTarget(selectedUser)">Set Target</button>
        <button mat-menu-item (click)="handleChangePassword(selectedUser)">Change password</button>
        <button mat-menu-item (click)="openUserDialog(selectedUser)">Profile</button>
    </ng-template>
</mat-menu>
