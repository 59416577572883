import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  report: any;

  /**
   * @param mixed campaignSelectedId
   */

  flag: any;

  /**
   * @param mixed campaignSelectedId
   */

  app: any;

  /**
   * @param mixed campaignSelectedId
   */

  categoryId: any;

  /**
   * @param mixed categoryId
   */

  status: any;

  /**
   * @param mixed Status
   */


  campaignSelectedId: any;
  /**
   * @param mixed campaignSelectedId
   */


  data: any = [];

  /**
   * @param mixed Data
   */

  isDialogOpen: any = false;


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }


  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }


  constructor(public service: AppService,
              private dialog: MatDialog) {

    this.app = this.service.app;

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);
  }

  getCampaigns(){
    let clients = this.getClients();
    if(this.app.data.hasOwnProperty('campanies')) {
      this.getCampanyClients().data.map((client: any) => {
        let position = clients.data.map.findIndex((incomingClient: any) => {
          return incomingClient.id === client.id;
        });if (position === -1) clients.data.unsift(client);
        else clients.data[position] = Object.assign(clients.data[position], {campany_client_id: client.campany_client_id});
      });
    }
    let campaigns = this.getClientCampaigns(clients);
    return campaigns;
  }

  getUnkownCampaign(){

  }

  hasUnkownCampaign(){
    const l: any = this.app.data.campaigns.data.filter((campaign: any) => {
      return !this.service.empty(campaign.cntrl_num) && campaign.cntrl_num.toString() === this.service.app.settings.unknownCampaignCntrlNum;});
    return l.length === 0 ? false : true;
  }


  setUnknown(callback){
    let formValues: any = new Parameters();
    formValues.setAttributes({});
    formValues.setAttributes({});
    formValues.setPortalId(0);
    formValues.setClientId(this.service.app.data.clients.data[0].id);
    formValues.setCampanyId(this.service.app.data.campanies.data[0].id);
    formValues.setName('Unknown');
    formValues.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));
    formValues.setEndingAt(moment().add(1, 'days').format('MM/DD/YYYY'));
    formValues.setCalls(1);
    formValues.setSales(0);
    formValues.setDescription('');
    formValues.setCntrlNum(this.service.app.settings.unknownCampaignCntrlNum);
    this.service.httpService('post', '/campaigns', formValues.getAttributes(),
        {ignoreLoadingBar: 'true', notify: false},
        (response: any) => {
          callback(response, 200);
        }, (error: any) => {
          callback(error, 400);
        });

  }


  getClientCampaigns(clients: any){
    let campaigns: any = {};
    campaigns = {data: clients.data.map((client: any) => { return client.campaigns.data; }).flat(1),
      trashed:  {data: clients.data.map((client: any) => { return client.campaigns.trashed.data; }).flat(1)}};
    return campaigns;
  }

  getCampaignByCntrlNum(cntrlNum: any){
    var campaigns: any = [] = this.service.app.data.campaigns.data.filter((campaign: any) => {
      return !this.service.empty(campaign.cntrl_num) &&  campaign.cntrl_num.toString() === cntrlNum.toString();});
    return campaigns[0];
  }

  getCampanyClients(){
    return this.service.app.data.campanies.data.map((campany: any) => {
      return campany.clients;}).flat(1)[0];
  }



  getClients(){
    return this.service.app.data.clients;
  }

  getCampaignsByCategoryId(categoryId: any){
    var campaigns: any = [] = this.service.app.data.campaigns.data.filter((campaign: any) => {
      return parseFloat(campaign.category_id) === parseFloat(categoryId);});
    return campaigns;
  }

  getCampaignByCampaign(campaign: any){
    var campaigns: any = [] = this.service.app.data.campaigns.data.filter((item: any) => {
      return parseFloat(item.id) === parseFloat(campaign.id); });
    return campaigns.length === 0 ? this.addCampaign(campaign) : campaigns[0];
  }

  addCampaign(campaign: any){
    this.service.app.data.campaigns.data.unshift(campaign);
    return campaign;
  }

  getCampaignById(campaignId: any){
    var campaigns: any = [] = this.service.app.data.campaigns.data.filter((campaign: any) => {
      return parseFloat(campaign.id) === parseFloat(campaignId);});
    return campaigns[0];
  }

  getCampaignPositionById(campaignId: any){
    var position: any = this.service.app.data.campaigns.data.findIndex((campaign: any) => {
      return parseFloat(campaign.id) === parseFloat(campaignId);});
    return position;
  }


  search(searchText: any, callback: any){
    this.service.httpService('post', this.service.app.routes.categories.search,
        {searchText: searchText}, {ignoreLoadingBar : 'true', notify: 'false'},
        (response: any) => {
          setTimeout((result: any) =>{
            response.map((item: any) => {
              result = [];
              result = this.service.app.data.categories.data.filter((itm: any) => {
                return parseFloat(item.id) === parseFloat(itm.id);
              });if(result.length === 0){
                this.service.app.data.categories.data.push(item);
                callback(null);
              }
            });
          });
        }, (error: any) => {
        });
  }

  openDialog(params: any) {
    // setTimeout(() => {
    //   params = new Parameters();
    //   params.setAttributes({});
    //   if (this.isDialogOpen === false){
    //     this.isDialogOpen = true;
    //     const dialogRef = this.dialog.open(DialogCampaignsComponent, {
    //       data  : {params: params.getAttributes()},
    //       width : '120%',
    //       height: '100%',
    //       disableClose: true,
    //     });
    //     dialogRef.afterClosed().subscribe(() => {
    //       this.isDialogOpen = false;
    //     });
    //   }
    // });

  }
}
