import { Component, OnInit, Input,OnChanges, SimpleChanges, OnDestroy, AfterViewInit, AfterContentInit } from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from "../../../../parameters";
import {FormService} from '../../../form.service';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-advert-responses',
  templateUrl: './advert-responses.component.html',
  styleUrls: ['./advert-responses.component.scss']
})
export class AdvertResponsesComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterContentInit {
  @Input() question: any;
  @Input() position: any;
  incomingQuestion: any;
  incomingImg: any;
  incomingSettings: any;
  timeOut: any = 3107;
  incomingItem: any;
  incomingResults: any;
  incomingResult: any;
  incomingOption: any;
  images: any = [];
  result: any = 0;
  width: any = 0;
  incomingForm: any;
  incomingPhotoSelected: any;
  backgroundColor: any = '#ebebeb';
  incremental: any = 0;
  focusInterval: any;
  imageCurrentPosition = -1;
  isForConfirmation: any = false;
  incomingFormSettings: any;
  outgoingRequest: any;
  incomingIsConfirm: any;
  incomingIsConfirmResult: any;
  incomingIsConfirmResults: any;
  constructor(public service: AppService,
     private broadcastChannel: BroadcastChannelService,
     public  formService: FormService) {
     this.incomingQuestion = new Parameters();
     this.incomingImg      = new Parameters();
     this.incomingSettings = new Parameters();
     this.incomingItem     = new Parameters();
     this.outgoingRequest   = new Parameters();
     this.incomingForm     = new Parameters();
     this.incomingResults  = new Parameters();
     this.incomingResult   = new Parameters();
     this.incomingOption   = new Parameters();
     this.incomingIsConfirm = new Parameters();
     this.incomingFormSettings = new Parameters();
     this.incomingPhotoSelected = new Parameters();
     this.incomingIsConfirmResult = new Parameters();
     this.incomingIsConfirmResults = new Parameters();
  }

  ngOnInit(): void {
  }

  onCarouselCellChange(incremental: any, img: any){
      setTimeout(() => {
       this.incomingQuestion.setAttributes(Object.assign({}, this.question));
       this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
       this.incomingSettings.setImages(this.incomingSettings.getImages() ?
       this.incomingSettings.attributes.images : []);
       if(this.incomingSettings.getImages().length !== 1){
        if((this.incomingSettings.getImages().length - 1) === incremental){
//          setTimeout(() => {
//          this.ngOnDestroy();
//          this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});}, this.timeOut);
        }
       }
      });
  }

  next(caption: any, response: any){
       if(!this.isForConfirmation){
          this.incomingQuestion.setAttributes(this.question);
          setTimeout(() => {
              this.formService.questions.map((question: any) => {
                this.incomingItem.setAttributes(question);
                if(!this.incomingQuestion.getCntrlNum().toString().includes(this.incomingItem.getCntrlNum())){
                  if(this.incomingItem.getLabel().toString() === caption){
                   this.incomingItem.getOptions().map((option: any) =>{
                    this.incomingOption.setAttributes(option);
                    if(this.incomingOption.getLabel().toString() === response){
                         this.incomingItem.setData(response);
                         this.incomingResult.setAttributes({});
                         this.incomingResults.setAttributes({});
                         this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
                         this.incomingResult.setLabel(this.incomingOption.getLabel());
                         this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
                         this.incomingItem.setResult(Object.assign({}, this.incomingResults.getAttributes()));
                         this.incomingItem.setFlag(3);
                    }
                   });
                  }
                }
          });
          this.ngOnDestroy();
          this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});});
       }else{
            this.incomingIsConfirm.setAttributes(
              this.incomingForm.getControls().filter((question: any)=>{
               return question.cntrl_num.toString() === this.incomingFormSettings.getIsConfirm();
              })[0]
            );
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setId(this.incomingIsConfirm.getResponseResultId());
            this.outgoingRequest.setData(this.incomingIsConfirm.getOptions()[0].label);
            this.incomingIsConfirmResults.setAttributes({});
            this.incomingIsConfirmResult.setAttributes({});
            this.incomingIsConfirmResult.setLabel(this.incomingIsConfirm.getOptions()[0].label);
            this.incomingIsConfirmResult.setCntrlNum(this.incomingIsConfirm.getOptions()[0].cntrl_num);
            this.incomingIsConfirmResults.setUnknown(this.incomingIsConfirm.getOptions()[0].cntrl_num,
              this.incomingIsConfirmResult.getAttributes());
            this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.incomingIsConfirmResults.getAttributes())));
            this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
            }, (error: any) =>  {
            });
       }
  }


  ngOnChanges(changes: SimpleChanges): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#000';
    this.isForConfirmation = this.service.getNavigatedUrl().toString().includes('/forms') &&
         this.service.getNavigatedUrl().toString().includes('/responses') &&
         this.service.getNavigatedUrl().toString().includes('/confirmation');
//  document.getElementsByTagName( 'body')[0].style.overflow = 'hidden';
    this.incomingForm.setAttributes(this.formService.selected);
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingFormSettings.setIsConfirm(this.incomingFormSettings.attributes.hasOwnProperty('is_confirm') ?
     this.incomingFormSettings.getIsConfirm() : '');
    this.backgroundColor = this.incomingFormSettings.attributes.hasOwnProperty('background_color') ? this.incomingFormSettings.getBackgroundColor() : this.backgroundColor;
    this.incomingQuestion.setAttributes(Object.assign({}, this.question));
    this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
    this.incomingSettings.setImages(this.incomingSettings.getImages() ?
    this.incomingSettings.attributes.images : []);
  }

  getWindow(){ return {height: window.innerHeight, width: window.innerWidth};}

  ngOnDestroy(): void {
   this.formService.isAdvert = false;
// document.getElementsByTagName( 'body')[0].style.overflow = 'visible';
   document.getElementsByTagName( 'body')[0].style.backgroundColor = this.backgroundColor;
   clearInterval(this.focusInterval);
  }

  ngAfterViewInit(){
   setTimeout(() => {
      this.formService.isAdvert = true;
   });
  }

  move() {
      if (this.incremental === 0) {
        this.incremental = 1;
        var elem = document.getElementsByClassName('myBar')[0];
        this.width = 1;
        // @ts-ignore
          var focusInterval = setInterval(() => {
              this.frame(elem);
          }, 10);
        this.frame(elem);
     }
  }

  frame(elem: any) {
    if (this.width >= 100) {
        this.width = 0;
        this.incremental = 0;
    } else {
        this.width++;
        elem.style.width = this.width + '%';
    }
  }

  calculateImgWidth(naturalWidth: any){
   return (naturalWidth  * 25)/100;
  }

  setSelectedImage(image: any){
      this.incomingPhotoSelected.setAttributes(image);
      if(this.incomingPhotoSelected.attributes.hasOwnProperty('id')){
          this.position = this.service.findIndex(this.images, 'id', this.incomingPhotoSelected.getId());
          if(this.position === (this.incomingSettings.attributes.images.length - 1))
              this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
      }
  }


  ngAfterContentInit(){
        setTimeout(() => {
//         this.move();
           const nextSlides = document.getElementsByClassName('carousel-arrow-next');
           for (var i = 0; i < nextSlides.length; i++) {
             const slide = nextSlides[i];
             if (slide instanceof HTMLElement) {
                 slide.addEventListener('click', () => {
                     this.incomingQuestion.setAttributes(Object.assign({}, this.question));
                     this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
                     this.images = this.incomingSettings.getImages();
                     this.imageCurrentPosition += 1;
                     this.setSelectedImage(this.incomingSettings.attributes.images[this.imageCurrentPosition]);

                 });
            }
           }

           const prevSlides = document.getElementsByClassName('carousel-arrow-prev');
           for (var i = 0; i < prevSlides.length; i++) {
                const slide = prevSlides[i];
                if (slide instanceof HTMLElement) {
                    slide.addEventListener('click', () => {
                        this.imageCurrentPosition = this.imageCurrentPosition === -1 ? -1 : this.imageCurrentPosition - 1;
                        this.setSelectedImage(this.incomingSettings.attributes.images[this.imageCurrentPosition]);

                    });
                }
           }

        }, 1107);
  }


}
