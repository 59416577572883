import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Parameters} from "../../parameters";
import {NotifierService} from "angular-notifier";
import {BroadcastChannelService} from "../../broadcast-channel.service";

@Component({
  selector: 'app-settings-criterias-dialog',
  templateUrl: './settings-criterias-dialog.component.html',
  styleUrls: ['./settings-criterias-dialog.component.scss']
})
export class SettingsCriteriasDialogComponent implements OnInit {

  incomingCriteria: any;
  incomingDialogParams: any;
  charts: any = [];
  constructor(private  service: AppService,
              private notifier: NotifierService,
              private broadcastChannel: BroadcastChannelService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<SettingsCriteriasDialogComponent>) {
    this.incomingCriteria = new Parameters();
    this.incomingDialogParams = new Parameters();
    this.incomingDialogParams.setAttributes(this.data.criteria);
    this.incomingCriteria.setAttributes(this.incomingDialogParams.getAttributes());
    this.incomingCriteria.setDefaultContainerWidth(
              this.incomingCriteria.attributes.hasOwnProperty('default_container_width') ?
              this.incomingCriteria.getDefaultContainerWidth(): '');
    this.incomingCriteria.setTextSize(
              this.incomingCriteria.attributes.hasOwnProperty('text_size') ?
              this.incomingCriteria.getTextSize(): '');
   this.incomingCriteria.setChart(
              this.incomingCriteria.attributes.hasOwnProperty('chart') ?
              this.incomingCriteria.getChart(): '');
   this.incomingCriteria.setTextRotationAngle(
              this.incomingCriteria.attributes.hasOwnProperty('text_rotation_angle') ?
              this.incomingCriteria.getTextRotationAngle(): '');
  }

  ngOnInit(): void {
    this.service.httpService( 'get', '../assets/json/reports.json', {}, {},(response: any) => {
      this.charts = response;
    },  (error: any) => {
    });
  }

  sendNotification(){
    this.broadcastChannel.emitNavChangeEvent('criteriaUpdate');
  }

}
