<mat-dialog-content>
    <ng-container *ngIf="!this.data.hasOwnProperty('responses') || !showImg">
        <carousel [cellsToScroll]="1"
                  [cellsToShow]="1"
                  [height]="this.service.getHeight()"
                  [overflowCellsLimit]="1"
                  [transitionDuration]="1"
                  [autoplay]="false"
                  [lightDOM]="false"
                  [arrows]="false"
                  [dots]="false"
                  [loop]="true">
            <div class="carousel-cell">
                <ng-container>
                    <img [ngStyle]="{'animation': 'imgViewx 5s','width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                         src="{{incomingResponseResult.getUnknown('data')}}" #img />
                </ng-container>
            </div>
        </carousel>
    </ng-container>
    <div *ngIf="this.data.hasOwnProperty('responses')"
         [ngStyle]="{'width': (showImg ? '' : '1px'),'height': (showImg ? '' : '1px')}">
<!--        <div [ngStyle]="{'position': 'relative','top': '-97px','left': '0px','width':'100%','height': '100%'}">-->
<!--            <mat-tab-group [(selectedIndex)]="selectedTabIndex">-->
<!--                <mat-tab *ngFor="let response of (this.data.responses);let incrementalResponse=index">-->
<!--                    <ng-container *ngFor="let responseResult of response.datas ;let incrementalResponseResult=index">-->
<!--                        <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">-->
<!--                            <div *ngIf="incrementalResponseResult === 0">-->
<!--                                <img [ngStyle]="{'width': '100%', 'max-height': this.service.getHeight()+'px', 'display':'block', 'object-fit': 'cover'}"-->
<!--                                     class="cursor"-->
<!--                                     src="{{responseResult.data}}"-->
<!--                                     #img />-->
<!--                            </div>-->
<!--                        </ng-container>-->
<!--                    </ng-container>-->
<!--                </mat-tab>-->
<!--            </mat-tab-group>-->
<!--            <a  class=""-->
<!--                mat-mini-fab-->
<!--                [ngStyle]="{'position': 'absolute','z-index': '9999999','top':'50%','left':'5px','background': '#fff','color':'#000'}">-->
<!--                <i class="fa fa-chevron-left"></i>-->
<!--            </a>-->

<!--            <a  class=""-->
<!--                mat-mini-fab-->
<!--                [ngStyle]="{'position': 'absolute','z-index': '9999999','top':'50%','right':'5px','background': '#fff','color':'#000'}">-->
<!--                <i class="fa fa-chevron-right"></i>-->
<!--            </a>-->
<!--        </div>-->
            <carousel [cellsToScroll]="1"
                      [cellsToShow]="1"
                      [height]="this.service.getHeight()"
                      [overflowCellsLimit]="1"
                      [transitionDuration]="1"
                      [autoplay]="false"
                      [lightDOM]="false"
                      [arrows]="true"
                      [dots]="false"
                      [loop]="true"
                     *ngIf="this.data.responses.length !== 0 && showCarousel"
                      #myCarousel>
                <ng-container *ngFor="let response of (this.data.responses);let incrementalResponse=index">
                    <ng-container *ngFor="let responseResult of response.datas ;let incrementalResponseResult=index">
                        <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">
                            <div class="carousel-cell {{incrementalResponse === 1 ? ' ' : ''}}"
                                 *ngIf="incrementalResponseResult === 0">
                                <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                                     src="{{responseResult.data}}"
                                     #img />
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </carousel>
    </div>
</mat-dialog-content>
