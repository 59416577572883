import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HeaderComponent} from './header/header.component';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {ContactComponent} from "./contact/contact.component";
import {WeddingsComponent} from "./weddings/weddings.component";
import {MeetingsComponent} from "./meetings/meetings.component";
import {VenueComponent} from "./venue/venue.component";
import {PlanyourvisitComponent} from "./planyourvisit/planyourvisit.component";
import {OurservicesComponent} from "./ourservices/ourservices.component";
import {AccessibilityComponent} from "./accessibility/accessibility.component";
import {OffersComponent} from "./offers/offers.component";
import {EventsComponent} from "./events/events.component";
import {TicketsComponent} from "./tickets/tickets.component";
const routes: Routes = [
    { path: 'app' , component: HeaderComponent,
        children : [
            {path : 'home'                   , component : HomeComponent},
            {path : 'contact'                , component : ContactComponent},
            {path : 'weddings'               , component : WeddingsComponent},
            {path : 'meetings'               , component : MeetingsComponent},
            {path : 'venue'                  , component : VenueComponent},
            {path : 'ourservices'            , component : OurservicesComponent},
            {path : 'accessibility'          , component : AccessibilityComponent},
            {path : 'offers'                 , component : OffersComponent},
            {path : 'events'                 , component : EventsComponent},
            {path : 'tickets'                , component : TicketsComponent},

        ]},
    { path: 'wait..'                         , component : MainComponent, data: {routeName: 'wait'}},
    { path: 'home'                           , component : HomeComponent},
    { path: '**'                             , component : MainComponent, data: {routeName: 'app'}},
    { path: '404'                            , redirectTo: '/wait..', pathMatch: 'full', data: {routeName: 'app'}},
    { path: ''                               , redirectTo: '/wait..', pathMatch: 'full', data: {routeName: 'app'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
