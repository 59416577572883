import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';
import {FormService} from '../../forms/form.service';
import * as $ from 'jquery';
import {
 SpeechSynthesisUtteranceFactoryService,
 SpeechSynthesisService,
} from '@kamiazya/ngx-speech-synthesis';
@Component({
  selector: 'app-invitee-welcome-screen',
  templateUrl: './invitee-welcome-screen.component.html',
  styleUrls: ['./invitee-welcome-screen.component.scss'],
  providers: [
     SpeechSynthesisUtteranceFactoryService,
   ]
})
export class InviteeWelcomeScreenComponent implements OnInit {
  incomingResponse: any;
  incomingQuestions:any;
  incomingForm: any;
  incomingSettings: any;
  outgoingQuestion: any;
  controls: any;
  incomingQuestion: any;
  incomingInvitee: any;
  incomingResponseResult: any;
  incomingOption: any;
  outgoingRequest: any;
  incomingResult: any;
  incomingResults: any;
  googleTranslateUrl: any;
  outgoingRequestParams: any;
  incomingBannerQuestion: any;
  incomingAutoNumber: any;
  incomingQuestionSettings: any;
  constructor(public  service: AppService,
              private formService : FormService,
              public speechSynthesisUtteranceFactoryService: SpeechSynthesisUtteranceFactoryService,
              public speechSynthesisService: SpeechSynthesisService) {
   this.incomingQuestion = new Parameters();
   this.incomingResponse = new Parameters();
   this.incomingResult   = new Parameters();
   this.outgoingQuestion = new Parameters();
   this.incomingSettings = new Parameters();
   this.incomingOption = new Parameters();
   this.incomingBannerQuestion = new Parameters();
   this.outgoingRequestParams = new Parameters();
   this.incomingQuestionSettings = new Parameters();
   this.incomingForm = new Parameters();
   this.incomingInvitee = new Parameters();
   this.incomingQuestions = new Parameters();
   this.incomingResponseResult = new Parameters();
   this.incomingResults = new Parameters();
   this.incomingAutoNumber = new Parameters();
   this.outgoingRequest = new Parameters();
   this.incomingResponse.setAttributes(this.service.response);
   this.incomingForm.setAttributes(this.incomingResponse.getForm());
   this.incomingSettings.setAttributes(this.incomingForm.getSettings());
   this.incomingInvitee.setAttributes({});
   this.incomingAutoNumber.setAttributes({});
   this.incomingBannerQuestion.setAttributes({});
   this.incomingQuestionSettings.setAttributes({});
  }

  getSpeekInputText(){
   let soundText: any = '';
   if(this.service.empty(this.incomingSettings.getUnknown('language'))){
    soundText = 'karibu ' +
         this.incomingForm.getName().toLowerCase() + ' ' +
         this.incomingInvitee.getSalutation().toLowerCase() + ' ' +
         this.incomingInvitee.getName().toLowerCase();
   }else{
    soundText = 'welcome to ' +
      this.incomingForm.getName().toLowerCase() + ' ' +
      this.incomingInvitee.getSalutation().toLowerCase() + ' ' +
      this.incomingInvitee.getName().toLowerCase() + ' ' +
      this.incomingInvitee.getJobPosition().toLowerCase() + " from " + ' ' +
      this.incomingInvitee.getCompany().toLowerCase();
   }return soundText;
  }

  voiceSpeek(callback: any){
      if(parseFloat(this.incomingSettings.getText2SpeechEnabled()) === 0){
          const sound: any = new Audio('../assets/audio/attention.mp3');
          sound.play();
          sound.onended = () =>{
              const soundWelcome: any = new Audio('../assets/audio/welcome.mp3');
              soundWelcome.play();
              soundWelcome.onended = () =>{
               callback('welcome');
              }

          }
      }else{
           var params: any = {
               input: {
                   text: this.getSpeekInputText(),
               },
               audioConfig: {
                   audioEncoding: 'MP3',
                   pitch: 0,
                   speakingRate: 0.8
               },
               voice: {
                   languageCode: 'en-US',
                   name :  "en-US-News-L" ,
                   ssmlGender: "FEMALE"
               }
           };

//         LINEAR16
           params = JSON.stringify(params);
           var request = new XMLHttpRequest();
           request.open('POST', 'https://content-texttospeech.googleapis.com/v1/text:synthesize?alt=json&key=AIzaSyDYb6xEZk88bN8Cnl4ndElwoWzaKZzeZnE',true);
           request.setRequestHeader('Content-type', 'application/json')
           request.responseType = 'json';
           request.onload = () =>{
               this.incomingResult.setAttributes(request.response);
               this.incomingResult.setUnknown('audioContent', 'data:audio/x-wav;base64,' + this.incomingResult.getUnknown('audioContent'));
               let blob: any = this.service.dataURItoBlob(this.incomingResult.getUnknown('audioContent'));
               const arrayBuffer: any = Uint8Array.from(this.incomingResult.getUnknown('audioContent')).buffer;
               this.outgoingRequest.setAttributes({});
                   this.outgoingRequest.setSrc(null);
                   this.outgoingRequest.setFlag(0);
                   this.outgoingRequest.setCaption('');
                   this.outgoingRequest.setOrderBy('');
                   this.outgoingRequest.setStatus(1);
                   this.outgoingRequest.setUrl('');
                   this.outgoingRequest.setPath('');
                   this.outgoingRequest.setAvatar(blob);
                   this.service.httpService('post', this.service.app.routes.texttospeech.writeFile,
                     this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'},(result: any) => {
                        this.incomingResult.setAttributes(result);
                        const sound: any = new Audio('../assets/audio/attention.mp3');
                        sound.play();
                        sound.onended = () =>{
                            this.service.playSoundByUrl(this.incomingResult.getSrc(),()=>{
                              callback(this.incomingResult.getSrc());
                            });
                        }
                     }, (error: any) => {
                      callback(null);
                     });

           }
           request.onerror = (e: any) => {
            callback(null);
           }
           request.send(params);

     }
  }




  playSound(){
   const url: any = this.googleTranslateUrl;
   const sound: any = new Audio(url);
   sound.volume = 1;
   sound.play();
  }
  
  updateSettings(callback: any){
    setTimeout(() => {
          this.outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
          this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
          this.formService.update(this.outgoingRequest, (result: any) => {
          callback(null);
          });
    });
  }

  setQuestions(callback: any){
       this.incomingAutoNumber.setAttributes({});
       this.incomingAutoNumber.setData('');
       this.incomingForm.getControls().map((question: any)=>{
            this.incomingQuestion.setAttributes(question);
            this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
            this.incomingQuestionSettings.setIsAutoNumber(this.incomingQuestionSettings.attributes.hasOwnProperty('is_auto_number') ?
             this.incomingQuestionSettings.getIsAutoNumber() : 0);
            this.incomingResponse.getUnknown('datas').map((responseResult: any)=>{
              this.incomingResponseResult.setAttributes(responseResult);
              this.incomingResponseResult.setResult(this.incomingResponseResult.attributes.hasOwnProperty('result') ?
               this.incomingResponseResult.getResult() : {});
              this.outgoingQuestion.setAttributes(this.incomingResponseResult.getUnknown('formControl'));
              if(this.outgoingQuestion.getCntrlNum().includes(this.incomingQuestion.getCntrlNum())){
               this.incomingQuestion.setData(this.incomingResponseResult.getData());
               this.incomingQuestion.setResult(this.incomingResponseResult.getResult());
              }
            });
            if(parseFloat(this.incomingQuestionSettings.getIsAutoNumber()) === 1){
             this.incomingAutoNumber.setAttributes(this.incomingQuestion.getAttributes());
            }
       }); callback(null);
  }


  ngOnInit(): void {

   // speechSynthesis.speak(new SpeechSynthesisUtterance('Currently it is 3 o\'clock in the afternoon.'));
   //    var su
   //    su = new SpeechSynthesisUtterance();
   //    su.text = "Hello World";
   //    window.speechSynthesis.speak(su);
   //    speechSynthesis.cancel();

      // const speech: any = new  SpeechSynthesisUtterance('mashindano');
      // [speech.voice] = speechSynthesis.getVoices();
      // speechSynthesis.speak(speech);

      // const message = new SpeechSynthesisUtterance();
      // message.text = "Hi, I'm Javascript";
      // message.volume = 1; // Volume range = 0 - 1
      // message.rate = 1.2; // Speed of the text read , default 1
      // message.lang = 'en-US'; // Language, default 'en-US'
      // speechSynthesis.speak(message);
      this.googleTranslateUrl = 'https://translate.google.com/?sl=en&tl=sw&text=mashindano&op=translate';
      this.incomingSettings.setAttendeeName(this.incomingSettings.attributes.hasOwnProperty('attendee_name') ?
                this.incomingSettings.getAttendeeName() : '');
      this.incomingSettings.setAttendeeEmail(this.incomingSettings.attributes.hasOwnProperty('attendee_email') ?
         this.incomingSettings.getAttendeeEmail() : '');
      this.incomingSettings.setIsAttending(this.incomingSettings.attributes.hasOwnProperty('is_attending') ?
         this.incomingSettings.getIsAttending() : '');
      this.incomingSettings.setBanner(this.incomingSettings.attributes.hasOwnProperty('banner') ?
         this.incomingSettings.getBanner() : '');
      this.incomingSettings.setText2SpeechEnabled(this.incomingSettings.attributes.hasOwnProperty('text_2_speech_enabled') ?
         this.incomingSettings.getText2SpeechEnabled() : 0);
      this.incomingSettings.setAutoNumber(this.incomingSettings.attributes.hasOwnProperty('auto_number') ?
         this.incomingSettings.getAutoNumber() : '');
      this.incomingSettings.setBackgroundColor(this.incomingSettings.attributes.hasOwnProperty('background_color') ?
          this.incomingSettings.getBackgroundColor() : '');
      this.incomingSettings.setLogoUrl(this.incomingSettings.attributes.hasOwnProperty('logo_url') ?
         this.incomingSettings.getLogoUrl() : '');
      this.incomingSettings.setColor(this.incomingSettings.attributes.hasOwnProperty('color') ?
          this.incomingSettings.getColor() : '');
      this.incomingSettings.setUnknown('language',this.incomingSettings.attributes.hasOwnProperty('language') ?
          this.incomingSettings.getUnknown('language') : '');
      document.getElementsByTagName( 'body')[0].style.backgroundColor = this.incomingSettings.getBackgroundColor();
      document.getElementsByTagName( 'body')[0].style.color = this.incomingSettings.getColor();
      this.outgoingRequest.setAttributes({});
      this.controls = this.incomingResponse.getUnknown('datas');
      this.setQuestions(()=> {});
      this.controls.map((responseResult:any) => {
        this.incomingResponseResult.setAttributes(responseResult);
        this.incomingQuestion.setAttributes(Object.assign({}, this.incomingResponseResult.getUnknown('formControl')));
        this.incomingQuestion.setUnknown('data',this.incomingResponseResult.getUnknown('data'));
        if(this.incomingQuestion.getCntrlNum().toString() === this.incomingSettings.getAttendeeName())
          this.incomingInvitee.setName(this.incomingResponseResult.getData());
        if(this.incomingQuestion.getCntrlNum().toString() === this.incomingSettings.getAttendeeEmail())
          this.incomingInvitee.setEmail(this.incomingResponseResult.getData());
        if(this.incomingQuestion.getCntrlNum().toString() === this.incomingSettings.getBanner())
           this.incomingBannerQuestion.setAttributes(this.incomingQuestion.getAttributes());
        if(this.incomingQuestion.getCntrlNum().toString() === this.incomingSettings.getSalutation())
                    this.incomingInvitee.setSalutation(this.incomingResponseResult.getData());
        if(this.incomingQuestion.getCntrlNum().toString() === this.incomingSettings.getJobPosition())
           this.incomingInvitee.setJobPosition(this.incomingResponseResult.getData());
        if(this.incomingQuestion.getCntrlNum().toString() === this.incomingSettings.getAttendeePhone())
           this.incomingInvitee.setPhone(this.incomingResponseResult.getData());
        if(this.incomingQuestion.getCntrlNum().toString() === this.incomingSettings.getCompanyName())
          this.incomingInvitee.setCompany(this.incomingResponseResult.getData());
        if(this.incomingQuestion.getCntrlNum().toString() === this.incomingSettings.getIsAttending()){
          this.incomingInvitee.setIsAttending(this.incomingResponseResult.getData());
          this.incomingQuestion.setAttributes(Object.assign({}, this.incomingForm.getControls().filter((question: any)=>{return question.cntrl_num === this.incomingSettings.getIsAttending();})[0]));
          this.incomingQuestion.getOptions().map((option: any)=>{
           this.incomingOption.setAttributes(Object.assign({}, option));
           if(this.incomingOption.getLabel().toString().toLowerCase().includes('no')){
                this.outgoingRequestParams.setAttributes({});
                this.outgoingRequestParams.setData('Yes');
                this.outgoingRequestParams.setControlId(this.incomingQuestion.getControlId());
                this.outgoingRequestParams.setFormControlId(this.incomingQuestion.getId());
                this.outgoingRequestParams.setPostId(this.incomingResponse.getId());
                this.incomingResults.setAttributes({});
                this.incomingResult.setAttributes({});
                this.incomingResult.setLabel(this.incomingOption.getLabel());
                this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
                this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
                this.outgoingRequestParams.setResult(JSON.stringify(Object.assign({}, this.incomingResults.getAttributes())));
                if(parseFloat(this.incomingResponseResult.getId()) !== 0)
                this.outgoingRequestParams.setId(this.incomingResponseResult.getId());
           }
          });
        }
      });

      if(Object.keys(this.incomingInvitee.getAttributes()).length === 0){
       this.service.repeatScan();
       window.history.back();
      }else{
       if(this.service.empty(this.incomingInvitee.getIsAttending()) || this.incomingInvitee.getIsAttending().toString().toLowerCase().includes('no')){
           this.voiceSpeek((resultCallback: any ) => {
               if(resultCallback === null){
                this.service.repeatScan();
                window.history.back();
               }else{
                   window.history.back();
                   this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequestParams.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
                     this.incomingResult.setAttributes(result);
                   }, (error: any) =>  {
                   });
               }
           });
       }else {
        if(this.incomingSettings.getAttributes().hasOwnProperty('auto_number') &&
        !this.service.empty(this.incomingSettings.getAutoNumber()) &&
        this.incomingAutoNumber.getAttributes().hasOwnProperty('cntrl_num')){
             if(this.service.empty(this.incomingAutoNumber.getData())){
                  this.incomingAutoNumber.setData(parseFloat(this.incomingSettings.getAutoNumber())+1);
                  this.outgoingRequest.setAttributes({});
                  this.outgoingRequest.setData(parseFloat(this.incomingSettings.getAutoNumber())+1);
                  this.outgoingRequest.setControlId(this.incomingAutoNumber.getControlId());
                  this.outgoingRequest.setFormControlId(this.incomingAutoNumber.getId());
                  this.outgoingRequest.setPostId(this.incomingResponse.getId());
                  if(parseFloat(this.incomingResponseResult.getId()) !== 0)
                  this.outgoingRequest.setId(this.incomingResponseResult.getId());
                  this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
                  this.incomingSettings.setAutoNumber(this.incomingAutoNumber.getData());
                  this.updateSettings((result: any) =>{});
                  }, (error: any) =>  {
                  });
             }
        }else{
        this.service.cardScannedWithSomeoneelse();
        window.history.back();
        }
       }
      }
  }

  goB(){ window.history.back();
  }

}
