<div layout="row" class="">
  <div class="col-lg-2 col-md-2"></div>
  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
    <div>
      <app-label-create-control [question]="question"></app-label-create-control>
      <br><br>
    </div>
    <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
      <div class="panel-bodyy">
        <div >
          <div class='pg'>
            <center>
              <div class="{{service.parseFloat(incomingSettings.attributes.lfd) === 1 ? 'hidden-lg' : ''}}">
                <table>
                  <tr *ngFor="let option of question.options">
                    <td class="cChoice">
                      <mat-checkbox
                              #checked
                              class="pull-left"
                              [ngModel]='question.data.includes(option.cntrl_num)'
                              (contextmenu)="onContextMenu($event, option)"
                              (mousedown)="incomingSelectedOption.setAttributes(option);"
                              (change)="onClick(checked,option)"
                              [value]="option"
                      >
                        <span ngClass.md="sawasdee20"
                              ngClass.xl="sawasdee20"
                              ngClass.lg="sawasdee20"
                              ngClass.sm="sawasdee20"
                              ngClass.xs="sawasdee20"
                              [ngStyle]="{'color': formService.color}">{{service.upperStartingCharacter(option.label, true)}}
                        </span>
                      </mat-checkbox>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="{{service.parseFloat(incomingSettings.attributes.lfd) === 0 ? 'hidden-lg' : ''}} hidden-sm hidden-xs  hidden-md">
                <section class="post-section blog-post" style="background-color: transparent !important;">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"
                           *ngFor="let option of question.options"
                           (click)='onOptionSelected(option)'>
                        <div class="post-box" style="background-color:{{question.data.includes(option.cntrl_num) ? 'gold' : ''}} !important">
                          <div class="text-box">
                            <div class='pull-left'>
                              <mat-checkbox
                                      #checked
                                      [checked]="question.data.includes(option.cntrl_num)"
                                      [value]="option">
                                <span class="sawasdee20" [ngStyle]="{'color': '#5A738E'}">
                                  {{service.upperStartingCharacter(option.label, true)}}
                                </span>
                              </mat-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </center>
          </div>
        </div>
<!--        <div *ngIf="incomingForm.ge/ztSettings().hasOwnProperty('lfd')">-->
<!--          <div *ngIf="incomingForm.getSettings().lfd === 1" class='pg'>-->
<!--            <section class="post-section blog-post" style="background-color: transparent !important;">-->
<!--              <div class="container">-->
<!--                <div class="row">-->
<!--                  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 cursor"-->
<!--                       *ngFor="let option of question.options"-->
<!--                       (click)='onOptionSelected(option)'>-->
<!--                    <div class="post-box" style="background-color:{{question.data.includes(option.cntrl_num) ? 'gold' : ''}} !important">-->
<!--                      <div class="text-box">-->
<!--                        <h4>-->
<!--                          <a class="sawasdee18">{{option.label}}</a>-->
<!--                        </h4>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </section>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="row">
      <br><app-other-option-questions [question]="question" [position]="position"></app-other-option-questions>
    </div>
    <div class="row">
      <center>
        <app-next-button></app-next-button>
      </center>
    </div>
  </div>
  <div class="col-lg-2 col-md-2"></div>
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="inspector">
    <button mat-menu-item (click)="handleUpdate(incomingSelectedOption.getAttributes())">Edit</button>
    <button mat-menu-item (click)="removeOption(incomingSelectedOption.getAttributes())">Delete</button>
  </ng-template>
</mat-menu>
