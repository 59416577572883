<span class="pull-right cursor"
      (click)="onContextMenu($event, {})"
      (contextmenu)="onContextMenu($event, {})"
      (mousedown)="flag = 2">
    <i class="material-icons">more_vert</i>
</span>
<span class="pull-right" [hidden]="searchInput">
    <mat-form-field
            class="example-full-width"
            appearance='standard'
            style="width: 200px !important;">
        <input type="text"
               #searchElement
               aria-label = "Number"
               matInput
               (mouseleave)="searchIcon = false; searchInput = true"
               [(ngModel)]="searchCntrl"
        >
    </mat-form-field>
</span>
<a style="margin-right: 10px"
   class="material-icons pull-right cursor"
   [hidden]="searchIcon" (click)="onSearchIconClicked();searchIcon = false; searchInput= false">
    search
</a>
<center>
    <img class="img-circle"
         src="{{formService.selected.campaign.client.logo}}"
         style="width:40px;height:40px;margin-top: 10px">
</center>
<div class="row plist" style="margin-top: 5%">
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-0"></div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-sm-12">
        <mat-tab-group  [(selectedIndex)]="selectedTabIndex" mat-align-tabs="center">
            <mat-tab label="Published({{getResonsesByStatus('1').length}})">
                <div><br><br>
                    <section class="post-section blog-post" style="background-color: transparent !important;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 col-sm-6"
                                     (click)="view()"
                                     (contextmenu)="onContextMenu($event, response)"
                                     (mousedown)="flag = 1; selectedResponse = response"
                                     *ngFor="let response of getResonsesByStatus('1')" style="cursor: pointer">
                                    <div class="post-box" style="min-height: 200px !important">
                                        <div class="text-box">
                                            <h4>&nbsp;</h4>
                                            <center>
                                                <h4 class="primary-color">{{getTitle(response)}}</h4>
                                                <h5 class="primary-color">{{service.timeSince(response.date +  ' ' + response.starting_at)}}</h5>
                                                <p clas="primary-color">{{response.form.hasOwnProperty('progress') ? response.form.progress : 0}} %</p>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </mat-tab>
            <mat-tab label="Saved({{getResonsesByStatus('0').length}})">
                <div><br><br>
                    <section class="post-section blog-post" style="background-color: transparent !important;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 col-sm-6"
                                     (click)="addResponse()"
                                      style="cursor: pointer">
                                    <div class="post-box" style="min-height: 200px !important">
                                        <div class="text-box">
                                            <h4> &nbsp; </h4>
                                            <center><a class="cursor"><i class="material-icons">add</i></a></center>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6"
                                     (mousedown)="selectedResponse = response"
                                     (contextmenu)="onContextMenu($event, response)"
                                     (click)="selectedResponse = response;view()"
                                     *ngFor="let response of getResonsesByStatus('0')" style="cursor: pointer">
                                    <div class="post-box" style="min-height: 200px !important">
                                        <div class="text-box">
                                            <h4>&nbsp;</h4>
                                            <center>
                                                <h4 class="">{{getTitle(response)}}</h4>
                                                <h5 class="">{{service.timeSince(response.date +  ' ' + response.starting_at)}}</h5>
                                                <p  clas="">{{response.form.hasOwnProperty('progress') ? response.form.progress : 0}} %</p>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </mat-tab>

<!--            <mat-tab label="Dashboard">-->
<!--                <div><br><br>-->
<!--&lt;!&ndash;               <app-dashboard2-form [formId]="incomingForm.getId()"></app-dashboard2-form>&ndash;&gt;-->
<!--                </div>-->
<!--            </mat-tab>-->
<!--            <mat-tab label="{{incomingReport.getStartingAt()}}">-->
<!--                <div><br><br>-->
<!--                    <div>-->
<!--                        <a mat-mini-fab-->
<!--                           ngClass="pull-right"-->
<!--                           [ngStyle]="{'background': '#fff', 'color': '#263238'}"-->
<!--                           (click)="openDateDialog()">-->
<!--                            <i class="material-icons">keyboard_arrow_down</i></a>-->
<!--                        <div>-->
<!--                            <table id="datatable" class="table table-striped table-bordered" style="width:100%" [hidden]="service.responses.length === 0">-->
<!--                                <thead>-->
<!--                                <tr>-->
<!--                                    <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="service.parseFloat(incomingSettings.getResponsesCounter()) === 0">Outlet</th>-->
<!--                                    <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="service.parseFloat(incomingSettings.getResponsesCounter()) === 0">Address</th>-->
<!--                                    <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="service.parseFloat(incomingSettings.getResponsesCounter()) === 0">Phone</th>-->
<!--                                    <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngFor="let response of (service.responses.length === 0 ? [] : (getQuestions(service.responses[0].datas)))"><span title="{{service.removeHtmlTags(response.formControl.label)}}" [innerHTML]="service.limitTo(response.formControl.label.toString(), 60)"></span></th>-->
<!--                                </tr>-->
<!--                                </thead>-->
<!--                                <tbody>-->
<!--                                <tr *ngFor="let response of (getResponsesByUserId(service.app.data.user.id)| jsonFilter:searchCntrl)">-->
<!--                                    <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="response.hasOwnProperty('outlet')">{{response.outlet.name}}</td>-->
<!--                                    <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="response.hasOwnProperty('outlet')">{{response.outlet.address}}</td>-->
<!--                                    <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="response.hasOwnProperty('outlet')">{{response.outlet.phone}}</td>-->
<!--                                    <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2"  *ngFor="let resp of getQuestions(response.datas)">-->
<!--                                        <center>-->
<!--                                            <div *ngIf="resp.formControl.control.id !== 24 && resp.formControl.control.id !== 2 && resp.formControl.control.id !== 7"> <span [innerHTML]="resp.data"></span></div>-->
<!--                                            <div *ngIf="resp.formControl.control.id === 2">{{resp.data}}</div>-->
<!--                                            <div *ngIf="resp.formControl.control.id === 24">-->
<!--                                                <table style="width: 100%; background-color: #FFFFFF" class="table ">-->
<!--                                                    <thead style="width: 100%">-->
<!--                                                    <th *ngFor="let optional of resp.formControl.options" style="font-size: 17px; margin: 2px; min-width: 200px">-->
<!--                                                        <span class='cursor headline2' [innerHTML]="optional.label"></span>-->
<!--                                                    </th>-->
<!--                                                    </thead>-->
<!--                                                    <tbody>-->
<!--                                                    <tr *ngFor="let row of resp.rows" style="height: 50px !important; width: 100%; min-width: 200px">-->
<!--                                                        <td *ngFor="let option of resp.formControl.options; let optionIndex = index"-->
<!--                                                            contenteditable="true"-->
<!--                                                            style="height: 100%; width: 100%">-->
<!--                                                            <div #tdElement-->
<!--                                                                 style="height: 100%; width: 100%;min-width: 200px" *ngIf="option.type_id !== 3"-->
<!--                                                                 [innerHTML]="row.hasOwnProperty(option.cntrl_num) ? row[option.cntrl_num].data : ''">-->
<!--                                                            </div>-->
<!--                                                            <div style="height: 100%; width: 100% ; min-width: 200px" *ngIf="option.type_id === 3">-->
<!--                                                                <center>-->
<!--                                                                    <mat-checkbox-->
<!--                                                                        #checkboxElement-->
<!--                                                                        class="cursor"-->
<!--                                                                    >-->
<!--                                                                    </mat-checkbox>-->
<!--                                                                </center>-->
<!--                                                            </div>-->
<!--                                                        </td>-->
<!--                                                    </tr>-->
<!--                                                    </tbody>-->
<!--                                                </table>-->
<!--                                            </div>-->
<!--                                        </center>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                </tbody>-->
<!--                            </table>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </mat-tab>-->
        </mat-tab-group>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-0"></div>
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <div *ngIf="flag === 1">
            <button mat-menu-item (click)="view()">View</button>
            <button mat-menu-item (click)="setTitle()">Set Title</button>
            <button mat-menu-item (click)="remove()">Delete</button>
        </div>
        <div *ngIf="flag === 2">
<!--        <button mat-menu-item (click)="getResponse()"> Search for response  </button>-->
            <button mat-menu-item (click)="service.logout()"> Logout  </button>
        </div>
    </ng-template>
</mat-menu>
