<div layout="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="panel panel-default" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-body">
                <div>
                    <mat-form-field  class="example-full-width" appearance="fill">
                        <mat-label></mat-label>
                        <input  matInput
                                #pointElement
                                type="text"
                                [formControl] = "typeControl"
                                [matAutocomplete] = "type"/>
                        <a class="btn pull-right cursor" (click)="addPoint()"><i class="material-icons">add</i></a>
                        <mat-autocomplete #type = "matAutocomplete"
                                          (optionSelected)="onTypeSelectionChanged($event)"
                                          [displayWith]="displayFn">
                            <mat-option *ngFor="let type of filteredTypesOptions | async; let typeOf = index" [value]= "type" >
                                {{type.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <h4 style="color: red; font-weight: normal"
                            class="sawasdee18"
                            *ngIf="service.empty(pointElement.value) === false &&
                                              ((filteredTypesOptions | async) | json).length === 2">
                            **---Not Found *---
                        </h4>
                    </mat-form-field>
                </div>
                <div [ngStyle]="{'height': '440px', 'width': '100%'}">
                    <agm-map
                            #gm
                            [latitude]="lat"
                            [longitude]="lng"
                            [fitBounds]="false"
                            [zoom]="zoom"
                            (mapReady)="mapReadyHandler($event)"
                            [disableDefaultUI]="false"
                            [styles]="service.app.settings.map.styles.style1"
                            style="height : {{'430px'}}; width : 100%">
                        <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
                        <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
                        <div *ngIf="question !== null">
                            <div *ngIf="question.options.length !== 0">
                                <agm-direction
                                        [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : question.settings.hasOwnProperty('stroke_color') ? question.settings.stroke_color : '#ff8000'}}"
                                        [provideRouteAlternatives]="false"
                                        [origin]="origin.attributes"
                                        [destination]="destination.attributes"
                                        [waypoints]="waypoints">
                                </agm-direction>
                            </div>
                        </div>
                        <div *ngFor="let question of formService.questions">
                            <agm-marker
                                    *ngFor="let marker of question.options"
                                    [latitude]="marker.settings.lat"
                                    [longitude]="marker.settings.lng"
                                    (markerClick)="onmarkerClick(infoWindow,gm, marker)"
                                    [agmFitBounds]="true"
                                    [openInfoWindow]="true"
                                    [iconUrl]='icon'>
                                <agm-info-window
                                        #infoWindow>
                                    <div class="normal">
                                        <mat-tab-group mat-align-tabs="start">
                                            <mat-tab label="Images">
                                                <a class="cursor defaultColor"
                                                   mat-mini-fab
                                                   [ngStyle]="{'position': 'fixed', 'top':'50px','right':'10px'}"
                                                   (click)="img.click(); incomingSelectedOption.setAttributes(marker)"><i class="material-icons">add</i></a>
                                                <section class="post-section blog-post">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-md-4 col-sm-6" *ngFor="let image of (marker.settings.hasOwnProperty('images') ? marker.settings.images : [])">
                                                                <div class="post-box">
                                                                    <div class="frame" style="width:360px;height: 200px">
                                                                        <a>
                                                                            <img src="{{image.src}}" alt="img" [ngStyle]="{'max-height':'199px'}">
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </mat-tab>
                                            <mat-tab label="Video">

                                            </mat-tab>
                                            <mat-tab label="Settings">
                                                <mat-accordion [multi]="true">
                                                    <mat-expansion-panel [expanded]="true">
                                                        <div class="headline22">
                                                            <span class="headline22">Starting point</span>
                                                            <mat-checkbox
                                                                    class="pull-right"
                                                                    [checked]="marker.settings.hasOwnProperty('start') && marker.settings.start === 1"
                                                                    (change)="($event.checked ? marker.settings.start = 1 : marker.settings.start = 0);updateGeoSettings(marker, 'start')"
                                                            >
                                                            </mat-checkbox>
                                                        </div>
                                                    </mat-expansion-panel>
                                                    <mat-expansion-panel [expanded]="true">
                                                        <div class="headline22">
                                                            <span class="headline22">Ending point</span>
                                                            <mat-checkbox
                                                                    class="pull-right"
                                                                    [checked]="marker.settings.hasOwnProperty('end') && marker.settings.end === 1"
                                                                    (change)="($event.checked ? marker.settings.end = 1 : marker.settings.end = 0);updateGeoSettings(marker, 'end')"
                                                            >
                                                            </mat-checkbox>
                                                        </div>
                                                    </mat-expansion-panel>
                                                    <mat-expansion-panel [expanded]="true">
                                                        <div class="headline22" style="margin-top: 10px">
                                                            <table>
                                                                <td [ngStyle]="{'width':'100%'}" valign="top">
                                                                    Position
                                                                </td>
                                                                <td valign="top">
                                                                    <mat-form-field  class="pull-right" appearance="outline">
                                                                        <mat-label></mat-label>
                                                                        <input type="text"
                                                                               placeholder=""
                                                                               aria-label = "Number"
                                                                               matInput
                                                                               autocomplete="off"
                                                                               [ngStyle]="{'text-align':'center'}"
                                                                               [(ngModel)]="marker.settings.position"
                                                                        />
                                                                    </mat-form-field>
                                                                </td>
                                                            </table>
                                                        </div>
                                                    </mat-expansion-panel>
                                                    <mat-expansion-panel [expanded]="true">
                                                        <div class="headline22">
                                                            <span class="headline22">Title</span>
                                                            <div>
                                                                <mat-form-field  class="example-full-width"
                                                                                 appearance="fill">
                                                                    <input matInput
                                                                           type="text"
                                                                           [(ngModel)]="marker.label"/>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                    <mat-expansion-panel [expanded]="true">
                                                        <div class="headline22">
                                                            <span class="headline22">strokeColor</span>
                                                            <div>
                                                                <mat-form-field  class="example-full-width"
                                                                                 appearance="fill">
                                                                    <input matInput
                                                                           type="text"
                                                                           [(ngModel)]="marker.strokeColor"/>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                    <mat-expansion-panel [expanded]="true">
                                                        <div class="headline22">
                                                            <span class="headline22">Description</span>
                                                            <div>
                                                                <mat-form-field  class="example-full-width"
                                                                                 appearance="fill">
                                                                <textarea matInput type="text"
                                                                          placeholder="Description.."
                                                                          [(ngModel)]="marker.settings.description"></textarea>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                            </mat-tab>
                                        </mat-tab-group>
                                    </div>
                                </agm-info-window>
                            </agm-marker>
                        </div>
                        <agm-marker
                                [latitude]="currentLat"
                                [longitude]="currentLng"
                                [agmFitBounds]="true"
                                [openInfoWindow]="true"
                                (markerClick)="onClickCurrentPosition($event)"
                                [iconUrl]='currentPositionIcon'
                        >
                        </agm-marker>
                   </agm-map>
               </div>
                <div *ngIf="question !== null">
                    <mat-form-field  class="example-full-width" appearance="fill">
                        <mat-label>Stroke color</mat-label>
                        <input  matInput
                                type="text"
                                [ngModel]="question.settings.hasOwnProperty('stoke_color') ? question.settings['stoke_color'] : ''"
                                (input)="setSettings($event, 'stoke_color')">
                    </mat-form-field>
                </div>
            </div>
        </div>
   </div>
</div>
<input type="file" (change)="getImg($event)" [ngStyle]="{'display':'none'}" accept="image/*" #img>

