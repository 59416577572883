import {AfterViewInit, Component, ChangeDetectionStrategy, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AppService} from '../../../../app.service';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {Parameters} from '../../../../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormService} from '../../../form.service';
import {ProgressDialogComponent} from '../../../../main/progress-dialog/progress-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-respond-autocomplete-controls',
  templateUrl: './respond-autocomplete-controls.component.html',
  styleUrls: ['./respond-autocomplete-controls.component.scss']
})
export class RespondAutocompleteControlsComponent implements  AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('questionElement', {static: false}) questionElement: any;
  @ViewChild('matAutocompleteTrigger', {static: false}) matAutocompleteTrigger: any;
  @Input() question: any;
  @Input() position: any;
  @Input() status: any;
  @Input() item: any;
  data: any = '';
  optionalControl = new FormControl();
  filteredOptions: any;
  subscription: any;
  incomingOption: any;
  incomingQuestion: any;
  interval: any;
  incomingQ: any;
  incomingOpt: any;
  allOptions: any = [];
  dialogRef: any;
  spinner: any = false;
  outgoingRequest: any;
  incomingFilteredQustion: any;
  options: any = [];
  incomingResult: any;
  incomingResults: any;
  incomingSettings: any;
  incomingItem: any;
  constructor(public service: AppService,
              public formService: FormService,
              private dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService) {
    this.questionElement = ElementRef;
    this.matAutocompleteTrigger = MatAutocompleteTrigger;
    this.incomingOption   = new Parameters();
    this.incomingQuestion = new Parameters();
    this.outgoingRequest  = new Parameters();
    this.incomingResult   = new Parameters();
    this.incomingQ = new Parameters();
    this.incomingOpt = new Parameters();
    this.incomingFilteredQustion = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingItem = new Parameters();
    this.incomingResults = new Parameters();
  }

  start(changes: any){
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.setData(this.incomingQuestion.attributes.hasOwzznProperty('data') ? this.incomingQuestion.getData() : '');
    if(this.status !== undefined){
        this.incomingItem.setAttributes(this.item);
        this.incomingQuestion.setData(
        this.incomingItem.attributes.hasOwnProperty('data') ?
        this.incomingItem.getData() : '');
        this.incomingQuestion.setResult(this.incomingItem.attributes.hasOwnProperty('result') ? this.incomingItem.getResult() : {});
        this.setResult();
        if(this.incomingQuestion.attributes.hasOwnProperty('settings')){
          this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
          this.incomingQuestion.setOptions(this.incomingSettings.attributes.hasOwnProperty('options') ? this.incomingSettings.getOptions() : []);
        }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
        this.ngStart(() => {
          this.incomingQuestion.setAttributes(this.question);
          this.questionElement.nativeElement.value = this.incomingQuestion.attributes.hasOwnProperty('data') ? this.incomingQuestion.getData() : '';
          this.setResult();
          if(!this.service.empty(this.incomingQuestion.attributes.data)){
            setTimeout(() => {
              this.allOptions.map((option: any) => {
                this.incomingOption.setAttributes(option);
                if(this.incomingOption.attributes.label.toString().includes(this.incomingQuestion.attributes.data)){
                  this.optionalControl.setValue(this.incomingOption.getAttributes());
                }
              });
            });
          }
          this.ngAfterViewInit();
        });
  }


  ngStart(callback: any){
    this.requestAllOptions((resp: any) => {
      this.incomingQuestion.setAttributes(this.question);
      this.allOptions = Array.prototype.concat([], this.incomingQuestion.getOptions());
      try {
          if(this.incomingQuestion.attributes.hasOwnProperty('reference')) {
            if(!this.service.empty(this.incomingQuestion.getReference())){
              this.incomingQ.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQuestion.getReference()));
              if(Object.keys(this.incomingQ.getAttributes()).length !== 0){
                let options: any = this.incomingQ.getOptions().filter((option: any) => {
                  this.incomingOption.setAttributes(option);
                  return this.incomingOption.getLabel().toString() === this.incomingQ.getData();
                }); if(options.length > 0){
                  this.incomingOpt.setAttributes(options[0]);
                  this.allOptions = this.allOptions.filter((option: any) => {
                    this.incomingOption.setAttributes(option);
                    return !isNaN(this.incomingOpt.getCode()) && !isNaN(this.incomingOption.getReference()) ?
                        parseFloat(this.incomingOpt.getCode()) === parseFloat(this.incomingOption.getReference()) :
                        this.incomingOpt.getCode().toString().trim() === this.incomingOption.getReference().toString().trim();
                  });
                }else this.allOptions =  Array.prototype.concat([], []);
              }
            }
          }
      }catch (e) {}

      this.filteredOptions = this.optionalControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(name  => name ? this._filterOptional(name) : this.allOptions.slice(0, 20))
      );

      setTimeout(() => {


        this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
            .subscribe((item: any) => {
              if(item instanceof Object){
              }
            });

        callback({});
      });
   // this.dialogRef.close(null);
    });
  }

  openPanel(){
   this.matAutocompleteTrigger.openPanel();
  }

  openprogressDialog(){
    this.dialogRef =  this.dialog.open(ProgressDialogComponent, {
      data  : {},
      width : '100%',
      disableClose: true,
    });
  }


  requestAllOptions(callback: any){
    this.incomingQuestion.setAttributes(this.question);
    setTimeout((params: any) => {
      this.incomingQuestion.setAttributes(params.q);
      if(parseFloat(this.incomingQuestion.getItems()) === 2){
        callback({});
        // this.openprogressDialog();
        // this.service.httpService('post', '/questions/' + this.incomingQuestion.getId() + '/options/allrequest', {}, {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
        //   this.incomingQuestion.setOptions(response);
        //   this.incomingQuestion.setItems(0);
        //   callback({});
        //   }, (error: any) =>  {
        //   callback({});
        // });
      }else callback({});
    }, 0, {q: this.incomingQuestion.getAttributes()});
  }

  ngOnDestroy(): void {
      if(this.subscription){
         this.subscription.unsubscribe();
      }
  }

  onChangeOptionalText(event: any){
    this.question.data = '';
    this.setResult();
    setTimeout(() => {
      if(!this.service.empty(event.target.value)){
        this.incomingQuestion.setAttributes(this.question);
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setQuestionId(this.incomingQuestion.getId());
        this.outgoingRequest.setSearchText(event.target.value);
        if(this.incomingQuestion.attributes.hasOwnProperty('reference')) {
          if(!this.service.empty(this.incomingQuestion.getReference())){
            this.incomingFilteredQustion.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQuestion.getReference()));
            if(Object.keys(this.incomingFilteredQustion.getAttributes()).length !== 0) {
              if(!this.service.empty(this.incomingFilteredQustion.getCode())){
                this.outgoingRequest.setReference(this.incomingFilteredQustion.getCode());
              }
            }
          }
        }
        this.spinner = true;
        this.service.httpService('post', '/options/searchrequest',
            this.outgoingRequest.getAttributes(),
            {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
              // response.map((option: any) => {
              //   this.incomingOption.setAttributes(option);
              //   // let position: any = this.service.findIndex(this.allOptions, 'cntrl_num', this.incomingOption.getCntrlNum());
              //   // if(position === -1) this.incomingQuestion.getOptions().unshift(this.incomingOption.getAttributes());
              // });
              this.incomingQuestion.setOptions(response);
              this.incomingQuestion.setItems(0);
              this.spinner = false;
              this.ngStart(() => {
              });
            }, (error: any) =>  {
             this.spinner = false;
            });
      }
    }, this.spinnerTimeout());
  }
  spinnerTimeout(){return 112;}
  ngAfterViewInit(): void {
    setTimeout(()=> {
        if(this.questionElement.nativeElement){
          this.questionElement.nativeElement.focus();
          this.matAutocompleteTrigger.closePanel();
        }
    });
  }

  getLabelByOption(option: any){
    this.incomingOption.setAttributes(option);
    return this.service.empty(this.incomingOption.getCode()) ? this.incomingOption.getLabel() : this.incomingOption.getLabel() + ' (' + this.incomingOption.getCode() + ')';
  }

  displayFn(value: any){
    return !value.code || !/[^\s]+/.test(value.code) ? value.label : value.label + ' (' + value.code + ')';
  }

  onOptionalSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.question.data = event.option.value.label;
    this.setResult();
    this.spinner = false;
  }

  setResult(){
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.setResult({});
    this.incomingResults.setAttributes({});
    if(!this.service.empty(this.incomingQuestion.getData())){
        this.options = this.incomingQuestion.getOptions().filter((option: any) => {return option.label.toString() === this.incomingQuestion.getData().toString();});
        if(this.options.length !== 0){
          this.incomingOption.setAttributes(Object.assign({}, this.options[0]));
          this.incomingResult.setAttributes({});
          this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
          this.incomingResult.setLabel(this.incomingOption.getLabel());
          this.incomingResult.setCode(!this.service.empty(this.incomingOption.getCode()) ? this.incomingOption.getCode() : '');
          this.incomingResult.setReference(!this.service.empty(this.incomingQuestion.getReference()) ? this.incomingQuestion.getReference() : '')
          this.incomingQuestion.setResult(this.incomingResult.getAttributes());
          this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
        }
    }
  }

  private _filterOptional(label: string) {
    const filterValue = label.toString().toLowerCase();
    return this.allOptions.filter((option: any) => option.label.toString().toLowerCase().includes(filterValue) || (!this.service.empty(option.code) && option.code.toString().toLowerCase().includes(filterValue))).slice(0, 20);
  }

}
