<div id="main">
    <!--<div [ngStyle]="{'position':'fixed','top':'0px','background-color':'red','z-index':'9999999','height':'100%','width':'100%'}">dsfkdsf</div>-->
    <div class="banner-outer banner-2 home">
        <div class="banner-2" [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()/2+'px'}">
            <!--            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
            <!--                 src="assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg" />-->
            <div class="img" [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()/2+'px','z-index':'0'}">
                <carousel [cellsToScroll]="1"
                          [cellsToShow]="1"
                          [height]="service.getHeight()/2"
                          [autoplay]="true"
                          [arrows]="false"
                          [dots]="false"
                          [loop]="true"
                          *ngIf="meetings.length!==0">
                    <div class="carousel-cell"
                         [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()/2+'px'}"
                         *ngFor="let meeting of meetings">
                        <img src="{{meeting}}"
                             class="mdl-shadow--2dp"
                             [ngStyle]="{'width': '100%', 'height': service.getHeight()/2+'px', 'display':'block', 'object-fit': 'cover'}"/>
                    </div>
                </carousel>
            </div>
        </div>
    </div>
</div>
<div class="">
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 mitra18X2" [ngStyle]="{'color':'#fff'}">
        <br><br>
        <mat-tab-group mat-align-tabs="center">
            <mat-tab >
                <ng-template mat-tab-label>
                    <div>
                        <div class="mitra18X2" [ngStyle]="{'cursor':'pointer'}">meetings/events</div>
                    </div>
                </ng-template>
                <div class="mitra14X2">
                    {{('Spanning an area of 2,000 sqm and featuring the latest technology, Johari Rotana’s meeting, conference and wedding centre is one of the largest and most modern in Dar es Salaam and is designed to host a wide range of events, conferences and weddings.\n' +
                    '\n' +
                    '                    The 816 sqm Almasi Ballroom is the largest hotel ballroom in Tanzania and the most versatile ballroom in Dar es Salaam which can accommodate up to 950 guests. It can be used in its entirety or divided into smaller separate venues and is ideal for staging a wide range of events, from conferences, social gatherings, corporate banquets and cocktail parties to special occasions, including celebrations and weddings.\n' +
                    '\n' +
                    '                    All six meeting rooms in Dar es Salaam are located on the same floor and can be configured in several different formats, while the Bridal Suite doubles up as a VIP room or staging area for larger conferences. All venues feature the latest audio-visual equipment and Fibre Optic WiFi connectivity and boast natural daylight and stunning harbour views that are guaranteed to inspire your guests.\n' +
                    '\n' +
                    '                    Our professional event planning and catering teams will take care of every detail for you, from venue décor and layout to tailor-made menus.\n' +
                    '                    ').toLowerCase()}}
                </div>
            </mat-tab>
            <mat-tab label="Audio and visual">
                <div class="mitra14X2">
                        {{('Wharehouse Arena offers a wide range of equipment and facilities to ensure your meeting or event in Dar es Salaam runs smoothly and successfully. It is the only hotel in Dar es Salaam to offer Fibre Optic WiFi, ensuring a large number of guests can stay connected without impacting the internet stability.\n' +
                        '\n' +
                        '                                Equipment and facilities include:\n' +
                        '\n' +
                        '                                Lighting systems\n' +
                        '\n' +
                        '                                Dimmer lights for all meeting rooms\n' +
                        '                                Natural daylight\n' +
                        '                                Channel lighting control console\n' +
                        '                                Projection equipment\n' +
                        '\n' +
                        '                                Overhead projectors\n' +
                        '\n' +
                        '                                Motorised projector screens\n' +
                        '                                Laser pointers\n' +
                        '                                Flipcharts with markers\n' +
                        '\n' +
                        '                                Music system\n' +
                        '\n' +
                        '                                Channel mixing console\n' +
                        '                                Built-in speakers\n' +
                        '                                Wired and wireless microphones\n' +
                        '\n' +
                        '                                Additional equipment can be provided upon request. Please feel free to enquire with our events team regarding any additional requirements needed.\n' +
                        '                                ').toLowerCase()}}
                    </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
</div>



