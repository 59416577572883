<div class="row">
    <a class="pull-left"><h4>{{incomingForm.attributes.hasOwnProperty('name') ? incomingForm.getName().toString().toUpperCase() : 'Untitled Form'}}, settings</h4></a>
    <a class="pull-right" [matDialogClose]="true" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
</div>
<div [ngStyle]="{'margin-top': '20px'}">
    <mat-accordion [multi]="true">
<!--        <mat-expansion-panel [expanded]="true">-->
<!--            <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                    Custom Report-->
<!--                </mat-panel-title>-->
<!--                <mat-panel-description>-->
<!--                    Pick questionnaries dimensions and metrics and decide how they should be displayed to get insight.-->
<!--                </mat-panel-description>-->
<!--            </mat-expansion-panel-header>-->
<!--            <div [ngStyle]="{'margin-top': '10px'}">-->
<!--                <div> <a mat-mini-fab (click)="addCustomReport()"-->
<!--                         class="pull-right defaultColor2">-->
<!--                    <i class="material-icons">add</i>-->
<!--                </a>-->
<!--                </div><br><br><br>-->
<!--                <div [ngStyle]="{'margin-top': '20px'}">-->
<!--                    <mat-card class="example-card" *ngIf="incomingCustomReportSelected.attributes.hasOwnProperty('title')">-->
<!--                        <mat-card-title-group>-->
<!--                            <mat-card-title>-->
<!--                                <span contentEditable="true"-->
<!--                                      #titleElement-->
<!--                                      (blur)="afterContentEditable(titleElement.innerText, incomingCustomReportSelected.getAttributes())"-->
<!--                                      [innerHTML]="incomingCustomReportSelected.getTitle()">-->
<!--                                </span>-->
<!--                                <i-->
<!--                                        class="material-icons cursor"-->
<!--                                        [ngStyle]="{'margin-left': '5px'}"-->
<!--                                        (click)="menuStatus = 2; onContextMenu($event, incomingCustomReportSelected.getAttributes())">arrow_drop_down_circle</i>-->
<!--                            </mat-card-title>-->
<!--                            <mat-card-subtitle></mat-card-subtitle>-->
<!--                            <img mat-card-sm-image-->
<!--                                 class="cursor thumbnail"-->
<!--                                 [src]="getChartById(incomingCustomReportSelected.getChart()).icon"-->
<!--                                 (click)="menuStatus = 1 ;onContextMenu($event, incomingCustomReportSelected.getAttributes())"-->
<!--                                 (contextmenu)="menuStatus === 1; onContextMenu($event, incomingCustomReportSelected.getAttributes())"-->
<!--                            >-->
<!--                        </mat-card-title-group>-->
<!--                        <mat-card-content>-->
<!--                            <div [ngStyle]="{'margin-top': '10px'}">-->
<!--                                <mat-form-field  class="example-full-width" appearance="fill">-->
<!--                                    <mat-label>Include questions</mat-label>-->
<!--                                    <input type="text"-->
<!--                                           #customReportElement-->
<!--                                           placeholder=""-->
<!--                                           aria-label = "Number"-->
<!--                                           matInput-->
<!--                                           [formControl] = "customReportControl"-->
<!--                                           [matAutocomplete] = "customReport">-->
<!--                                    <mat-autocomplete #customReport = "matAutocomplete"-->
<!--                                                      (optionSelected)="onCustomReportQuestionSelected($event, incomingCustomReportSelected.getAttributes()); customReportElement.value = ''"-->
<!--                                                      [displayWith]="displayFn">-->
<!--                                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >-->
<!--                                            {{question.label}}-->
<!--                                        </mat-option>-->
<!--                                    </mat-autocomplete>-->
<!--                                    <h4 style="color: red; font-weight: normal"-->
<!--                                        *ngIf="service.empty(customReportElement.value) === false &&-->
<!--                                        ((filteredQuestionsOptions | async) | json).length === 2">-->
<!--                                        **-&#45;&#45;Not Found *-&#45;&#45;</h4>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="default">-->
<!--                                <mat-list>-->
<!--                                    <mat-list-item *ngFor="let question of incomingCustomReportSelected.getQuestions(); let questionIncremental = index">-->
<!--                                        <span class="" mat-line-->
<!--                                              (click)="menuStatus = 3 ;onContextMenu($event, question)"-->
<!--                                              (contextmenu)="menuStatus = 3 ;onContextMenu($event, question)"-->
<!--                                              (mousedown)="incomingCustomReportQuestionSelected.setAttributes(question)">-->
<!--                                            <span>-->
<!--                                                {{question.label}}-->
<!--                                            </span>-->
<!--                                            <i class="material-icons pull-right cursor" (click)="onCustomReportQuestionRemoved(question)">close</i>-->
<!--                                            <span class="pull-right" *ngIf="!service.empty(incomingCustomReportSelected.getGroupBy()) && question.cntrl_num.toString().includes(incomingCustomReportSelected.getGroupBy())">-->
<!--                                                <mat-checkbox-->
<!--                                                    #customReportcheckedElement-->
<!--                                                    [checked]="true">-->
<!--                                                </mat-checkbox>-->
<!--                                            </span>-->
<!--                                        </span>-->
<!--                                    </mat-list-item>-->
<!--                                    <mat-list-item>-->
<!--                                        <span class="" mat-line [ngStyle]="{'margin-top': '50px'}">-->
<!--                                            <textarea-->
<!--                                                    matInput-->
<!--                                                    [placeholder]="'Description'"-->
<!--                                                    (blur)="updateCustomReport(incomingCustomReportSelected.getAttributes())"-->
<!--                                                    [(ngModel)]="incomingCustomReportSelected.attributes.description">-->
<!--                                            </textarea>-->
<!--                                        </span>-->
<!--                                    </mat-list-item>-->
<!--                                </mat-list>-->
<!--                            </div>-->
<!--                        </mat-card-content>-->
<!--                        <mat-card-actions>-->
<!--                            &lt;!&ndash;                         <button mat-button>Delete</button>&ndash;&gt;-->
<!--                        </mat-card-actions>-->
<!--                    </mat-card>-->
<!--                </div>-->
<!--            </div>-->
<!--        </mat-expansion-panel>-->
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Set criteria
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-tab-group>
                <mat-tab label="Criteria">
                    <div [ngStyle]="{'margin-top': '10px'}">
                        <div>
                            <a mat-mini-fab (click)="addCriteria()"
                               class="pull-right defaultColor2">
                                <i class="material-icons">add</i></a>
                        </div><br><br>
                        <div [ngStyle]="{'margin-top': '0px'}">
                            <table [ngStyle]="{'width':'100%'}">
                                <tr *ngIf="incomingCriteriaSelected.hasAttribute('title')">
                                    <td class="cursor">
                                        <span (click)="menuStatus = 5 ;updateCriteria()">{{incomingCriteriaSelected.getTitle()}}</span>
                                        <span class="cursor"
                                              [ngStyle]="{'margin-left':'5px'}"
                                              (click)="menuStatus = 5 ;onContextMenu($event, null)">
                                            <a><i class="material-icons">keyboard_arrow_down</i></a>
                                        </span>
                                        <span class="cursor"
                                              [ngStyle]="{'margin-left':'5px'}"
                                              (click)="deleteCriteria()">
                                            <a><i class="material-icons">close</i></a>
                                        </span>
                                        <span class="cursor"
                                              [ngStyle]="{'margin-left':'5px'}"
                                              (click)="openSettingsDialog(incomingCriteriaSelected.getAttributes())">
                                            <a><i class="material-icons">settings</i></a>
                                        </span>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline"
                                                        [ngStyle]="{'width':'80px'}">
                                            <mat-label><span class="headline"></span></mat-label>
                                            <input type="text"
                                                   placeholder=""
                                                   aria-label = "Number"
                                                   matInput
                                                   autocomplete="off"
                                                   [(ngModel)]="incomingCriteriaSelected.attributes.target"
                                                   (blur)="update()"
                                                   [ngStyle]="{'text-align':'center'}"
                                            />
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-slide-toggle
                                            [checked]="this.incomingCriteriaSelected.getAttributes().hasOwnProperty('trending') && service.parseFloat(this.incomingCriteriaSelected.getTrending()) === 1"
                                            (change)="($event.checked ? this.incomingCriteriaSelected.setTrending(1) : this.incomingCriteriaSelected.setTrending(0));update()">
                                        </mat-slide-toggle>
                                    </td>
                                    <td>
                                        <center>
                                            <table>
                                                <tr *ngFor="let question of incomingCriteriaSelected.getQuestions();let criterialQuestionIncremental = index">
                                                    <td>{{question.label}}</td>
                                                    <td class="cursor"
                                                        [ngStyle]="{'color':'red'}"
                                                        (contextmenu)="incomingCriteriaQuestionSelected.setAttributes(question);menuStatus = 4 ;onContextMenu($event, null)"
                                                        (click)="incomingCriteriaQuestionSelected.setAttributes(question);">
                                                        {{question.operator}}
                                                    </td>
                                                    <td [ngStyle]="{'width':'100px'}">
                                                        <div *ngIf="!(question.hasOwnProperty('group_by') &&
                                                            question.group_by === 1 &&
                                                            !(question.control_id === 3 ||
                                                                question.control_id === 4 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 8 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 20||
                                                                question.control_id === 27||
                                                                question.control_id === 32))">
                                                            <ng-container *ngIf="
                                                                !(question.control_id === 3 ||
                                                                question.control_id === 4 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 8 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 20||
                                                                question.control_id === 27||
                                                                question.control_id === 32)">
                                                                <mat-form-field  class="example-full-width" appearance="outline">
                                                                    <mat-label></mat-label>
                                                                    <input type="text"
                                                                           placeholder=""
                                                                           list="listQuestions{{question.id}}"
                                                                           aria-label = "Number"
                                                                           autocomplete="off"
                                                                           [(ngModel)]="question.value"
                                                                           [ngStyle]="{'text-align':'center'}"
                                                                           (blur)="update()"
                                                                           matInput>
                                                                    <datalist id="listQuestions{{question.id}}">
                                                                        <option value="{{option.label}}" *ngFor="let option of question.options">
                                                                    </datalist>
                                                                </mat-form-field>
                                                            </ng-container>

                                                            <ng-container *ngIf="
                                                                question.control_id === 3 ||
                                                                question.control_id === 4 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 8 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 20||
                                                                question.control_id === 27||
                                                                question.control_id === 32">
                                                                <table>
                                                                    <tr *ngFor="let option of question.options">
                                                                        <td>
                                                                            <mat-checkbox
                                                                                #criteriaQuestionCheckbox
                                                                                class="pull-left"
                                                                                [value]="option"
                                                                                [checked]="option.hasOwnProperty('checked') && option.checked === 1"
                                                                                (change)="($event.checked ? option.checked = 1 : option.checked = 0);
                                                                                     checkCriteriaQuestionOption($event, question,option,criteriaQuestionCheckbox);
                                                                                     update()"
                                                                            >
                                                                              <span [ngStyle]="{'font-size':'14px'}">
                                                                                {{service.upperStartingCharacter(option.label, true)}}</span>
                                                                            </mat-checkbox>
                                                                        </td>
                                                                        <td class="cursor"
                                                                            (click)="openSettingsDialog(option)">
                                                                            <i class="materials-icon">settings</i>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </ng-container>
                                                        </div>
                                                    </td>
                                                    <td title="Group by">
                                                        <ng-container *ngIf="
                                                        question.control_id === 3 ||
                                                        question.control_id === 4 ||
                                                        question.control_id === 5 ||
                                                        question.control_id === 8 ||
                                                        question.control_id === 5 ||
                                                        question.control_id === 20||
                                                        question.control_id === 27||
                                                        question.control_id === 32">
                                                            <div>
                                                                <mat-slide-toggle
                                                                    #cateriQuestionSlide
                                                                    class="pull-right"
                                                                    [checked]="question.hasOwnProperty('group_by') && question.group_by === 1"
                                                                    (change)="($event.checked ? question.group_by = 1 : question.group_by = 0); hasCompleteCriteriaQuestions($event,question);update()">
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </ng-container>
                                                    </td>
                                                    <td>
                                                         <span class="cursor"
                                                               [ngStyle]="{'margin-left':'5px'}"
                                                               (click)="removeCriteriaQuestion(question, criterialQuestionIncremental)">
                                                            <a><i class="material-icons">close</i></a>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </center>
                                        <center>
                                            <a mat-mini-fab (click)="selectQuestion()"
                                               class="defaultColor2">
                                                <i class="material-icons">keyboard_arrow_down</i></a>
                                        </center>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Review">
                    <div [ngStyle]="{'margin': '20px'}">
                        <center>
                            <table>
                                <tr *ngFor="let criteria of service.orderBy((incomingSettings.attributes.hasOwnProperty('criterias')?incomingSettings.getCriterias():[]),'asc','index'); let incremental = index">
                                    <td>{{criteria.title}}</td>
                                    <td>
                                        <span><i class="material-icons"
                                                 *ngIf="incremental !== 0"
                                                 [ngStyle]="{'color':'#888','cursor':'pointer'}"
                                                 (click)="upShiftCriteria(criteria,incremental)">arrow_upward</i></span>
                                        <span><i class="material-icons"
                                                 *ngIf="incremental !== (incomingSettings.getCriterias().length-1)"
                                                 [ngStyle]="{'color':'#888','cursor':'pointer'}"
                                                 (click)="downShiftCriteria(criteria,incremental)">arrow_downward</i></span>
                                    </td>
                                </tr>
                            </table>
                        </center>
                    </div>
                </mat-tab>
            </mat-tab-group>

        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4 class=""> Pre-ﬁlled forms for faster data collection</h4>
                </mat-panel-title>
                <mat-panel-description>
                    Follow-up surveys and repeat data collection locations
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="">
                <mat-checkbox
                        class="pull-right"
                        [checked]="incomingForm.getSettings().hasOwnProperty('phase') && incomingForm.getSettings().phase === 1"
                        (change)="($event.checked ? incomingForm.getSettings().phase = 1 : incomingForm.getSettings().phase = 0); update()"
                >
                </mat-checkbox>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class=""> Is Text 2 Speech Enabled ?</span>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="">
                <mat-slide-toggle
                        class="pull-right"
                        [checked]="incomingSettings.getAttributes().hasOwnProperty('text_2_speech_enabled') && incomingSettings.getText2SpeechEnabled() === 1"
                        (change)="($event.checked ? incomingSettings.setText2SpeechEnabled(1): incomingSettings.setText2SpeechEnabled(0)); update()"
                >
                </mat-slide-toggle>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class=""> Is GPS Tracked ?</span>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="">
                <mat-slide-toggle
                        class="pull-right"
                        [checked]="incomingSettings.getAttributes().hasOwnProperty('is_gps_tracked') && incomingSettings.getIsGPSTracked() === 1"
                        (change)="($event.checked ? incomingSettings.setIsGPSTracked(1): incomingSettings.setIsGPSTracked(0)); update()"
                >
                </mat-slide-toggle>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class=""> Is Outlet ?</span>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="">
                <mat-slide-toggle
                        class="pull-right"
                        [checked]="incomingSettings.getAttributes().hasOwnProperty('is_outlet') && incomingSettings.getIsOutlet() === 1"
                        (change)="($event.checked ? incomingSettings.setIsOutlet(1): incomingSettings.setIsOutlet(0)); update()"
                >
                </mat-slide-toggle>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class=""> Form can be saved before published</span>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="">
                <mat-slide-toggle
                        class="pull-right"
                        [checked]="incomingForm.getSettings().hasOwnProperty('canBeSavedBeforePublished') && incomingForm.getSettings().canBeSavedBeforePublished === 1"
                        (change)="($event.checked ? incomingForm.getSettings().canBeSavedBeforePublished = 1 : incomingForm.getSettings().canBeSavedBeforePublished = 0); update()"
                >
                </mat-slide-toggle>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <!--            <mat-expansion-panel-header>-->
            <!--                <mat-panel-title>-->
            <!--                    <h4 class=""></h4>-->
            <!--                </mat-panel-title>-->
            <!--                <mat-panel-description>-->
            <!--                </mat-panel-description>-->
            <!--            </mat-expansion-panel-header>-->
            <div class="">
                Large Format Display
                <mat-checkbox
                        class="pull-right"
                        [checked]="incomingForm.getSettings().hasOwnProperty('lfd') && incomingForm.getSettings().lfd === 1"
                        (change)="($event.checked ? incomingForm.getSettings().lfd = 1 : incomingForm.getSettings().lfd = 0); update()"
                >
                </mat-checkbox>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div class="">
                Header
                <mat-checkbox
                        class="pull-right"
                        [checked]="incomingForm.getSettings().hasOwnProperty('header') && incomingForm.getSettings().header === 1"
                        (change)="($event.checked ? incomingForm.getSettings().header = 1 : incomingForm.getSettings().header = 0); update()"
                >
                </mat-checkbox>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div class="">
                Sales Target based on outlets
                <mat-checkbox
                        class="pull-right"
                        [checked]="incomingForm.getSettings().hasOwnProperty('sales_based_on_outlets') && incomingForm.getSettings().sales_based_on_outlets === 1"
                        (change)="($event.checked ? incomingForm.getSettings().sales_based_on_outlets = 1 : incomingForm.getSettings().sales_based_on_outlets = 0); update()"
                >
                </mat-checkbox>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div class="">
                Rate options
                <div>
                    <table>
                        <td class="cursor" (click)="updateRateOption()">{{incomingRateOptionSelected.hasAttribute('cntrl_num') ? incomingRateOptionSelected.getTitle() : ''}}</td>
                        <td><a><i class="material-icons cursor">keyboard_arrow_down</i></a></td>
                        <td><a><i class="material-icons cursor" (click)="removeRateOption()">close</i></a></td>
                        <td><a><i class="material-icons cursor" (click)="addRateOption()">add</i></a></td>
                    </table>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <div class="">
                Hide response counter
                <mat-checkbox
                        class="pull-right"
                        [checked]="incomingForm.getSettings().hasOwnProperty('responses_counter') && incomingForm.getSettings().responses_counter === 1"
                        (change)="($event.checked ? incomingForm.getSettings().responses_counter = 1 : incomingForm.getSettings().responses_counter = 0); update()"
                >
                </mat-checkbox>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4 class="">Allow respodents to save questionnaries before submiting</h4>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table class="" [ngStyle]="{'width':'100%'}">
                    <tr>
                        <td [ngStyle]="{'width':'100%'}" valign="top">
                            Powered by
                            <i class="material-icons pull-right cursor"
                               (click)="img.click()">add</i>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" [ngStyle]="{'width':'100%'}">
                            <img src="{{logo.path}}"
                                 class="thumbnail pull-left mdl-card mdl-shadow--2dp"
                                 *ngFor="let logo of incomingForm.attributes.settings.logo"
                                 [ngStyle]="{'max-width':'200px', 'max-height':'200px', 'margin-left':  '10px'}"
                                 (contextmenu)="this.incomingPoweredBySelected.setAttributes(logo); menuStatus = 6; onContextMenu($event, logo)"
                                 alt="{{logo.caption}}">
                            <!--                            <mat-grid-list cols="4" rowHeight="4:1">-->
                            <!--                                <mat-grid-tile-->
                            <!--                                        [ngStyle]="{'background':'#ebebeb'}"-->
                            <!--                                        *ngFor="let logo of incomingForm.attributes.settings.logo">-->
                            <!--                                    -->
                            <!--                                </mat-grid-tile>-->
                            <!--                            </mat-grid-list>-->

                            <!--                           <div class="demo-card-image mdl-card mdl-shadow--2dp pull-right"-->
                            <!--                                 style="background:url({{logo.path}}) center / cover"-->
                            <!--                                *ngFor="let logo of incomingForm.attributes.settings.logo">-->
                            <!--                                <div class="mdl-card__title mdl-card--expand"></div>-->
                            <!--                                <div class="mdl-card__actions">-->
                            <!--                                    <span class="demo-card-image__filename"></span>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <!--                            <mat-card class="example-card"-->
                            <!--                                      *ngFor="let logo of incomingForm.attributes.settings.logo">-->
                            <!--                                <mat-card-header>-->
                            <!--                                    <div mat-card-avatar class="example-header-image"></div>-->
                            <!--                                    <mat-card-title></mat-card-title>-->
                            <!--                                    <mat-card-subtitle></mat-card-subtitle>-->
                            <!--                                </mat-card-header>-->
                            <!--                                <img mat-card-image src="{{logo.path}}"-->
                            <!--                                     [ngStyle]="{'width':'100%'. 'height':'auto','max-height':'200px'}"-->
                            <!--                                     alt="Photo of a Shiba Inu">-->
                            <!--                                <mat-card-content>-->

                            <!--                                </mat-card-content>-->
                            <!--                                <mat-card-actions>-->
                            <!--                                    <button mat-button>LIKE</button>-->
                            <!--                                    <button mat-button>SHARE</button>-->
                            <!--                                </mat-card-actions>-->
                            <!--                            </mat-card>-->

                        </td>
                    </tr>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}"
                        class=''
                        valign="top">
                        Advert link
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.advert_url"
                                   #advertUrl
                                   (blur)="incomingForm.attributes.settings.advert_url = advertUrl.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}"
                        class=''
                        valign="top">
                        Auto Number
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.auto_number"
                                   #autoNumber
                                   (blur)="incomingForm.attributes.settings.auto_number = autoNumber.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}"
                        class=''
                        valign="top">
                        Reference
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.reference"
                                   #reference
                                   (blur)="incomingForm.attributes.settings.reference = reference.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            >
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}"
                        class=''
                        valign="top">
                        Submit button text
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.submit"
                                   #submit
                                   (blur)="incomingForm.attributes.settings.submit = submit.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Banner
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmBanner
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setBanner(''); update()"
                                   [formControl] = "bannerControl"
                                   [matAutocomplete] = "banner"/>
                            <mat-autocomplete #banner = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setBanner($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmBanner.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}"
                        class=''
                        valign="top">
                        outlets That Sell Kpi
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.outlets_that_sell_kpi"
                                   #outletsThatSellKpi
                                   (blur)="incomingForm.attributes.settings.outlets_that_sell_kpi = outletsThatSellKpi.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}"
                        class=''
                        valign="top">
                        Visted outlets alias name
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.visted_outlet_alias_name"
                                   #vistedOutletAlias
                                   (blur)="incomingForm.attributes.settings.visted_outlet_alias_name = vistedOutletAlias.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Text color
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.color"
                                   #textColor
                                   (blur)="incomingForm.attributes.settings.color = textColor.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table class="">
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Target
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.target"
                                   #target
                                   (blur)="incomingForm.attributes.settings.target = target.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Starting
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.starting_at"
                                   #starting
                                   (blur)="incomingForm.attributes.settings.starting_at = starting.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Background color
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.background_color"
                                   #backgroundColor
                                   (blur)="incomingForm.attributes.settings.background_color = backgroundColor.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <ng-container *ngIf="!service.empty(incomingSettings.attributes.outlet)">
            <mat-expansion-panel [expanded]="true">
                <div style="margin-top: 10px">
                    <table>
                        <td [ngStyle]="{'width':'100%'}" valign="top">
                            New Customers reach kpi
                        </td>
                        <td valign="top">
                            <mat-form-field  class="pull-right" appearance="outline">
                                <mat-label></mat-label>
                                <input type="text"
                                       placeholder=""
                                       aria-label = "Number"
                                       matInput
                                       autocomplete="off"
                                       [(ngModel)]="incomingForm.attributes.settings.new_customers_kpi"
                                       #newCustomersKpi
                                       (blur)="incomingForm.attributes.settings.new_customers_kpi = newCustomersKpi.value; update()"
                                       [ngStyle]="{'text-align':'center'}"
                                />
                            </mat-form-field>
                        </td>
                    </table>
                </div>
            </mat-expansion-panel>
        </ng-container>

        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label>Set Responses Display Title</mat-label>
                    <input matInput type="text"
                           [(ngModel)]="incomingForm.attributes.response_display_title" #responseDisplayTitleElement (blur)="incomingForm.setResponseDisplayTitle(responseDisplayTitleElement.value); update()">
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label>Set Key Performance Display Title</mat-label>
                    <input matInput type="text"
                           [(ngModel)]="incomingForm.attributes.kpi_display_title"
                           #kpiDisplayTitleElement
                           (blur)="incomingForm.setKpiDisplayTitle(kpiDisplayTitleElement.value);
                           update()">
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label>Title based on</mat-label>
                    <input type="text"
                           #titleElement
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           [formControl] = "titleControl"
                           [matAutocomplete] = "title"/>
                    <mat-autocomplete #title = "matAutocomplete"
                                      (optionSelected)="incomingForm.setTitle($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(titleElement.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label>Capture location throught this field</mat-label>
                    <input type="text"
                           #coordinateElement
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           [formControl] = "coordinateControl"
                           [matAutocomplete] = "coordinates"/>
                    <mat-autocomplete #coordinates = "matAutocomplete"
                                      (optionSelected)="incomingForm.setGpsReference($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(coordinateElement.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label>Restriction</mat-label>
                    <input type="text"
                           #uniqidElement
                           placeholder=""
                           aria-label = "Number"
                           (blur)="onTextChange($event, 'uniqid')"
                           matInput
                           [formControl] = "uniqidControl"
                           [matAutocomplete] = "uniqid"/>
                    <mat-autocomplete #uniqid = "matAutocomplete"
                                      (optionSelected)="incomingForm.setUniqid($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(uniqidElement.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label>Change Respodent Name</mat-label>
                    <input matInput
                           type="text"
                           [(ngModel)]="incomingForm.attributes.respondent_alias"
                           #respodentAliasElement
                           (blur)="incomingForm.setRespondentAlias(respodentAliasElement.value);
                           update()">
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <!--    <mat-expansion-panel [expanded]="true">-->
        <!--            <div style="margin-top: 10px">-->
        <!--                <mat-form-field  class="example-full-width" appearance="outline">-->
        <!--                    <mat-label>Core Business field</mat-label>-->
        <!--                    <input type="text"-->
        <!--                           #coreBusinessFieldElement-->
        <!--                           placeholder=""-->
        <!--                           aria-label = "Number"-->
        <!--                           matInput-->
        <!--                           [formControl] = "coreBusinessFieldControl"-->
        <!--                           [matAutocomplete] = "coreBusinessField"/>-->
        <!--                    <mat-autocomplete #coreBusinessField = "matAutocomplete"-->
        <!--                                      (optionSelected)="incomingForm.setCoreBusinessField($event.option.value.cntrl_num); this.update()"-->
        <!--                                      [displayWith]="displayFn">-->
        <!--                        <mat-option *ngFor="let question of filteredCoreBusinessFieldOptions | async; let questionOf = index"-->
        <!--                                    [value]= "question" >-->
        <!--                            {{question.label}}-->
        <!--                        </mat-option>-->
        <!--                    </mat-autocomplete>-->
        <!--                    <h4 style="color: red; font-weight: normal"-->
        <!--                        *ngIf="service.empty(coreBusinessFieldElement.value) === false &&-->
        <!--                                 ((filteredCoreBusinessFieldOptions | async) | json).length === 2">-->
        <!--                        **---Not Found *---</h4>-->
        <!--                </mat-form-field>_-->
        <!--            </div>-->
        <!--    </mat-expansion-panel>-->
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Products</mat-label>
                    <mat-chip-list #chipList aria-label="selection">
                        <mat-chip
                                *ngFor="let questional of currentQuestionalProducts"
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="remove(questional)">
                            {{questional.label}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input
                                placeholder=""
                                #productElement
                                [formControl]="productCtrl"
                                [matAutocomplete]="product"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #product="matAutocomplete" (optionSelected)="onSelectProduct($event)">
                        <mat-option *ngFor="let question of filteredProductOptions | async" [value]="question">
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(productElement.value) === false &&
                                     ((filteredProductOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Outlet</mat-label>
                    <input type="text"
                           #outletElm
                           placeholder=""
                           aria-label = "Number"
                           (input)="incomingSettings.setOutlet(''); update()"
                           matInput
                           [formControl] = "outletControl"
                           [matAutocomplete] = "outlet"/>
                    <mat-autocomplete #outlet = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setOutlet($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(outletElm.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Address</mat-label>
                    <input type="text"
                           #elmAddress
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setLocation(''); update()"
                           [formControl] = "addressControl"
                           [matAutocomplete] = "address"/>
                    <mat-autocomplete #address = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setLocation($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmAddress.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Contact
                    </td>
                    <td valign="top">
                        <mat-form-field  class="pull-right" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.contact"
                                   #contact
                                   (blur)="incomingForm.attributes.settings.contact = contact.value; update()"
                                   [ngStyle]="{'text-align':'center'}"
                            />
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 4">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Response Channel
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label></mat-label>
                            <input type="text"
                                   #elmresponseChannel
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   [formControl] = "responseChannelControl"
                                   [matAutocomplete] = "responseChannel"/>
                            <mat-autocomplete #responseChannel = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setResponseChannel($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmresponseChannel.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 4">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Invitation card sent through whatsapp
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmInvitationCardSentThroughWhatsapp
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setInvitationCardSentThroughWhatsapp(''); update()"
                                   [formControl] = "invitationCardSentThroughWhatsappControl"
                                   [matAutocomplete] = "invitationCardSentThroughWhatsapp"/>
                            <mat-autocomplete #invitationCardSentThroughWhatsapp = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setInvitationCardSentThroughWhatsapp($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmInvitationCardSentThroughWhatsapp.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true" *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 4">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Invitation card emailed
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmInvitationCardEmailed
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setInvitationCardEmailed(''); update()"
                                   [formControl] = "invitationCardEmailedControl"
                                   [matAutocomplete] = "invitationCardEmailed"/>
                            <mat-autocomplete #invitationCardEmailed = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setInvitationCardEmailed($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmInvitationCardEmailed.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Frame
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingSettings.attributes.qrcode_frame"
                                   #elmQrcodeFrame
                                   (blur)="incomingSettings.setQrcodeFrame(elmQrcodeFrame.value); update()"
                                   [ngStyle]="{'text-align':'center'}"/>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Invitation message
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmInvitationContents
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setInvitationContents(''); update()"
                                   [formControl] = "invitationContentsControl"
                                   [matAutocomplete] = "invitationContents"/>
                            <mat-autocomplete #invitationContents = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setInvitationContents($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmInvitationContents.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Invitation card
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmInvitationCard
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setInvitationCard(''); update()"
                                   [formControl] = "invitationCardControl"
                                   [matAutocomplete] = "invitationCard"/>
                            <mat-autocomplete #invitationCard = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setInvitationCard($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmInvitationCard.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        is Invited
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmIsInvited
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setIsInvited(''); update()"
                                   [formControl] = "isInvitedControl"
                                   [matAutocomplete] = "isInvited"/>
                            <mat-autocomplete #isInvited = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setIsInvited($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmIsInvited.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Job Position
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmJobPosition
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setJobPosition(''); update()"
                                   [formControl] = "jobPositionControl"
                                   [matAutocomplete] = "jobPosition"/>
                            <mat-autocomplete #jobPosition = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setJobPosition($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmJobPosition.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        is Confirm
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmIsConfirm
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setIsConfirm(''); update()"
                                   [formControl] = "isConfirmControl"
                                   [matAutocomplete] = "IsConfirm"/>
                            <mat-autocomplete #IsConfirm = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setIsConfirm($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmIsConfirm.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        is Invited by whatsapp
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmIsInvitedByWhatsapp
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setIsInvitedByWhatsapp(''); update()"
                                   [formControl] = "isInvitedByWhatsappControl"
                                   [matAutocomplete] = "IsInvitedByWhatsapp"/>
                            <mat-autocomplete #IsInvitedByWhatsapp = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setIsInvitedByWhatsapp($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmIsInvitedByWhatsapp.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Date on set
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.date_on_set"
                                   #elmDateOnSet
                                   (blur)="incomingForm.attributes.settings.date_on_set = elmDateOnSet.value; update()"
                                   [ngStyle]="{'text-align':'center'}"/>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Logo
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.logo"
                                   #elmLogoUrl
                                   (blur)="incomingForm.attributes.settings.logo = elmLogoUrl.value; update()"
                                   [ngStyle]="{'text-align':'center'}"/>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Venue
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.venue"
                                   #elmVenue
                                   (blur)="incomingForm.attributes.settings.venue = elmVenue.value; update()"
                                   [ngStyle]="{'text-align':'center'}"/>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Time
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.time"
                                   #elmTime
                                   (blur)="incomingForm.attributes.settings.time = elmTime.value; update()"
                                   [ngStyle]="{'text-align':'center'}"/>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Attending Date
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.attending_date"
                                   #elmAttendingDate
                                   (blur)="incomingForm.attributes.settings.attending_date = elmAttendingDate.value; update()"
                                   [ngStyle]="{'text-align':'center'}"/>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Declined Landing Page Contents
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <textarea type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.declined_landing_page_contents"
                                   #elementDeclinedLandingPageContents
                                   (blur)="incomingForm.attributes.settings.declined_landing_page_contents = elementDeclinedLandingPageContents.value; update()"
                                   [ngStyle]="{'text-align':'center'}">
                            </textarea>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Arriving invitation card contents
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <textarea type="text"
                                      placeholder=""
                                      aria-label = "Number"
                                      matInput
                                      autocomplete="off"
                                      [(ngModel)]="incomingForm.attributes.settings.arriving_invitation_card_contents"
                                      #arrivingInvitationCardContents
                                      (blur)="incomingSettings.setArrivingInvitationCardContents(arrivingInvitationCardContents.value); update()"
                                      [ngStyle]="{'text-align':'center'}">
                            </textarea>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        After Accept Invitation Landing Page Contents
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <textarea type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.after_accept_landing_page_contents"
                                   #elementAfterAcceptLandingPageContents
                                   (blur)="incomingForm.attributes.settings.after_accept_landing_page_contents = elementAfterAcceptLandingPageContents.value; update()"
                                   [ngStyle]="{'text-align':'center'}">
                            </textarea>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
        <div style="margin-top: 10px">
            <table>
                <td [ngStyle]="{'width':'100%'}" valign="top">
                    Description
                </td>
                <td valign="top">
                    <mat-form-field  class="example-full-width" appearance="outline">
                        <mat-label class=""></mat-label>
                        <input type="text"
                               placeholder=""
                               aria-label = "Number"
                               matInput
                               autocomplete="off"
                               [(ngModel)]="incomingForm.attributes.settings.description"
                               #elmDescription
                               (blur)="incomingForm.attributes.settings.description = elmDescription.value; update()"
                               [ngStyle]="{'text-align':'center'}"/>
                    </mat-form-field>
                </td>
            </table>
        </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        RSVP Date
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   autocomplete="off"
                                   [(ngModel)]="incomingForm.attributes.settings.rsvp_date"
                                   #elmARSVPDate
                                   (blur)="incomingForm.attributes.settings.rsvp_date = elmARSVPDate.value; update()"
                                   [ngStyle]="{'text-align':'center'}"/>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <ng-container>
            <mat-expansion-panel [expanded]="true">
                <div style="margin-top: 10px">
                    <table>
                        <td [ngStyle]="{'width':'100%'}" valign="top">
                            is Attending
                        </td>
                        <td valign="top">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <mat-label class=""></mat-label>
                                <input type="text"
                                       class="textAlignCenter"
                                       #elmIsAttending
                                       placeholder=""
                                       aria-label = "Number"
                                       matInput
                                       (input)="incomingSettings.setIsAttending(''); update()"
                                       [formControl] = "isAttendingControl"
                                       [matAutocomplete] = "isAttending"/>
                                <mat-autocomplete #isAttending = "matAutocomplete"
                                                  (optionSelected)="incomingSettings.setIsAttending($event.option.value.cntrl_num); update()"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                        {{question.label}}
                                    </mat-option>
                                </mat-autocomplete>
                                <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.empty(elmIsAttending.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                    **---Not Found *---</h4>
                            </mat-form-field>
                        </td>
                    </table>
                </div>
            </mat-expansion-panel>
        </ng-container>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Attendee pic
                    </td>
                    <td valign="top">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class=""></mat-label>
                            <input type="text"
                                   class="textAlignCenter"
                                   #elmAttendeePic
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingSettings.setAttendeePic(''); update()"
                                   [formControl] = "attendeePicControl"
                                   [matAutocomplete] = "attendeePic"/>
                            <mat-autocomplete #attendeePic = "matAutocomplete"
                                              (optionSelected)="incomingSettings.setAttendeePic($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.empty(elmAttendeePic.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Contact person name</mat-label>
                    <input type="text"
                           #elmContactPersonName
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setContactPersonName(''); update()"
                           [formControl] = "contactPersonNameControl"
                           [matAutocomplete] = "contactPersonName"/>
                    <mat-autocomplete #contactPersonName = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setContactPersonName($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmContactPersonName.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Contact person phone</mat-label>
                    <input type="text"
                           #elmContactPersonPhone
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setContactPersonPhone(''); update()"
                           [formControl] = "contactPersonPhoneControl"
                           [matAutocomplete] = "contactPersonPhone"/>
                    <mat-autocomplete #contactPersonPhone = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setContactPersonPhone($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmContactPersonPhone.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Contact person title</mat-label>
                    <input type="text"
                           #elmContactPersonTitle
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setContactPersonTitle(''); update()"
                           [formControl] = "contactPersonTitleControl"
                           [matAutocomplete] = "contactPersonTitle"/>
                    <mat-autocomplete #contactPersonTitle = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setContactPersonTitle($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmContactPersonTitle.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Attendee name</mat-label>
                    <input type="text"
                           #elmAttendeeName
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setAttendeeName(''); update()"
                           [formControl] = "attendeeNameControl"
                           [matAutocomplete] = "attendeeName"/>
                    <mat-autocomplete #attendeeName = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setAttendeeName($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmAttendeeName.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Attendee email</mat-label>
                    <input type="text"
                           #elmAttendeeEmail
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setAttendeeEmail(''); update()"
                           [formControl] = "attendeeEmailControl"
                           [matAutocomplete] = "attendeeEmail"/>
                    <mat-autocomplete #attendeeEmail = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setAttendeeEmail($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmAttendeeEmail.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Attendee phone</mat-label>
                    <input type="text"
                           #elmAttendeePhone
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setAttendeePhone(''); update()"
                           [formControl] = "attendeePhoneControl"
                           [matAutocomplete] = "attendeePhone"/>
                    <mat-autocomplete #attendeePhone = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setAttendeePhone($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmAttendeePhone.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class=""> company Name</mat-label>
                    <input type="text"
                           #elmCompanyName
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setCompanyName(''); update()"
                           [formControl] = "companyNameControl"
                           [matAutocomplete] = "companyName"/>
                    <mat-autocomplete #companyName = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setCompanyName($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmCompanyName.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class=""> Salutation</mat-label>
                    <input type="text"
                           #elmSalutation
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setSalutation(''); update()"
                           [formControl] = "salutationControl"
                           [matAutocomplete] = "salutation"/>
                    <mat-autocomplete #salutation = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setSalutation($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmSalutation.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">RSVP confirmation </mat-label>
                    <input type="text"
                           #elmRSVPConfirmation
                           placeholder=""
                           aria-label = "Number"
                           matInput
                           (input)="incomingSettings.setRSVPConfirmation(''); update()"
                           [formControl] = "rsvpConfirmationControl"
                           [matAutocomplete] = "rsvpConfirmation"/>
                    <mat-autocomplete #rsvpConfirmation = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setRSVPConfirmation($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elmRSVPConfirmation.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label class="">Date</mat-label>
                    <input type="text"
                           #elementDate
                           placeholder=""
                           aria-label = "Number"
                           (input)="incomingSettings.setDate(''); update()"
                           matInput
                           [formControl] = "dateControl"
                           [matAutocomplete] = "date"/>
                    <mat-autocomplete #date = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setDate($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(elementDate.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label class="">Stock</mat-label>
                    <input type="text"
                           placeholder=""
                           aria-label = "Number"
                           #stockItemElm
                           matInput
                           [formControl] = "stockItemControl"
                           [matAutocomplete] = "stockItems"/>
                    <mat-autocomplete #stockItems = "matAutocomplete"
                                      (optionSelected)="incomingSettings.setStock($event.option.value.cntrl_num); update()"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                            {{question.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.empty(stockItemControl.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<input type="file" (change)="getImg($event)" [ngStyle]="{'display':'none'}" accept="image/*" #img>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [hasBackdrop]="false">
    <ng-template matMenuContent let-item="inspector">
        <div *ngIf="menuStatus === 1">
            <div style="min-width: 400px; margin: 5px">
                <mat-grid-list cols="2" rowHeight="2:1">
                    <mat-grid-tile
                            [title]="chart.title"
                            *ngFor="let chart of charts; let chartIncremental = index">
                        <img class="cursor"
                             src="{{chart.icon}}"
                             (click)="incomingCustomReportSelected.setChart(chart.id); updateCustomReport(incomingCustomReportSelected.getAttributes())"
                             [ngStyle]="{'width': 'max-width: 100px', 'max-height':'100px', 'margin': '5px'}"
                        />
                        <mat-checkbox [checked]="true" class="pull-right" *ngIf="chart.id === incomingCustomReportSelected.getChart()"></mat-checkbox>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
        <div *ngIf="menuStatus === 2" [ngStyle]="{'margin': '5px'}" class="default">
            <mat-list>
                <mat-list-item *ngFor="let report of incomingForm.getCustomReports().data">
                    <span class="" mat-line (click)="onCustomReportSelected(report)">
                        {{report.title}}
                        <mat-checkbox class="pull-right cursor" [ngStyle]="{'margin-left': '20px'}"></mat-checkbox>
                    </span>
                </mat-list-item>
            </mat-list>
        </div>
        <div *ngIf="menuStatus === 3" [ngStyle]="{'margin': '5px'}" class="default">
            <button mat-menu-item
                    (click)="incomingCustomReportSelected.setGroupBy(incomingCustomReportQuestionSelected.getCntrlNum());
                                                updateCustomReport(incomingCustomReportSelected.getAttributes())">Group By</button>
        </div>
        <div *ngIf="menuStatus === 4" [ngStyle]="{'margin': '5px'}" class="default">
            <button mat-menu-item (click)="setCriteriaQuestionOperator(operator)"
                    *ngFor="let operator of operators">{{operator}}</button>
        </div>
        <div *ngIf="menuStatus === 5" [ngStyle]="{'margin': '5px'}" class="default">
            <button mat-menu-item
                    (keydown)="$event.stopPropagation()"
                    (click)="$event.stopPropagation();incomingCriteriaSelected.setAttributes(criteria)"
                    *ngFor="let criteria of incomingSettings.getCriterias()">{{criteria.title}}</button>
        </div>
        <div *ngIf="menuStatus === 6" [ngStyle]="{'margin': '5px'}" class="default">
            <button mat-menu-item
                    (click)="removePoweredBy()">Delete
            </button>
        </div>
    </ng-template>
</mat-menu>
