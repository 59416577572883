<div id="example-radio-group-label"
    class="cursor"
     [ngStyle]="{'color': this.color}"
    #element>
    <h4>
        <span [ngStyle]="{'color':'red'}">{{(question.required === 1 ? '* ' : '') }}</span>
        <span [ngStyle]="{'color': this.color}">
            <ng-container *ngIf="this.service.parseFloat(incomingForm.getCategoryId()) === 5;else labelText">
                <span class="cursor mitra" [ngStyle]="{'font-size':'24px'}">{{service.upperStartingCharacter(service.removeHtmlTags(question.label), true)}}</span>
            </ng-container>
            <ng-template #labelText>
                <ng-container >
                    <span class="cursor sawasdee20">{{service.upperStartingCharacter(service.removeHtmlTags(question.label), true)}}</span>
                </ng-container>
            </ng-template>
        </span>
    </h4>
</div>
<!--ngClass.md="sawasdee38"-->
<!--ngClass.lg="sawasdee38"-->
<!--ngClass.sm="sawasdee38"-->
<!--ngClass.xs="sawasdee38"-->
<!--ngClass.xl="sawasdee38"-->
<!--(click)="formService.setItemTitle(question, 'label', 'Untitled Question')"-->
