<div class="custom">
    <agm-map
        #gm
        (mapReady)="mapReadyHandler($event)"
        (mapClick)="onClickMap(gm)"
        [latitude]="lat"
        [longitude]="lng"
        [fitBounds]="false"
        [zoom]="zoom"
        (contextmenu)="onContextMenu($event, {})"
        [disableDefaultUI]="true"
        [styles]="service.app.settings.map.styles.style1"
        [ngStyle]="{'height' : getWindow().height + 'px'}">
        <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
        <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
        <ng-container *ngIf="service.app.data.routes.data.length > 0">
            <ng-container *ngFor="let route of service.app.data.routes.data">
                <agm-direction
                        [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : route.hasOwnProperty('stroke_color') ? route.stroke_color : '#ff8000'}}"
                        [provideRouteAlternatives]="false"
                        [origin]="{ lat: service.parseFloat(route.origin.lat), lng: service.parseFloat(route.origin.lng)}"
                        [destination]="{ lat: service.parseFloat(route.destination.lat), lng: service.parseFloat(route.destination.lng) }"
                        [waypoints]="route.waypoints2"
                >
                </agm-direction>
            </ng-container>
        </ng-container>

<!--   [label]="{color: '#fff', text: waypoint.location.title.toString().toLowerCase(), fontSize: '14px',fontFamily:'Sawasdee'}"-->
        <ng-container *ngFor="let route of service.app.data.routes.data">
            <ng-container *ngFor="let waypoint of route.waypoints">
                <agm-overlay
                        [latitude]="waypoint.location.lat"
                        [longitude]="waypoint.location.lng">
                    <ul class="list-inline bubbleStyle2a"
                        [ngStyle]="{'font-family':'Sawasdee','font-size':'14px'}">
                        <li>
                            <i class="cursor material-icons"
                               (click)="markerRemove(waypoint)">cancel</i>
                        </li>
                        <li>
                                <span class="cursor"
                                      (click)="markerUpdate(waypoint)">
                                    {{waypoint.location.title}}
                                </span>
                        </li>
                    </ul>
                </agm-overlay>
<!--                <agm-marker-->
<!--                        [ngStyle]="{'background-color':'#000'}"-->
<!--                        [latitude]="waypoint.location.lat"-->
<!--                        [longitude]="waypoint.location.lng"-->
<!--                        [iconUrl]="icon"-->
<!--                        [agmFitBounds]="false"-->
<!--                        [openInfoWindow]="true">-->
<!--                    <agm-info-window-->
<!--                            [isOpen]="true"-->
<!--                            [disableAutoPan]="false"-->
<!--                            #infoWindow>-->
<!--                        <span class="bubbleStyle2a"-->
<!--                              [ngStyle]="{'font-family':'Sawasdee','font-size':'14px'}">-->
<!--                            <span class="cursor"-->
<!--                                  (click)="markerUpdate(waypoint)">-->
<!--                                <span class="cursor pull-left">{{waypoint.location.title}}</span>-->
<!--                                <i class="cursor material-icons pull-left"-->
<!--                                   (click)="markerRemove(waypoint)">cancel</i>-->
<!--                            </span>-->
<!--                        </span>-->
<!--                    </agm-info-window>-->
<!--                </agm-marker>-->
            </ng-container>
        </ng-container>

        <agm-marker
                [latitude]="currentLocationLat"
                [longitude]="currentLocationLng"
                [agmFitBounds]="true"
                [openInfoWindow]="true"
                (markerClick)="onClickCurrentLocationPosition($event)"
                [iconUrl]='currentLocationPositionIconUrl'>
        </agm-marker>
    </agm-map>
</div>
<a mat-mini-fab
   class="defaultColor2"
   (click)="addRoute()"
   [ngStyle]="{'position': 'absolute','top':'15px','right':'15px'}">
    <i class="material-icons">add</i>
</a>
<!--<a mat-flat-button-->
<!--   class="sawasdee20"-->
<!--   (click)="addRoute()"-->
<!--   *ngIf="addRouteStatus === 1"-->
<!--   [ngStyle]="{'position': 'absolute','top':'5px','right':'5px'}">-->
<!--    save-->
<!--</a>-->
<div class="child">
    <div class="bar">
        <input type="text"
               matInput
               #addressElement
               autocomplete="off"
               placeholder=""
               class="searchbar sawasdee18"
               placeholder="Search for..."
               [address]="addressValue"
               [ngStyle]="{'text-align': 'center'}"
               (input)="onInputTextChange($event)"
               (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
               (onLocationSelected)="onLocationSelected($event)"
               matGoogleMapsAutocomplete>
        <a> <i class="material-icons voice">search</i></a>
    </div>
</div>


<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [hasBackdrop]="false">
    <ng-template matMenuContent let-item="inspector">
        <div class="sawasdee18">
            <li mat-menu-item
                (click)="addMarker()">add route point</li>
        </div>
    </ng-template>
</mat-menu>

