import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ChartService} from '../../../../../chart.service';
import {AppService} from '../../../../../app.service';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class PiechartComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @Input() limit: any;
  @Input() height: any;
  @Input() donut: any;
  @Input() type: any;
  options: any;
  theme: any;
  constructor(public chart: ChartService, private service: AppService) {
    this.theme  = this.chart.theme();
  }
  

  ngOnInit(): void {
   this.type = this.type === undefined ? 'donut' : this.type;
  }
  ngAfterViewInit(): void {
    setTimeout(() =>{
      this.options = this.getOptions(
          this.data.legend,
          this.data.data);
    }, 1000);
  }


  getOptions(legend: any, values: any){
    var options: any = {
      tooltip: {
        trigger: 'item',
        color: '#fff',
        textStyle: {
          color: '#fff',
          fontFamily: 'Google Sans'
        },
        formatter: '{a} {b} : {c} ({d}%)'
      },
      legend: {
        x: 'center',
        y: 'bottom',
        show: false,
        data: legend,
      },
      toolbox: {
          show: false,
          feature: {
              magicType: {
                  show: true,
                  type: ['pie', 'funnel'],
                  option: {
                      funnel: {
                          x: '25%',
                          width: '50%',
                          funnelAlign: 'left',
                          max: 1548
                      }
                  }
              },
              restore: {
                  show: true,
                  title: 'Restore'
              },
              saveAsImage: {
                  show: true,
                  title: 'Save Image'
              }
          }
      },
      calculable: true,
      series: [{
        name: '',
        type: 'pie',
        radius: '55%',
        center: ['50%', '48%'],
        itemStyle: {
          normal: {
            label: {
              formatter: function(d) {
               return legend[d.dataIndex] + ' - ' + d.percent + '%';
              },
              textStyle: {
                color: '#5f6368',
                font: 'Google Sans',
                fontFamily: 'Google Sans',
                fontSize: 10,
                size: 9
              },
              show: true
            }, labelLine: {
              show: true
            }
          },
          emphasis: {
            label: {
              show: true,
              position: 'center',
              textStyle: {
                fontSize: '10',
                fontWeight: 'normal',
                fontFamily: 'Google Sans',
                color: 'rgb(136, 136, 136)'

              }
            }
          }
        },
        data: values
      }]


    };
    if(this.type.toString().includes('donut')){
     options.series[0]['radius'] = ['35%', '55%'];
   }
    return options;
  }

}
// {d}% : {c} : {b}
