import {Component, Input, OnInit} from '@angular/core';
import {FormService} from "../../../form.service";
import {Parameters} from "../../../../parameters";
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-label-create-control',
  templateUrl: './label-create-control.component.html',
  styleUrls: ['./label-create-control.component.scss']
})
export class LabelCreateControlComponent implements OnInit {
  @Input() question: any;
  incomingForm: any;
  incomingSettings: any;
  color:any = '#5A738E';
  constructor(public formService: FormService,
   public service: AppService) {
   this.incomingForm = new Parameters();
   this.incomingSettings = new Parameters();
   this.incomingForm.setAttributes(this.formService.selected);
   this.incomingSettings.setAttributes(this.incomingForm.getSettings());
   this.color = this.incomingSettings.attributes.hasOwnProperty('color') ? this.incomingSettings.getColor() : this.color;
  }

  ngOnInit(): void {
  }

}
