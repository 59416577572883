<div [ngStyle]="{'position':'fixed','top':'0px','left':'0px','width': this.service.getWidth()+'px','height': this.service.getHeight()+'px','background-color': '#fff'}"
class="containerRelative">
    <div class="centered" [ngStyle]="{'width': this.service.getWidth()+'px','height': this.service.getHeight()+'px'}">
        <img src="assets/images/winners/spak3.gif"
             *ngIf='winnerShowImg'
             [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"/>
    </div>
</div>


