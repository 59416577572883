import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {Location} from '@angular/common';
import {timeout} from 'rxjs/operators';
import {AppService} from "../app.service";

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  @Input() app: any;
  @Output() onOpenDialog = new EventEmitter();
  searchCntrl: any;
  constructor(
    private location: Location,
    public service: AppService,
    private broadcastChannel: BroadcastChannelService) {
  }

  ngOnInit(): void {
    window.addEventListener('scroll',() => {
      // if(window.pageYOffset  > 200){
      // }
    });
  }
  openDialog(){
    this.onOpenDialog.emit();
  }

  onOpenDialogClicked(){
    this.broadcastChannel.emitNavChangeEvent('add');
    this.broadcastChannel.emitNavChangeEvent('register');
    this.broadcastChannel.emitNavChangeEvent('create');
  }

  modules(){ return this.app.settings.modules.slice(0, 2);}


  iframeHeight(){
    return window.innerHeight * 4;
  }

  requestMore(){this.broadcastChannel.emitNavChangeEvent('more');}
  load(){}
  back(){ this.location.back(); setTimeout(() => { if(window.location.href.toString().includes('wait..')) this.forward(); }, 900);}
  forward(){ this.location.forward();}

  onSearchTextChange(s: any){
    this.broadcastChannel.emitNavChangeEvent({searchText: s});
  }

  handleSearch(){ this.broadcastChannel.emitNavChangeEvent('search');}
  enterKeyUp(s: any){ this.broadcastChannel.emitNavChangeEvent('search');}

  getUrl(){return window.location.href;}

}
