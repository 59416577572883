<!--&lt;!&ndash;INNER BANNER START&ndash;&gt;-->
<!--<section id="inner-banner">-->
<!--    <div class="container">-->
<!--        <h1 class="mitra" [ngStyle]="{'color':'#fff'}">&nbsp; </h1>-->
<!--    </div>-->
<!--</section>-->
<!--&lt;!&ndash;INNER BANNER END&ndash;&gt;-->

<!--MAIN START-->
<div id="main">
    <!--RECENT JOB SECTION START-->
    <section class="resumes-section padd-tb" [ngStyle]="{'color':'#888'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 mitra">
                    <div class="resumes-content">
                        <div class="summary-box">
                            <h4 class="mitra">our services: <i>We operate with international standards to serve you with excellence</i></h4><br>
                            <h4 class="mitra">When attending an event at the warehouse Arena, visitors can expect the following range of services</h4><br>
                            <div class="outer">
                                <strong class="title mitra"><h4 class="mitra">WI-FI</h4></strong>
                                <div class="">
                                    <p class="sawasdeeX4 font11 textBlack">We offer complimentary Wi-Fi access throughout the venue.</p>
                                </div>
                            </div>
                            <div class="outer"> <strong class="title mitra"><h4 class="mitraX2">{{('Food & Beverage').toUpperCase()}}</h4></strong>
                                <div class="col">
                                    <div>
                                        <ul class="sawasdeeX4 font11 textBlack">
                                            <li>
                                                The warehouse Arena offers a range of food and beverage choices operated by our partner.
                                                Guests can enjoy a wide selection of their preferred food and beverage at various locations
                                                throughout our venue.
                                            </li>
                                            <li>
                                                Food and beverages from outside The Arena are not permitted, and any items brought will regrettably be confiscated at the security check points, except for prescribed medication.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="outer padd-none"> <strong class="title mitra"><h4 class="mitraX2">{{('Medical services').toUpperCase()}}</h4></strong>
                                <div class="">
                                    <p class="sawasdeeX4 font11 textBlack">
                                        Medical and first-aid provisions are available at our first-aid room located at the ground level.
                                        In case of urgent medical assistance, reach out to any of The Arena staff who will contact the
                                        onsite medical team.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--RECENT JOB SECTION END-->
</div>
<!--MAIN END-->