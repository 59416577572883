<a class="pull-left"><h4>{{!this.incomingSelectedItem.getAttributes().hasOwnProperty('id') ? 'Item'  : incomingSelectedItem.getName()}}</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<input type="hidden" class="nullInput"/>
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
    <mat-list>
        <mat-list-item>
            <span class="" mat-line>
              <mat-form-field  class="example-full-width">
                <mat-label>Name *</mat-label>
                <input matInput type="text"  formControlName="name">
              </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item>
            <span class="" mat-line>
              <mat-form-field  class="example-full-width">
                 <mat-label> Category *</mat-label>
                 <input type="text"
                        #elementCategory
                        placeholder=""
                        aria-label = "Number"
                        matInput
                        [formControl] = "teamControl"
                        [matAutocomplete] = "category"/>
                        <mat-autocomplete #category = "matAutocomplete"
                                          (optionSelected)="onTeamSelectionChanged($event)"
                                          [displayWith]="displayFn">
                            <mat-option *ngFor="let category of filteredTeamsOptions | async; let teamOf = index" [value]= "category">
                                {{category.name}}
                            </mat-option>
                        </mat-autocomplete>
                  <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.empty(elementCategory.value) === false &&
                                            ((filteredTeamsOptions | async) | json).length === 2">
                                            **---Not Found *---</h4>
              </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item>
            <span class="" mat-line>
              <small>Photo</small>
              <div class="upload-box">
                  <div class="hold">
                      <a>Maximum file size 20 MB <img src="{{photo}}" class="img-circle" width="40" height="40" alt="" *ngIf="photo"></a>
                      <span class="btn-file"> Browse File
                          <input type="file"
                                 (change)="handleFileInput($event)"
                          >
                      </span>
                  </div>
              </div>
            </span>
        </mat-list-item>
        <mat-list-item>
            <span class="" mat-line>
                 <mat-form-field class="example-full-width">
                    <mat-label>Description</mat-label>
                    <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
                    <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                  </mat-form-field>
            </span>
        </mat-list-item>
    </mat-list>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <center>
                <div class="example-button-row">
                    <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{!this.incomingSelectedItem.getAttributes().hasOwnProperty('id') ? 'Add' : 'Edit'}}"/>
                </div>
            </center>
        </div>
    </div>
</form>
