import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from "@angular/material/menu";
import {AppService} from "../../../../app.service";
import {BroadcastChannelService} from "../../../../broadcast-channel.service";
import {FormService} from "../../../form.service";
import {AddQuestionComponent} from "../../../questions/add/add-question.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-create-choice-control',
  templateUrl: './create-choice-control.component.html',
  styleUrls: ['./create-choice-control.component.scss']
})
export class CreateChoiceControlComponent implements OnInit {
  @Input() question: any;
  @Input() position: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  data: any;
  questions: any;
  form: any;
  label: any;
  selectedOptonal: any;
  constructor(
      public service: AppService,
      public dialog: MatDialog,
      public broadcastChannel: BroadcastChannelService,
      public formService: FormService) {
    this.form = formService.selected;
    this.questions = this.form.controls;
    this.contextMenu = MatMenuTrigger;
  }

  ngOnInit(): void {
  }


  onContextMenu(event: MouseEvent, choice: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item : choice };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


  isChecked(element: any, optional: any){
    let l: any = false;
    if(!this.service.empty(this.question.data) &&
        !this.service.empty(optional.label) &&
        this.question.data.toString() === optional.label.toString())
      l = true;
    return l;
  }

  addOptionalNextQuestional(optional: any){
    this.formService.isOptionalClicked = true;
    setTimeout(() => {
      const dialogRef = this.dialog.open(AddQuestionComponent, {
        disableClose: false,
        data: {
          optionalCntrlNum: optional.cntrl_num,
          position: this.service.findIndex(this.formService.questions, 'cntrl_num', this.question.cntrl_num)
        },
        width: '70%',
        height: '100%',
        autoFocus: true,
      });
      dialogRef.afterClosed().subscribe(() => {
      });
    });
  }

  addOptional(){
    this.label = prompt('', 'others');
    if (this.label !== null) {
      this.formService.addOptional(this.question.options, this.label);
      this.formService.others.push(this.formService.newOptional);
      window.localStorage.setItem('others', JSON.stringify(this.formService.others));
    }
  }

  updateOptionalTitle(optional: any){
    setTimeout(() => {
      this.formService.setItemTitle(optional, 'label', optional.label);
    });
  }

  radioChange(event: any, optional){
    setTimeout(() =>{
      this.question.options.map((opt: any) => {
        if(opt.label.toString() === optional.label.toString()) {
          this.formService.showQuestions(opt);
        } else this.formService.hideOptionalQuestions(opt);
      });
      this.question.data = optional.label;
      if(this.question.data.toString() === optional.label.toString()) {}
    });
  }

  viewNextQuestions(optional: any){
    this.broadcastChannel.emitNavChangeEvent({action: 'next',  optionalId: optional.id, cntrlNum: optional.cntrl_num});
  }

  deleteOptional(optional: any){
    this.question.options.splice(this.service.findIndex(this.question.options, 'cntrl_num', optional.cntrl_num), 1);
    if(optional.hasOwnProperty('id')) {
      this.formService.deletedOptions.push(optional);
    }
  }

  isnull(radionButton: any) {
    if(this.service.empty(this.question.data)) {
      radionButton.checked = false;
    }
  }

}
