<mat-drawer-container class="example-container"
                      [ngStyle]="{'position':'fixed','top':'0px','left':'0px','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}">
    <mat-drawer #drawer class="example-sidenav" mode="side"
                [ngStyle]="{'width':'100%','height':'100%'}">
        <app-register-invitee [placeholderFullName]="'Full name of another person to replace you ? '"></app-register-invitee>
    </mat-drawer>
    <div class="example-sidenav-content"
         [ngStyle]="{'width':this.service.getWidth()+'px','height':this.service.getHeight()+'px','color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
        <div *ngIf="contentsShow"
             class="containerAbsolutex"
             [ngStyle]="{'width':this.service.getWidth()+'px','height':this.service.getHeight()+'px','color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
            <div class="centeredx">
                <div class="">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
<!--                        <center>-->
<!--                            <img class="img-circle mdl-shadow&#45;&#45;2dp"-->
<!--                                 src="{{incomingClient.getUnknown('logo')}}"-->
<!--                                 [ngStyle]="{'width':'60px','height':'60px','margin-bottom':'60px'}"/>-->
<!--                        </center>-->
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 sawasdee20X2"
                         [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
                        <center>
                          <div  [innerHTML]="contents | safeUrl"></div>
                        </center>
                    </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                         [ngStyle]="{'width':this.service.getWidth()+'px','color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}"
                         *ngIf="!this.service.empty(contents) && this.service.getNavigatedUrl().includes('whatsapp=true') && this.responseService.isConfirmed===false">
                        <center><br>
                            <a (click)="invitationDecline()" style=" color  : {{incomingSettingsForm.getBackgroundColor()}};
                                                     background      : #fff;
                                                     font-size       : 14px;
                                                     border          : 0;
                                                     border-radius   : 4px;
                                                     display         : inline-block;
                                                     line-height     : 24px;
                                                     margin          : 8px 0;
                                                     min-height      : 20px;
                                                     outline         : 0;
                                                     cursor          : pointer;
                                                     outline-color   : currentcolor;
                                                     outline-style   : none;
                                                     outline-width   : 0px;
                                                     padding         : 8px 20px;
                                                     text-align      : center;
                                                     vertical-align  : middle;
                                                     white-space     : nowrap;
                                                     text-decoration : none;">Decline</a>&nbsp;&nbsp;&nbsp;
                            <a (click)="acceptInvitation()" style="  color : {{incomingSettingsForm.getBackgroundColor()}};
                                                     background      : #fff;
                                                     font-size       : 14px;
                                                     border          : 0;
                                                     border-radius   : 4px;
                                                     display         : inline-block;
                                                     line-height     : 24px;
                                                     margin          : 8px 0;
                                                     min-height      : 20px;
                                                     outline         : 0;
                                                     cursor          : pointer;
                                                     outline-color   : currentcolor;
                                                     outline-style   : none;
                                                     outline-width   : 0px;
                                                     padding         : 8px 20px;
                                                     text-align      : center;
                                                     vertical-align  : middle;
                                                     white-space     : nowrap;
                                                     text-decoration : none;">Accept</a>
                        </center><br><br><br><br>
                    </div>
                </div>
            </div>
        </div>

        <div  *ngIf="!contentsShow"
              class="containerRelativex sawasdee20X2"
              [ngStyle]="{'width':this.service.getWidth()+'px','height':this.service.getHeight()+'px','color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
            <div class="centeredx">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <center>
                        <img class="img-circle mdl-shadow--2dp"
                             src="{{incomingClient.getUnknown('logo')}}"
                             [ngStyle]="{'width':'60px','height':'60px','margin-bottom':'60px'}"/>
                    </center>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 cChoice"
                     [ngStyle]="{'background-color':this.incomingSettingsForm.getBackgroundColor()}">
                    <center>
                        <div></div>
                        <div  [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}"
                              [innerHTML]="!this.service.empty(this.incomingSettingsForm.getDeclinedLandingPageContents()) ?
                                       this.incomingSettingsForm.getDeclinedLandingPageContents() :
                                       this.service.upperStartingCharacter(this.service.app.strings.invitation.decline.message,true)">
                        </div>
                        <br><br>
<!--                    <ng-container *ngIf="showDeclinenote">-->
<!--                            <div [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">Note: do you want to add another person to replace you ? </div>-->
<!--                            <div [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">-->
<!--                                <mat-radio-group-->
<!--                                        aria-labelledby="example-radio-group-label"-->
<!--                                        class="example-radio-group">-->
<!--                                    <mat-radio-button-->
<!--                                            class="example-radio-buttonn"-->
<!--                                            #checked-->
<!--                                            (click)="onRadioButtonChange(option)"-->
<!--                                            *ngFor="let option of options"-->
<!--                                            [value]="option">-->
<!--                                        <a [ngStyle]="{'color': '#fff'}">{{service.setSmallCharacters(option.label.toString())}}</a>-->
<!--                                    </mat-radio-button>-->
<!--                                </mat-radio-group>-->
<!--                            </div>-->
<!--                        </ng-container>-->
                    </center>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
            </div>
        </div>

        <!--<mat-progress-bar-->
        <!--        *ngIf="service.progress"-->
        <!--        class="progressCSS"-->
        <!--        mode="query">-->
        <!--</mat-progress-bar>-->

    </div>
</mat-drawer-container>








