import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  SimpleChanges, ViewChild
} from '@angular/core';
import {AppService} from "../../../../app.service";
import {Parameters} from "../../../../parameters";
import {map, startWith} from 'rxjs/internal/operators';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatMenuTrigger} from '@angular/material/menu';
import {FormService} from '../../../form.service';

@Component({
  selector: 'app-respond-table-controls',
//   changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './respond-table-controls.component.html',
  styleUrls: ['./respond-table-controls.component.scss']
})
export class RespondTableControlsComponent implements AfterViewInit, OnChanges,  OnDestroy {
  @Input() question: any;
  @Input() position: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  @ViewChild('tdElement', {static: false})  element: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  label: any;
  incomingQuestion: any;
  incomingOption: any;
  incomingRow: any;
  percentageField1: any;
  percentageField2: any;
  autPercentage: any;
  default: any = [];
  incomingFilteredOption: any;
  incomingFilteredRow: any;
  incomingOptions: any;
  filteredSuggestions: any;
  filteredAutocompletes: any;
  suggestionControl = new FormControl();
  autocompleteControl = new FormControl();
  incomingResult: any;
  incomingItem: any;;
  incomingData: any;
  result: any;
  incomingResults: any;
  outgoingResult: any;
  constructor(public service: AppService,
    public formService: FormService) {
    this.incomingQuestion = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingRow = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingFilteredOption = new Parameters();
    this.incomingFilteredRow = new Parameters();
    this.incomingOptions = new Parameters();
    this.incomingItem = new Parameters();
    this.incomingData = new Parameters();
    this.outgoingResult = new Parameters();
    this.incomingResults = new Parameters();
    this.element = ElementRef;
    this.contextMenu = MatMenuTrigger;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngStart();
    this.ngAfterViewInit();
  }


  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


  getItem(option: any, item: any){
    this.incomingOption.setAttributes(option);
    this.incomingItem.setAttributes(item);
    this.incomingOption.setItem(this.incomingItem.getAttributes());
    return this.incomingOption.getAttributes();
  }

  getAutocompleteOptions(options: any){
      this.filteredAutocompletes = this.autocompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(name => name ? this._filterAutocompletes(name, options) : options.slice())
      );
  }

  private _filterAutocompletes(name: string, options: any) {
      const args: any = name.toString().toLowerCase();
      return options.filter((option: any) => JSON.stringify(option).toLowerCase().includes(args));
  }

  getDefaultItems(items: any){
   let outgoingItems: any = [];
   items.map((item: any) =>{
    this.incomingItem.setAttributes(Object.assign({}, item));
    outgoingItems.push(this.incomingItem.getAttributes());
    return this.incomingItem.getAttributes();
   });return outgoingItems;
  }


  ngStart(){
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.setRows(this.incomingQuestion.attributes.hasOwnProperty('rows') ? this.incomingQuestion.getRows() :
      (this.service.empty(this.incomingQuestion.getData()) ? [] :  JSON.parse(this.incomingQuestion.getData())));
      this.default = !this.service.empty(this.incomingQuestion.getDefault()) ?
               (this.incomingQuestion.getDefault() instanceof Object ? this.incomingQuestion.getDefault() : JSON.parse(this.incomingQuestion.getDefault())) : [];
      if(this.incomingQuestion.getOptions().length > 0){
        if(this.question.rows.length === 0 && this.default.length === 0){
          for (let l = 0; l < 5; l++) {
            this.incomingRow.setAttributes({});
            this.incomingQuestion.getOptions().map((optional: any) => {
              this.incomingOption.setAttributes(optional);
              this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {data: ''});
            });this.incomingQuestion.getRows().push(this.incomingRow.getAttributes());
          }
          this.incomingQuestion.setData(JSON.stringify(this.incomingQuestion.getRows()));
//        this.incomingQuestion.setResult(this.incomingQuestion.getRows());
          this.update();
        }
        this.default.map((row: any) =>  {
          this.incomingRow.setAttributes(Object.assign({},row));
          if(!this.hasRowByCntrlNum(this.incomingRow.getCntrlNum())){
            this.incomingQuestion.getRows().push(this.incomingRow.getAttributes());
          } else {
                let l: any = this.service.findIndex(this.incomingQuestion.getRows(), 'cntrl_num', this.incomingRow.getCntrlNum());
                let r: any = this.incomingQuestion.getRows()[l];
                let incomingR: any = new Parameters();
                incomingR.setAttributes(r);
                for (let key in this.incomingRow.attributes) {
                  if (incomingR.attributes.hasOwnProperty(key)) {
                    if(this.incomingRow.getUnknown(key).hasOwnProperty('default')) {
                      incomingR.getUnknown(key).data = this.incomingRow.getUnknown(key).data;
                      incomingR.getUnknown(key).default = this.incomingRow.getUnknown(key).default;
                    }
                  }else incomingR.attributes[key] = this.incomingRow.getUnknown(key);

                }
          }
        });
        this.update();
      }
      this.startSuggestions();

  }

  displayFn2(value: any){
    return value.label;
  }

  focus(){}
  startSuggestions(){
    this.filteredSuggestions = this.suggestionControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.description),
        map(name => name ? this._filterSuggestion(name) : this.service.app.data.suggestions.slice())
    );
  }

  onSuggestionSelectionChanged(event: MatAutocompleteSelectedEvent, option: any, row: any){
    this.onInputTextChange(option, event.option.value.description, row);
  }



  onKeyUp(event: any, element: any){
      setTimeout(() => {
       if(this.service.empty(this.element.target.value)){
       }else this.element.target.value = this.service.numberWithCommans(parseFloat(this.element.target.value.toString().replace(/,/g, '')));
      });
  }

  onAutocompleteSelectionChanged(event: MatAutocompleteSelectedEvent, option: any, row: any){
      this.onInputTextChange(option, event.option.value.label, row);
  }

  setSuggestion(text: any){
    this.service.setSuggestion(text, (text: any) => {
      // this.startSuggestions();
    });
  }

  getSuggestions(event: any){
    setTimeout(() => {
      this.service.getSuggestions(event.target.value, () => {
        if(this.service.empty(event.target.value)){
          this.startSuggestions();
        }
      });
    });
  }

  displayFn(value: any){
    return value.description;
  }

  private _filterSuggestion(name: string) {
    const filterValue: any = name.toString().toLowerCase();
    return this.service.app.data.suggestions.filter((option: any) => option.description.toString().toLowerCase().includes(filterValue));
  }

  istableNull(){
    let results: any = false;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOptions.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingQuestion.getOptions(), 'cntrl_num'));
    if(parseFloat(this.incomingQuestion.getRequired()) === 1){
      this.incomingQuestion.getRows().map((row: any) => {
        this.incomingRow.setAttributes(row);
        for(var key in this.incomingRow.getAttributes()){
          if(this.incomingRow.getUnknown(key).hasOwnProperty('data') &&
              !this.incomingRow.getAttributes()[key].hasOwnProperty('default') &&
              this.incomingOptions.getUnknown(key).type_id !== 26 &&
              this.service.empty(this.service.removeHtmlTags(this.incomingRow.getAttributes()[key].data))
          )
            results = true;
        }
      });
    }else{
      results = true;
      this.incomingQuestion.getRows().map((row: any) => {
        this.incomingRow.setAttributes(row);
        for(var key in this.incomingRow.getAttributes()){
          if(this.incomingRow.getUnknown(key).hasOwnProperty('data') &&
              this.incomingOptions.getUnknown(key).type_id !== 26 &&
              !this.incomingRow.getAttributes()[key].hasOwnProperty('default') &&
              !this.service.empty(this.service.removeHtmlTags(this.incomingRow.getAttributes()[key].data)))
            results = false;
        }
      });
    }
    return results;
  }

  hasRowByCntrlNum(cntrlNum: any){
    this.incomingQuestion.setAttributes(this.question);
    let rows: any  = this.service.getJSObjectByArrayAndLabel(this.incomingQuestion.getRows(), 'cntrl_num');
    return rows.hasOwnProperty(cntrlNum);
  }




  isheaderShouldDislayed(){
    let l: any = false;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.getOptions().map((option: any) => {
      this.incomingOption.setAttributes(option);
      if(!this.service.empty(this.service.removeHtmlTags(this.incomingOption.getLabel()))) l = true;
    });return l;
  }

  isAllDefault(){
    let output: any = true;
    this.incomingQuestion.setAttributes(this.question);
    if(this.incomingQuestion.attributes.hasOwnProperty('rows')){
      this.incomingQuestion.getRows().map((row: any) => {
        let outputresult: any = false;
        this.incomingRow.setAttributes(row);
          for(var key in this.incomingRow.getAttributes()){
          if(this.incomingRow.getAttributes()[key].hasOwnProperty('default')) outputresult = true;
        }if(outputresult === false) output = false;
      });
    }return output;
  }

  contentEditable(option: any, row: any){
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOption.setAttributes(option);
    this.incomingRow.setAttributes(row);
    let result: any = parseFloat(this.incomingOption.getTypeId()) !== 3;
    result = this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).hasOwnProperty('default') ? false : result;
    result = parseFloat(this.incomingOption.getTypeId()) === 26 ? false : result;
    if(!this.service.empty(this.incomingQuestion.getAutoPercentageField()))
      result = this.incomingOption.getCntrlNum() === this.incomingQuestion.getAutoPercentageField() ? false : result;
    return result;
  }

  isrNull(){
    let results: any = true;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.getRows().map((row: any) => {
      this.incomingRow.setAttributes(row);
      for(var key in this.incomingRow.getAttributes()){
        if(this.incomingRow.getUnknown(key).hasOwnProperty('data') &&
            !this.service.empty(this.incomingRow.getAttributes()[key].data) &&
            !this.incomingRow.getAttributes()[key].hasOwnProperty('default'))
          results = false;
      }
    });
    return results;
  }


  getRowByCntrlNum(cntrlNum: any){
    this.incomingQuestion.setAttributes(this.question);
    let rows: any  = this.service.getJSObjectByArrayAndLabel(this.incomingQuestion.getRows(), 'cntrl_num');
    return rows[cntrlNum];
  }

  removeTableRow(row: any){
    setTimeout(() => {
      this.incomingRow = new Parameters();
      this.incomingQuestion.setAttributes(this.question);
      this.incomingRow.setAttributes(row);
      let indexOf: any = this.incomingQuestion.getRows().indexOf(this.incomingRow.getAttributes());
      if(indexOf === -1){}else this.incomingQuestion.getRows().splice(indexOf, 1);
      this.update();
    });
  }

  addRow(){
    this.incomingQuestion.setAttributes(this.question);
    let tablerow: any = {};
    this.incomingQuestion.getOptions().map((optional: any) => {
      this.incomingOption.setAttributes(optional);
      tablerow[this.incomingOption.getCntrlNum()] = {data: ''};
    });
    this.incomingQuestion.setRows(this.incomingQuestion.getAttributes().hasOwnProperty('rows') ? this.incomingQuestion.getRows() : []);
    this.question.rows.push(tablerow);
    this.service.playSound();
  }

  onCellClicked(row: any, option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingRow.setAttributes(row);
      this.incomingOption.setAttributes(option);
      if(parseFloat(this.incomingOption.getTypeId()) === 3){
        if(this.incomingRow.getAttributes().hasOwnProperty(this.incomingOption.getCntrlNum())){
          alert(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data);
          if(this.service.empty(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data)){
            this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data = 1;
          }
        }
      }
    });
  }

  update(){
    this.incomingQuestion.setAttributes(this.question);
    this.question.data = this.incomingQuestion.getRows().length === 0 ? '' : JSON.stringify(this.question.rows);
    this.incomingQuestion.setIsNull(this.istableNull());
    this.summationboxinsertValues();
  }

  isRowCanceled(row: any){
    let output: any = true;
    this.incomingRow.setAttributes(row);
    for(let key in this.incomingRow.attributes) {
      if(this.incomingRow.getUnknown(key).hasOwnProperty('default')) output = false;
    }return output;
  }

  summationboxinsertValues(){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.getRows().map((row: any) => {
        this.incomingRow.setAttributes(row);
        if(this.hasOutputsummationbox()){
          this.outputSummationbox(this.incomingRow.getAttributes());
        }
      });
    });
  }


  hasOutputsummationbox(){
    let output: any = false;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.getOptions().map((option: any) => {
      this.incomingFilteredOption.setAttributes(option);
      if (parseFloat(this.incomingFilteredOption.getTypeId()) === 26) output = true;
    });return output;
  }

  getSummationbox(){
    let output: any = false;
    this.incomingQuestion.setAttributes(this.question);
    return this.incomingQuestion.getOptions().filter((option: any) => {
      this.incomingFilteredOption.setAttributes(option);
      return parseFloat(this.incomingFilteredOption.getTypeId()) === 26;
    })[0];
  }

  outputSummationbox(row: any){
    this.incomingFilteredRow.setAttributes(row);
    this.incomingQuestion.setAttributes(this.question);
    let output: any = 0;
    this.incomingQuestion.getOptions().filter((option: any) => {
      this.incomingFilteredOption.setAttributes(option);
      if(parseFloat(this.incomingFilteredOption.getTypeId()) !== 26){
        if(!this.service.empty(this.incomingFilteredRow.getUnknown(this.incomingFilteredOption.getCntrlNum()).data) &&
            !isNaN(this.incomingFilteredRow.getUnknown(this.incomingFilteredOption.getCntrlNum()).data.replaceAll(',', '')))
        output += parseFloat(this.incomingFilteredRow.getUnknown(this.incomingFilteredOption.getCntrlNum()).data.replaceAll(',', ''));}
    }); this.incomingFilteredRow.getUnknown(this.getSummationbox().cntrl_num).data = output === 0 ? '' : this.service.numberWithCommans(output);
  }

  onChangecheckbox(event: any, row: any, option: any){
    setTimeout(() => {
      this.incomingRow.setAttributes(row);
      this.incomingOption.setAttributes(option);
      this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {data: event.checked ? 1 : ''});
      this.incomingQuestion.setData(JSON.stringify(this.incomingQuestion.getRows()));
      this.update();
    });
  }

  isChecked(checkbox: any, row: any, option: any){
    this.incomingRow.setAttributes(row);
    this.incomingOption.setAttributes(option);
    checkbox.checked = !this.service.empty(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data) &&
        parseFloat(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data) === 1;
    return checkbox.checked;
  }

  isRadioShouldChecked(item: any, data: any){
   this.result = false;
   this.incomingItem.setAttributes(item);
   this.incomingData.setAttributes(data);
   if(this.incomingData.attributes.hasOwnProperty('result')){
    this.incomingResult.setAttributes(this.incomingData.getResult());
    this.result = this.incomingResult.attributes.hasOwnProperty(this.incomingItem.getCntrlNum());
   }return this.result;
  }

  getLabels(results: any){
      this.result = '';
     for(var key in results){
       this.incomingResult.setAttributes(results[key]);
       this.result = this.service.empty(this.result) ? this.incomingResult.getLabel() : this.result + this.service.separator + this.incomingResult.getLabel();
     } return this.result;
  }

  onInputTextChange(option: any, tdElementVal: any, row: any){
    this.incomingRow.setAttributes(row);
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOption.setAttributes(option);
    if(parseFloat(this.incomingOption.getTypeId()) === 5){
     this.incomingItem.setAttributes(tdElementVal);
     this.incomingResult.setAttributes({});
     this.incomingResult.setLabel(this.incomingItem.getLabel());
     this.incomingResult.setCntrlNum(this.incomingItem.getCntrlNum());
     this.incomingResults.setAttributes({});
     this.incomingResults.setUnknown(this.incomingItem.getCntrlNum(), this.incomingResult.getAttributes());
     this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {
     result: this.incomingResults.getAttributes(),
     data: this.incomingItem.getLabel()});
    }else if(parseFloat(this.incomingOption.getTypeId()) === 3){
          this.incomingData.setAttributes(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()));
          this.incomingItem.setAttributes(tdElementVal);
          this.incomingResult.setAttributes({});
          this.incomingResult.setLabel(this.incomingItem.getLabel());
          this.incomingResult.setCntrlNum(this.incomingItem.getCntrlNum());
          this.incomingResults.setAttributes(this.incomingData.attributes.hasOwnProperty('result') ? this.incomingData.getResult(): {});
          if(this.incomingResults.attributes.hasOwnProperty(this.incomingItem.getCntrlNum())){
           delete this.incomingResults.attributes[this.incomingItem.getCntrlNum()];
          }else this.incomingResults.setUnknown(this.incomingItem.getCntrlNum(), this.incomingResult.getAttributes());
              this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {
               result: this.incomingResults.getAttributes(),
               data: this.getLabels(this.incomingResults.getAttributes())
              });
    }
   else {
       this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {
          data: !isNaN(this.service.removeHtmlTags(tdElementVal).replaceAll(',', '')) &&
              !this.service.empty(this.service.removeHtmlTags(tdElementVal)) ?
              this.service.removeHtmlTags(tdElementVal.trim()) :
                  this.service.removeHtmlTags(tdElementVal.trim())
        });
        if(!this.service.empty(this.incomingQuestion.getAutoPercentageField()) &&
            !this.service.empty(this.incomingQuestion.getPercentageField1()) &&
            !this.service.empty(this.incomingQuestion.getPercentageField2())){
          this.autPercentage = 0;
          this.percentageField1 = 0;
          this.percentageField2 = 0;
          if(this.incomingRow.getAttributes().hasOwnProperty(this.incomingQuestion.getPercentageField1())){
            this.percentageField1 = this.service.empty(this.incomingRow.getUnknown(this.incomingQuestion.getPercentageField1()).data) ?  '' :
                this.incomingRow.getUnknown(this.incomingQuestion.getPercentageField1()).data;
            this.incomingRow.setUnknown(this.incomingQuestion.getPercentageField1(), {
              data: !this.service.empty(this.percentageField1) ?
                  this.service.numberWithCommans(parseFloat(this.percentageField1.toString().replaceAll(',', ''))) :
                  this.percentageField1
            });
          }
          if(this.incomingRow.getAttributes().hasOwnProperty(this.incomingQuestion.getPercentageField2())){
            this.percentageField2 = this.service.empty(this.incomingRow.getUnknown(this.incomingQuestion.getPercentageField2()).data) ? '' :
                this.incomingRow.getUnknown(this.incomingQuestion.getPercentageField2()).data;
            this.incomingRow.setUnknown(this.incomingQuestion.getPercentageField2(), {
              data: !this.service.empty(this.percentageField2) ?
                  this.service.numberWithCommans(parseFloat(this.percentageField2.toString().replaceAll(',', ''))) :
                  this.percentageField2
            });
          }
          this.percentageField1 = parseFloat(this.percentageField1.toString().replaceAll(',', '').trim());
          this.percentageField2 = parseFloat(this.percentageField2.toString().replaceAll(',', '').trim());
          this.incomingRow.setUnknown(this.incomingQuestion.getAutoPercentageField(), {data: this.percentageField2 === 0 || this.service.empty(this.percentageField2) || this.percentageField1 === 0 || this.service.empty(this.percentageField1) ? '' :
                this.service.percentageOf(this.percentageField1.toString().replaceAll(',', ''), this.percentageField2.toString().replaceAll(',', ''))});
        }
    }
    this.update();
  }


  // onInputTextChange(option: any, tdElement: any, row: any){
  //   this.incomingRow.setAttributes(row);
  //   this.incomingQuestion.setAttributes(this.question);
  //   this.incomingOption.setAttributes(option);
  //   this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {
  //     data: !isNaN(this.service.removeHtmlTags(tdElement.innerText).replaceAll(',', '')) &&
  //     !this.service.empty(this.service.removeHtmlTags(tdElement.innerText)) ?
  //         this.service.removeHtmlTags(tdElement.innerText.trim()) :
  //         this.service.removeHtmlTags(tdElement.innerText.trim())
  //   });
  //   // this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {
  //   //   data: !isNaN(this.service.removeHtmlTags(tdElement.innerText).replaceAll(',', '')) &&
  //   //   !this.service.empty(this.service.removeHtmlTags(tdElement.innerText)) ?
  //   //       this.service.numberWithCommans(parseFloat(this.service.removeHtmlTags(tdElement.innerText.trim()).replaceAll(',', ''))) :
  //   //       this.service.removeHtmlTags(tdElement.innerText.trim())
  //   // });
  //   if(!this.service.empty(this.incomingQuestion.getAutoPercentageField()) &&
  //       !this.service.empty(this.incomingQuestion.getPercentageField1()) &&
  //       !this.service.empty(this.incomingQuestion.getPercentageField2())){
  //     this.autPercentage = 0;
  //     this.percentageField1 = 0;
  //     this.percentageField2 = 0;
  //     if(this.incomingRow.getAttributes().hasOwnProperty(this.incomingQuestion.getPercentageField1())){
  //       this.percentageField1 = this.service.empty(this.incomingRow.getUnknown(this.incomingQuestion.getPercentageField1()).data) ?  '' :
  //           this.incomingRow.getUnknown(this.incomingQuestion.getPercentageField1()).data;
  //       this.incomingRow.setUnknown(this.incomingQuestion.getPercentageField1(), {
  //         data: !this.service.empty(this.percentageField1) ?
  //             this.service.numberWithCommans(parseFloat(this.percentageField1.toString().replaceAll(',', ''))) :
  //             this.percentageField1
  //       });
  //     }
  //     if(this.incomingRow.getAttributes().hasOwnProperty(this.incomingQuestion.getPercentageField2())){
  //       this.percentageField2 = this.service.empty(this.incomingRow.getUnknown(this.incomingQuestion.getPercentageField2()).data) ? '' :
  //           this.incomingRow.getUnknown(this.incomingQuestion.getPercentageField2()).data;
  //       this.incomingRow.setUnknown(this.incomingQuestion.getPercentageField2(), {
  //         data: !this.service.empty(this.percentageField2) ?
  //             this.service.numberWithCommans(parseFloat(this.percentageField2.toString().replaceAll(',', ''))) :
  //             this.percentageField2
  //       });
  //     }
  //     this.percentageField1 = parseFloat(this.percentageField1.toString().replaceAll(',', '').trim());
  //     this.percentageField2 = parseFloat(this.percentageField2.toString().replaceAll(',', '').trim());
  //     this.incomingRow.setUnknown(this.incomingQuestion.getAutoPercentageField(), {data: this.percentageField2 === 0 || this.service.empty(this.percentageField2) || this.percentageField1 === 0 || this.service.empty(this.percentageField1) ? '' :
  //           this.service.percentageOf(this.percentageField1.toString().replaceAll(',', ''), this.percentageField2.toString().replaceAll(',', ''))});
  //   } this.update();
  //   // setTimeout(() => {
  //   //   tdElement.focus();
  //   //   tdElement.setSelectionRange(2, 2);
  //   // });
  // }

  rs(tdElement: any, row: any, optional: any){
    setTimeout(() => {
      row[optional.cntrl_num].data = tdElement.innerText;
      this.update();
    }, 1020);
  }

  ngAfterViewInit(): void {
    setTimeout(() =>{
      if(this.element.nativeElement){
        this.element.nativeElement.focus();
      }
    });
  }

  ngOnDestroy(): void {
  }




}
