<div class="container">
    <div *ngIf="formService.isAdvert">
<!--        <div [ngStyle]="{'position':'fixed','top':'-30px', 'width':'100%','height':'auto','z-index':'99999'}">-->
<!--            <center>-->
<!--                <table>-->
<!--                    <td class="profilex">-->
<!--                        <center>-->
<!--                            &lt;!&ndash;                            <img src="{{logo.path}}"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                 class="thumbnail mdl-shadow&#45;&#45;2dp pull-left"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                 *ngFor="let logo of incomingForm.attributes.settings.logo"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                 [ngStyle]="{'max-width':'100px', 'max-height':'100px', 'margin-left': '10px'}"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                 alt="{{logo.caption}}">&ndash;&gt;-->
<!--                            <table>-->
<!--                                <td *ngFor="let logo of incomingForm.attributes.settings.logo"-->
<!--                                    [ngStyle]="{'padding-right':'10px'}">-->
<!--                                    <div class="profile_pic">-->
<!--                                        <img src="{{logo.path}}"-->
<!--                                             [ngStyle]="{'width':'60px', 'height':'60px'}"-->
<!--                                             alt="{{logo.caption}}"-->
<!--                                             class="img-circle profile_img">-->
<!--                                    </div>-->
<!--                                </td>-->
<!--                            </table>-->

<!--                        </center>-->
<!--                    </td>-->
<!--                </table>-->
<!--            </center>-->
<!--        </div>-->
        <carousel [cellsToScroll]="1"
                  [cellsToShow]="1"
                  [height]="getWindow().height"
                  [autoplay]="false"
                  [arrows]="true"
                  [dots]="true"
                  [loop]="false">
            <div class="carousel-cell"
                 *ngFor="let image of incomingSettings.attributes.images; let incremental = index"
                 (mouseenter)="onCarouselCellChange(incremental, image)">
                <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                     src="{{image.src}}" #img />
<!--                <div  [ngStyle]="{'display:'block', 'object-fit': 'cover', 'background-repeat':'no-repeat','width': '100%','height': '100%','background-image': 'url('+image.src+')'}">-->
<!--                </div>-->
                <div [ngStyle]="{'position':'absolute','top':'0%','height':'100%','width':'100%','margin-top':'10px','margin-bottom':'10px'}">
                    <div class="vertical-center"
                         [ngStyle]="{'padding-top':'50px','padding-bottom':'50px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)'}">
                        <center>
                            <div class="sawasdee18" [innerHTML]="image.caption"></div>
                            <div  class="sawasdee26"
                                  [innerHTML]="image.settings.hasOwnProperty('action_caption') ? image.settings.action_caption : ''">
                            </div>
                            <div class="sawasdee20"
                                 [innerHTML]="image.settings.hasOwnProperty('submit_caption') ? image.settings.submit_caption : ''">
                            </div>
                            <div>
                                <center>
                                    <table>
                                        <td class="cursor"
                                            title="Go"
                                            *ngIf="!service.empty(image.settings.hasOwnProperty('action_button1') ? image.settings.action_button1 : '')"
                                            (click)="next(image.settings.hasOwnProperty('action_caption')      ? image.settings.action_caption : '', image.settings.hasOwnProperty('action_button1') ? image.settings.action_button1 : '')">
                                            <!--                                    <button mat-raised-button class="sawasdee20 mdl-shadow--2dp" [ngStyle]="{'background-color': '#fff', 'color':'#000'}">-->
                                            <!--                                            <span [innerHTML]="image.settings.hasOwnProperty('action_button1') ? image.settings.action_button1 : ''"></span>-->
                                            <!--                                    </button>-->
                                            <center>
                                                <button class="sawasdee20x" mat-fab [ngStyle]="{'font-family':'ubuntu condensed','background-color': '#fff','color':'#000', 'margin-top':'40px'}">
<!--                                                    <i class="material-icons">play_arrow</i>-->
<!--                                                    <i class="material-icons">arrow_forward_ios</i>-->
                                                    <i class="material-icons">keyboard_arrow_down</i>
                                                </button>
                                                <!--                                            <div class="myProgress" [ngStyle]="{'margin-top':'10px','width':'40%'}">-->
                                                <!--                                                <div class="myBar"></div>-->
                                                <!--                                            </div>-->
                                            </center>

                                        </td>
                                        <td class="cursor"
                                            *ngIf="!service.empty(image.settings.hasOwnProperty('action_button2') ? image.settings.action_button2 : '')"
                                            (click)="next(image.settings.hasOwnProperty('action_caption') ? image.settings.action_caption : '', image.settings.hasOwnProperty('action_button2') ? image.settings.action_button2 : '')">
                                            <div class="bubbleStyle2 sawasdee18" [ngStyle]="{'color':'#fff'}">
                                                <span>{{service.removeHtmlTags(image.settings.hasOwnProperty('action_button2') ?
                                                    (isForConfirmation ? 'confirm your participation and, thanks for being with us' : image.settings.action_button2) : '')}}
                                                    <i class="fa fa-angle-right"></i>
                                                </span>

                                            </div>
<!--                                            <button mat-raised-button class="sawasdee18 mdl-shadow&#45;&#45;2dp">-->
<!--                                              <span>{{service.removeHtmlTags(image.settings.hasOwnProperty('action_button2') ? image.settings.action_button2 : '')}}</span>-->
<!--                                            </button>-->
<!--                                            <div class="theme-style-1">-->
<!--                                                <section class="account-option" style="padding: 0px !important;background-color: transparent !important;">-->
<!--                                                    <div class="container">-->
<!--                                                        <a class="sawasdee18 "-->
<!--                                                           [ngStyle]="{'margin-left': '30px','background':'transparent','color':'#fff','border':'0px'}">-->
<!--                                                            <table>-->
<!--                                                                <td valign="top">{{service.removeHtmlTags(image.settings.hasOwnProperty('action_button2') ? image.settings.action_button2 : '')}}</td>-->
<!--                                                                <td valign="top"><i [ngStyle]="{'margin-left': '20px'}" class="fa fa-angle-right"></i></td>-->
<!--                                                            </table>-->
<!--                                                        </a>-->
<!--                                                    </div>-->
<!--                                                </section>-->
<!--                                            </div>-->
                                        </td>
                                    </table>
                                </center>
                            </div>

                        </center>
                    </div>
                </div>
            </div>
        </carousel>
    </div>
</div>
