import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotifierService} from "angular-notifier";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {Parameters} from "../../parameters";

@Component({
  selector: 'app-datediff',
  templateUrl: './datediff.component.html',
  styleUrls: ['./datediff.component.scss']
})
export class DatediffComponent implements OnInit {

  startingAt: any;
  endingAt: any;
  incomingResult: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private notifier: NotifierService,
              public broadcastChannel: BroadcastChannelService,
              public dialogRef: MatDialogRef<DatediffComponent>) {
    this.incomingResult = new Parameters();
    this.incomingResult.setAttributes({});
  }

  ngOnInit(): void {
  }

  onSelectStartingAt(event: any){
    this.endingAt = event > this.endingAt ? event : this.endingAt;
    this.startingAt = event;
    // this.formValues.controls['starting_at'].setValue(moment(new Date(event)).format('MM/DD/YYYY'));
  }

  onSelectEndingAt(event: any){
    this.startingAt = event < this.startingAt ? new Date(event) : this.startingAt;
    this.endingAt = event;
    // this.formValues.controls['ending_at'].setValue(moment(new Date(event)).format('MM/DD/YYYY'));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  takeDateDiff(){
    this.incomingResult.setStartingAt(this.startingAt);
    this.incomingResult.setEndingAt(this.endingAt);
    this.broadcastChannel.emitNavChangeEvent({dateDiff : this.incomingResult.getAttributes()});
    this.dialogRef.close(null);
  }

}
