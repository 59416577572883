<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a>
<a class=""><h4>{{keys === 0 ? 'Open project': incomingSelectedCampaign.getName()}}</h4></a>
<br>
<div>
    <form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
        <mat-list>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width" appearance="fill">
                    <mat-label>Name *</mat-label>
                    <input matInput type="text"  formControlName="name">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width" appearance='fill'>
                     <mat-label> Client *</mat-label>
                     <input type="text"
                            #clientElement
                            placeholder=""
                            aria-label = "Number"
                            matInput
                            (input)="onChangeClientText($event)"
                            [formControl] = "clientControl"
                            [matAutocomplete] = "client">
                                <mat-autocomplete #client = "matAutocomplete"
                                                  (optionSelected)="onClientSelectionChanged($event)"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let client of filteredClientsOptions | async; let clienteOf = index" [value]= "client" >
                                        {{client.name}}
                                    </mat-option>
                                </mat-autocomplete>
                    <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="addClient()">add</i>
                    <h4 style="color: red; font-weight: normal"
                          *ngIf="service.empty(clientElement.value) === false &&
                          ((filteredClientsOptions | async) | json).length === 2">
                          **---Not Found *---</h4>
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width" appearance='fill'>
                     <mat-label> Account *</mat-label>
                     <input type="text"
                            #campanyElement
                            placeholder=""
                            aria-label = "Number"
                            matInput
                            [formControl] = "campanyControl"
                            [matAutocomplete] = "campany"/>
                                <mat-autocomplete #campany = "matAutocomplete"
                                                  (optionSelected)="onCampanySelectionChanged($event)"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let campany of filteredCampaniesOptions | async; let campanyOf = index" [value]= "campany" >
                                        {{campany.name}}
                                    </mat-option>
                                </mat-autocomplete>
                    <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="onaddCampanyClicked()" *ngIf="service.app.data.user.role.id === 1">add</i>
                      <h4 style="color: red; font-weight: normal"
                          *ngIf="service.empty(campanyElement.value) === false &&
                          ((filteredCampaniesOptions | async) | json).length === 2">
                          **---Not Found *---</h4>
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
              <span class="" mat-line>
                   <mat-form-field class="example-full-width" appearance='fill'>
                      <mat-label>Description</mat-label>
                      <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
                      <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                    </mat-form-field>
              </span>
            </mat-list-item>
        </mat-list>
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <mat-calendar [selected]="startingAt" (selectedChange)="onSelectStartingAt($event)" id="startingAt"></mat-calendar>
            </div>
            <div class="col-md-6 col-sm-6">
                <mat-calendar [selected]="endingAt" (selectedChange)="onSelectEndingAt($event)"></mat-calendar>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <center>
                    <div class="example-button-row">
                        <input type="submit" class="btn btn-md submit-btn defaultColor" value="{{keys === 0 ? 'Done' : 'Edit'}}" >
                    </div>
                </center>
            </div>
        </div>
    </form>
</div>

