import { Component, OnInit } from '@angular/core';
import {AppService} from "../app.service";
import {HeaderComponent} from "../header/header.component";

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  meetings: any = ['assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg'];
  constructor(public service: AppService,
              private headerComponent: HeaderComponent) {
  }

  ngOnInit(): void {
    this.headerComponent.headerClass = 'header-2';
    // document.getElementsByTagName('body')[0].style.backgroundColor = '#f5f5f5';
  }

}
