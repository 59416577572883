import {Component, Input, OnDestroy, AfterViewInit, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../../../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../../form.service';
import {Parameters} from '../../../../parameters';
import {NotifierService} from 'angular-notifier';
import {DeleteDialogComponent} from '../../../../main/delete-dialog.component';
import {DialogGenerateQrcodeComponent} from '../../../../invitations/dialog/dialog-generate-qrcode/dialog-generate-qrcode.component';
import {ResponseService} from '../response.service';
import {FilterDashboardComponent} from '../../../../dashboard/filter-dashboard.component';
import {MessageDisplayComponent} from '../../../../main/message/message-display.component';
import {take} from 'rxjs/operators';
import {DashboardService} from "../../../../dashboard/dashboard.service";
import {PdfFormService} from "../../../pdf/pdf-form.service";
import * as moment from 'moment';
import {CampaignService} from "../../../../campaigns/campaign.service";
import {ClientService} from "../../../../clients/client.service";
import {TableService} from '../../../controls/table/table.service';
import {StorageMap } from '@ngx-pwa/local-storage';
import {Options, NgxQrcodeStylingComponent } from 'ngx-qrcode-styling';
@Component({
  selector: 'app-table-responses-form',
  templateUrl: './table-responses-form.component.html',
  styleUrls: ['./table-responses-form.component.scss']
})
export class TableResponsesFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() flag: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  @ViewChild('qrcode', { static: false }) public qrcode!: NgxQrcodeStylingComponent;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  form: any;
  formId: any;
  responses: any = {data:[],trashed:{data:[]}};
  incomingForm: any;
  subscription: any;
  searchCntrl: any;
  outgoingForm: any;
  teams: any;
  isDialogOpen: any = false;
  responseSelected: any;
  questionalValues: any;
  campaign: any;
  dialogRef: any;
  descendinglastAt: any = null;
  incomingClient: any;
  incomingCampaign: any;
  incomingQuestion: any;
  incomingResponseResult: any;
  incomingOption: any;
  incomingQuestions: any;
  incomingPost: any;
  incomingFormSettings: any;
  incomingResponse: any;
  incomingSelectedQuestion: any;
  results: any;
  result: any;
  incomingResponseSelected: any;
  posts: any = [];
  outgoingRequest: any;
  incomingInvitationQuestion: any;
  public qrcodeConfig: Options = {
       width: 300,
       height: 300,
       data: '',
       image: '',
       margin: 5,
       dotsOptions: {
         color: "#000",
         type: "dots"
       },
       backgroundOptions: {
         color: "#ffffff",
       },
       imageOptions: {
         crossOrigin: "anonymous",
         margin: 0
       }
  };

  constructor(public  service: AppService,
              public  dialog: MatDialog,
              private pdfFormService: PdfFormService,
              private activatedRoute: ActivatedRoute,
              public  broadcastChannel: BroadcastChannelService,
              private notifier: NotifierService,
              private dashboardService: DashboardService,
              public  tableService: TableService,
              private campaignService: CampaignService,
              private clientServce: ClientService,
              private storage: StorageMap,
              private router: Router,
              private formService: FormService,
              private responseService: ResponseService) {
    this.incomingCampaign = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingOption   = new Parameters();
    this.outgoingForm = new Parameters();
    this.incomingResponseResult   = new Parameters();
    this.incomingSelectedQuestion = new Parameters();
    this.incomingQuestions = new Parameters();
    this.incomingPost = new Parameters();
    this.incomingInvitationQuestion = new Parameters();
    this.incomingResponseSelected = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingResponse = new Parameters();
    this.app = service.app;
    this.incomingInvitationQuestion.setAttributes({});
    this.contextMenu = MatMenuTrigger;
    this.formId = this.activatedRoute.snapshot.params.id;
    this.formId = this.service.decrypt(this.formId);
    this.form = this.formService.getFormById(this.formId);
    this.responses = this.form.responses;
    this.incomingForm = new Parameters();
    this.incomingForm.attributes = this.form;
    this.responseService.reportValues.attributes.form_id = this.incomingForm.attributes.id;
    this.campaign = this.campaignService.getCampaignById(this.campaignService.campaignSelectedId);
    this.incomingCampaign.setAttributes(this.campaign);
    this.incomingClient = new Parameters();
    this.incomingClient.setAttributes(this.clientServce.getClientById(this.incomingCampaign.attributes.client_id));
    this.incomingCampaign.setClient(this.incomingClient.getAttributes());
    this.service.subHeader = this.service.upperStartingCharacter(this.incomingCampaign.getName(), true) + ', ' + this.incomingClient.getName().toString().toLowerCase();
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingFormSettings.setOutlet(this.incomingFormSettings.hasAttribute('outlet') ? this.incomingFormSettings.getOutlet() : '');
    this.incomingFormSettings.setInvitationContents(this.incomingFormSettings.hasAttribute('invitation_contents') ? this.incomingFormSettings.getInvitationContents() : '');
    this.incomingFormSettings.setResponsesCounter(this.incomingFormSettings.hasAttribute('responses_counter') ? this.incomingFormSettings.getResponsesCounter() : 1);
    this.incomingFormSettings.setIsInvitedControl(this.incomingFormSettings.hasAttribute('is_invited_control') ? this.incomingFormSettings.getIsInvitedControl() : '');
    this.incomingFormSettings.setBackgroundColor(this.incomingFormSettings.hasAttribute('background_color') ? this.incomingFormSettings.getBackgroundColor() : '');
  }

  getQuestionById(id: any){return this.incomingForm.getControls().filter((question: any) => {return parseFloat(question.id) === parseFloat(id);})[0];}
  ngOnInit(): void {
     this.incomingSelectedQuestion.setAttributes(window.location.href.toString().includes('questions') ? this.getQuestionById(this.service.decrypt(this.activatedRoute.snapshot.params.questionId)) : {});
     document.title = this.campaign.name.toString().toUpperCase() + ', ' + this.incomingClient.getName();
     this.results = this.form.name.toString().toUpperCase();
     this.results = window.location.href.toString().includes('questions') ? this.incomingSelectedQuestion.getLabel().toString().toUpperCase() : this.results;
     this.service.header =  this.results+ '  ' + this.service.setDisplayedDate(this.responseService.reportValues) +  ' ,responses' ;
     if(this.responseService.isDialogOpen === false) {
      if(this.responses.data.length===0){
        this.responses.data = [];
        this.responses.trashed = {data:[]};
        this.report();
      }
     }setTimeout(() => {
       this.emitter();
       this.searchCntrl = this.responseService.searchText;
     });
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      window.scrollTo(this.responseService.xScrollingPart, this.responseService.yScrollingPart);
    },this.service.timeout());
  }


  sendInvitationThroughtEmail(response: any){
    const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: 'Invitation', action: 'send email to'},width : 'auto',});
    dialogRef.afterClosed().subscribe((item: any) => {
      if (item instanceof Object){
        if (item.hasOwnProperty('response')){
          if (item.response.toString().includes('successfull')){
              this.incomingResponseSelected.setAttributes(this.responseSelected);
               this.outgoingRequest.setAttributes({});
               this.outgoingRequest.setFormId(this.incomingForm.getId());
               this.outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
               this.outgoingRequest.setUnknown('backgroundColor',this.incomingFormSettings.getBackgroundColor());
               this.service.httpService( 'post', this.service.app.routes.invitations.email, this.outgoingRequest.getAttributes(), {},
                   (result: any) => {
                    this.incomingResponseSelected.attributes =
                      Object.assign(this.incomingResponseSelected.attributes,result);
                      this.notifier.notify('success', this.service.app.strings.invitation.email.successfull);
                   this.sendInvitationThroughtWhatsapp(response);
                   },  (error: any) => {this.notifier.notify('success', this.service.app.strings.invitation.email.error);
                   });
          }
        }
      }
    });
  }

  download(){
   this.incomingResponseSelected.setAttributes(this.responseSelected);
   this.qrcode.download("png").subscribe((result: any) => {
   });
  }

  sendInvitationThroughtWhatsapp(response: any){
      const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: 'Invitation', action: 'whatsapp invitation to'},width : 'auto',});
      dialogRef.afterClosed().subscribe((item: any) => {
        if (item instanceof Object){
          if (item.hasOwnProperty('response')){
            if (item.response.toString().includes('successfull')){
               this.incomingResponseSelected.setAttributes(this.responseSelected);
               this.outgoingRequest.setAttributes({});
               this.outgoingRequest.setFormId(this.incomingForm.getId());
               this.outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
               this.outgoingRequest.setUnknown('backgroundColor',this.incomingFormSettings.getBackgroundColor());
               this.incomingResponseSelected.getUnknown('datas').map((responseResult: any) => {
                  this.incomingResponseResult.setAttributes(responseResult);
                  this.incomingQuestion.setAttributes(this.incomingResponseResult.getUnknown('formControl'));
                  this.incomingQuestion.setResponseResultId(this.incomingResponseResult.getId());
                  this.incomingQuestion.setData(this.incomingResponseResult.getData());
                  this.incomingQuestion.setResult(this.incomingResponseResult.getResult());
                  if(this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvitationContents()){
        //          this.service.voiceSpeek(this.service.removeHtmlTags(this.incomingResponseResult.getData()) + " "+this.service.app.strings.invitation.whatsapp.link, (result: any) =>{
        //          this.outgoingRequest.setAudioUrl(result);
                    this.service.httpService( 'post', this.service.app.routes.invitations.whatsapp, this.outgoingRequest.getAttributes(), {},
                        (result: any) => {
                          this.incomingResponseSelected.attributes =
                          Object.assign(this.incomingResponseSelected.attributes,result);
                          this.notifier.notify('success', this.service.app.strings.invitation.whatsapp.successfull);
                         },  (error: any) => {
        //               this.notifier.notify('success', this.service.app.strings.invitation.whatsapp.error);
                         });
        //            });
                  }
               });
            }
          }
        }
      });
//       const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: 'Invitation', action: 'send whatsapp message to'},width : 'auto',});
//       dialogRef.afterClosed().subscribe((item: any) => {
//         if (item instanceof Object){
//           if (item.hasOwnProperty('response')){
//             if (item.response.toString().includes('successfull')){
//
//             }
//           }
//         }
//       });
  }

  isInvitationCardEmailed(callback: any){
   this.result = false;
   this.incomingResponseSelected.getUnknown('datas').map((responseResult: any) => {
     this.incomingResponseResult.setAttributes(responseResult);
      if(this.incomingResponseResult.getUnknown('formControl').cntrl_num.toString() === this.incomingFormSettings.getIsInvited().toString()){
        if(this.incomingResponseResult.getUnknown('data').toLowerCase().includes('yes'))
         this.result = true;
      }
   });
   callback(this.result);
  }

  sendInvitationCardRequest(){
       if(this.posts.length !== 0){
        this.posts.map((post: any,incremental: any) => {
           if(this.responseService.isDialogOpen === false){
                this.responseService.isDialogOpen = true;
                this.incomingResponseSelected.setAttributes(post);
                this.responseService.setSelected(this.incomingResponseSelected.getAttributes());
                this.isInvitationCardEmailed((result: any) => {
                 if(!result){
                  this.outgoingRequest.setAttributes({});
                  this.outgoingRequest.setFormId(this.incomingForm.getId());
                  this.outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
                  this.outgoingRequest.setUnknown('backgroundColor',this.incomingFormSettings.getBackgroundColor());
                  this.service.httpService( 'post', this.service.app.routes.invitations.email, this.outgoingRequest.getAttributes(), {},
                        (result: any) => {
                         this.notifier.notify('success', this.service.app.strings.invitation.email.successfull);
                         this.posts.splice(this.service.findIndex(this.posts, 'id',
                           parseFloat(this.incomingResponseSelected.getId())), 1);
                         this.responseService.isDialogOpen = false;
                         this.sendInvitationCardRequest();
                        },  (error: any) => {
                         this.notifier.notify('success', this.service.app.strings.invitation.email.error);
                         this.posts.splice(this.service.findIndex(this.posts, 'id',
                          parseFloat(this.incomingResponseSelected.getId())), 1);
                         this.responseService.isDialogOpen = false;
                         this.sendInvitationCardRequest();
                        });
                  }else {
                    this.posts.splice(this.service.findIndex(this.posts, 'id',
                      parseFloat(this.incomingResponseSelected.getId())), 1);
                      this.responseService.isDialogOpen = false;
                      this.sendInvitationCardRequest();
                  }
                });
           }
         });
      }else this.fetchAllByDescendingAndSendInvitation();
  }

  emitter(){
    this.subscription = this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if (item instanceof Object) {
          if (item.hasOwnProperty('searchText') === true){
            this.searchCntrl = item.searchText;
            this.responseService.searchText = item.searchText;
          }
        } else if (item.toString().toLowerCase() === 'add') {
          this.addResponse();
        }else if (item.includes('extract')) {
          this.responseService.reportValues.setDisplay('excel');
          if(this.responseService.isDialogOpen === false) {
            this.responseService.isDialogOpen = true;
            this.dialog.open(FilterDashboardComponent, {
              data: {report: this.responseService.reportValues.getAttributes()},
              width: '55%',
              height: '100%'
            }).beforeClosed().subscribe(() =>{
              this.responseService.isDialogOpen = false;
            });
          }
        }else if (item.includes('qrcodeCloseDialog')) {
          if(this.responseService.isDialogOpen === false) {
               this.posts.splice(this.service.findIndex(this.posts, 'id',
                parseFloat(this.incomingResponseSelected.getId())), 1);
                setTimeout(() => {
                  this.openDialogGenerateQrcodeComponent();
                });
           }
        }else if (item.includes('search')) {
         this.handleSearch();
        }else if (item.includes('qrcode')) {
             if(this.responseService.isDialogOpen === false) {
               this.responseService.isDialogOpen = true;
               this.generateQrcodeUrl();
             }
        }else if (item.includes('sendInvitation')) {
          if(this.responseService.isDialogOpen === false) {
            this.responseService.isDialogOpen = true;
            this.sendInvitationCard();
          }
        }else if (item === 'openDateMenu') {
          this.openMenuDate();
        } else if (item === 'report') {
          this.responseService.isDialogOpen = false;
          this.report();
       // this.formService.setDate(this.responseService, this.dashboardService);
        }else if (item.includes('more')) {
            if(this.responses.data.length !== 0){
             this.responseService.reportValues.setDescendinglastAt(this.responses.data[this.responses.data.length - 1].id);
             this.report();
            }

        }
      });
  }

  fetchAllByDescendingAndSendInvitation(){
     if(this.incomingResponseSelected.getAttributes().hasOwnProperty('id')){
       this.outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
                this.outgoingRequest.setFormId(this.incomingForm.getId());
                this.outgoingRequest.setCategoryId(6);
                this.outgoingRequest.setDescendinglastAt(this.incomingResponseSelected.getId());
                if(this.outgoingRequest.getAttributes().hasOwnProperty('data'))
                delete this.outgoingRequest.data;
                this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {},
                (responses: any) => {
                 if(responses.length !== 0){
                  this.posts = responses;
                  this.responseService.isDialogOpen = false;
                  this.sendInvitationCardRequest();
                 }
                }, (error: any) => {
                });
     }
  }

  sendInvitationCard(){
       setTimeout(() => {
         this.outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
            this.outgoingRequest.setFormId(this.incomingForm.getId());
            this.outgoingRequest.setCategoryId(6);
            if(this.outgoingRequest.getAttributes().hasOwnProperty('data'))
            delete this.outgoingRequest.data;
            this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {},
            (responses: any) => {
             this.posts = responses;
             this.responseService.isDialogOpen = false;
             this.sendInvitationCardRequest();
            }, (error: any) => {
            });
       });

  }

  fetchAllByDescending(){
   if(this.incomingResponseSelected.getAttributes().hasOwnProperty('id')){
     this.outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
              this.outgoingRequest.setFormId(this.incomingForm.getId());
              this.outgoingRequest.setCategoryId(6);
              this.outgoingRequest.setUnknown('qrcode',null);
              this.outgoingRequest.setDescendinglastAt(this.incomingResponseSelected.getId());
              if(this.outgoingRequest.getAttributes().hasOwnProperty('data'))
              delete this.outgoingRequest.data;
              this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {},
              (responses: any) => {
               if(responses.length !== 0){
                this.posts = responses;
                this.responseService.isDialogOpen = false;
                this.openDialogGenerateQrcodeComponent();
               }
              }, (error: any) => {
              });
   }
  }

  openDialogGenerateQrcodeComponent(){
     if(this.posts.length !== 0){
      this.posts.map((post: any,incremental: any) => {
         if(this.responseService.isDialogOpen === false){
              this.responseService.isDialogOpen = true;
              this.incomingResponseSelected.setAttributes(post);
              this.responseService.setSelected(this.incomingResponseSelected.getAttributes());
              this.dialogRef = this.dialog.open(DialogGenerateQrcodeComponent, {
                  data: {},
                  width: '55%',
                  height: '100%'
              }).beforeClosed().subscribe(() =>{
                  this.responseService.isDialogOpen = false;
              });
         }
       });
    }else this.fetchAllByDescending();
  }

  generateQrcodeUrl(){
     setTimeout(() => {
       this.outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.attributes));
          this.outgoingRequest.setFormId(this.incomingForm.getId());
          this.outgoingRequest.setCategoryId(6);
          this.outgoingRequest.setUnknown('qrcode',null);
          if(this.outgoingRequest.getAttributes().hasOwnProperty('data'))
          delete this.outgoingRequest.data;
          this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {},
          (responses: any) => {
           this.posts = responses;
           this.responseService.isDialogOpen = false;
           this.openDialogGenerateQrcodeComponent();
          }, (error: any) => {
          });
     });

  }

  copy(){
    document.execCommand('copy');
  }

  addResponse(){
     if(!this.responseService.isDialogOpen){
//     this.service.app.data.status = 0;
//     this.responseService.responseSelectedCntrlNum = null;
//     this.formService.setSelected({});
//     this.responseService.setSelected({});
//     this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.attributes.id) + '/feedback');
       this.responseService.isDialogOpen = true;
        this.outgoingForm.setAttributes(Object.assign({},this.incomingForm.getAttributes()));
        this.responseService.setPost(this.outgoingForm.getAttributes(), (response: any)=>{
           let responses: any = [];
           this.formService.setSelected(this.incomingForm.getAttributes());
           let controls: any = [];
           this.outgoingForm.getControls().map((question: any) => {
             this.incomingQuestion.setAttributes(Object.assign({},question));
             this.incomingQuestion.setData('');
             this.incomingQuestion.setResult({});
//           this.controls.push(this.incomingQuestion.getAttributes());
           });
           this.incomingPost.setAttributes(response);
           this.incomingPost.setForm(this.incomingForm.getAttributes());
           this.responseService.setSelected(this.incomingPost.getAttributes());
           responses.push(this.incomingPost.getAttributes());
           this.storage.set('responses', responses).subscribe(() => {});
           this.storage.set('others', []).subscribe(() => {});
           this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.attributes.id) + '/respond');
           this.responseService.isDialogOpen = false;
        });


     }

  }


  report(){
    if(this.responseService.isDialogOpen === false) {
      this.responseService.isDialogOpen = true;
      setTimeout((outgoingRequest: any = {}) => {
            outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
            delete outgoingRequest.data;
            if(window.location.href.toString().includes('questions'))
             outgoingRequest.question_id = this.service.decrypt(this.activatedRoute.snapshot.params.questionId);
             if(window.location.href.toString().includes('labels'))
               outgoingRequest.optionCntrlNum = this.service.decrypt(this.activatedRoute.snapshot.params.label);
            this.service.httpService('post', '/reports', outgoingRequest, {}, (responses: any) => {
              if(this.responseService.reportValues.attributes.hasOwnProperty('descending_last_at')){
               responses.map((response: any) => {
                this.incomingResponse.setAttributes(response);
                this.responses.data.push(this.incomingResponse.getAttributes());
                delete this.responseService.reportValues.attributes.descending_last_at;
                delete outgoingRequest.descending_last_at;
               });
              }else this.responses.data = responses;
              this.responseService.reportValues.attributes.data = 'values';
              this.responseService.isDialogOpen = false;
            }, (error: any) => {
             this.responseService.isDialogOpen = false;
             if(this.responseService.reportValues.attributes.hasOwnProperty('descending_last_at'))
              delete this.responseService.reportValues.attributes.descending_last_at;
            });
          });
    }
  }

  print(form: any){
    this.formService.setSelected(this.form);
    this.formService.questions = Object.assign(this.formService.questions, this.form.controls);
    setTimeout((position: any) => {
      this.responseSelected.datas.map((question: any) => {
        this.questionalValues = new Parameters();
        this.questionalValues.setAttributes(question);
        position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.questionalValues.attributes.formControl.cntrl_num);
        if(this.formService.questions[position]){
           this.formService.questions[position] = Object.assign(this.formService.questions[position] , {data: this.questionalValues.attributes.data});
        }
      });
      this.pdfFormService.downloadPdf();
    });
  }


  openMenuDate(){
    if(this.responseService.isDialogOpen === false) {
    this.responseService.isDialogOpen = true;
    this.responseService.reportValues.setDisplay('dashboard');
    this.dialog.open(FilterDashboardComponent, {data: {report: this.responseService.reportValues.getAttributes()}, width: '55%', height: '100%'}).beforeClosed().subscribe(() => {
      this.responseService.isDialogOpen = false;
    });
    }
  }


  handleSearch(){
      setTimeout((outgoingRequest: any = {}) => {
        if(!this.service.empty(this.searchCntrl)){
          this.responseService.reportValues.setDisplay('dashboard');
          outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
          outgoingRequest.searchText = this.searchCntrl;
          delete outgoingRequest.data;
          this.service.httpService('post', '/forms/xx/responses/searchrequest', outgoingRequest, {}, (result: any) => {
            this.responses = Object.assign(this.responses, {data: result});
            }, (error: any) => {
          });
        }
      });
    // if(this.responseService.isDialogOpen === false) {
    //   this.responseService.isDialogOpen = true;
    //   setTimeout((outgoingRequest: any = {}) => {
    //     if(!this.service.empty(this.searchCntrl)){
    //       this.responseService.reportValues.setDisplay('dashboard');
    //       outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
    //       outgoingRequest.searchText = this.searchCntrl;
    //       delete outgoingRequest.data;
    //       this.service.httpService('post', '/forms/xx/responses/searchrequest', outgoingRequest, {}, (response: any) => {
    //         this.responses.data = response;
    //         this.responseService.isDialogOpen = false;}, (error: any) => {
    //         this.responseService.isDialogOpen = false;
    //       });
    //     }
    //   });
    // }
  }




  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  handleDestroy(response: any){
    this.incomingResponseSelected.setAttributes(this.responseSelected);
    this.dialog.open(DeleteDialogComponent, {
      data   : {
        data : this.responses,
        model: this.incomingResponseSelected.getAttributes(),
        title: this.incomingResponseSelected.getTitle(),
        url  : this.service.app.routes.responses.delete.toString().replaceAll('xx',this.incomingResponseSelected.getId()),
        key  : 'id',
        id   : this.incomingResponseSelected.getId(),
      },
      width : 'auto',
    });
  }

  getCampaignByForm(form: any){
    let campaign: any = this.service.only(this.app.data.campaigns.data.filter((campaign: any) => { return campaign.id === form.campaign_id;})[0]);
    let client: any = this.service.only(this.getClientByCampaign(campaign));
    campaign = Object.assign(campaign, {client: client});
    form = Object.assign(form, {campaign: campaign});
    return campaign;
  }


  getClientByCampaign(campaign: any){
    return this.app.data.clients.data.filter((client: any) => { return client.id === campaign.client_id; })[0];
  }

  handleUpdate(post: any){
    this.incomingForm = new Parameters();
    this.incomingForm.setAttributes(this.form);
    this.incomingForm.setCampaign(this.incomingCampaign.getAttributes());
    this.formService.setSelected(this.incomingForm.getAttributes());
    this.incomingForm.getControls().map((question: any) => {
      this.incomingQuestion.setAttributes(question);
      this.responseSelected.datas.map((resp: any) => {
        if(resp.formControl.cntrl_num.toString() === this.incomingQuestion.getCntrlNum().toString()){
          this.incomingQuestion.setData(resp.data);
          this.incomingQuestion.setResult(resp.result);
        }
      });
    });
    this.incomingPost.setAttributes(this.responseSelected);
    this.incomingPost.setForm(this.incomingForm.getAttributes());
    this.responseService.setSelected(this.incomingPost.getAttributes());
    this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.attributes.id) + '/respond');
  }

  handleRestore(inspector: any){
   this.incomingForm.setAttributes(this.form);
   this.service.restore('/forms/' + parseFloat(this.incomingForm.getId()) + '/restore',
   this.responses, 'id', parseFloat(this.incomingForm.getId()), this.form);
  }



  handleResponses(form: any){
    this.subscription.unsubscribe();
    this.incomingForm.setAttributes(this.form);
    this.incomingForm.getControls().map((question: any) => {
      this.incomingQuestion.setAttributes(question);
      this.tableService.setQuestion(this.incomingQuestion.getAttributes());
    });this.responseService.setSelected(this.form);
    this.router.navigateByUrl('/app/forms/' + this.form.id + '/responses');
  }

  openDialog(response: any){
    this.responseService.setSelected(this.incomingForm.getAttributes());
    // this.inspectorsComponent.openDialog();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.responseService.yScrollingPart = window.pageYOffset;
    this.responseService.xScrollingPart = window.pageXOffset;
    if(this.responseService.reportValues.attributes.hasOwnProperty('descending_last_at'))
         delete this.responseService.reportValues.attributes.descending_last_at;
  }


}
