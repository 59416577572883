import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';

@Component({
  selector: 'app-number-controls',
  templateUrl: './number-controls.component.html',
  styleUrls: ['./number-controls.component.scss']
})
export class NumberControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  controls: any;
  constructor(private metthodsService: AppService) { }
  ngOnInit(): void {
    this.controls = this.metthodsService.app.data.controls;
  }

}
