import { Component, OnInit, OnChanges, SimpleChanges, Input, OnDestroy, AfterViewInit} from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from "../../../../parameters";
import {FormService} from "../../../form.service";
import {NotifierService} from 'angular-notifier';
import {ResponseService} from '../../../responses/data/response.service';
import {BroadcastChannelService} from "../../../../broadcast-channel.service";
import * as moment from 'moment';
@Component({
  selector: 'app-view-winner-control',
  templateUrl: './view-winner-control.component.html',
  styleUrls: ['./view-winner-control.component.scss']
})
export class ViewWinnerControlComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() question: any;
  @Input() position: any;
  data: any;
  incomingQuestion: any;
  incomingForm: any;
  incomingSettings: any;
  incomingResponse: any;
  film: any;
  drumRollWinner: any;
  filmUrl: any = 'assets/audio/filmmusic.mp3';
  subscription: any;
  posts: any;
  outgoingQuestion: any;
  questions: any = [];
  outgoingRequest: any;
  incomingCriteria: any;
  incomingResult: any;
  incomingCustom: any;
  winnerShowImg: any = false;
  drumRollWrong: any;
  constructor(public service: AppService,
              private notifierService: NotifierService,
              private broadcastChannelService: BroadcastChannelService,
              private responseService: ResponseService,
              public formService: FormService) {
     this.incomingQuestion = new Parameters();
     this.incomingSettings = new Parameters();
     this.incomingForm = new Parameters();
     this.incomingResponse = new Parameters();
     this.outgoingQuestion = new Parameters();
     this.incomingCriteria = new Parameters();
     this.outgoingRequest = new Parameters();
     this.incomingResult = new Parameters();
     this.incomingCustom = new Parameters();
  }

  ngOnChanges(changes: SimpleChanges): void {
     this.incomingResponse.setAttributes(this.responseService.selected);
     this.incomingForm.setAttributes(Object.assign({}, this.formService.getSelected()));
     this.incomingSettings.setAttributes(this.incomingForm.getSettings());
     this.incomingSettings.setCriterias(this.incomingSettings.attributes.hasOwnProperty('criterias') ?
      this.incomingSettings.getCriterias() : []);
     this.incomingQuestion.setAttributes(this.question);
     this.incomingQuestion.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') &&
       !this.service.empty(this.incomingQuestion.getResult()) ? Object.assign({}, this.incomingQuestion.getResult()) : {});
//   this.playFilm(this.filmUrl);
     this.broadcastChannelService.emitNavChangeEvent({action : 'nextButtonHidden'});
     this.subscription =  this.broadcastChannelService.getNavChangeEmitter().pipe()
         .subscribe((item: any) => {
           if(item instanceof Object){
            if(item.hasOwnProperty('requestText')){
              if(item.requestText.toString().includes('responseQuestionsValidation')){
                     this.posts = [];
                     this.formService.questions.map((question: any) => {
                        this.outgoingQuestion.setAttributes({});
                        this.outgoingQuestion.attributes = Object.assign(this.outgoingQuestion.attributes, question);
                         this.incomingQuestion.setResult(this.incomingQuestion.hasAttribute('result') &&
                              !this.service.empty(this.outgoingQuestion.getResult()) ?
                              JSON.stringify(this.outgoingQuestion.getResult()) : '');
                          this.outgoingQuestion.attributes.form_control_id = this.outgoingQuestion.attributes.control.id;
                          this.outgoingQuestion.attributes = Object.assign(this.outgoingQuestion.getAttributes(), {data: this.service.empty(this.outgoingQuestion.attributes.data) ? '' : this.outgoingQuestion.attributes.data});
                          delete this.outgoingQuestion.attributes.options;
                          this.questions.push(this.outgoingQuestion.attributes);
                      });
                      this.incomingResponse.setEndingAt(moment(new Date()).format('HH:mm:ss'));
                      this.incomingResponse.setTitle('');
                      this.incomingResponse.setDepartmentId(1);
                      this.incomingResponse.setOutletId(this.incomingResponse.getAttributes().hasOwnProperty('outlet_id') ?
                       this.incomingResponse.getOutletId() :
                       this.responseService.outletId);
                      this.incomingResponse.setStatus(parseFloat('0'));
                      this.incomingResponse.setLat(this.incomingResponse.attributes.hasOwnProperty('lat') ?
                       this.incomingResponse.getLat() : 0);
                      this.incomingResponse.setLng(this.incomingResponse.attributes.hasOwnProperty('lng') ?
                       this.incomingResponse.getLng() : 0);
                      this.incomingResponse.setAltitude(this.incomingResponse.attributes.hasOwnProperty('altitude') ?
                       this.incomingResponse.getAltitude() : 0);
                      if (this.incomingResponse.attributes.hasOwnProperty('datas'))
                        delete this.incomingResponse.attributes.datas;
                      if (this.incomingResponse.attributes.hasOwnProperty('id') &&
                          this.incomingResponse.attributes.hasOwnProperty('form'))
                        delete this.incomingResponse.attributes.form;
                      if (this.incomingResponse.attributes.hasOwnProperty('department'))
                        delete this.incomingResponse.attributes.department;
                      if (this.incomingResponse.attributes.hasOwnProperty('posted_by'))
                        delete this.incomingResponse.attributes.posted_by;
                      this.incomingResponse.setControls(this.questions);
                      this.posts.push(this.incomingResponse.getAttributes());
                      let outgoingPosts: any = JSON.stringify(this.posts);
                      this.service.httpService('post', this.service.app.routes.responses.post, {posts: outgoingPosts}, {}, (response: any) => {
                        this.incomingSettings.getCriterias().map((criteria: any)=> {
                             this.incomingCriteria.setAttributes(criteria);
                             if(this.incomingCriteria.getTitle().toString().toLowerCase().includes('winner')){
                                 alert(99);
                                 this.outgoingRequest.setAttributes(this.responseService.reportValues.attributes);
                                 this.outgoingRequest.setId(this.incomingCriteria.getId());
                                 this.outgoingRequest.setFormId(this.incomingForm.getId());
                                 this.outgoingRequest.setSection('criterias');
                                 this.outgoingRequest.setType('form-dashboard');
                                 this.outgoingRequest.setDuration('custom');
                                 this.outgoingRequest.setUnknown('responseCntrlNum',this.incomingResponse.getCntrlNum());
                                 this.outgoingRequest.setCntrlNum(this.incomingCriteria.getCntrlNum());
                                 this.service.httpService('post',
                                     this.service.app.routes.reports.get,
                                     this.outgoingRequest.getAttributes(), {}, (result: any) => {
                                         this.incomingResult.setAttributes(result);
                                         this.incomingCriteria.setAttributes(this.incomingResult.getCriterias()[0]);
                                         this.incomingCustom.setAttributes(this.incomingCriteria.getCustom());
                                         this.responseService.film.pause();
                                         if(parseFloat(this.incomingCustom.getResponses()) ===1){
                                             this.playSoundDrumRollWinner((result: any) => { });
                                             setTimeout(()=>{
                                                 this.drumRollWinner.pause();
                                                 this.playSoundWinner((result: any) => {});
                                                 setTimeout(()=>{
                                                     this.winnerShowImg=true;
                                                 },this.service.timeout()*parseFloat('2'));
                                             },this.service.timeout()*parseFloat('6'));
                                         }else{
                                             this.playSoundDrumRollWrong((result: any) => {});
                                         }

                                     },(error: any)=> {});
                             }
                        });
                      },(error: any)=> {});

              }
            }
           }
          });
     this.broadcastChannelService.emitNavChangeEvent("responseQuestionsValidation");
  }

  playSoundDrumRollWinner(callback: any){
       this.drumRollWinner = new Audio('../assets/audio/drumRollWinner.mp3');
       this.drumRollWinner.play();
       this.drumRollWinner.onended = () =>{
           callback(null);
       }
  }

   playSoundDrumRollWrong(callback: any){
         this.drumRollWrong = new Audio('../assets/audio/drumRollWrong.mp3');
         this.drumRollWrong.play();
         this.drumRollWrong.onended = () =>{
             callback(null);
         }
  }

  playSoundWinner(callback: any){
     const sound: any = new Audio('../assets/audio/winner.mp3');
     sound.play();
     sound.onended = () =>{
         callback(null);
     }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.film.pause();
  }

  playFilm(url: any){
    this.film = new Audio(url);
    this.film.play();
  }


  ngAfterViewInit(): void {
   setTimeout(()=>{
//    this.service.startTyper('sffdjfdjfdfd dfdfdfdf dkfdf','startTyper');
//    this.service.voiceSpeek('something wrong please scan again', ()=>{});
   });
  }

}
