import { Component, OnInit, NgZone, ViewChild, ElementRef, Input } from '@angular/core';
import {MatDrawer } from '@angular/material/sidenav';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';
import {MatDialog} from '@angular/material/dialog';
import {ClientService} from '../../clients/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseService} from '../../forms/responses/data/response.service';
import {FormService} from '../../forms/form.service';
import {NotifierService} from 'angular-notifier';
import {MessageDisplayComponent} from '../../main/message/message-display.component';
import {ProgressDialogComponent} from '../../main/progress-dialog/progress-dialog.component';
import {StorageMap } from '@ngx-pwa/local-storage';
import {Options, NgxQrcodeStylingComponent, NgxQrcodeStylingService } from 'ngx-qrcode-styling';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-register-invitee',
  templateUrl: './register-invitee.component.html',
  styleUrls: ['./register-invitee.component.scss']
})
export class RegisterInviteeComponent implements OnInit {
  @ViewChild('drawer', {static: false})  drawer: any;
  @ViewChild('email' , {static: false})  elementHtmlEmail: ElementRef;
  @ViewChild('phone' , {static: false})  elementHtmlPhone: ElementRef;
  @ViewChild('name'  , {static: false})  elementHtmlName:  ElementRef;
  @ViewChild('declineCenterContents'  , {static: false})  elementHtmlDeclineCenterContents:  ElementRef;
  @Input() placeholderFullName: any;
  formValues: FormGroup = new FormGroup({});
  formId: any;
  responseId: any;
  logo: any;
  frame: any;
  outgoingRequest:any;
  incomingForm: any;
  incomingResponse: any;
  incomingQuestion: any;
  incomingClient: any;
  showDeclinenote: any = true;
  incomingCampaign: any;
  incomingInvitee: any;
  outgoingQuestion: any;
  incomingResponseResult: any;
  incomingSettingsForm: any;
  incomingIsConfirmQuestion: any;
  incomingSettingsQuestion: any;
  outgoingResults: any;
  incomingResult: any;
  outgoingResult: any;
  dialogRef: any;
  isConfirmed: any = false;
  incomingInvitationCardQuestion: any;
  incomingResponseChannelQuestion: any;
  incomingConfirmationContentsQuestion: any;
  contents: any = '';
  incomingQrcodeQuestion: any;
  dateSpliter: any;
  result: any;
  outgoingPosts : any = [];
  img: any;
  contentsShow: any = true;
  options: any = [];
  incomingOption: any;
  incomingNameQuestion: any;
  incomingPhoneQuestion: any;
  incomingEmailQuestion: any;
  outgoingNameQuestion: any;
  outgoingPhoneQuestion: any;
  outgoingEmailQuestion: any;
  outgoingQrcodeQuestion: any;
  outgoingInvitationQuestion: any;
  outgoingInvitationCardQuestion: any;
  outgoingQuestions: any = [];
  outgoingResponse: any;
  invitees: any = [];
  CC: any = [];
  outgoingImgRequest: any;
  config: any = {
       width: 300,
       height: 300,
       data: '',
       image: '',
       margin: 0,
       dotsOptions: {
         color: "#000",
         type: "dots"
       },
       backgroundOptions: {
         color: "#ffffff",
       },
       imageOptions: {
         crossOrigin: "anonymous",
         margin: 0
       }
  };
  constructor(public  service: AppService,
              public zone: NgZone,
              private responseService: ResponseService,
              private qrcode: NgxQrcodeStylingService,
              private clientService: ClientService,
              private storage: StorageMap,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              private notifier: NotifierService,
              private formService : FormService,
              public  router: Router,
              private activatedRoute: ActivatedRoute) {
              this.outgoingRequest = new Parameters();
              this.incomingInvitee = new Parameters();
              this.incomingOption = new Parameters();
              this.outgoingResponse = new Parameters();
              this.outgoingImgRequest = new Parameters();
              this.incomingForm = new Parameters();
              this.incomingResponseChannelQuestion = new Parameters();
              this.incomingInvitationCardQuestion = new Parameters();
              this.incomingConfirmationContentsQuestion = new Parameters();
              this.incomingResponse = new Parameters();
              this.incomingClient = new Parameters();
              this.incomingCampaign = new Parameters();
              this.outgoingResults = new Parameters();
              this.incomingResult = new Parameters();
              this.incomingSettingsForm = new Parameters();
              this.outgoingQuestion = new Parameters();
              this.incomingQuestion = new Parameters();
              this.incomingQrcodeQuestion = new Parameters();
              this.incomingResponseResult = new Parameters();
              this.incomingSettingsQuestion = new Parameters();
              this.incomingIsConfirmQuestion = new Parameters();
              this.outgoingNameQuestion = new Parameters();
              this.outgoingPhoneQuestion = new Parameters();
              this.outgoingEmailQuestion = new Parameters();
              this.outgoingQrcodeQuestion = new Parameters();
              this.outgoingInvitationQuestion = new Parameters();
              this.outgoingInvitationCardQuestion = new Parameters();
              this.outgoingResult = new Parameters();
              this.incomingNameQuestion = new Parameters();
              this.incomingPhoneQuestion = new Parameters();
              this.incomingEmailQuestion = new Parameters();
              document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
              this.incomingIsConfirmQuestion.setAttributes({});
              this.incomingResponseChannelQuestion.setAttributes({});
              this.outgoingNameQuestion.setAttributes({});
              this.outgoingPhoneQuestion.setAttributes({});
              this.outgoingEmailQuestion.setAttributes({});
              this.outgoingQrcodeQuestion.setAttributes({});
              this.outgoingInvitationQuestion.setAttributes({});
              this.outgoingInvitationCardQuestion.setAttributes({});
              this.outgoingResponse.setAttributes({});
  }

  ngOnInit(): void {
    this.drawer = MatDrawer;
    this.incomingOption.setAttributes({});
    this.setInvitees({});
    this.incomingOption.setUnknown('label','yes');
    this.options.push(this.incomingOption.getAttributes());
    this.incomingOption.setAttributes({});
    this.incomingOption.setUnknown('label','no');
    this.options.push(this.incomingOption.getAttributes());
    this.responseId =  this.service.decrypt(this.activatedRoute.snapshot.params.responseId);
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setCntrlNum(this.responseId);
    this.formValues = this.formBuilder.group({
          email:    ['', Validators.compose([ Validators.required])],
          password: ['', Validators.compose([ Validators.required, Validators.minLength(5)
          ])]
    });
    setTimeout(()=>{
        if(Object.keys(this.responseService.selected).length === 0){
            this.router.navigateByUrl('/wait..');
            setTimeout(()=>{
                this.service.httpService('post', this.service.app.routes.responses.get.toString(), this.outgoingRequest.getAttributes(), {notify: false}, (result: any) => {
                     this.incomingResponse.setAttributes(result);
                     this.responseService.setSelected(this.incomingResponse.getAttributes());
                     this.init();
                     window.history.back();
                    }, (error: any) =>  {
                    });
            });
        }else{
         this.incomingResponse.setAttributes(this.responseService.getSelected());
         this.init();
        }
    });
  }

  validateRequestParms(callback: any){
   this.outgoingNameQuestion.setUnknown('data',this.elementHtmlName.nativeElement.value);
   this.outgoingPhoneQuestion.setUnknown('data',this.elementHtmlPhone.nativeElement.value);
   this.outgoingEmailQuestion.setUnknown('data',this.elementHtmlEmail.nativeElement.value);
   if(this.service.empty(this.elementHtmlName.nativeElement.value)){
       this.elementHtmlName.nativeElement.focus();
       this.notifier.notify('success', this.service.app.strings.invitation.registration.validation.required.name);
       callback(false);
   }else if(this.service.empty(this.elementHtmlEmail.nativeElement.value)){
     this.elementHtmlEmail.nativeElement.focus();
     this.notifier.notify('success', this.service.app.strings.invitation.registration.validation.required.email);
     callback(false);
   }else if(!this.service.validateEmail(this.elementHtmlEmail.nativeElement.value)){
      this.notifier.notify('success', this.service.app.strings.email.invalid);
      callback(false);
   }else callback(true);
  }

  setCrypt(ciphertext: any, callback: any){
         try{
            var result: any = this.service.encrypt(ciphertext);
            if(this.service.empty(this.service.decrypt(result))) this.setCrypt(ciphertext,callback);else callback(result);
         }catch(e){ this.setCrypt(ciphertext,callback);
         }
  }

  hasQrcode(elementHtml: any,callback: any){
   let element:any = document.getElementById(elementHtml);
   if(element instanceof HTMLElement){
    callback(elementHtml);
   }else this.hasQrcode(elementHtml,callback);
  }

  setQrcodeUrl(callback: any){
      this.setCrypt(this.outgoingResponse.getCntrlNum(),(result: any)=>{
         this.config.data=window.location.host + '/app/invitations/' + result +'/confirmation?q=';
         this.config.data=this.config.data.toString().includes('https://') ? this.config.data : 'https://' + this.config.data;
         if(!this.service.empty(this.incomingSettingsForm.getBackgroundColor()))
         this.config.data=this.config.data +'&extension='+this.incomingSettingsForm.getBackgroundColor();
         if(!this.service.empty(this.service.logo))
         this.config.data= this.config.data +'&access='+this.incomingClient.getUnknown('logo').split('clients/')[1];
         this.outgoingQrcodeQuestion.setUnknown('data',this.config.data);
         setTimeout((result)=>{
              let element:any = document.getElementById('qrcode');
              this.qrcode.create(this.config, element).subscribe((res) => {
                   setTimeout(() => {
                       setTimeout(() => {
                         html2canvas(element).then(canvas => {
                              this.zone.run(() =>{
                                this.outgoingQrcodeQuestion.getResult().path = canvas.toDataURL("image/png");
                                this.outgoingInvitationCardQuestion.setUnknown('data',
                                    this.outgoingInvitationCardQuestion.getUnknown('data').replaceAll('*qrcode',canvas.toDataURL("image/png")));
                                this.outgoingInvitationCardQuestion.setUnknown('data',
                                    this.outgoingInvitationCardQuestion.getUnknown('data').replaceAll('*name',this.outgoingNameQuestion.getUnknown('data')));
                                this.outgoingInvitationCardQuestion.setUnknown('data',
                                    this.outgoingInvitationCardQuestion.getUnknown('data').replaceAll('*frame',this.incomingSettingsForm.getFrameDataUrl()));
                                    callback(null);
    //                              this.outgoingImgRequest.setAttributes({});
    //                              this.outgoingImgRequest.setFlag(0);
    //                              this.outgoingImgRequest.setCaption('');
    //                              this.outgoingImgRequest.setOrderBy('');
    //                              this.outgoingImgRequest.setStatus(1);
    //                              this.outgoingImgRequest.setUrl('');
    //                              this.outgoingImgRequest.setPath('');
    //                              this.outgoingImgRequest.setAvatar(this.service.dataURItoBlob(this.outgoingQrcodeQuestion.getResult().path));
    //                              this.service.httpService('post', this.service.app.routes.images.addRequest,
    //                                  this.outgoingImgRequest.getAttributes(), {},(result: any) => {
    //                                     this.incomingResult.setAttributes(result);
    //                                     this.outgoingQrcodeQuestion.getResult().src = this.incomingResult.getPath();
    //                                     this.outgoingInvitationCardQuestion.setUnknown('data',
    //                                       this.outgoingInvitationCardQuestion.getUnknown('data').replaceAll('*qrcode',this.incomingResult.getPath()));
    //                                     callback(null);
    //                                  }, (error: any) => {
    //                                  });
                              });
                         });
                       });
                   },this.service.timeout());
              });
         });
     });
  }

  onSubmit(registerForm: NgForm){

  }

  init(){
       this.incomingForm.setAttributes(this.incomingResponse.getForm());
       this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
       this.incomingClient.setAttributes(this.incomingCampaign.getClient());
       this.clientService.setSelected(this.incomingClient.getAttributes());
       this.incomingSettingsForm.setAttributes(this.incomingForm.getSettings());
       this.incomingSettingsForm.setIsConfirm(this.incomingSettingsForm.hasAttribute('is_confirm') ?
         this.incomingSettingsForm.getIsConfirm() : '');
       this.formService.setSelected(this.incomingForm.getAttributes());
       this.incomingForm.getControls().map((question: any) => {
         this.incomingQuestion.setAttributes(question);
         this.incomingResponse.getUnknown('datas').map((responseResult: any) => {
          this.incomingResponseResult.setAttributes(responseResult);
           if(this.incomingResponseResult.getUnknown('formControl').cntrl_num.toString() === this.incomingQuestion.getCntrlNum().toString()){
             this.incomingQuestion.setResponseResultId(this.incomingResponseResult.getId());
             this.incomingQuestion.setData(this.incomingResponseResult.getData());
             this.incomingQuestion.setResult(this.incomingResponseResult.getResult());
             this.incomingQuestion.setPostId(this.incomingResponse.getId());
           }
         });
       });
       this.incomingResponse.setForm(this.incomingForm.getAttributes());
       this.formService.setSelected(this.incomingForm.getAttributes());
       this.responseService.setSelected(this.incomingResponse.getAttributes());
       this.service.app = Object.assign(this.service.app,{data:{status:0}});
       this.incomingSettingsForm.setDateOnSet(this.incomingSettingsForm.attributes.hasOwnProperty('date_on_set') ?
      this.incomingSettingsForm.getDateOnSet() : '');
      this.incomingSettingsForm.setVenue(this.incomingSettingsForm.attributes.hasOwnProperty('venue') ?
          this.incomingSettingsForm.getVenue() : '');
      this.incomingSettingsForm.setTime(this.incomingSettingsForm.attributes.hasOwnProperty('time') ?
          this.incomingSettingsForm.getTime() : '');
     this.incomingSettingsForm.setContact(this.incomingSettingsForm.attributes.hasOwnProperty('contact') ?
          this.incomingSettingsForm.getContact() : '');
     this.incomingSettingsForm.setDeclinedLandingPageContents(this.incomingSettingsForm.attributes.hasOwnProperty('declined_landing_page_contents') ?
          this.incomingSettingsForm.getDeclinedLandingPageContents() : '');
      this.incomingSettingsForm.setRSVPDate(this.incomingSettingsForm.attributes.hasOwnProperty('rsvp_date') ?
          this.incomingSettingsForm.getRSVPDate() : '');
      this.incomingSettingsForm.setAttendingDate(this.incomingSettingsForm.attributes.hasOwnProperty('attending_date') ?
          this.incomingSettingsForm.getAttendingDate() : '');
      this.incomingSettingsForm.setJobPosition(this.incomingSettingsForm.attributes.hasOwnProperty('job_position') ?
      this.incomingSettingsForm.getJobPosition() : '');
      this.incomingSettingsForm.setResponseChannel(this.incomingSettingsForm.attributes.hasOwnProperty('response_channel') ?
        this.incomingSettingsForm.getResponseChannel() : '');
      this.incomingSettingsForm.setAdvertUrl(this.incomingSettingsForm.attributes.hasOwnProperty('advert_url') ?
              this.incomingSettingsForm.getAdvertUrl() : '');
      this.incomingSettingsForm.setInvitationCard(this.incomingSettingsForm.attributes.hasOwnProperty('invitation_card') ?
        this.incomingSettingsForm.getInvitationCard() : '');
      this.incomingSettingsForm.setBackgroundColor(this.incomingSettingsForm.attributes.hasOwnProperty('background_color') ?
        this.incomingSettingsForm.getBackgroundColor() : '');
      this.incomingSettingsForm.setContactPersonName(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_name') ?
        this.incomingSettingsForm.getContactPersonName() : '');
      this.incomingSettingsForm.setFrameDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('frame_data_url') ?
        this.incomingSettingsForm.getFrameDataUrl() : '');
      this.incomingSettingsForm.setContactPersonPhone(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_phone') ?
      this.incomingSettingsForm.getContactPersonPhone() : '');
      this.incomingSettingsForm.setInvitationContents(this.incomingSettingsForm.attributes.hasOwnProperty('invitation_contents') ?
            this.incomingSettingsForm.getInvitationContents() : '');
      this.incomingSettingsForm.setContactPersonTitle(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_title') ?
      this.incomingSettingsForm.getContactPersonTitle() : '');
      this.incomingSettingsForm.setCompanyName(this.incomingSettingsForm.attributes.hasOwnProperty('company_name') ?
      this.incomingSettingsForm.getCompanyName() : '');
      this.incomingSettingsForm.setAttendeeName(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_name') ?
          this.incomingSettingsForm.getAttendeeName() : '');
      this.incomingSettingsForm.setAttendeePhone(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_phone') ?
          this.incomingSettingsForm.getAttendeePhone() : '');
      this.incomingSettingsForm.setAttendeeEmail(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_email') ?
          this.incomingSettingsForm.getAttendeeEmail() : '');
      this.incomingSettingsForm.setColor(this.incomingSettingsForm.attributes.hasOwnProperty('color') ?
        this.incomingSettingsForm.getColor() : '');
      this.incomingSettingsForm.setConfirmationContents(this.incomingSettingsForm.attributes.hasOwnProperty('confirmation_contents') ?
          this.incomingSettingsForm.getConfirmationContents() : '');
      this.incomingSettingsForm.setSalutation(this.incomingSettingsForm.attributes.hasOwnProperty('salutation') ?
      this.incomingSettingsForm.getSalutation() : '');
      this.incomingSettingsForm.setLogoUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_url') ?
      this.incomingSettingsForm.getLogoUrl() : '');
      this.incomingSettingsForm.setLogoDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_data_url') ?
      this.incomingSettingsForm.getLogoDataUrl() : '');
      this.setReferenceQuestions();
      if(this.incomingConfirmationContentsQuestion.getAttributes().hasOwnProperty('id')){
         this.incomingConfirmationContentsQuestion.setData(this.service.empty(this.incomingConfirmationContentsQuestion.getData()) ?
         this.incomingConfirmationContentsQuestion.getUnknown('label') :
          this.incomingConfirmationContentsQuestion.getUnknown('data'));
         this.incomingConfirmationContentsQuestion.setData(this.incomingConfirmationContentsQuestion.getData().toString().replaceAll('*imgWidth', (this.service.getWidth()-20)+'px'));
         this.incomingConfirmationContentsQuestion.setData(this.incomingConfirmationContentsQuestion.getData().toString().replaceAll('*imgHeight', (this.service.getHeight()-20)+'px'));
         this.incomingConfirmationContentsQuestion.setData(this.incomingConfirmationContentsQuestion.getData().toString().replaceAll('*width', this.service.getWidth()+'px'));
         this.incomingConfirmationContentsQuestion.setData(this.incomingConfirmationContentsQuestion.getData().toString().replaceAll('*height', this.service.getHeight()+'px'));
         this.incomingConfirmationContentsQuestion.setData(this.incomingConfirmationContentsQuestion.getData().toString().replaceAll('*imgDisplay', ''));
         this.contents  = this.incomingConfirmationContentsQuestion.getAttributes().hasOwnProperty('id') ?
         this.incomingConfirmationContentsQuestion.getData() : '';
      }document.getElementsByTagName( 'body')[0].style.backgroundColor = this.incomingSettingsForm.getBackgroundColor();
      document.getElementsByTagName( 'body')[0].style.color = this.incomingSettingsForm.getColor();
      this.result = this.service.getNavigatedUrl().toString().split('?');
      if(this.result.length > 1){
       if(this.result[1].toString().includes('mail=true')) this.acceptInvitation();
       else if(this.result[1].toString().includes('mail=false')) {
         this.zone.run(() =>{
          this.contentsShow = false;
         });
         this.declineInvitation();
       }
      }
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getLabel());
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*display1', 'none'));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*display2', 'none'));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*display3', ''));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*name', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*date', ''));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*salutation', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getSalutation()).data));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*company', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getCompanyName()).data));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*month', ''));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*contactPersonName', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonName()).data));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*contactPersonPhone', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonPhone()).data));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*contactPersonTitle', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonTitle()).data));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*event', this.incomingForm.getName()));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*rsvpDate', this.incomingSettingsForm.getRSVPDate()));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*attendingDate', this.incomingSettingsForm.getAttendingDate()));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*venue', this.incomingSettingsForm.getVenue()));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*logo', this.service.empty(this.incomingSettingsForm.getLogoUrl()) ? '*logo' :this.incomingSettingsForm.getLogoUrl() ));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*jobPosition', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getJobPosition()).data));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*qrcode', ''));
  //     this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*displayQrcode', 'none'));
  //      if(this.incomingIsConfirmQuestion.hasAttribute('id')){
  //       this.incomingIsConfirmQuestion.setData(this.service.empty(this.incomingIsConfirmQuestion.getData()) ? 'No' : this.incomingIsConfirmQuestion.getData());
  //       if(this.incomingIsConfirmQuestion.getData().toString().toLowerCase() === 'yes'){
  // //           this.notifier.notify('success', this.service.app.strings.invitation.confirm.repeatation);
  //              this.isConfirmed = true;
  //              this.viewIvitationCard();
  //       }else{
  //           this.outgoingRequest.setAttributes({});
  //           this.outgoingRequest.setId(this.incomingIsConfirmQuestion.getResponseResultId());
  //           this.outgoingRequest.setData(this.incomingIsConfirmQuestion.getOptions()[0].label);
  //           this.outgoingResults.setAttributes({});
  //           this.outgoingResult.setAttributes({});
  //           this.outgoingResult.setLabel(this.incomingIsConfirmQuestion.getOptions()[0].label);
  //           this.outgoingResult.setCntrlNum(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num);
  //           this.outgoingResults.setUnknown(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num,
  //             this.outgoingResult.getAttributes());
  //           this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
  //           this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
  // //         this.notifier.notify('success', this.service.app.strings.invitation.confirm.successfull);
  //            this.isConfirmed = true;
  //            this.viewIvitationCard();
  //           }, (error: any) =>  { this.service.app.strings.invitation.confirm.successfull;
  //           });
  //       }
  //      }
  //   this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/respond');
  }

    async blobToBase64Async(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onerror = (e) => reject(fileReader.error);
        fileReader.onloadend = (e) => {
          const dataUrl = fileReader.result as string;
          // remove "data:mime/type;base64," prefix from data url
          const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
          resolve(base64);
        };
        fileReader.readAsDataURL(blob);
      });
    }



    async  fetchToBase64Async (url: any): Promise<string> {
        try {
        fetch('http://catfacts-api.appspot.com/api/facts?number=99', { mode: 'no-cors'})
               .then(response => response.blob())
               .then(blob => {
                 const base64 = this.blobToBase64Async(blob);
                 return blob;
               }).catch(e => {
                 console.log(e);
                 return e;
               });

//           const response = await fetch(url,{ mode: 'no-cors'});
//           if (!response.ok) {
//             const responseText = await response.text();
//             throw new Error("server status: " + response.status + "\n" + "server response:" + "\n" + responseText);
//           }
//           const blob = await response.blob();
//           const base64 = await this.blobToBase64Async(blob);
          return '';
        } catch (e) {
          throw new Error("failed to fetch: " + url + "\n" + "caused by: " + e);
        }
    }

  getDataUrl(url: any){
     this.fetchToBase64Async(url).then(
         (value) =>{ },
         (error) =>{ }
     );
  }





  acceptInvitation(){
       setTimeout(()=>{
           if(this.incomingIsConfirmQuestion.hasAttribute('id')){
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setId(this.incomingIsConfirmQuestion.getResponseResultId());
            this.outgoingRequest.setData(this.incomingIsConfirmQuestion.getOptions()[0].label);
            this.outgoingRequest.setFormControlId(this.incomingIsConfirmQuestion.getId());
            this.outgoingRequest.setPostId(this.incomingIsConfirmQuestion.getPostId());
            this.outgoingRequest.setControlId(this.incomingIsConfirmQuestion.getControlId());
            this.outgoingResults.setAttributes({});
            this.outgoingResult.setAttributes({});
            this.outgoingResult.setLabel(this.incomingIsConfirmQuestion.getOptions()[0].label);
            this.outgoingResult.setCntrlNum(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num);
            this.outgoingResults.setUnknown(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num,
            this.outgoingResult.getAttributes());
            if(parseFloat(this.outgoingRequest.getId()) === 0) delete this.outgoingRequest.attributes.id;
            this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
            this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
              this.setChannel();
              setTimeout(()=>{
               this.isConfirmed = true;
               this.viewIvitationCard();
              });
            }, (error: any) =>  { this.service.app.strings.invitation.confirm.successfull;
            });
           }
       });
  }

  setChannel(){
      if(this.incomingResponseChannelQuestion.attributes.hasOwnProperty('id')){
         this.outgoingRequest.setAttributes({});
         this.outgoingRequest.setId(this.incomingResponseChannelQuestion.getResponseResultId());
         this.outgoingRequest.setFormControlId(this.incomingResponseChannelQuestion.getId());
         this.outgoingRequest.setPostId(this.incomingResponseChannelQuestion.getPostId());
         this.outgoingRequest.setControlId(this.incomingResponseChannelQuestion.getControlId());
         this.outgoingResults.setAttributes({});
         this.outgoingResult.setAttributes({});
         this.result = this.service.getNavigatedUrl().toString().split('?');
         if(this.result[1].toString().includes('mail=')){
          this.outgoingRequest.setData(this.incomingResponseChannelQuestion.getOptions()[1].label);
          this.outgoingResult.setLabel(this.incomingResponseChannelQuestion.getOptions()[1].label);
          this.outgoingResult.setCntrlNum(this.incomingResponseChannelQuestion.getOptions()[1].cntrl_num);
          this.outgoingResults.setUnknown(this.incomingResponseChannelQuestion.getOptions()[1].cntrl_num,
          this.outgoingResult.getAttributes());
         }else if(this.result[1].toString().includes('whatsapp=')){
          this.outgoingRequest.setData(this.incomingResponseChannelQuestion.getOptions()[2].label);
          this.outgoingResult.setLabel(this.incomingResponseChannelQuestion.getOptions()[2].label);
          this.outgoingResult.setCntrlNum(this.incomingResponseChannelQuestion.getOptions()[2].cntrl_num);
          this.outgoingResults.setUnknown(this.incomingResponseChannelQuestion.getOptions()[2].cntrl_num,
          this.outgoingResult.getAttributes());
         }else{
          this.outgoingRequest.setData(this.incomingResponseChannelQuestion.getOptions()[0].label);
          this.outgoingResult.setLabel(this.incomingResponseChannelQuestion.getOptions()[0].label);
          this.outgoingResult.setCntrlNum(this.incomingResponseChannelQuestion.getOptions()[0].cntrl_num);
          this.outgoingResults.setUnknown(this.incomingResponseChannelQuestion.getOptions()[0].cntrl_num,
          this.outgoingResult.getAttributes());
         }
         if(parseFloat(this.outgoingRequest.getId()) === 0) delete this.outgoingRequest.attributes.id;
         this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
         this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
         }, (error: any) =>  {
         });
      }
  }

  viewIvitationCard(){
      setTimeout(() => {
        this.responseService.setSelected(this.incomingResponse.getAttributes());
          this.formService.setSelected(this.incomingForm.getAttributes());
          this.router.navigateByUrl('/app/invitations/' +
           this.service.encrypt(this.incomingResponse.getId()) + '/ '+
           this.service.encrypt(this.incomingForm.getId()) + '/card');
      });

  }

  declineInvitation(){
         setTimeout(()=>{
          if(this.elementHtmlDeclineCenterContents){
           window.scrollTo(0, 0);
           document.getElementsByTagName( 'body')[0].scrollTo(0, 0);
           this.elementHtmlDeclineCenterContents.nativeElement.scrollTo(0, 0);
          }
         },this.service.timeout());
         setTimeout(()=>{
             if(this.incomingIsConfirmQuestion.hasAttribute('id')){
                this.incomingIsConfirmQuestion.setData(this.service.empty(this.incomingIsConfirmQuestion.getData()) ? 'No' : this.incomingIsConfirmQuestion.getData());
                this.outgoingRequest.setAttributes({});
                this.outgoingRequest.setId(this.incomingIsConfirmQuestion.getResponseResultId());
                this.outgoingRequest.setData(this.incomingIsConfirmQuestion.getOptions()[1].label);
                this.outgoingRequest.setFormControlId(this.incomingIsConfirmQuestion.getId());
                this.outgoingRequest.setPostId(this.incomingIsConfirmQuestion.getPostId());
                this.outgoingRequest.setControlId(this.incomingIsConfirmQuestion.getControlId());
                this.outgoingResults.setAttributes({});
                this.outgoingResult.setAttributes({});
                this.outgoingResult.setLabel(this.incomingIsConfirmQuestion.getOptions()[0].label);
                this.outgoingResult.setCntrlNum(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num);
                this.outgoingResults.setUnknown(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num,
                this.outgoingResult.getAttributes());
                if(parseFloat(this.outgoingRequest.getId()) === 0) delete this.outgoingRequest.attributes.id;
                this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
                this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: false}, (result: any) => {
//                  this.result = this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getSalutation()).data + ' ' + this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data;
                    this.result = '';
                    this.zone.run(() =>{
                     this.contentsShow = false;
                    });
//                  this.notifier.notify('success', this.service.app.strings.invitation.decline.replaceAll('*status',this.result));
                    this.setChannel();
//                  setTimeout(()=>{ window.open(this.incomingSettingsForm.getAdvertUrl(),'_self');
//                  },this.service.timeout() * 9);
                }, (error: any) =>  {
                });
             }
         });
  }

  onRadioButtonChange(option: any){
   this.incomingOption.setAttributes(option);
   if(this.incomingOption.getUnknown('label').toString().toLowerCase().includes('yes')){
     this.outgoingNameQuestion.setAttributes(Object.assign({},this.incomingNameQuestion.getAttributes()));
     this.outgoingPhoneQuestion.setAttributes(Object.assign({},this.incomingPhoneQuestion.getAttributes()));
     this.outgoingEmailQuestion.setAttributes(Object.assign({},this.incomingEmailQuestion.getAttributes()));
     this.outgoingQrcodeQuestion.setAttributes(Object.assign({},this.incomingQrcodeQuestion.getAttributes()));
     this.outgoingInvitationQuestion.setAttributes(Object.assign({},this.incomingConfirmationContentsQuestion.getAttributes()));
     this.outgoingInvitationCardQuestion.setAttributes(Object.assign({},this.incomingInvitationCardQuestion.getAttributes()));
     this.outgoingNameQuestion.setUnknown('data','');
     this.outgoingPhoneQuestion.setUnknown('data','');
     this.outgoingEmailQuestion.setUnknown('data','');
     this.outgoingQrcodeQuestion.setUnknown('data','');
     this.outgoingInvitationQuestion.setUnknown('data',this.incomingConfirmationContentsQuestion.getUnknown('label'));
     this.outgoingInvitationCardQuestion.setUnknown('data',this.incomingInvitationCardQuestion.getUnknown('label'));
     this.outgoingQrcodeQuestion.setResult({path:'',src:''});
     this.drawer.toggle();
   }else{
    this.showDeclinenote = false;
    this.notifier.notify('success', this.service.app.strings.invitation.decline.note);
   }
  }

  register(){
    if(Object.keys(this.outgoingResponse.getAttributes()).length ===0){
        this.validateRequestParms((result: any)=>{
          if(result){
            this.dialogRef = this.dialog.open(ProgressDialogComponent, {
             data  : {},
             width : 'auto',
             height: 'auto',
             disableClose: true,
            });
            this.outgoingQuestions = [];
            this.outgoingResponse.setAttributes(Object.assign({},this.incomingResponse.getAttributes()));
            if (this.outgoingResponse.attributes.hasOwnProperty('id'))
             delete this.outgoingResponse.attributes.id;
            this.outgoingQuestions.push(this.outgoingNameQuestion.getAttributes());
            this.outgoingQuestions.push(this.outgoingPhoneQuestion.getAttributes());
            this.outgoingQuestions.push(this.outgoingEmailQuestion.getAttributes());
            this.outgoingResponse.setControls( this.outgoingQuestions);
            this.outgoingResponse.setPostId(this.service.random());
            this.outgoingResponse.setCntrlNum(this.outgoingResponse.getPostId());
            this.outgoingResponse.setRegisteredBy(this.incomingResponse.getPostId());
            this.outgoingPosts = [];
            this.outgoingPosts.push(this.outgoingResponse.getAttributes());
            this.outgoingPosts = JSON.stringify(this.outgoingPosts);
            this.service.ngxLoadingBarColor = 'orange';
            this.setQrcodeUrl((result: any)=>{
              this.service.httpService('post', this.service.app.routes.responses.post, {posts: this.outgoingPosts}, {}, (response: any) => {
                 this.setInvitationCardUrlAndSend((result: any)=>{
                  this.setInvitees(this.outgoingResponse.getAttributes());
                  this.dialogRef.close();
                  this.notifier.notify('success', this.service.app.strings.invitation.registration.replace.replaceAll('*someone',this.outgoingNameQuestion.getUnknown('data')));
                  this.outgoingNameQuestion.setUnknown('data','');
                  this.outgoingPhoneQuestion.setUnknown('data','');
                  this.outgoingEmailQuestion.setUnknown('data','');
                  this.outgoingQrcodeQuestion.setUnknown('data','');
                  this.outgoingQrcodeQuestion.setResult({path:'',src:''});
                  this.outgoingInvitationQuestion.setUnknown('data','');
                  this.elementHtmlName.nativeElement.value = '';
                  this.elementHtmlPhone.nativeElement.value = '';
                  this.elementHtmlEmail.nativeElement.value = '';
    //            this.outgoingInvitationCardQuestion.setUnknown('data','');
                  this.outgoingResponse.setAttributes({});
                  setTimeout(()=>{
                  this.router.navigateByUrl('/wait..');
                  });
                 });
              },(error: any)=> {
              });
            });
          }
        });
    }
  }

  sendInvitationCard(request: any,callback: any){
        this.outgoingRequest.setAttributes(request);
        this.service.httpService('post', this.service.app.routes.invitations.card.delivery,
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true',notify: 'false'},(result: any) => {
         callback();
        }, (error: any) => {
         callback();
        });
  }

   getOptions(){
    const opt: any = {
      margin: 1,
      filename: 'invitation-card.pdf',
      image: { type: 'jpeg', quality: 0.20 },
      html2canvas: {scale: 7, logging: true},
//      jsPDF: {unit: 'in', format: 'a4', orientation: 'p'} useCORS: true
    };
  }

  getClientlogo(callback: any){
       if(this.incomingEmailQuestion.getAttributes().hasOwnProperty('id') &&
                           !this.service.empty(this.incomingEmailQuestion.getData()) &&
                           this.incomingEmailQuestion.getData().toString().includes('@gmail.comx') &&
                           !this.service.empty(this.incomingClient.getUnknown('logo'))){
         this.logo = this.incomingClient.getUnknown('logo');
         callback(null);
       }else if(!this.service.empty(this.incomingClient.getUnknown('logo'))){
         this.outgoingRequest.setAttributes({});
         this.outgoingRequest.setUrl(this.incomingClient.getUnknown('logo'));
         this.service.httpService('post', this.service.app.routes.files.base64,
          this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},(result: any) => {
            this.logo = 'data:image/jpg;base64,'+result;
            callback(null);
          }, (error: any) => {
          callback(null);
          });
       }else callback(null);
  }


  setInvitationCardUrlAndSend(callback: any){
         this.getClientlogo(()=>{
             const pdfHtmlContents: any = document.getElementById('htmlDOC');
             setTimeout(()=>{
                      html2pdf()
                          .from(pdfHtmlContents)
                          .set(this.getOptions())
                          .toPdf()
                          .output('datauristring')
                          .then((pdfBase64: any) => {
                             let blob: any = this.service.dataURItoBlob(pdfBase64);
                             this.outgoingRequest.setAttributes({});
                             this.outgoingRequest.setSrc(null);
                             this.outgoingRequest.setFlag(0);
                             this.outgoingRequest.setCaption('');
                             this.outgoingRequest.setOrderBy('');
                             this.outgoingRequest.setStatus(1);
                             this.outgoingRequest.setUrl('');
                             this.outgoingRequest.setPath('');
                             this.outgoingRequest.setAvatar(blob);
                             this.outgoingRequest.setExtension('pdf');
                             this.service.httpService('post', this.service.app.routes.images.addRequest,
                                this.outgoingRequest.getAttributes(), {},(result: any) => {
                                 this.incomingResult.setAttributes(result);
                                 this.outgoingRequest.setAttributes({});
                                 this.outgoingRequest.setFlag('whatsapp');
                                 this.outgoingRequest.setResponseCntrlNum(this.outgoingResponse.getCntrlNum());
                                 this.outgoingRequest.setFormId(this.incomingForm.getId());
                                 this.outgoingRequest.setData(this.service.app.strings.invitation.card.receive.toString());
                                 this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*logo','*logo'));
                                 this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*event',this.incomingForm.getName()));
                                 this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*venue', this.incomingSettingsForm.getVenue()));
                                 this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*time', this.incomingSettingsForm.getTime()));
                                 this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*name', this.outgoingNameQuestion.getUnknown('data')));
                                 this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*attendingDate', this.incomingSettingsForm.getAttendingDate()));
                                 this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*regards', this.incomingSettingsForm.getContact()));
                                 this.outgoingRequest.setClientName(this.incomingClient.getName());
                                 this.outgoingRequest.setLogoDataUrl(this.logo);
                                 this.outgoingRequest.setEventName(this.incomingForm.getName() +': Invitation card');
                                 this.outgoingRequest.setRecipientEmail(this.outgoingEmailQuestion.getUnknown('data'));
                                 this.outgoingRequest.setSenderEmail(this.incomingClient.getEmail());
                                 this.outgoingRequest.setRecipientPhone(this.outgoingPhoneQuestion.getUnknown('data'));
                                 this.outgoingRequest.setPdfUrl(this.incomingResult.getSrc());
                                 this.CC = [];
                                 this.CC.push(this.incomingEmailQuestion.getUnknown('data'));
                                 this.outgoingRequest.setCC(JSON.stringify(this.CC));
                                 this.sendInvitationCard(this.outgoingRequest.getAttributes(), ()=>{
                                  this.outgoingRequest.setFlag('email');
                                  this.sendInvitationCard(this.outgoingRequest.getAttributes(), ()=>{
                                     this.zone.run(() =>{
                                      callback(null);
                                     });
                                  });
                                 });
                               }, (error: any) => {
                               });
                       });

             },this.service.timeout()*12);
         });
    }


  setInvitees(response: any){
     let controls: any = response.hasOwnProperty('cntrl_num') ? response.controls.map((control: any)=>{return Object.assign({},control);}):[];
     response.controls = controls;
     this.storage.get('invitees').subscribe((responses: any) => {
        if(responses) {
        this.invitees = responses;
        if(response.hasOwnProperty('cntrl_num'))
         this.invitees.unshift(Object.assign({},response));
         this.storage.set('invitees', this.invitees).subscribe(() => {});
        }else{
         this.invitees = [];
         if(response.hasOwnProperty('cntrl_num'))
         this.invitees.push(Object.assign({},response));
         this.storage.set('invitees', this.invitees).subscribe(() => {});
        }
     });
  }

  setReferenceQuestions(){
     this.incomingIsConfirmQuestion.setAttributes({});
     this.incomingInvitationCardQuestion.setAttributes({});
     this.incomingConfirmationContentsQuestion.setAttributes({});
     this.incomingNameQuestion.setAttributes({});
     this.incomingPhoneQuestion.setAttributes({});
     this.incomingEmailQuestion.setAttributes({});
     this.incomingForm.getControls().map((question: any)=>{
          this.outgoingQuestion.setAttributes(question);
          this.incomingSettingsQuestion.setAttributes(Object.assign({},this.outgoingQuestion.getSettings()));
          if(this.incomingSettingsForm.getIsConfirm().toString().includes(this.outgoingQuestion.getCntrlNum()))
           this.incomingIsConfirmQuestion.setAttributes(this.outgoingQuestion.getAttributes());
          else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getInvitationCard())
           this.incomingInvitationCardQuestion.setAttributes(this.outgoingQuestion.getAttributes());
          else if(parseFloat(this.outgoingQuestion.getControlId()) === 33)
            this.incomingQrcodeQuestion.setAttributes(this.outgoingQuestion.getAttributes());
          else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getInvitationContents())
            this.incomingConfirmationContentsQuestion.setAttributes(this.outgoingQuestion.getAttributes());
          else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getResponseChannel())
            this.incomingResponseChannelQuestion.setAttributes(this.outgoingQuestion.getAttributes());
          else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeeName())
            this.incomingNameQuestion.setAttributes(this.outgoingQuestion.getAttributes());
          else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeePhone())
            this.incomingPhoneQuestion.setAttributes(this.outgoingQuestion.getAttributes());
          else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeeEmail())
            this.incomingEmailQuestion.setAttributes(this.outgoingQuestion.getAttributes());
     });
    this.outgoingNameQuestion.setAttributes(Object.assign({},this.incomingNameQuestion.getAttributes()));
    this.outgoingPhoneQuestion.setAttributes(Object.assign({},this.incomingPhoneQuestion.getAttributes()));
    this.outgoingEmailQuestion.setAttributes(Object.assign({},this.incomingEmailQuestion.getAttributes()));
    this.outgoingQrcodeQuestion.setAttributes(Object.assign({},this.incomingQrcodeQuestion.getAttributes()));
    this.outgoingInvitationQuestion.setAttributes(Object.assign({},this.incomingConfirmationContentsQuestion.getAttributes()));
    this.outgoingInvitationCardQuestion.setAttributes(Object.assign({},this.incomingInvitationCardQuestion.getAttributes()));
    this.outgoingNameQuestion.setUnknown('data','');
    this.outgoingPhoneQuestion.setUnknown('data','');
    this.outgoingEmailQuestion.setUnknown('data','');
    this.outgoingQrcodeQuestion.setUnknown('data','');
    this.outgoingInvitationQuestion.setUnknown('data',this.incomingConfirmationContentsQuestion.getUnknown('label'));
    this.outgoingInvitationCardQuestion.setUnknown('data',this.incomingInvitationCardQuestion.getUnknown('label'));
    this.outgoingQrcodeQuestion.setResult({path:'',src:''});
  }


}
