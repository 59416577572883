import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planyourvisit',
  templateUrl: './planyourvisit.component.html',
  styleUrls: ['./planyourvisit.component.scss']
})
export class PlanyourvisitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
