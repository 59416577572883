import {Component, OnInit, Input, AfterViewInit, SimpleChanges, OnChanges, ViewChild, ElementRef} from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from '../../../../parameters';
import {FormService} from '../../../../forms/form.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {ResponseService} from '../../../responses/data/response.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-view-invitation-card-control',
  templateUrl: './view-invitation-card-control.component.html',
  styleUrls: ['./view-invitation-card-control.component.scss']
})
export class ViewInvitationCardControlComponent implements OnChanges, AfterViewInit {
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild('logoElement', {static: false})  logoElement: any;
  @ViewChild('elmQrcode', {static: false})  elmQrcode: any;
  @Input() question: any;
  @Input() position: any;
  incomingQuestion: any;
  incomingForm: any;
  incomingSettingsForm: any;
  dateSpliter: any;
  outgoingRequest: any;
  eventBylogo: any = {};
  incomingResult: any;
  incomingSettingsQuestion: any;
  incomingQrcode: any;
  outgoingQuestion: any;
  incomingResponse: any;
  incomingQrcodeSettings: any;
  invitationConfirmationUrl: any;
  incomingEmailQuestion: any;
  incomingQuestionResult: any;
  incomingQuestionResults: any;
  constructor(public service: AppService,
              private responseService: ResponseService,
              public formService: FormService) {
    this.incomingQuestion = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingSettingsForm = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingResponse = new Parameters();
    this.incomingEmailQuestion = new Parameters();
    this.incomingQrcodeSettings = new Parameters();
    this.incomingQrcode = new Parameters();
    this.incomingSettingsQuestion = new Parameters();
    this.incomingQuestionResult = new Parameters();
    this.incomingQuestionResults = new Parameters();
    this.outgoingQuestion = new Parameters();
    this.incomingResult = new Parameters();
    this.logoElement = ElementRef;
    this.elmQrcode = ElementRef;
    this.contextMenu = MatMenuTrigger;
    this
  }

  getDataUrl(){
      var request = new XMLHttpRequest();
      request.open('GET', 'http://159.223.131.156/server/uza/public/images/6403569e9bcb6.30');
      request.open('GET', 'http://159.223.131.156/server/uza/public/images/6403569e9bcb6.30');
      request.responseType = 'blob';
      request.onload = () =>{
      }
      request.send();

  }


  ngOnChanges(changes: any): void {
       this.incomingResponse.setAttributes(this.responseService.getSelected());
       this.incomingQuestion.setAttributes(this.question);
       this.incomingQuestion.setResult(this.incomingQuestion.hasAttribute('result') ? this.incomingQuestion.getResult() : {});
       this.incomingSettingsQuestion.setAttributes(this.incomingQuestion.getSettings());
       this.incomingForm.setAttributes(this.incomingResponse.getForm());
       this.incomingSettingsForm.setAttributes(this.incomingForm.getSettings());
       this.incomingSettingsForm.setAttendingDate(this.incomingSettingsForm.attributes.hasOwnProperty('attending_date') ?
        this.incomingSettingsForm.getAttendingDate() : '');
        this.incomingSettingsForm.setTime(this.incomingSettingsForm.attributes.hasOwnProperty('time') ?
         this.incomingSettingsForm.getTime() : '');
       this.incomingSettingsForm.setVenue(this.incomingSettingsForm.attributes.hasOwnProperty('venue') ?
         this.incomingSettingsForm.getVenue() : '');
       this.incomingSettingsForm.setDescription(this.incomingSettingsForm.attributes.hasOwnProperty('description') ?
         this.incomingSettingsForm.getDescription() : '');
       this.incomingSettingsForm.setRSVPDate(this.incomingSettingsForm.attributes.hasOwnProperty('rsvp_date') ?
         this.incomingSettingsForm.getRSVPDate() : '');
       this.incomingSettingsForm.setJobPosition(this.incomingSettingsForm.attributes.hasOwnProperty('job_position') ?
         this.incomingSettingsForm.getJobPosition() : '');
       this.incomingSettingsForm.setContactPersonName(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_name') ?
         this.incomingSettingsForm.getContactPersonName() : '');
       this.incomingSettingsForm.setContactPersonPhone(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_phone') ?
         this.incomingSettingsForm.getContactPersonPhone() : '');
       this.incomingSettingsForm.setContactPersonTitle(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_title') ?
         this.incomingSettingsForm.getContactPersonTitle() : '');
       this.incomingSettingsForm.setCompanyName(this.incomingSettingsForm.attributes.hasOwnProperty('company_name') ?
         this.incomingSettingsForm.getCompanyName() : '');
       this.incomingSettingsForm.setAttendeeName(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_name') ?
          this.incomingSettingsForm.getAttendeeName() : '');
       this.incomingSettingsForm.setInvitationCard(this.incomingSettingsForm.attributes.hasOwnProperty('invitation_card') ?
          this.incomingSettingsForm.getInvitationCard() : '');
       this.incomingSettingsForm.setAttendeeEmail(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_email') ?
                    this.incomingSettingsForm.getAttendeeEmail() : '');
       this.incomingSettingsForm.setSalutation(this.incomingSettingsForm.attributes.hasOwnProperty('salutation') ?
         this.incomingSettingsForm.getSalutation() : '');
       this.incomingSettingsForm.setLogoUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_url') ?
         this.incomingSettingsForm.getLogoUrl() : '');
       this.incomingSettingsForm.setLogoDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_data_url') ?
          this.incomingSettingsForm.getLogoDataUrl() : '');
       this.setReferenceControls();
       this.dateSpliter = this.incomingSettingsForm.getDateOnSet().split('-');
       this.incomingQuestion.setData(this.incomingQuestion.getLabel().toString().replaceAll('*name', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data ));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*display1', 'none'));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*display2', ''));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*display3', 'none'));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*display4', 'none'));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*rsvpDate', this.incomingSettingsForm.getRSVPDate()));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*date', this.dateSpliter[2]));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*salutation', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getSalutation()).data));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*company', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getCompanyName()).data));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*month', this.dateSpliter[1]));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*event', this.incomingForm.getName()));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*contactPersonName', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonName()).data));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*contactPersonPhone', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonPhone()).data));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*contactPersonTitle', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonTitle()).data));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*venue', this.incomingSettingsForm.getVenue()));
       if(this){
       }
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*logo',
            this.incomingEmailQuestion.getAttributes().hasOwnProperty('id') &&
            !this.service.empty(this.incomingEmailQuestion.getData()) &&
            this.incomingEmailQuestion.getData().toString().includes('@gmail.com') && !this.service.empty(this.incomingSettingsForm.getLogoUrl()) ?
            this.incomingSettingsForm.getLogoUrl() : this.incomingSettingsForm.getLogoDataUrl() ));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*qrcode',
                                this.incomingEmailQuestion.getAttributes().hasOwnProperty('id') &&
                               !this.service.empty(this.incomingEmailQuestion.getData()) &&
                               this.incomingEmailQuestion.getData().toString().includes('@gmail.com') && !this.service.empty(this.incomingQrcode.getResult()) ?
                               this.incomingQrcode.getResult().src : this.incomingQrcode.getData() ));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*jobPosition', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getJobPosition()).data));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*attendanceConfirmationUrl', this.invitationConfirmationUrl));
  }

  setReferenceControls(){
   this.incomingQrcode.setAttributes({});
   this.incomingEmailQuestion.setAttributes({});
   this.incomingForm.getControls().map((question: any)=>{
    this.outgoingQuestion.setAttributes(question);
    this.incomingSettingsQuestion.setAttributes(this.outgoingQuestion.getSettings());
    if(!this.service.empty(this.incomingSettingsForm.getAttendeeEmail())){
      if(this.incomingSettingsForm.getAttendeeEmail().toString() === this.outgoingQuestion.getCntrlNum())
       this.incomingEmailQuestion.setAttributes(this.outgoingQuestion.getAttributes());
    }
    if(parseFloat(this.outgoingQuestion.getControlId()) === 33){
     this.incomingQrcode.setAttributes(this.outgoingQuestion.getAttributes());
    }
   });
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


  updateSettingsForm(){
     setTimeout(() => {
           this.outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
           this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
           this.outgoingRequest.setControls(JSON.stringify(this.outgoingRequest.getControls()));
           this.formService.update(this.outgoingRequest, (result: any) => {
            this.ngOnChanges({});
           });
     });

  }

  getLogo(event: any){
      const files: FileList = event.target.files;
      const reader = new FileReader();
      this.eventBylogo = event;
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
          this.outgoingRequest.setAttributes({});
          this.outgoingRequest.setSrc(reader.result);
          this.outgoingRequest.setFlag(0);
          this.outgoingRequest.setCaption('');
          this.outgoingRequest.setOrderBy('');
          this.outgoingRequest.setStatus(1);
          this.outgoingRequest.setUrl('');
          this.outgoingRequest.setPath('');
          this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
          event.target.files = null;
          this.incomingSettingsForm.setLogoDataUrl(reader.result);
           this.service.httpService('post', this.service.app.routes.images.add,
              this.outgoingRequest.getAttributes(), {},(result: any) => {
                 this.incomingResult.setAttributes(result);
                 this.incomingSettingsForm.setLogoUrl(this.incomingResult.getPath());
                 this.incomingForm.setSettings(this.incomingSettingsForm.getAttributes());
                 this.updateSettingsForm();
              }, (error: any) => {
              });
      };
  }


  ngAfterViewInit(): void {
   if(this.eventBylogo.hasOwnProperty('target'))
    this.eventBylogo.target.files = null;
  }


  getQrcode(event: any){
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setSrc(reader.result);
            this.outgoingRequest.setFlag(0);
            this.outgoingRequest.setCaption('');
            this.outgoingRequest.setOrderBy('');
            this.outgoingRequest.setStatus(1);
            this.outgoingRequest.setUrl('');
            this.outgoingRequest.setPath('');
            this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
            this.incomingQrcode.setData(reader.result);
            event.target.files = null;
            this.service.httpService('post', this.service.app.routes.images.add,
            this.outgoingRequest.getAttributes(), {},(result: any) => {
               this.incomingResult.setAttributes(result);
               this.incomingQrcode.setResult({src: this.incomingResult.getPath()});
               this.ngOnChanges({});
            }, (error: any) => {
            });
        };
  }



}
