<div id="main">
    <div class="banner-outer banner-2">
        <div class="banner-2">
            <div [ngStyle] = "{'height':'100%'}">
                <div *ngFor="let time of durations">
                    <div *ngIf="navTime === time.id && service.markers.overpassapi.railway.data.features.length !=0">
                        <agm-map
                            #gm
                            [latitude]="incomingReport.attributes.data.markers[time.tm].markers.length  === 0 ? lat : service.parseFloat(incomingReport.attributes.data.markers[time.tm].markers[0].lat)"
                            [longitude]="incomingReport.attributes.data.markers[time.tm].markers.length === 0 ? lng : service.parseFloat(incomingReport.attributes.data.markers[time.tm].markers[0].lng)"
                            [fitBounds]="false"
                            [zoom]="zoom"
                            (mapClick)="onMapClicked()"
                            [disableDefaultUI]="true"
                            [styles]="app.settings.map.styles.style1"
                            style="height : {{getHeight().height + 'px'}}">
                            <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
                            <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
<!--                        <agm-direction-->
<!--                                    *ngFor="let waypoint of service.markers.overpassapi.railway.data.waypoints; let waypointIncremental = index"-->
<!--                                    [visible]="true"-->
<!--                                    [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : '#ff8000'}}"-->
<!--                                    [provideRouteAlternatives]="false"-->
<!--                                    [origin]="waypoint.location"-->
<!--                                    [destination]="waypointIncremental === 0 ? waypoint.location : service.markers.overpassapi.railway.data.waypoints[waypointIncremental - 1].location"-->
<!--                            >-->
<!--                        </agm-direction>-->
                            <agm-marker
                                    *ngFor="let marker of (incomingReport.attributes.data.markers[time.tm].markers.length === 0 ? [] :  incomingReport.attributes.data.markers[time.tm].markers)"
                                    [latitude]="marker.lat"
                                    [longitude]="marker.lng"
                                    [agmFitBounds]="true"
                                    [openInfoWindow]="true"
                                    (markerClick)="onClickMarker($event, marker)"
                                    [iconUrl]="icon">
                                <agm-info-window
                                        (infoWindowClose)="onInfoWindowClose(gm,infoWindow)"
                                        #infoWindow>
                                    <div>
                                        {{marker.postedBy.length === 0 ? marker.date : marker.postedBy.name}}
                                        <mat-divider></mat-divider>
                                        <div>
                                            <!-- <carousel [cellsToShow]=1 [height]="200" [width]="200" [cellWidth]="100">-->
                                            <!--   <div class="carousel-cell">-->
                                            <!--      <img style='width: 200px;max-height: 300px' src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">-->
                                            <!--   </div>-->
                                            <!--   <div class="carousel-cell">-->
                                            <!--       <img src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">-->
                                            <!--   </div>-->
                                            <!-- </carousel>-->
                                        </div>
                                        <div> <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner></div>
                                        <div *ngIf="marker.hasOwnProperty('response')">
                                            <div><br><br>
                                                <table>
                                                    <tbody>
                                                    <tr *ngFor="let response of  marker.response[0].datas">
                                                        <td style="border-bottom-style: dotted;border-bottom: 1px solid #5f6368;padding-right: 20px;" [innerHTML]="response.formControl.label">
                                                            {{tableService.setQuestion(response.formControl)}}
                                                        </td>
                                                        <td style="border-bottom-style: dotted;border-left-style: dotted;border-bottom: 1px solid #5f6368;padding-left: 20px;border-left: 1px solid #5f6368;">
                                                            <div *ngIf="response.formControl.control.id !== 24 &&
                                                                        response.formControl.control.id !== 2 &&
                                                                        response.formControl.control.id !== 7">
                                                                <span [innerHTML]="response.data"></span>
                                                            </div>
                                                            <div *ngIf="response.formControl.control.id === 24">
                                                                <table  class="table table-striped table-bordered">
                                                                    <thead *ngIf="tableService.isheaderShouldDislayed()" style="width: 100%">
                                                                        <th *ngFor="let optional of response.formControl.options" style="font-size: 17px; margin: 2px; min-width: 200px">
                                                                            <span class='cursor headline2' [innerHTML]="optional.label"></span>
                                                                        </th>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr *ngFor="let row of response.rows" style="height: 50px !important; width: 100%; min-width: 200px">
                                                                        <td *ngFor="let option of response.formControl.options; let optionIndex = index" style="height: 100%; width: 100%">
                                                                            <div #tdElement style="height: 100%; width: 100%;min-width: 200px"
                                                                                 *ngIf="option.type_id !== 3"
                                                                                 [innerHTML]="row.hasOwnProperty(option.cntrl_num) ?
                                                                                 row[option.cntrl_num].data : ''">
                                                                            </div>
                                                                            <div style="height: 100%; width: 100% ; min-width: 200px"
                                                                                 *ngIf="option.type_id === 3">
                                                                                <center>
                                                                                    <mat-checkbox
                                                                                        #checkboxElement
                                                                                        [checked]="tableService.isChecked(checkboxElement, row, option)"
                                                                                        class="cursor"
                                                                                    >
                                                                                    </mat-checkbox>
                                                                                </center>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </agm-info-window>
                            </agm-marker>
                            <agm-marker
                                    *ngFor="let marker of service.markers.overpassapi.railway.data.features"
                                    [latitude]="service.parseFloat(marker.geometry.coordinates[1])"
                                    [longitude]="service.parseFloat(marker.geometry.coordinates[0])"
                                    [agmFitBounds]="true"
                                    [openInfoWindow]="true"
                                    (markerClick)="onClickMarker($event, marker)"
                                    [iconUrl]="railwayIcon">
                            </agm-marker>
                        </agm-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-drawer-container class="example-container" [matDialogClose]="true" [hasBackdrop]="true" autosize>
    <mat-drawer #drawer class="example-sidenav"
                mode="side"
                [(opened)]="opened"
                [disableClose]="false">
        <a (click)="drawer.toggle()" #drawerElement>&nbsp;</a>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 map" style="margin-left: 10px; margin-right: 10px">
            <br><br><br>
            <ngx-treeview
                    [config]="config"
                    [items]="items"
                    (selectedChange)="onSelectedChange($event)">
            </ngx-treeview>
        </div>
    </mat-drawer>

</mat-drawer-container>


<!--<div class="page map">-->
<!--    <div class="clearfix"></div>-->
<!--    <div class="row">-->
<!--        <div class="" style="width: 100% !important;">-->
<!--            <div>-->
<!--                <div class="fixed">-->
<!--                    <div class="roww">-->
<!--                        <div class="" style="width: 100% !important;">-->
<!--                            <div class="row top_tiles">-->
<!--                                <div class="animated flipInY selectedMatTab">-->
<!--                                    <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
<!--                                        <table style="width: 100%">-->
<!--                                            <tr style="width: 100%">-->
<!--                                                <td valign="top" style="max-width: 50%">-->
<!--                                                    <div class="animated flipInY selectedMatTab2  col-lg-12 col-md-12 col-sm-12 col-xs-12" style="border-right: 1px solid #263238 !important">-->
<!--                                                        <ngx-treeview-->
<!--                                                                [config]="config"-->
<!--                                                                [items]="items"-->
<!--                                                                (selectedChange)="onSelectedChange($event)"-->
<!--                                                        >-->
<!--                                                        </ngx-treeview>-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td valign="top"  style="width: 100%">-->
<!--                                                    <agm-map-->
<!--                                                        #gm-->
<!--                                                        [latitude]="lat"-->
<!--                                                        [longitude]="lng"-->
<!--                                                        [fitBounds]="false"-->
<!--                                                        [zoom]="zoom"-->
<!--                                                        [disableDefaultUI]="false"-->
<!--                                                        [styles]="app.settings.map.styles.style1"-->
<!--                                                        style="height : {{getHeight().height + 'px'}}">-->
<!--                                                        <agm-marker-->
<!--                                                                *ngFor="let marker of (markers.length === 0 ? [] :  markers)"-->
<!--                                                                [latitude]="marker.lat"-->
<!--                                                                [longitude]="marker.lng"-->
<!--                                                                [agmFitBounds]="true"-->
<!--                                                                [openInfoWindow]="true"-->
<!--                                                        >-->
<!--                                                            <agm-info-window-->
<!--                                                                    (infoWindowClose)="onInfoWindowClose(gm,infoWindow)"-->
<!--                                                                    #infoWindow>-->
<!--                                                                <div>-->
<!--                                                                    {{marker.postedBy.length === 0 ? marker.date : marker.postedBy.name}}-->
<!--                                                                    <hr>-->
<!--                                                                    <div>{{marker.address}}</div>-->
<!--                                                                </div>-->
<!--                                                            </agm-info-window>-->
<!--                                                        </agm-marker>-->
<!--                                                    </agm-map>-->
<!--                                                    .-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                        </table>-->
<!--                                    </div>-->

<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
