<div layout="row">
  <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
<!--    <i class="material-icons pull-right"-->
<!--       >more_vert</i>-->
    <div class="sawasdee20" id="doc">
      <div [ngStyle]="{'color': formService.color}"
           (contextmenu)="onContextMenu($event, {})"
           [innerHTML]="incomingQuestion.getData()|safeUrl"></div>
    </div>
    <div>
        <!--<center>-->
<!--        <a class='btn btn-lg mdl-shadow&#45;&#45;2dp' (click)="next()"-->
<!--           [ngStyle]="{'background-color': 'rgba(255, 255, 255, 0.57)', 'color':'#5A738E','margin-top':'50px'}">-->
<!--          <table class="cursor">-->
<!--            <td valign="bottom">-->
<!--              <span class="sawasdee20">next</span>-->
<!--            </td>-->
<!--          </table>-->

<!--        </a>-->
<!--</center>-->
    </div>
  </div>
  <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
</div>

<input type="file"
       id="logoInputFile"
       (change)="getLogo($event)"
       accept="image/*"
       [hidden]="true"
       #logoElement />

<input type="file"
       id="frame"
       (change)="onFrameFileChange($event)"
       accept="image/*"
       [hidden]="true"
       #frame />

<input type="file"
       (change)="getQrcode($event)"
       accept="image/*"
       [hidden]="true"
       #elmQrcode />

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="inspector">
    <button mat-menu-item (click)="logoElement.click()">logo</button>
    <button mat-menu-item (click)="elmQrcode.click()">Qr code</button>
    <button mat-menu-item (click)="frame.click()">Frame</button>
    <button mat-menu-item (click)="download()">Download</button>
  </ng-template>
</mat-menu>



