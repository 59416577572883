<!--<mat-progress-bar-->
<!--        mode="query"-->
<!--        class="logo"-->
<!--        color="warn"-->
<!--        [ngStyle]="{'height':'50px'}"-->
<!--        *ngIf="!incomingForm.attributes.hasOwnProperty('id')">-->
<!--</mat-progress-bar>-->
<img  class="logo img-circle"
      *ngIf="!incomingForm.attributes.hasOwnProperty('id') &&  !service.getLogo().includes('nullLogo')"
      src="{{service.empty(service.getLogo()) ? 'assets/images/coat.png' : service.getLogo()}}"
      alt="logo"/>
<div>
<!-- *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 1 ||-->
<!-- service.parseFloat(incomingForm.getCategoryId()) === 5"-->
<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div>
        <div style="width: 100%;height: {{getHeight() + 'px'}}"
             id="lElement" class="lElement">
            <div *ngIf="!formService.isAdvert">
                <div>
                    <div class="pull-right" *ngIf="!(incomingForm.getSettings().hasOwnProperty('lfd') && incomingForm.getSettings().lfd === 1) && service.app.data.status > 0">
                        <ul class="list-inline" [ngStyle]="{'color':'#fff'}">
                            <li>
                                <span class="">
                                        <span   class="sawasdee15"
                                                [ngStyle]="{'margin-right':'10px'}">
                                            {{getProgress()}} %
                                        </span>
                                </span>
                            </li>
                            <li>
                                <a  class="cursor" (click)='displayMainQuestions()'><img src="assets/images/option-icon-2.png" alt="" style="height: 20px" /></a>
                            </li>
                            <li>
                                <i class="material-icons cursor"
                                   (click)="onContextMenu($event, form)"
                                   style="">more_vert
                                </i>
                            </li>
                        </ul>
                    </div>
                    <center>
                        <br>
                        <table>
                            <tr>
                                <td>
                                    <center>
<!--                                    img class="img-circle" src="{{formService.selected.campaign.client.logo}}" style="width:40px;height:40px;margin-top: 10px" >-->
                                        <img src="{{logo.path}}"
                                             class="img-circle pull-left mdl-shadow--2dp"
                                             *ngFor="let logo of incomingForm.attributes.settings.logo"
                                             [ngStyle]="{'width':'60px', 'height':'60px', 'margin-left': '10px'}"
                                             alt="{{logo.caption}}">
                                        <br>
                                    </center>
                                </td>
                            </tr>
                            <tr>
<!--                                <td *ngIf="incomingForm.attributes.settings.header === 1">-->
<!--                                    <center [ngStyle]="{'margin-left': '0px','margin-right': '0px', 'color': this.formService.color}">-->
<!--                                        <h4 class=''>-->
<!--                                            <span [ngStyle]= "{'color': formService.color}">-->
<!--                                                {{formService.selected.name.toString()}}-->
<!--                                            </span>-->
<!--                                        </h4>-->
<!--                                        <p [innerHTML]='formService.selected.description'></p>-->
<!--                                    </center>-->
<!--                                </td>-->
                            </tr>
                        </table>
                    </center>
                </div>
            </div>
            <div style="width: 100%; margin-top: {{!formService.isAdvert ? 10 : 0 }}%" [hidden]="document">
                <center>
                    <div class="row">
                        <div class="col-md-2 col-sm-0 col-xs-0" *ngIf="!formService.isAdvert"></div>
                        <div class="{{!formService.isAdvert ? 'col-md-8 col-sm-12 col-xs-12' : 'col-md-12 col-sm-12 col-xs-12'}}">
                            <div class="row" *ngIf="!formService.submit">
                                <app-text-preview-controls         [question]="question"  *ngIf="question.control.id === 1"></app-text-preview-controls>
                                <app-image-preview-controls        [question]="question"  *ngIf="question.control.id === 2"></app-image-preview-controls>
                                <app-check-preview-controls        [question]="question"  *ngIf="question.control.id === 3"></app-check-preview-controls>
                                <app-select-preview-controls       [question]="question"  *ngIf="question.control.id === 4"></app-select-preview-controls>
                                <app-choice-preview-controls       [question]="question"  *ngIf="question.control.id === 5"></app-choice-preview-controls>
                                <app-address-preview-controls      [question]="question"  *ngIf="question.control.id === 6"></app-address-preview-controls>
                                <app-video-preview-controls        [question]="question"  *ngIf="question.control.id === 7"></app-video-preview-controls>
                                <app-respond-autocomplete-controls [question]="question"  *ngIf="question.control.id === 9"></app-respond-autocomplete-controls>
                                <app-number-preview-controls       [question]="question"  *ngIf="question.control.id === 10"></app-number-preview-controls>
                                <app-date-preview-controls         [question]="question"  *ngIf="question.control.id === 11"></app-date-preview-controls>
                                <app-description-preview-controls  [question]="question"  *ngIf="question.control.id === 12"></app-description-preview-controls>
                                <app-rate-preview-controls         [question]="question"  *ngIf="question.control.id === 13"></app-rate-preview-controls>
                                <app-outlet-preview-controls       [question]="question"  *ngIf="question.control.id === 14"></app-outlet-preview-controls>
                                <app-view-order-control            [question]="question"  *ngIf="question.control.id === 15"></app-view-order-control>
                                <app-textarea-preview-controls     [question]="question"  *ngIf="question.control.id === 16"></app-textarea-preview-controls>
                                <app-groups-preview-controls       [question]="question"  *ngIf="question.control.id === 18"></app-groups-preview-controls>
                                <app-image-choice-respond-control  [question]="question"  *ngIf="question.control.id === 20"></app-image-choice-respond-control>
                                <app-coordinate-respond-controls   [question]="question"  *ngIf="question.control.id === 22"></app-coordinate-respond-controls>
                                <app-respond-time-controls         [question]="question"  *ngIf="question.control.id === 19"></app-respond-time-controls>
                                <app-respond-email-controls        [question]="question"  *ngIf="question.control.id === 23"></app-respond-email-controls>
                                <app-respond-table-controls        [question]="question"  *ngIf="question.control.id === 24"></app-respond-table-controls>
                                <app-number-preview-controls       [question]="question"  *ngIf="question.control.id === 26"></app-number-preview-controls>
                                <app-chip-feedback-controls        [question]="question"  *ngIf="question.control.id === 27"></app-chip-feedback-controls>
                                <app-advert-responses              [question]="question"  *ngIf="question.control.id === 29"></app-advert-responses>
                                <app-number-preview-controls       [question]="question"  *ngIf="question.control.id === 30"></app-number-preview-controls>
                                <app-respond-ranks                 [question]="question"  *ngIf="question.control.id === 31"></app-respond-ranks>
                                <app-view-invitation-card-control  [question]="question"  *ngIf="question.control.id === 32"></app-view-invitation-card-control>
                                <app-view-qrcode-controls          [question]="question"  *ngIf="question.control.id === 33"></app-view-qrcode-controls>
                                <app-view-winner-control           [question]="question"  *ngIf="question.control.id === 34"></app-view-winner-control>
                                <app-view-submit-control           [question]="question"  *ngIf="question.control.id === 35"></app-view-submit-control>
                            </div>
                            <div *ngIf="!formService.isAdvert">
                                <div *ngIf="formService.submit">
                                    <center>
                                        <button class="mat-raised-button mdl-shadow--2dp"
                                                (click)="ngSubmit()"
                                                [ngStyle]="{'width':'auto','background-color':'#fff' ,'color':'#5A738E', 'font-weight':'normal'}">
                                            <h4 [innerHTML]="incomingForm.attributes.settings.submit"></h4>
                                        </button>
                                    </center>
                                </div>
                                <!--                        *ngIf="!getUrl().toString().includes('/feedback')"-->
                                <div class="">
                                    <div 
                                      *ngIf="(position !== 0 || (position === 0 && panel !== null)) || formService.submit"
                                      class="cursor matFabLeft" 
                                      (click)="previous()"
                                      [ngStyle]="{'position': 'fixed', 'top': '40%' , 'left': '-20px', 'background-color':'transparent','padding':'15px 15px 15px 0px'}">
                                        <a class="cursor"
                                           mat-mini-fab
                                           #rollbackButtonElement
                                           [ngStyle]="{'color':'#5A738E', 'background-color':'#fff'}"
                                           >
                                            <i class="material-icons">chevron_left</i>
                                        </a>
                                    </div>

                                     <div 
                                         *ngIf="!formService.submit && nextButtonStatus === true"
                                         class="cursor matFabRight"
                                         (click)="next()"
                                         [ngStyle]="{'position': 'fixed', 'top': '40%' , 'right': '-20px', 'background-color':'transparent','padding':'15px 0px 15px 15px '}"
                                         >
                                        <a
                                           class="cursor"
                                           mat-mini-fab
                                           #nextButtonElement
                                           [ngStyle]="{'color':'#5A738E', 'background-color':'#fff'}"
                                           >
                                            <i class="material-icons">chevron_right</i>
                                        </a>
                                    </div>
                                    


                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-0 col-xs-0" *ngIf="!formService.isAdvert"></div>
                    </div>
                </center>
            </div>
        </div>
    </div>
    <div *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 2">
        <div class="child" *ngIf="prevWindow === null">
            <div class="bar">
                <input class="searchbar sawasdee18"
                       [ngStyle]="{'text-align': 'center'}"
                       [placeholder]="'Where to ?'"
                       type="text"
                       title="Search">

                <!--            <mat-autocomplete #exhibitor = "matAutocomplete" (optionSelected)="onExhitorSelectionChanged($event)" [displayWith]="displayFn">-->
                <!--                <mat-option *ngFor="let exhibitor of filteredExhibitorsOptions | async; let exhibitorOf = index" [value]= "exhibitor" >-->
                <!--                    {{exhibitor.name}}-->
                <!--                </mat-option>-->
                <!--            </mat-autocomplete>-->
                <a> <i class="material-icons" [ngStyle]="{'margin-top': '0px'}">rotate_right</i> </a>
            </div>
        </div>
        <agm-map
                #gm
                (mapClick)="onmapClick($event)"
                [latitude]="formService.questions[0].options[0].settings.lat"
                [longitude]="formService.questions[0].options[0].settings.lng"
                [fitBounds]="false"
                [zoom]="zoom"
                [disableDefaultUI]="true"
                [styles]="service.app.settings.map.styles.style1"
                style="height : {{getHeight() + 'px'}}; width : 100%">
            <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
            <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
            <div *ngFor="let question of formService.questions">
                <agm-direction
                        [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : '#ff8000'}}"
                        [provideRouteAlternatives]="true"
                        [visible]="true"
                        [origin]="question.hasOwnProperty('origin') ? question.origin : {lat: 0, lng: 0}"
                        [destination]="question.hasOwnProperty('destination') ? question.destination : {lat: 0, lng: 0}"
                        [waypoints]="question.hasOwnProperty('waypoints') ? question.waypoints : []">
                </agm-direction>
                <agm-marker
                        *ngFor="let marker of question.options"
                        [latitude]="marker.settings.lat"
                        [longitude]="marker.settings.lng"
                        (markerClick)="onmarkerClick(infoWindow,gm)"
                        [agmFitBounds]="true"
                        [openInfoWindow]="true"
                        [label]="marker.settings.hasOwnProperty('label') ? marker.settings.label : ''"
                        [iconUrl]='icon'>
                    <agm-info-window
                            #infoWindow>
                        <tabel>
                            <tr>
                                <td class="sawasdee26"
                                    [innerHTML]="marker.hasOwnProperty('label') ? marker.label : ''">
                                </td>
                            </tr>
                            <tr>
                                <td class="sawasdee18"
                                    [innerHTML]="marker.settings.hasOwnProperty('description') ? marker.settings.description : ''">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <carousel
                                            [cellsToScroll]="1"
                                            [autoplay]="false"
                                            [height]="200"
                                            [arrows]="true">
                                        <div class="carousel-cell" *ngFor="let image of marker.settings.images">
                                            <img src="{{image.src}}" />
                                        </div>
                                    </carousel>
                                </td>
                            </tr>
                        </tabel>
                    </agm-info-window>
                </agm-marker>
            </div>
            <!--        <agm-marker-->
            <!--                [latitude]="currentLat"-->
            <!--                [longitude]="currentLng"-->
            <!--                [agmFitBounds]="true"-->
            <!--                [openInfoWindow]="true"-->
            <!--                (markerClick)="onClickCurrentPosition($event)"-->
            <!--                [iconUrl]='currentPositionIcon'-->
            <!--        >-->
            <!--        </agm-marker>-->
        </agm-map>
    </div>

    <div style="visibility: hidden; position: fixed"
         [style.left]="contextMenuPosition.x"
         [style.top]="contextMenuPosition.y"
         [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="inspector">
            <button mat-menu-item (click)="publish()">{{incomingResponse.attributes.hasOwnProperty('id') ?
                'Update' :
                (service.parseFloat(incomingForm.getCategoryId()) === 4 ? 'Register' : 'Publish')}}</button>
            <button mat-menu-item (click)="saveResponse();" *ngIf="service.hasToken() && !incomingResponse.attributes.hasOwnProperty('id')">Save</button>
            <button mat-menu-item (click)="export()" *ngIf="service.hasToken()">Export Pdf</button>
<!--        <button mat-menu-item (click)="setTitle()">Set Title</button>-->
            <button mat-menu-item (click)="remove()" *ngIf="service.hasToken() && !incomingResponse.attributes.hasOwnProperty('id')">Delete</button>
<!--        <button mat-menu-item (click)="profile()"*ngIf="service.app.data.status !== 0">{{service.app.data.user.name}}</button>-->
            <button mat-menu-item (click)="logout()" *ngIf="service.app.data.status === 1">Logout</button>
            <button mat-menu-item (click)="login()"  *ngIf="!service.hasToken()">Login</button>
        </ng-template>
    </mat-menu>

</div>
