<div class="sawasdee20x">
    <div  [ngStyle]="{'background':'url('+incomingClient.getUnknown('logo')+') center / cover','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}"
          class="containerRelative">
        <center><br>
<!--            <img src="{{incomingClient.getUnknown('logo')}}"-->
<!--                 class=""-->
<!--                 [ngStyle]="{'width': '100%', 'height': 'auto'}"/>-->
        </center>

        <div class="centered">
            <div class="col-md-12 col-sm-12 col-xs-12 form-group  top_search">
                    <mat-form-field [ngStyle]="{'width':'350px'}"
                                    appearance="outline">
                        <mat-label></mat-label>
                        <input  matInput
                                [ngStyle]="{'color':'#000','text-align':'center'}"
                                autocomplete="off"
                                #elmScanner
                                placeholder=""
                                (change)="onScanning($event,elmScanner)">
                        <mat-icon matSuffix [ngStyle]="{'color':this.incomingSettings.getBackgroundColor()}">qr_code</mat-icon>
                    </mat-form-field>
<!--                <div class="input-group">-->
<!--                    <input type="text"-->
<!--                           class="form-control"-->
<!--                           placeholder="scan..."-->
<!--                           autocomplete="off"-->
<!--                           #elmScanner-->
<!--                           (change)="onScanning($event,elmScanner)"-->
<!--                    />-->
<!--                    <span class="input-group-btn">-->
<!--                                <button class="btn btn-default"-->
<!--                                        type ="button">-->
<!--                                Go-->
<!--                                </button>-->
<!--                            </span>-->
<!--                </div>-->
            </div>
            <!--                    <mat-form-field [ngStyle]="{'width':'350px'}"-->
            <!--                                    appearance="fill">-->
            <!--                        <mat-label></mat-label>-->
            <!--                        <input  matInput-->
            <!--                                autocomplete="off"-->
            <!--                                #elmScanner-->
            <!--                                placeholder=""-->
            <!--                                (change)="onScanning($event,elmScanner)">-->
            <!--                        <mat-icon matSuffix>qr_code_2</mat-icon>-->
            <!--                    </mat-form-field>-->
        </div>
    </div>
<!--    <mat-tab-group>-->
<!--        <mat-tab label="scanner">-->
<!--            -->
<!--        </mat-tab>-->
<!--        <mat-tab label="webcam">-->
<!--            <zxing-scanner-->
<!--                    #scanner-->
<!--                    [formats]="this.formats"-->
<!--                    (scanSuccess)="scanSuccessHandler($event)"-->
<!--                    [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()+'px'}">-->
<!--            </zxing-scanner>-->
<!--        </mat-tab>-->
<!--    </mat-tab-group>-->
</div>
