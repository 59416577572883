import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import * as moment from 'moment';
import {DialogClientsComponent} from '../../clients/dialog-clients.component';
import {CampaignService} from '../campaign.service';
import {CampanyService} from "../../campanies/campany.service";
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {ClientService} from "../../clients/client.service";
@Component({
  selector: 'app-dialog-campaigns',
  templateUrl: './dialog-campaigns.component.html',
  styleUrls: ['./dialog-campaigns.component.scss']
})
export class DialogCampaignsComponent implements OnInit, OnDestroy {
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  clientControl = new FormControl();
  campanyControl = new FormControl();
  filteredClientsOptions: any;
  filteredCampaniesOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedCampaign: any;
  keys: any;
  selectedCampaign: any;
  addressValue: any;
  logo: any;
  startingAt: any;
  endingAt: any;
  clients: any;
  position: any;
  subscription: any;
  incomingCampany: any;
  incomingClient: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              private campanyService: CampanyService,
              public clienService: ClientService,
              public broadcastChannel: BroadcastChannelService,
              public campaignService: CampaignService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogCampaignsComponent>) {
    this.incomingCampany = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingCampany.setAttributes({});
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedCampaign = new Parameters();
    this.incomingSelectedCampaign.setAttributes(campaignService.selected);
    this.selectedCampaign = campaignService.selected;
    this.keys = Object.keys(this.selectedCampaign).length;
    this.startingAt = new Date();
    this.endingAt = new Date();
    this.clients = this.app.data.clients.data;
    this.incomingClient.setAttributes({});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty('client')) {
            this.clientControl.setValue(item.client);
            this.formValues.controls['client_id'].setValue(item.client.id);
          }else if (item.hasOwnProperty('campany')) {
            this.campanyControl.setValue(item.campany);
            this.formValues.controls['campany_id'].setValue(item.campany.id);
          }
        }

      });

    this.dialogRef.afterClosed().subscribe(result => {
      this.campaignService.setSelected({});
      this.subscription.unsubscribe();
    });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['' , Validators.compose([])],
      portal_id:   ['1', Validators.compose([])],
      client_id:   ['', Validators.compose([])],
      campany_id:  ['', Validators.compose([])],
      starting_at: ['', Validators.compose([])],
      ending_at:   ['', Validators.compose([])],
      calls:       ['', Validators.compose([])],
      sales:       ['', Validators.compose([])],
      category_id: ['1', Validators.compose([])],
      description: ['', Validators.compose([])],
      cntrl_num:   ['', Validators.compose([])],
    });

    this.formValues.controls['starting_at'].setValue(moment(new Date()).format('MM/DD/YYYY'));
    this.formValues.controls['ending_at'].setValue(moment(new Date()).format('MM/DD/YYYY'));
    if(this.app.data.user.role.id === 15){
      this.formValues.addControl('campany_id', new FormControl(this.app.data.campanies.data[0].id, Validators.compose([])));
    }
  //this.formValues.get('location').valueChanges.subscribe(value => console.log('value changed', ''));
    if(this.keys === 0) {
      this.selectedCampaign = {};
      this.formValues.controls['cntrl_num'].setValue(this.service.random());
    }else{
      this.formValues.controls['name'].setValue(this.incomingSelectedCampaign.getName());
      this.formValues.controls['description'].setValue(this.incomingSelectedCampaign.getDescription());
      this.startingAt = new Date(this.incomingSelectedCampaign.getStartingAt());
      this.endingAt   = new Date(this.incomingSelectedCampaign.getEndingAt());
      for(var key in this.incomingSelectedCampaign.getAttributes()) {
        if(this.incomingSelectedCampaign.attributes[key] instanceof Object || this.incomingSelectedCampaign.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingSelectedCampaign.attributes[key]);
          }else {
            if(this.incomingSelectedCampaign.attributes[key] instanceof Object || this.incomingSelectedCampaign.attributes[key] instanceof Array){} else{
              this.formValues.addControl(key, new FormControl(this.incomingSelectedCampaign.attributes[key], Validators.compose([])));
            }
          }
        }
      }
      if(this.service.empty(this.formValues.controls['cntrl_num'].value.toString())){
        this.formValues.controls['cntrl_num'].setValue(this.service.random());
      }
    }

    setTimeout(() => {
      this.filteredClientsOptions = this.clientControl.valueChanges.pipe(
        startWith(),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterClient(name) : this.app.data.clients.data.slice())
      );

      this.filteredCampaniesOptions = this.campanyControl.valueChanges.pipe(
          startWith(),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterCampany(name) : this.app.data.campanies.data.slice())
      );

      if(this.keys   === 0){
       if(this.app.data.campanies.data.length === 0){}else{
         this.incomingCampany.setAttributes(this.app.data.campanies.data[0]);
         this.campanyControl.setValue(this.incomingCampany.getAttributes());
         this.formValues.controls['campany_id'].setValue(this.incomingCampany.getId());
       }

       if(this.app.data.clients.data.length === 0){}else{
        this.incomingClient.setAttributes(this.app.data.clients.data[0]);
        this.clientControl.setValue(this.incomingClient.getAttributes());
        this.formValues.controls['client_id'].setValue(this.incomingClient.getId());
       }
      } else {
        this.incomingClient.setAttributes(this.clienService.getClientById(this.incomingSelectedCampaign.getClientId()));
        this.clientControl.setValue(this.incomingClient.getAttributes());
        this.clienService.setSelected(this.incomingClient.getAttributes());
        this.formValues.controls['client_id'].setValue(this.incomingClient.getId());

        this.incomingCampany.setAttributes(this.campanyService.getCampanyById(this.incomingSelectedCampaign.getCampanyId()));
        this.campanyControl.setValue(this.incomingCampany.getAttributes());
        this.campanyService.setSelected(this.incomingCampany.getAttributes());
        this.formValues.controls['campany_id'].setValue(this.incomingCampany.getId());
      }
    });
  }

  public handleAddressChange(address: Address) {
    console.log(address);
  }

  onaddCampanyClicked(){
    this.campanyService.setSelected({});
    this.campanyService.openDialog({});
    this.notifier.notify('success', 'open account');
  }

  getClient(campaign: any){
    return this.clients.filter((client: any) => { return client.id === campaign.client_id; })[0];
  }

  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
  }

//  onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//  }

  handlEditorFocus(){
  }

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['logo'].setValue(this.service.dataURItoBlob(reader.result));
      this.logo = reader.result;
    };
  }

  addClient(){
    this.clienService.setSelected({});
    this.clienService.openDialog({});
    this.notifier.notify('success', 'Add New Client');
  }


  onSelectStartingAt(event: any){
    this.endingAt = event > this.endingAt ? event : this.endingAt;
    this.startingAt = event;
    this.formValues.controls['starting_at'].setValue(moment(new Date(event)).format('MM/DD/YYYY'));
  }

  onSelectEndingAt(event: any){
    this.startingAt = event < this.startingAt ? new Date(event) : this.startingAt;
    this.endingAt = event;
    this.formValues.controls['ending_at'].setValue(moment(new Date(event)).format('MM/DD/YYYY'));
  }

  onChangeClientText(event){
      setTimeout((position: any) => {
        this.service.httpService('post', '/clients/search', {searchText: event.target.value}, {ignoreLoadingBar: 'true', notify: 'ignore'}, (response: any) => {
          response.map((incomingClient: any) => {
            position = this.app.data.clients.data.findIndex((client: any) => { return parseFloat(client.id) === parseFloat(incomingClient.id); });
            if(position === -1) {
              incomingClient = Object.assign(incomingClient, {campaigns: {data: [], trashed: {data: []}}})
              this.app.data.clients.data.unshift(incomingClient);
            }
          });
        }, (error: any) => {});
      }, 911);
  }




  private _filterClient(name: string) {
    const filterValue = name.toString().toLowerCase();
    return this.app.data.clients.data.filter((option: any) => option.name.toString().toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  handleAddressChanger(result: any){
  }

//handleAddressChange(result: PlaceResult){
//}


  onClientSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['client_id'].setValue(event.option.value.id);
  }

  private _filterCampany(name: string) {
    const filterValue = name.toString().toLowerCase();
    return this.app.data.campanies.data.filter((option: any) => option.name.toString().toLowerCase().includes(filterValue));
  }

  displayFn(value: any){
    return value.name;
  }

  onCampanySelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['campany_id'].setValue(event.option.value.id);
  }

  onSubmit(campanyForm: any) {
    this.service.httpService(
      'post',
      this.keys === 0 ? '/campaigns' : '/campaigns/' + this.incomingSelectedCampaign.getId() + '/update',
      campanyForm, {},
      (response: any) => {
        this.service.status = 1;
        if(this.keys === 0)  this.service.app.data.campaigns.data.unshift(response);
        else this.selectedCampaign = Object.assign(this.selectedCampaign, response);
        this.selectedCampaign = {};
        this.broadcastChannel.emitNavChangeEvent({campaign: response});
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'opened' : 'updated' ));
        this.closeDialog();
        campanyForm = Object.assign(campanyForm, {campaign_id: response.id});
        this.service.httpService('post', '/campany-campaigns', campanyForm, {ignoreLoadingBar: 'true', notify: 'ignore'}, (resp: any) => {
        }, (error: any) => {});
        }, (error: any) => {
      });
  }

}
