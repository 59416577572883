import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RoutesInviteeComponent} from './routes/routes-invitee.component';

@Injectable({
  providedIn: 'root'
})
export class InviteeService {

  constructor(private dialog: MatDialog) {
  }

  routes(){
    this.dialog.open(RoutesInviteeComponent, {data  : {}, width : '100%', height: '100%', });
  }
}
