import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, NgZone, ViewChild} from '@angular/core';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {ResponseService} from '../../../forms/responses/data/response.service';
import {CampaignService} from '../../../campaigns/campaign.service';
import {ClientService} from '../../../clients/client.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {Parameters} from '../../../parameters';
import {AppService} from "../../../app.service";
import {FormService} from '../../form.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import {jsPDF} from 'jspdf';
@Component({
  selector: 'app-description-preview-controls',
  templateUrl: './description-preview-controls.component.html',
  styleUrls: ['./description-preview-controls.component.scss']
})
export class DescriptionPreviewControlsComponent implements  OnChanges, AfterViewInit, OnDestroy {
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  @Input() question: any;
  @Input() position: any;
  incomingForm: any;
  outgoingRequest: any;
  incomingQuestion: any;
  incomingQuestionSelected: any;
  incomingResponseResult: any;
  incomingQrcodeQuestion: any;
  incomingFormSettings: any;
  incomingResponse: any;
  incomingIsConfirmQuestion: any;
  incomingInvitationCardQuestion: any;
  incomingResult : any;
  incomingEmailQuestion : any;
  incomingCampaign : any;
  incomingClient : any;
  incomingPicQuestion : any;
  incomingBannerQuestion : any;
  constructor(private broadcastChannel: BroadcastChannelService,
              public formService: FormService,
              public zone: NgZone,
              public campaignService: CampaignService,
              public clientService: ClientService,
              public  router: Router,
              private activatedRoute: ActivatedRoute,
              public responseService: ResponseService,
              public service: AppService) {
              this.incomingQuestion = new Parameters();
              this.incomingQuestionSelected = new Parameters();
              this.incomingQrcodeQuestion = new Parameters();
              this.incomingBannerQuestion = new Parameters();
              this.incomingResponseResult = new Parameters();
              this.incomingResult = new Parameters();
              this.incomingPicQuestion = new Parameters();
              this.outgoingRequest = new Parameters();
              this.incomingEmailQuestion = new Parameters();
              this.incomingCampaign = new Parameters();
              this.incomingClient = new Parameters();
              this.incomingForm = new Parameters();
              this.incomingFormSettings = new Parameters();
              this.incomingResponse = new Parameters();
              this.incomingIsConfirmQuestion = new Parameters();
              this.incomingInvitationCardQuestion = new Parameters();
              this.contextMenu = MatMenuTrigger;
              this.incomingBannerQuestion.setAttributes({});
              this.incomingPicQuestion.setAttributes({});
  }


  next(){
     this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
  }

  getDataUrl(url: any){
        var request = new XMLHttpRequest();
        request.open('GET', url);
        request.responseType = 'blob';
        request.onload = () =>{
        }
        request.send();

  }

  ngAfterViewInit(): void {
  }
  
  getLogo(event: any){
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setSrc(reader.result);
            this.outgoingRequest.setFlag(0);
            this.outgoingRequest.setCaption('');
            this.outgoingRequest.setOrderBy('');
            this.outgoingRequest.setStatus(1);
            this.outgoingRequest.setUrl('');
            this.outgoingRequest.setPath('');
            this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
            event.target.files = null;
            this.incomingFormSettings.setLogoDataUrl(reader.result);
            this.service.httpService('post', this.service.app.routes.images.add,
                this.outgoingRequest.getAttributes(), {},(result: any) => {
                   this.incomingResult.setAttributes(result);
                   this.incomingFormSettings.setLogoUrl(this.incomingResult.getPath());
                   this.incomingForm.setSettings(this.incomingFormSettings.getAttributes());
                   this.updateSettingsForm();
                }, (error: any) => {
                });
        };
  }

  onFrameFileChange(event: any){
          const files: FileList = event.target.files;
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = () => {
              this.outgoingRequest.setAttributes({});
              this.outgoingRequest.setSrc(reader.result);
              this.outgoingRequest.setFlag(0);
              this.outgoingRequest.setCaption('');
              this.outgoingRequest.setOrderBy('');
              this.outgoingRequest.setStatus(1);
              this.outgoingRequest.setUrl('');
              this.outgoingRequest.setPath('');
              this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
              event.target.files = null;
              this.incomingFormSettings.setFrameDataUrl(reader.result);
              this.service.httpService('post', this.service.app.routes.images.add,
                  this.outgoingRequest.getAttributes(), {},(result: any) => {
                     this.incomingResult.setAttributes(result);
                     this.incomingFormSettings.setFrameUrl(this.incomingResult.getPath());
                     this.incomingForm.setSettings(this.incomingFormSettings.getAttributes());
                     this.updateSettingsForm();
                  }, (error: any) => {
                  });
          };
    }

  updateSettingsForm(){
       setTimeout(() => {
             this.outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
               this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
               this.outgoingRequest.setControls(JSON.stringify(this.outgoingRequest.getControls()));
               this.formService.update(this.outgoingRequest, (result: any) => {
               this.ngOnChanges({});
             });
       });

  }
  
  onContextMenu(event: MouseEvent, inspector: any) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { 'item': inspector };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();

  }

  download(){
         const pdfHtmlContents: any = document.getElementById('doc');
         setTimeout(()=>{
//           let name: any = this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingFormSettings.getAttendeeName()).data));
             let name: any = 'doc';
             setTimeout(()=>{
                      html2pdf()
                          .from(pdfHtmlContents)
                          .set(this.getOptions())
                          .toImg()
                          .save(name+'.pdf').then(() => {
                      });
             },this.service.timeout());
         });
 }

 getOptions(){
        const opt: any = {
          margin: 1,
          filename: 'file.pdf',
          image: { type: 'jpeg', quality: 0.20 },
          html2canvas: {scale: 7, logging: true},
  //      jsPDF: {unit: 'in', format: 'a4', orientation: 'p'} useCORS: true
        }; return opt;
  }



  getQrcode(event: any){
          const files: FileList = event.target.files;
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = () => {
              this.outgoingRequest.setAttributes({});
              this.outgoingRequest.setSrc(reader.result);
              this.outgoingRequest.setFlag(0);
              this.outgoingRequest.setCaption('');
              this.outgoingRequest.setOrderBy('');
              this.outgoingRequest.setStatus(1);
              this.outgoingRequest.setUrl('');
              this.outgoingRequest.setPath('');
              this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
              this.zone.run(() =>{
               this.incomingQrcodeQuestion.setResult(Object.assign(this.incomingQrcodeQuestion.getResult(),{path: reader.result}));
              }); event.target.files = null;
              this.service.httpService('post', this.service.app.routes.images.add,
              this.outgoingRequest.getAttributes(), {},(result: any) => {
                 this.incomingResult.setAttributes(result);
                 this.incomingQrcodeQuestion.setResult(Object.assign(this.incomingQrcodeQuestion.getResult(),{src: this.incomingResult.getPath()}));
                 this.ngOnChanges({});
              }, (error: any) => {
              });
          };
  }

  setBanner(){
      setTimeout(()=>{
         let doc: any = document.getElementById('*banner');
         if(doc !== null && this.incomingBannerQuestion.getAttributes().hasOwnProperty('id')){
             doc.innerHTML = this.incomingBannerQuestion.getUnknown('data');
         }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.setData(this.incomingQuestion.getLabel());
      this.incomingResponse.setAttributes(this.responseService.getSelected());
      this.incomingForm.setAttributes(this.incomingResponse.getForm());
      this.incomingCampaign.setAttributes(this.incomingForm.getAttributes().hasOwnProperty('campaign') ? this.incomingForm.getCampaign() : this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
      this.incomingClient.setAttributes(this.incomingCampaign.getAttributes().hasOwnProperty('client') ? this.incomingCampaign.getClient() : this.clientService.getClientById(this.incomingCampaign.getClientId()));
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      this.incomingFormSettings.setVenue(this.incomingFormSettings.attributes.hasOwnProperty('venue') ?
          this.incomingFormSettings.getVenue() : '');
      this.incomingFormSettings.setRSVPDate(this.incomingFormSettings.attributes.hasOwnProperty('rsvp_date') ?
          this.incomingFormSettings.getRSVPDate() : '');
      this.incomingFormSettings.setTime(this.incomingFormSettings.attributes.hasOwnProperty('time') ?
          this.incomingFormSettings.getTime() : '');
      this.incomingFormSettings.setDescription(this.incomingFormSettings.attributes.hasOwnProperty('description') ?
          this.incomingFormSettings.getDescription() : '');
      this.incomingFormSettings.setContact(this.incomingFormSettings.attributes.hasOwnProperty('contact') ?
          this.incomingFormSettings.getContact() : '');
      this.incomingFormSettings.setFrameUrl(this.incomingFormSettings.attributes.hasOwnProperty('frame_url') ?
          this.incomingFormSettings.getFrameUrl() : '');
      this.incomingFormSettings.setBanner(this.incomingFormSettings.attributes.hasOwnProperty('banner') ?
          this.incomingFormSettings.getBanner() : '');
      this.incomingFormSettings.setFrameDataUrl(this.incomingFormSettings.attributes.hasOwnProperty('frame_data_url') ?
         this.incomingFormSettings.getFrameDataUrl() : '');
      this.incomingFormSettings.setAttendingDate(this.incomingFormSettings.attributes.hasOwnProperty('attending_date') ?
          this.incomingFormSettings.getAttendingDate() : '');
      this.incomingFormSettings.setJobPosition(this.incomingFormSettings.attributes.hasOwnProperty('job_position') ?
          this.incomingFormSettings.getJobPosition() : '');
      this.incomingFormSettings.setBackgroundColor(this.incomingFormSettings.attributes.hasOwnProperty('background_color') ?
           this.incomingFormSettings.getBackgroundColor() : '#000');
      this.incomingFormSettings.setContactPersonName(this.incomingFormSettings.attributes.hasOwnProperty('contact_person_name') ?
           this.incomingFormSettings.getContactPersonName() : '');
      this.incomingFormSettings.setContactPersonPhone(this.incomingFormSettings.attributes.hasOwnProperty('contact_person_phone') ?
           this.incomingFormSettings.getContactPersonPhone() : '');
      this.incomingFormSettings.setContactPersonTitle(this.incomingFormSettings.attributes.hasOwnProperty('contact_person_title') ?
           this.incomingFormSettings.getContactPersonTitle() : '');
      this.incomingFormSettings.setCompanyName(this.incomingFormSettings.attributes.hasOwnProperty('company_name') ?
           this.incomingFormSettings.getCompanyName() : '');
      this.incomingFormSettings.setInvitationCard(this.incomingFormSettings.attributes.hasOwnProperty('invitation_card') ?
        this.incomingFormSettings.getInvitationCard() : '');
      this.incomingFormSettings.setInvitationContents(this.incomingFormSettings.attributes.hasOwnProperty('invitation_contents') ?
         this.incomingFormSettings.getInvitationContents() : '');
      this.incomingFormSettings.setAttendeeName(this.incomingFormSettings.attributes.hasOwnProperty('attendee_name') ?
           this.incomingFormSettings.getAttendeeName() : '');
      this.incomingFormSettings.setSalutation(this.incomingFormSettings.attributes.hasOwnProperty('salutation') ?
           this.incomingFormSettings.getSalutation() : '');
      this.incomingFormSettings.setLogoUrl(this.incomingFormSettings.attributes.hasOwnProperty('logo_url') ?
           this.incomingFormSettings.getLogoUrl() : '');
      this.incomingFormSettings.setLogoDataUrl(this.incomingFormSettings.attributes.hasOwnProperty('logo_data_url') ?
           this.incomingFormSettings.getLogoDataUrl() : '');
      this.setReferenceQuestions();
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*display1', ''));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*display2', ''));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*display3', ''));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*color', this.incomingFormSettings.getBackgroundColor()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*frame', this.incomingFormSettings.getFrameDataUrl()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*description', this.incomingFormSettings.getDescription()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*contact', this.incomingFormSettings.getContact()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*name', !this.service.empty(this.incomingFormSettings.getAttendeeName()) ?  this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingFormSettings.getAttendeeName()).data : ''));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*salutation', !this.service.empty(this.incomingFormSettings.getSalutation()) ?  this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingFormSettings.getSalutation()).data : ''));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*company', !this.service.empty(this.incomingFormSettings.getCompanyName()) ?  this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingFormSettings.getCompanyName()).data : ''));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*event', this.incomingForm.getName()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*time', this.incomingFormSettings.getTime()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*rsvpDate', this.incomingFormSettings.getRSVPDate()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*attendingDate', this.incomingFormSettings.getAttendingDate()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*venue', this.incomingFormSettings.getVenue()));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*jobPosition', !this.service.empty(this.incomingFormSettings.getJobPosition()) ?  this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingFormSettings.getJobPosition()).data : ''));
      this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*displayQrcode', 'none'));
      this.setBanner();
      if(Object.keys(this.incomingPicQuestion.getAttributes()).length!==0){
         if(this.incomingPicQuestion.getAttributes().hasOwnProperty('imgSrc')){
             this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*pic', this.incomingPicQuestion.getUnknown('imgSrc')));
            }else{
                 this.outgoingRequest.setAttributes({});
                 this.outgoingRequest.setUrl(this.incomingPicQuestion.getUnknown('data'));
                 this.service.httpService('post', this.service.app.routes.files.base64,
                  this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},(result: any) => {
                    this.incomingPicQuestion.setUnknown('imgSrc','data:image/jpg;base64,'+result);
                    this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*pic', this.incomingPicQuestion.getUnknown('imgSrc')));
                  }, (error: any) => {
                  });
         }
      }
      this.incomingQrcodeQuestion.setResult(this.incomingQrcodeQuestion.getAttributes().hasOwnProperty('result') ? this.incomingQrcodeQuestion.getResult() : {path:'',src:''});
      if(this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvitationContents()){
                this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*logo',
                     this.incomingEmailQuestion.getAttributes().hasOwnProperty('id') &&
                     !this.service.empty(this.incomingEmailQuestion.getData()) &&
                     this.incomingEmailQuestion.getData().toString().includes('@gmail.com') && !this.service.empty(this.incomingFormSettings.getLogoUrl()) ?
                     this.incomingFormSettings.getLogoUrl() : this.incomingFormSettings.getLogoDataUrl() ));

                if(this.incomingQrcodeQuestion.getAttributes().hasOwnProperty('id')){
                   this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*qrcode',
                        this.incomingEmailQuestion.getAttributes().hasOwnProperty('id') &&
                        this.incomingQrcodeQuestion.getResult().hasOwnProperty('src') &&
                        !this.service.empty(this.incomingEmailQuestion.getData()) &&
                        this.incomingEmailQuestion.getData().toString().includes('@gmail.com') && !this.service.empty(this.incomingQrcodeQuestion.getResult().path) ?
                        this.incomingQrcodeQuestion.getResult().src : (this.incomingQrcodeQuestion.getResult().hasOwnProperty('path') ? this.incomingQrcodeQuestion.getResult().path: '')));
                }
      }else{
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*logo', this.incomingFormSettings.getLogoDataUrl()));
       this.incomingQuestion.setData(this.incomingQuestion.getData().toString().replaceAll('*qrcode', this.incomingQrcodeQuestion.getResult().hasOwnProperty('path') ? this.incomingQrcodeQuestion.getResult().path: ''));
      }
  }

  setReferenceQuestions(){
       this.incomingQrcodeQuestion.setAttributes({});
       this.incomingEmailQuestion.setAttributes({});
       this.incomingPicQuestion.setAttributes({});
       this.incomingBannerQuestion.setAttributes({});
       this.incomingForm.getControls().map((question: any) => {
         this.incomingQuestionSelected.setAttributes(question);
         if(!this.service.empty(this.incomingFormSettings.getAttendeeEmail())){
               if(this.incomingFormSettings.getAttendeeEmail().toString() === this.incomingQuestionSelected.getCntrlNum())
                this.incomingEmailQuestion.setAttributes(this.incomingQuestionSelected.getAttributes());
         }if(!this.service.empty(this.incomingFormSettings.getAttendeePic())){
           if(this.incomingFormSettings.getAttendeePic().toString() === this.incomingQuestionSelected.getCntrlNum())
            this.incomingPicQuestion.setAttributes(this.incomingQuestionSelected.getAttributes());
         }if(!this.service.empty(this.incomingFormSettings.getBanner())){
           if(this.incomingFormSettings.getBanner().toString() === this.incomingQuestionSelected.getCntrlNum())
             this.incomingBannerQuestion.setAttributes(this.incomingQuestionSelected.getAttributes());
         }if(parseFloat(this.incomingQuestionSelected.getControlId()) === 33){
          this.incomingQrcodeQuestion.setAttributes(this.incomingQuestionSelected.getAttributes());
          this.incomingQrcodeQuestion.setResult(this.service.empty(this.incomingQrcodeQuestion.getResult()) ? {} : this.incomingQrcodeQuestion.getResult());
         }
       });
  }

  ngOnDestroy(): void {
  }


}
