import { Component, OnInit, Inject,AfterViewInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../parameters';
import {AppService} from '../../app.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {FormService} from '../../forms/form.service';
import {ClientService} from '../../clients/client.service';
import {Options, NgxQrcodeStylingComponent, NgxQrcodeStylingService } from 'ngx-qrcode-styling';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-dialog-frame-outlets',
  templateUrl: './dialog-frame-outlets.component.html',
  styleUrls: ['./dialog-frame-outlets.component.scss']
})
export class DialogFrameOutletsComponent implements OnInit,AfterViewInit {
  incomingOutlet: any;
  frame: any = '';
  formId: any;
  incomingForm: any;
  incomingCampaign: any;
  incomingClient: any;
  outgoingRequest: any;
  incomingResult: any;
  incomingFormSettings: any;
  public config: any = {
       width: 220,
       height: 220,
       data: '',
       image: '',
       margin: 5,
       dotsOptions: {
         color: "#000",
         type: "dots"
       },
       backgroundOptions: {
         color: "#ffffff",
       },
       imageOptions: {
         crossOrigin: "anonymous",
         margin: 0
       }
  };
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private service: AppService,
              public formService: FormService,
              public campaignService: CampaignService,
              public clientService: ClientService,
              private qrcode: NgxQrcodeStylingService,
              public dialogRef: MatDialogRef<DialogFrameOutletsComponent>) {
   this.incomingOutlet = new Parameters();
   this.incomingForm = new Parameters();
   this.incomingCampaign = new Parameters();
   this.incomingClient = new Parameters();
   this.incomingResult = new Parameters();
   this.outgoingRequest = new Parameters();
   this.incomingFormSettings = new Parameters();
   this.incomingOutlet.setAttributes(data.outlet);
   this.frame = data.frame;
   this.formId = data.formId;
   this.incomingForm.setAttributes(this.formService.getFormById(this.formId));
   this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
   this.incomingCampaign.setAttributes(this.incomingForm.getAttributes().hasOwnProperty('campaign') ?
           this.incomingForm.getCampaign() :
           this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
   this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
  }

  ngOnInit(): void {
   this.incomingFormSettings.setBackgroundColor(this.incomingFormSettings.getAttributes().hasOwnProperty('background_color') ?
    this.incomingFormSettings.getBackgroundColor() : '');
  }

  setImgUrl(callback: any){
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setUrl(this.incomingClient.getUnknown('logo'));
      this.service.httpService('post', this.service.app.routes.files.base64,
       this.outgoingRequest.getAttributes(), {},(result: any) => {
        this.frame = this.frame.replaceAll('*logoCustomer','data:image/jpg;base64,'+result);
        callback(null);
       }, (error: any) => {
      });
  }

  setFrame(callback: any){
     let frame:any = document.getElementById('frame');
     this.outgoingRequest.setAttributes({});
     this.outgoingRequest.setUrl(frame.src);
     this.service.httpService('post', this.service.app.routes.files.base64,
      this.outgoingRequest.getAttributes(), {},(result: any) => {
       this.frame = this.frame.replaceAll(this.outgoingRequest.getUrl(),'data:image/jpg;base64,'+result);
       callback(null);
      }, (error: any) => {
     });
  }

  
  ngAfterViewInit(): void{
    setTimeout(()=>{
        this.setFrame((result: any)=> {
              this.setImgUrl((result: any)=> {
                   setTimeout(()=>{
                      this.service.setCrypt(this.incomingOutlet.getId(),(outlet: any)=>{
                                 this.config.data = window.location.host + '/app/customers/' + outlet + '/' + this.service.encrypt(this.formId) +'/orders?q=';
                                 this.config.data = this.config.data.toString().includes('http://') ? this.config.data : 'http://' + this.config.data;
                                 if(!this.service.empty(this.incomingFormSettings.getBackgroundColor()))
                                 this.config.data = this.config.data +'&extension='+this.incomingFormSettings.getBackgroundColor();
                                 if(!this.service.empty(this.incomingClient.getUnknown('logo')))
                                 this.config.data = this.config.data +'&access='+this.incomingClient.getUnknown('logo').split('clients/')[1];
                                 let elm:any = document.getElementById('qrcode');
                                 this.qrcode.create(this.config, elm).subscribe((res) => {
                                      setTimeout(() => {
                                        let doc: any = document.getElementById('doc');
                                        html2canvas(doc).then(canvas => {
                                             this.outgoingRequest.setAttributes({});
                                             this.outgoingRequest.setSrc(canvas.toDataURL("image/png"));
                                             this.outgoingRequest.setFlag(0);
                                             this.outgoingRequest.setCaption('');
                                             this.outgoingRequest.setOrderBy('');
                                             this.outgoingRequest.setStatus(1);
                                             this.outgoingRequest.setUrl('');
                                             this.outgoingRequest.setPath('');
                                             this.outgoingRequest.setAvatar(this.service.dataURItoBlob(canvas.toDataURL("image/png")));
                                             this.service.httpService('post', this.service.app.routes.images.add,
                                                 this.outgoingRequest.getAttributes(), {},(result: any) => {
                                                    this.incomingResult.setAttributes(result);
                                                    this.incomingOutlet.setFrame(this.incomingResult.getPath());
                                                    this.dialogRef.close(null);
                                                    this.service.httpService('post',this.service.app.routes.outlets.update,
                                                      this.incomingOutlet.getAttributes(), {ignoreLoadingBar: 'true'},
                                                      (response: any) => {
                                                    }, (error: any) => {
                                                    });
                                                 }, (error: any) => {
                                                 });
                                        });
                                      },this.service.timeout());
                                 });
                       });
                   });
             });
        });
    });
  }


}
