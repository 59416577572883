<div layout="row">
  <div class="col-lg-2 col-md-2"></div>
  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
    <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
      <div class="panel-bodyy">
        <div> <app-label-create-control [question]="question"></app-label-create-control></div>
        <div>
          <mat-form-field class="example-full-width" appearance="fill">
            <input  class="sawasdee26"
                    matInput
                    placeholder="{{question.placeholder}}"
                    autocomplete="off"
                    #matAutocompleteTrigger="matAutocompleteTrigger"
                    [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                    (input)="onInputTextChange($event);getSuggestions($event)"
                    (keydown)='validateTextLength($event)'
                    (blur)='addSuggestion($event)'
                    [autofocus]="true"
                    [formControl] = "suggestionControl"
                    [matAutocomplete] = "suggestions"
                    #element>
            <mat-autocomplete #suggestions = "matAutocomplete"
                              (optionSelected)="onSuggestionSelectionChanged($event)"
                              [displayWith]="displayFn">
              <mat-option *ngFor="let suggestion of filteredSuggestions | async; let suggestionIncresemental = index" [value]= "suggestion" >
                {{suggestion.description}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 row">
          <center>
            <app-next-button></app-next-button>
          </center>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-2"></div>
</div>
