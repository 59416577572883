import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';

@Component({
  selector: 'app-header-jobinnv120',
  templateUrl: './header-jobinnv120.component.html',
  styleUrls: ['./header-jobinnv120.component.scss']
})
export class HeaderJobinnv120Component implements OnInit {

  app: any;
  constructor(public service: AppService,
              private broadcastChannelService: BroadcastChannelService) { }
  headerClass = 'header-2';
  ngOnInit(): void {
    this.app = this.service.app;
  }

  onMenuClicked(){
    this.broadcastChannelService.emitNavChangeEvent('menu.toggle');
  }


  openDateMenu(){
    this.broadcastChannelService.emitNavChangeEvent('openDateMenu');
  }

}
