import {Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges} from '@angular/core';
import {AppService} from '../../../../app.service';
import {FormService} from '../../../form.service';
import {Parameters} from '../../../../parameters';
@Component({
  selector: 'app-respond-email-controls',
  templateUrl: './respond-email-controls.component.html',
  styleUrls: ['./respond-email-controls.component.scss']
})
export class RespondEmailControlsComponent implements OnChanges, AfterViewInit {
  @ViewChild('emailElement', {static: false})  emailElement: any;
  @Input() question: any;
  @Input() position: any;
  label: any;
  incomingQuestion: any;
  constructor(public service: AppService,
              public formService: FormService) {
     this.emailElement = ElementRef;
     this.incomingQuestion = new Parameters();
  }

  ngOnChanges(changes: any): void {
    this.incomingQuestion.setAttributes(this.question);
  }

  ngAfterViewInit(): void {
      setTimeout(() => {
        if (this.emailElement.nativeElement){
          this.emailElement.nativeElement.focus();
        }
      });
  }

}
