<!--BANNER START-->
<div class="banner-outer banner-2">
    <div class="banner-2"> <img src="assets/images/warehouse/img/379917541_864737445070182_6333753520672505327_n.jpg" alt="banner"> </div>
    <div class="caption">
        <div class="holder">
            <form action="#">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Job Title, Skills or Company">
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter City, State, Province or Country">
                        </div>
                        <div class="col-md-3">
                            <input type="text" placeholder="Category">
                        </div>
                        <div class="col-md-1">
                            <button type="submit"><i class="fa fa-search"></i></button>
                        </div>
                    </div>
                </div>
            </form>
            <h1>Belong Anywhere</h1>
            <strong>Search Online Jobs or Hire Employees!</strong> </div>
    </div>
</div>

<!--POPULAR JOB CATEGPRIES START-->
<section class="popular-job-caregries popular-categories">
    <div class="holder">
        <div class="container">
            <h4>Popular Job Categories</h4>
            <div id="popular-job-slider" class="owl-carousel owl-theme">
                <div class="item">
                    <div class="box"> <img alt="img" src="images/categories-icon-1.png">
                        <h4><a href="#">Art, Design &amp; Multimedia</a></h4>
                        <strong>6,213 Jobs</strong>
                        <p>Available in Design &amp; Multimedia</p>
                    </div>
                </div>
                <div class="item">
                    <div class="box"> <img alt="img" src="images/categories-icon-2.png">
                        <h4><a href="#">Restaurant, Food, Hotels</a></h4>
                        <strong>3,750 Jobs</strong>
                        <p>Available in Restaurant &amp; Hotels</p>
                    </div>
                </div>
                <div class="item">
                    <div class="box"> <img alt="img" src="images/categories-icon-3.png">
                        <h4><a href="#">Transporation</a></h4>
                        <strong>1,265 Jobs</strong>
                        <p>Available in Transportation</p>
                    </div>
                </div>
                <div class="item">
                    <div class="box"> <img alt="img" src="images/categories-icon-4.png">
                        <h4><a href="#">Healthcare &amp; Medicine</a></h4>
                        <strong>5,913 Jobs</strong>
                        <p>Available in Medical</p>
                    </div>
                </div>
                <div class="item">
                    <div class="box"> <img alt="img" src="images/categories-icon-5.png">
                        <h4><a href="#">Software &amp; Development</a></h4>
                        <strong>7,418 Jobs</strong>
                        <p>Available in IT</p>
                    </div>
                </div>
                <div class="item">
                    <div class="box"> <img alt="img" src="images/categories-icon-6.png">
                        <h4><a href="#">Accounting &amp; Finance</a></h4>
                        <strong>8,045 Jobs</strong>
                        <p>Available in Accounts &amp; Finance</p>
                    </div>
                </div>
                <div class="item">
                    <div class="box"> <img alt="img" src="images/categories-icon-7.png">
                        <h4><a href="#">Education &amp; Academia</a></h4>
                        <strong>3,891 Jobs</strong>
                        <p>Available in Education</p>
                    </div>
                </div>
                <div class="item">
                    <div class="box"> <img alt="img" src="images/categories-icon-8.png">
                        <h4><a href="#">Construction, Engineering</a></h4>
                        <strong>9,942 Jobs</strong>
                        <p>Available in Architect</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--POPULAR JOB CATEGPRIES END-->
<!--BANNER END-->

<!--MAIN START-->
<div id="main">
    <!--RECENT JOB SECTION START-->
    <section class="recent-row padd-tb">
        <div class="container">
            <div class="row">
                <div class="col-md-9 col-sm-8">
                    <div id="content-area">
                        <h2>Recent Hot Jobs</h2>
                        <ul id="myList">
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-1.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Marketing Graphic Design / 2D Artist</a></h4>
                                        <p>Design Communication Studio</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Moorgate, London</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$4000 - $5000</strong> <a href="#" class="btn-1 btn-color-1 ripple">Part Time</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-2.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Public Relation Executive Manager</a></h4>
                                        <p>Life Insurance LLC</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$7000 - $7500</strong> <a href="#" class="btn-1 btn-color-2 ripple">Full Time</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-3.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Instructor Classroom Head</a></h4>
                                        <p>School of Design and Multimedia</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$30/hr - $40/hr</strong> <a href="#" class="btn-1 btn-color-3 ripple">Freelance</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-4.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Technical Database Engineer</a></h4>
                                        <p>Datebase Management Company</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$2200 - $3000</strong> <a href="#" class="btn-1 btn-color-4 ripple">Contract</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-5.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Marketing Graphic Design / 2D Artist</a></h4>
                                        <p>Design Communication Studio</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$4000 - $5000</strong> <a href="#" class="btn-1 btn-color-1 ripple">Part Time</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-2.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Public Relation Executive Manager</a></h4>
                                        <p>Life Insurance LLC</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$7000 - $7500</strong> <a href="#" class="btn-1 btn-color-2 ripple">Full Time</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-3.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Instructor Classroom Head</a></h4>
                                        <p>School of Design and Multimedia</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$30/hr - $40/hr</strong> <a href="#" class="btn-1 btn-color-3 ripple">Freelance</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-4.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Technical Database Engineer</a></h4>
                                        <p>Datebase Management Company</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$2200 - $3000</strong> <a href="#" class="btn-1 btn-color-4 ripple">Contract</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-2.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Public Relation Executive Manager</a></h4>
                                        <p>Life Insurance LLC</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$7000 - $7500</strong> <a href="#" class="btn-1 btn-color-2 ripple">Full Time</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-3.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Instructor Classroom Head</a></h4>
                                        <p>School of Design and Multimedia</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$30/hr - $40/hr</strong> <a href="#" class="btn-1 btn-color-3 ripple">Freelance</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-4.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Technical Database Engineer</a></h4>
                                        <p>Datebase Management Company</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$2200 - $3000</strong> <a href="#" class="btn-1 btn-color-4 ripple">Contract</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-1.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Marketing Graphic Design / 2D Artist</a></h4>
                                        <p>Design Communication Studio</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Moorgate, London</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$4000 - $5000</strong> <a href="#" class="btn-1 btn-color-1 ripple">Part Time</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-2.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Public Relation Executive Manager</a></h4>
                                        <p>Life Insurance LLC</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$7000 - $7500</strong> <a href="#" class="btn-1 btn-color-2 ripple">Full Time</a> </div>
                            </li>
                            <li>
                                <div class="box">
                                    <div class="thumb"><a href="#"><img src="images/recent-job-thumb-3.jpg" alt="img"></a></div>
                                    <div class="text-col">
                                        <h4><a href="#">Instructor Classroom Head</a></h4>
                                        <p>School of Design and Multimedia</p>
                                        <a href="#" class="text"><i class="fa fa-map-marker"></i>Nationwide</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 30, 2015 - Feb 20, 2016 </a> </div>
                                    <strong class="price"><i class="fa fa-money"></i>$30/hr - $40/hr</strong> <a href="#" class="btn-1 btn-color-3 ripple">Freelance</a> </div>
                            </li>
                        </ul>
                        <div id="loadMore"> <a href="#" class="load-more"><i class="fa fa-user"></i>Load More Jobs</a></div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-4">
                    <h2>Featured Jobs</h2>
                    <aside>
                        <div class="sidebar">
                            <div class="box">
                                <div class="thumb"> <a href="#"><img src="images/features-img-1.jpg" alt="img"></a>
                                    <div class="caption"><img src="images/envato-text.png" alt="envato"></div>
                                </div>
                                <div class="text-box"> <a href="#" class="btn-time">Part Time</a>
                                    <h4><a href="#">UI/UX Visual Designers</a></h4>
                                    <a href="#" class="text"><i class="fa fa-map-marker"></i>Moorgate, London</a> <a href="#" class="text"><i class="fa fa-calendar"></i>Dec 22, 2015 - Mar 17, 2016 </a> <strong class="price"><i class="fa fa-money"></i>$4000 - $5000</strong> <a href="#" class="btn-apply">Apply for this Job</a> </div>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </section>
    <!--RECENT JOB SECTION END-->
</div>
<!--MAIN END-->

<!--FOOTER START-->