<!--<div layout="row" *ngIf="showImg" >-->
<!--    <div    [ngStyle]="{'background-color': '#000','position': 'fixed','top': '0px','left': '0px','width': service.getWidth()+'px','height': service.getHeight()+'px'}">-->
<!--        <img    class="mdl-shadow&#45;&#45;2dp"-->
<!--                src="{{this.picUrl}}" [ngStyle]="{'width':'100%', 'height':'auto'}"/>-->
<!--    </div>-->

<!--    <div [ngStyle]="{'width': service.getWidth()+'px','position': 'fixed','left': '0px','bottom': '5px'}">-->
<!--        <center>-->
<!--            <table [ngStyle]="{'width':'100%'}">-->
<!--                <tr [ngStyle]="{'width':'100%'}">-->
<!--                    <td>-->
<!--    &lt;!&ndash;               <a mat-mini-fabx&ndash;&gt;-->
<!--    &lt;!&ndash;                       class="defaultColorr">&ndash;&gt;-->
<!--    &lt;!&ndash;                    &lt;!&ndash;<i class="material-icons">crop_free</i>&ndash;&gt;&ndash;&gt;-->
<!--    &lt;!&ndash;                        <i class="material-icons cursor"&ndash;&gt;-->
<!--    &lt;!&ndash;                           [ngStyle]="{'color':'#fff','font-size':'42px'}"&ndash;&gt;-->
<!--    &lt;!&ndash;                           (click)="sendCapturedCameraImg()">&ndash;&gt;-->
<!--    &lt;!&ndash;                            adjust&ndash;&gt;-->
<!--    &lt;!&ndash;                        </i>&ndash;&gt;-->
<!--    &lt;!&ndash;                </a>&ndash;&gt;-->
<!--                        <a class="btn btn-block defaultColor2 sawasdee15"-->
<!--                           (click)="sendCapturedCameraImg(drawer)">-->
<!--                            UPload your image-->
<!--&lt;!&ndash;                        upload your image to {{service.upperStartingCharacter(incomingForm.getName(),true).toLowerCase()}}, historical photo album&ndash;&gt;-->
<!--                        </a>-->
<!--                    </td>-->
<!--                </tr>-->
<!--                <tr [ngStyle]="{'width':'100%'}">-->
<!--                    <td>-->
<!--&lt;!&ndash;                        <a mat-mini-fabx&ndash;&gt;-->
<!--&lt;!&ndash;                           class="defaultColorr">&ndash;&gt;-->
<!--&lt;!&ndash;                            <i class="material-icons cursor"&ndash;&gt;-->
<!--&lt;!&ndash;                               [ngStyle]="{'color':'#fff','font-size':'42px'}"&ndash;&gt;-->
<!--&lt;!&ndash;                               (click)="cancelCaptureCameraImg()">close</i>&ndash;&gt;-->
<!--&lt;!&ndash;                        </a>&ndash;&gt;-->
<!--                        <a class="btn btn-block defaultColor2 sawasdee16"-->
<!--                           (click)="cancelCaptureCameraImg()">-->
<!--                            cancel-->
<!--                        </a>-->
<!--                    </td>-->
<!--                </tr>-->
<!--            </table>-->
<!--        </center>-->
<!--    </div>-->
<!--</div>-->
<!--<div *ngIf="!showImg">-->
<!--    <mat-drawer-container-->
<!--            class="example-container"-->
<!--            [ngStyle]="{'position':'fixed','top':'0px','left':'0px','width':service.getWidth()+'px','height':service.getHeight()+'px'}">-->
<!--        <mat-drawer #drawer-->
<!--                    class="example-sidenav"-->
<!--                    mode="push"-->
<!--                    [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()+'px'}">-->
<!--            <div class="cursor"-->
<!--                 *ngIf="this.responseService.broadCastedResponses.length > 0"-->
<!--                 (click)="addBroadCastedResponses()"-->
<!--                 [ngStyle]="{'z-index': '9999999','position': 'absolute','top':'10px','right':'20px','padding':'20fpx'}"-->
<!--                 >-->
<!--                <div matBadge="{{this.responseService.broadCastedResponses.length}}"-->
<!--                     matBadgeColor="warn"-->
<!--                     matBadgeOverlap="false"-->
<!--                     class="cursor">-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="cursor"-->
<!--                 [ngStyle]="{'z-index': '9999999','position': 'absolute','top':'5px','left':'20px','padding':'20px'}">-->
<!--                <ul class="list-inline" [ngStyle]="{'color':'#fff'}">-->
<!--                    <li (click)="drawer.toggle()">-->
<!--                        <i class="material-icons"-->
<!--                           [ngStyle]="{'color':'#fff'}">-->
<!--                            arrow_back_ios-->
<!--                        </i>-->
<!--                        Back-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div class="cursor"-->
<!--                 [ngStyle]="{'z-index': '9999999','position': 'absolute','top':'40%','left':'45%','padding':'20px'}">-->
<!--                <a mat-fab-->
<!--                   (click)="drawer.toggle()"-->
<!--                   class="defaultColor2">-->
<!--                    <i class="material-icons"-->
<!--                       [ngStyle]="{'color':'#000'}"-->
<!--                    >linked_camera</i>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="cursor"-->
<!--                 [ngStyle]="{'z-index': '9999999','position': 'absolute','top':'5px','right':'20px','padding':'20px'}">-->
<!--                <ul class="list-inline" [ngStyle]="{'color':'#fff'}">-->
<!--                    <li (click)="selectedTabIndex = 0">-->
<!--                        1-->
<!--                    </li>-->
<!--                    <li (click)="selectedTabIndex = 1">-->
<!--                        <img src="assets/images/option-icon-2.png"-->
<!--                           [ngStyle]="{'color':'#fff'}" />-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <mat-tab-group-->
<!--                    [(selectedIndex)]="selectedTabIndex"-->
<!--                    [ngStyle]="{'position': 'absolute','top':'-99px','right':'0px'}"-->
<!--                           *ngIf="incomingForm.getResponses().data.length !== 0">-->
<!--                <mat-tab>-->
<!--&lt;!&ndash;               <ng-template mat-tab-label>&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="material-icons">tune</i>&ndash;&gt;-->
<!--&lt;!&ndash;               </ng-template>&ndash;&gt;-->
<!--                    <div [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px'}">-->
<!--                        <carousel [cellsToScroll]="1"-->
<!--                                  [cellsToShow]="1"-->
<!--                                  [height]="this.service.getHeight()"-->
<!--                                  [autoplay]="true"-->
<!--                                  [arrows]="true"-->
<!--                                  [dots]="false"-->
<!--                                  [loop]="true">-->
<!--                            <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">-->
<!--                                <ng-container *ngFor="let responseResult of response.datas">-->
<!--                                    <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">-->
<!--                                        <div class="carousel-cell">-->
<!--                                            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
<!--                                                 src="{{responseResult.data}}" #img />-->
<!--                                        </div>-->
<!--                                    </ng-container>-->
<!--                                </ng-container>-->
<!--                            </ng-container>-->
<!--                        </carousel>-->
<!--                    </div>-->
<!--                </mat-tab>-->
<!--                <mat-tab>-->
<!--&lt;!&ndash;                <ng-template mat-tab-label>&ndash;&gt;-->
<!--&lt;!&ndash;                   <i class="fa fa-bars"></i>&ndash;&gt;-->
<!--&lt;!&ndash;               </ng-template>&ndash;&gt;-->
<!--                    <div>-->
<!--                        <div [ngStyle]="{'width':this.service.getWidth()+'px'}">-->
<!--                            <mat-grid-list cols="{{service.getWidth()/175}}" rowHeight="1:1">-->
<!--                                <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">-->
<!--                                    <ng-container *ngFor="let responseResult of response.datas">-->
<!--                                        <mat-grid-tile-->
<!--                                                *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">-->
<!--                                            <div class="cursor demo-card-image mdl-card mdl-shadow&#45;&#45;2dp"-->
<!--                                                 (contextmenu)="incomingResponseResultSelected.setAttributes(responseResult);onContextMenu($event, responseResult)"-->
<!--                                                 style="background:url({{responseResult.data}}) center / cover"-->
<!--                                                 (click)="incomingResponseResultSelected.setAttributes(responseResult);-->
<!--                                                   view(responseResult)">-->
<!--                                            </div>-->
<!--                                        </mat-grid-tile>-->
<!--                                    </ng-container>-->
<!--                                </ng-container>-->
<!--                            </mat-grid-list>-->
<!--                        </div>-->
<!--                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="hasOldImg">-->
<!--                            <a  class=""-->
<!--                                (click)="getOldImg()"-->
<!--                                mat-mini-fab-->
<!--                                [ngStyle]="{'background': '#fff','color':'#000'}">-->
<!--                                <i class="fa fa-chevron-down"></i>-->
<!--                            </a>-->
<!--                            <br><br><br>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </mat-tab>-->
<!--            </mat-tab-group>-->
<!--        </mat-drawer>-->
<!--        <div class="example-sidenav-content" [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()+'px','background':'#000'}">-->
<!--    &lt;!&ndash;    <div [ngStyle]="{'background':'#000','width':service.getWidth()+'px','height':service.getHeight()+'px'}">&ndash;&gt;-->
<!--    &lt;!&ndash;            <video id="video" [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()+'px'}"></video>&ndash;&gt;-->
<!--    &lt;!&ndash;            <canvas id="canvas"></canvas>&ndash;&gt;-->
<!--    &lt;!&ndash;    </div>&ndash;&gt;-->
<!--            <webcam *ngIf="showWebcam"-->
<!--                    (imageCapture)="handleImage($event)"-->
<!--                    [height]="service.getHeight()"-->
<!--                    [width]="service.getWidth()"-->
<!--                    [trigger]="triggerObservable"-->
<!--                    [allowCameraSwitch]="allowCameraSwitch"-->
<!--                    [switchCamera]="nextWebcamObservable"-->
<!--                    [videoOptions]="videoOptions"-->
<!--                    [imageQuality]="1"-->
<!--                    (cameraSwitched)="cameraWasSwitched($event)"-->
<!--                    (initError)="handleInitError($event)"-->
<!--            ></webcam>-->

<!--            <div [ngStyle]="{'z-index':'9999999','position':'absolute','top':'20px','right':'20px'}"-->
<!--                 *ngIf="isCameraOpened">-->
<!--                <img (click)="drawer.toggle()"-->
<!--                     #imgDrawer-->
<!--                     id="imgDrawer"-->
<!--                     class="fa fa-bars cursor"-->
<!--                     src="assets/images/menu-icon-2.png"-->
<!--                     [ngStyle]="{'color':'#fff','font-size':'32px'}" />-->
<!--            &lt;!&ndash;     view_comfy&ndash;&gt;-->
<!--            </div>-->

<!--            <div [ngStyle]="{'z-index':'9999999','position':'absolute','bottom':'5px','left':'0px','width':service.getWidth()+'px'}"-->
<!--                 *ngIf="isCameraOpened">-->
<!--                <center>-->
<!--                    <table>-->
<!--                        <td class="cursor">-->
<!--                            <i (click)="triggerSnapshot()"-->
<!--                               class="material-icons cursor"-->
<!--                               [ngStyle]="{'color':'#fff','font-size':'62px'}">motion_photos_on</i>-->
<!--                        </td>-->
<!--                        &lt;!&ndash;            <td  class="cursor"&ndash;&gt;-->
<!--                        &lt;!&ndash;                (click)="switchFlash(true)">&ndash;&gt;-->
<!--                        &lt;!&ndash;                <i class="material-icons"&ndash;&gt;-->
<!--                        &lt;!&ndash;                   [ngStyle]="{'color':'#fff','font-size':'62px'}">flash_on</i>&ndash;&gt;-->
<!--                        &lt;!&ndash;            </td>&ndash;&gt;-->
<!--                        &lt;!&ndash;            <td class="cursor"&ndash;&gt;-->
<!--                        &lt;!&ndash;                (click)="switchFlash(false)">&ndash;&gt;-->
<!--                        &lt;!&ndash;                <i class="material-icons"&ndash;&gt;-->
<!--                        &lt;!&ndash;                   [ngStyle]="{'color':'#fff','font-size':'62px'}">flash_off</i>&ndash;&gt;-->
<!--                        &lt;!&ndash;            </td>&ndash;&gt;-->
<!--                    </table>-->
<!--                </center>-->
<!--            </div>-->
<!--        </div>-->
<!--    </mat-drawer-container>-->
<!--    <div layout="row">-->
<!--        <div *ngIf="!isCameraOpened">-->
<!--            <ng-container *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 6;else template">-->
<!--            </ng-container>-->
<!--            <ng-template #template>-->
<!--                <ng-container>-->
<!--                    <div class="col-lg-2 col-md-2"></div>-->
<!--                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">-->
<!--                        <div>-->
<!--                            <app-label-create-control [question]="question"></app-label-create-control>-->
<!--                            <br><br>-->
<!--                        </div>-->
<!--                        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">-->
<!--                            <div class="panel-bodyy">-->
<!--                                <div>-->
<!--                                    <center>-->
<!--                                        <img (click)="image.click()"-->
<!--                                             *ngIf="!service.empty(question.pic)"-->
<!--                                             class="cursor img-thumbnail"-->
<!--                                             src="{{question.hasOwnProperty('pic') ? question.pic : 'assets/images/categories-icon-1.png'}}"-->
<!--                                             style="width: auto; max-height: 300px">-->
<!--                                        <a mat-mini-fab class="defaultColor" *ngIf="service.empty(question.pic)"><i class="material-icons" (click)="image.click()">photo_camera</i></a><br><br>-->
<!--                                    </center>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-2 col-md-2"></div>-->
<!--                </ng-container>-->
<!--            </ng-template>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<ng-container *ngIf="!isUploading">
    <div [ngStyle]="{'position': 'fixed','top':'-108px','right':'0px'}">
        <div [ngStyle]="{'width':'100%','height':(this.service.getHeight()+108)+'px','overflow-y':'scroll'}">
            <mat-tab-group [(selectedIndex)]="selectedTabIndex"
                           (click)="onClickTabGroup()"
                           (selectedTabChange)="onSelectedTabChange($event)">
                <mat-tab>
    <!--            <ng-template mat-tab-label>-->
    <!--                <i class="material-icons">tune</i>-->
    <!--            </ng-template>-->
                    <div [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px','height':(this.service.getHeight()+9)+'px','background':'url('+'assets/images/parrten-bg.png'+') repeat'}">
                        <carousel [cellsToScroll]="1"
                                  [cellsToShow]="1"
                                  [height]="this.service.getHeight()"
                                  [autopcay]="false"
                                  [pauseOnHover]="true"
                                  [arrows]="true"
                                  [dots]="false"
                                  [loop]="true"
                                  *ngIf="incomingForm.getResponses().data.length && selectedTabIndex === 0 && showCarousel">
                            <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">
                                <ng-container *ngFor="let responseResult of response.datas;let incrementalResponseResult=index">
                                    <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">
                                        <div class="carousel-cell" *ngIf="incrementalResponseResult === 0">
                                            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                                                 (error)="onErrorHandle($event)"
                                                 src="{{responseResult.data}}"
                                                 #img />
                                            <div [ngStyle]="{'position':'fixed','top':'0%','height':'100%','width':'100%','margin-top':'10px','margin-bottom':'10px','background':'url('+'assets/images/parrten-bg.png'+') repeat'}">
                                                <div class="vertical-center"
                                                     [ngStyle]="{'padding-top':'50px','padding-bottom':'50px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)'}">
                                                    <center>
                                                        <img src="{{this.service.logo}}"
                                                             class="img-circle mdl-shadow--2dp"
                                                             [ngStyle]="{'width': '60px','height':'60px','margin-bottom':'20px'}"
                                                             *ngIf="!this.service.empty(this.service.logo)"/>
                                                        <div class="sawasdee18">welcome to kisiga wedding,live photo wall</div>
                                                        <div  class="sawasdee26">
                                                            let got everything saved as memories, share your wedding photos or view existing ones
                                                        </div>
                                                        <div class="sawasdee20">
                                                            every upload is saved automatically in a kisiga wedding album and appears on TV screen
                                                        </div>
                                                        <div class="mitra"
                                                             [ngStyle]="{'margin-top': '10px'}">
                                                            <center>
<!--                                                                <div class="banner-menu">-->
<!--                                                                    <ul>-->
<!--                                                                        <li [ngStyle]="{'cursor':'pointer'}"-->
<!--                                                                            (click)="image.click()">-->
<!--                                                                            <span class="mitra26Wht">upload image</span>-->
<!--                                                                        </li>-->
<!--                                                                        <li [ngStyle]="{'cursor':'pointer'}">-->
<!--                                                                            <span class="mitra26Wht">upload video</span>-->
<!--                                                                        </li>-->
<!--                                                                        <li [ngStyle]="{'cursor':'pointer'}"-->
<!--                                                                            (click)="selectedTabIndex = 1">-->
<!--                                                                            <span class="mitra26Wht">open camera</span>-->
<!--                                                                        </li>-->
<!--                                                                        <li [ngStyle]="{'cursor':'pointer'}"-->
<!--                                                                            (click)="selectedTabIndex = 3">-->
<!--                                                                            <span class="mitra26Wht">view album</span>-->
<!--                                                                        </li>-->
<!--                                                                    </ul>-->
<!--                                                                </div>-->
                                                                <div class="btn ovalButtonBlackground"
                                                                     (click)="image.click()">
                                                                    upload image
                                                                </div>
<!--                                                                <div class="btn ovalButton">-->
<!--                                                                    upload video-->
<!--                                                                </div>-->
                                                                <div class="btn ovalButtonBlackground"
                                                                     (click)="selectedTabIndex = 1">
                                                                    open camera
                                                                </div>
                                                                <div class="btn ovalButtonBlackground"
                                                                     (click)="selectedTabIndex = 3">
                                                                    view <span class="sawasdee14">al</span>bum
<!--                                                                {{service.upperStartingCharacter(incomingForm.getName(),true).toLowerCase()}}-->
                                                                </div>
                                                            </center>
                                                        </div>

                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </carousel>
                    </div>

                    <!--                    <div [ngStyle]="{'background':'url('+this.service.logo+') center / cover','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}">-->
<!--                        <center>-->
<!--                            <img src="{{this.service.logo}}"-->
<!--                                 class="img-circle mdl-shadow&#45;&#45;2dp"-->
<!--                                 [ngStyle]="{'width': '60px','height':'60px'}"/>-->
<!--                            <div class="sawasdee16BoldX4">{{service.upperStartingCharacter(incomingForm.getName(),true).toLowerCase()}}</div>-->
<!--                        </center>-->
<!--                        <section class="post-section blog-post"-->
<!--                                 [ngStyle]="{'background-color': 'transparent'}">-->
<!--                            <div class="container">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"-->
<!--                                         (click)="image.click()">-->
<!--                                        <div class="post-box">-->
<!--                                            <div class="text-box sawasdee16BoldX4">-->
<!--                                                <h4>-->
<!--                                                    <a>upload image</a>-->
<!--                                                </h4>-->
<!--                                                <p></p>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor">-->
<!--                                        <div class="post-box">-->
<!--                                            <div class="text-box sawasdee16BoldX4">-->
<!--                                                <h4>-->
<!--                                                    <a>upload video</a>-->
<!--                                                </h4>-->
<!--                                                <p></p>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"-->
<!--                                         (click)="selectedTabIndex = 1">-->
<!--                                        <div class="post-box">-->
<!--                                            <div class="text-box sawasdee16BoldX4">-->
<!--                                                <h4>-->
<!--                                                    <a>open camera and capture your image</a>-->
<!--                                                </h4>-->
<!--                                                <p></p>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"-->
<!--                                         (click)="selectedTabIndex = 3">-->
<!--                                        <div class="post-box">-->
<!--                                            <div class="text-box sawasdee16BoldX4">-->
<!--                                                <h4>-->
<!--                                                    <a>view posted images</a>-->
<!--                                                </h4>-->
<!--                                                <p></p>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor">-->
<!--                                        <div class="post-box">-->
<!--                                            <div class="text-box sawasdee16BoldX4">-->
<!--                                                <h4>-->
<!--                                                    <a>view posted video</a>-->
<!--                                                </h4>-->
<!--                                                <p></p>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </section>-->
<!--                    </div>-->
                </mat-tab>
                <mat-tab>
                    <!--            <ng-template mat-tab-label>-->
                    <!--                <i class="material-icons">tune</i>-->
                    <!--            </ng-template>-->
                    <div  [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px','height': this.service.getHeight()+'px'}">
                        <webcam
                                *ngIf="selectedTabIndex === 1"
                                (imageCapture)="handleImage($event)"
                                [height]="service.getHeight()"
                                [width]="service.getWidth()"
                                [trigger]="triggerObservable"
                                [allowCameraSwitch]="allowCameraSwitch"
                                [switchCamera]="nextWebcamObservable"
                                [videoOptions]="videoOptions"
                                [imageQuality]="1"
                                (cameraSwitched)="cameraWasSwitched($event)"
                                (initError)="handleInitError($event)"></webcam>
                        <div [ngStyle]="{'z-index':'9999999','position':'fixed','bottom':'5px','left':'0px','width':service.getWidth()+'px'}">
                            <center>
                                <ul class="list-inline">
                                    <li class="cursor">
                                        <i (click)="triggerSnapshot()"
                                           class="material-icons cursor"
                                           [ngStyle]="{'color':'#fff','font-size':'62px'}">motion_photos_on</i>
                                    </li>
                                </ul>
                            </center>
                        </div>
                        <div [ngStyle]="{'z-index':'9999999','position':'fixed','top':'5px','right':'20px'}">
                            <ul class="list-inline">
                                <li (click)="showNextWebcam(true)">
                                    <i class="material-icons cursor"
                                       [ngStyle]="{'color':'#fff'}">flip_camera_android</i>
                                </li>
                                <li class="cursor" (click)="selectedTabIndex = 3">
<!--                                <img src="assets/images/menu-icon-2.png"-->
<!--                                         [ngStyle]="{'color':'#fff'}" />-->
                                    <i class="material-icons cursor"
                                       [ngStyle]="{'color':'#fff'}">close</i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <!--            <ng-template mat-tab-label>-->
                    <!--                <i class="material-icons">tune</i>-->
                    <!--            </ng-template>-->
                    <div>
                        <div [ngStyle]="{'background-color': '#000','position': 'fixed','top': '0px','left': '0px','width': service.getWidth()+'px','height': service.getHeight()+'px'}">
                            <img  class="mdl-shadow--2dp"
                                  src="{{this.picUrl}}" [ngStyle]="{'width':'100%', 'height':'auto'}"/>
                        </div>

                        <div [ngStyle]="{'background-color':'rgba(30, 18, 18, 0.67)','width': service.getWidth()+'px','position': 'fixed','right': '0px','top': '40%','padding-top': '40px','padding-bottom': '40px'}">
                            <center>
<!--                                <section class="post-section blog-post"-->
<!--                                         [ngStyle]="{'background-color': 'transparent'}">-->
<!--                                    <div class="container">-->
<!--                                        <div class="row">-->
<!--                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"-->
<!--                                                 (click)="sendCapturedCameraImg(drawer)">-->
<!--                                                <div class="post-box">-->
<!--                                                    <div class="text-box sawasdee16BoldX4">-->
<!--                                                        <h4>-->
<!--                                                            <a>Upload your image</a>-->
<!--                                                        </h4>-->
<!--                                                        <p></p>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"-->
<!--                                                 (click)="cancelCaptureCameraImg()">-->
<!--                                                <div class="post-box">-->
<!--                                                    <div class="text-box sawasdee16BoldX4">-->
<!--                                                        <h4>-->
<!--                                                            <a>Cancel</a>-->
<!--                                                        </h4>-->
<!--                                                        <p></p>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </section>-->
                                    <table [ngStyle]="{'width':'100%'}">
                                        <tr [ngStyle]="{'width':'100%'}">
                                            <td class="mitra18" [ngStyle]="{'width':'100%'}">
                                                <center>
                                                    <div class="btn ovalButtonBlackground"
                                                         (click)="sendCapturedCameraImg(drawer)">
                                                        upload image
                                                    </div>
                                                    <div class="btn ovalButtonBlackground"
                                                         (click)="cancelCaptureCameraImg()"
                                                         [ngStyle]="{'width':'100%'}">
                                                        cancel
                                                    </div>
                                                </center>
                                            </td>
                                        </tr>
<!--                                        <tr [ngStyle]="{'width':'100%'}">-->
<!--                                            <td>-->
<!--                                                <a class="btn btn-block defaultColor2 mitra20"-->
<!--                                                   (click)="sendCapturedCameraImg(drawer)">-->
<!--                                                    upload your image-->
<!--                                                </a>-->
<!--                                            </td>-->
<!--                                        </tr>-->
<!--                                        <tr [ngStyle]="{'width':'100%'}">-->
<!--                                            <td>-->
<!--                                                <a class="btn btn-block defaultColor2 mitra20"-->
<!--                                                   (click)="cancelCaptureCameraImg()">-->
<!--                                                    cancel-->
<!--                                                </a>-->
<!--                                            </td>-->
<!--                                        </tr>-->
                                    </table>
                            </center>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <!--            <ng-template mat-tab-label>-->
                    <!--                <i class="material-icons">tune</i>-->
                    <!--            </ng-template>-->
                    <div [ngStyle]="{'position': 'fixed','top':'-97px','right':'0px'}">
                        <div [ngStyle]="{'width':'100%','height': (this.service.getHeight()+97)+'px','overflow-y':'scroll'}">
                            <mat-tab-group [(selectedIndex)]="selectedTabIndex2">
                                <mat-tab>
                                    <div [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px'}"
                                         *ngIf="incomingForm.getResponses().data.length !== 0">
                                        <carousel [cellsToScroll]="1"
                                                  [cellsToShow]="1"
                                                  [height]="this.service.getHeight()"
                                                  [autoplay]="false"
                                                  [arrows]="true"
                                                  [dots]="false"
                                                  [loop]="true">
                                            <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">
                                                <ng-container *ngFor="let responseResult of response.datas; let incrementalResponseResult =index">
                                                    <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">
                                                        <div class="carousel-cell" *ngIf="incrementalResponseResult === 0">
                                                            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                                                                 src="{{responseResult.data}}" #img />
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </carousel>
                                    </div>
                                </mat-tab>
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <img src="assets/images/option-icon.png"
                                             [ngStyle]="{'color':'#fff'}" />
                                    </ng-template>
                                    <div [ngStyle]="{'background-color': '#000','width':this.service.getWidth()+'px'}">
                                        <mat-grid-list cols="{{service.getWidth()/175}}" rowHeight="1:1">
                                            <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">
                                                <ng-container *ngFor="let responseResult of response.datas;let incrementalResponseResult=index">
                                                    <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">
                                                        <mat-grid-tile *ngIf="incrementalResponseResult === 0">
                                                            <div class="cursor demo-card-image mdl-card mdl-shadow--2dp"
                                                                 (contextmenu)="incomingResponseResultSelected.setAttributes(responseResult);onContextMenu($event, responseResult)"
                                                                 style="background:url({{responseResult.data}}) center / cover"
                                                                 (click)="incomingResponseResultSelected.setAttributes(responseResult);view(responseResult)">
                                                            </div>
                                                        </mat-grid-tile>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </mat-grid-list>
                                        <div class="cursor"
                                             [ngStyle]="{'z-index': '9999999','position': 'fixed','top':'5px','left':'5px'}">
                                            <div [ngStyle]="{'padding':'0px'}">
                                                <ul class="list-inline" [ngStyle]="{'color':'#fff'}">
                                                    <li (click)="selectedTabIndex = 0">
                                                        <img src="assets/images/menu-icon-2.png"
                                                             [ngStyle]="{'color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)'}" />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="cursor"
                                             [ngStyle]="{'z-index': '9999999','position': 'fixed','top':'5px','right':'5px'}">
                                            <div [ngStyle]="{'padding':'0px'}">
                                                <ul class="list-inline" [ngStyle]="{'color':'#fff'}">
                                                    <li>
                                                        <div class="mitra"
                                                             [ngStyle]="{'margin-top': '0px'}">
                                                            <center>
                                                                <div class="btn ovalButtonBlackground2"
                                                                     (click)="image.click()">
                                                                    upload image
                                                                </div>
<!--                                                            <div class="btn ovalButtonBlackground">-->
<!--                                                                    upload video-->
<!--                                                            </div>-->
                                                                <div class="btn ovalButtonBlackground2"
                                                                     (click)="selectedTabIndex = 1">
                                                                    open camera
                                                                </div>
                                                            </center>
                                                        </div>
                                                    </li>
<!--                                                    <li (click)="selectedTabIndex2 === 1 ? selectedTabIndex2 = 0 : selectedTabIndex2 = 1;">-->
<!--                                                        <img src="assets/images/option-icon-2.png"-->
<!--                                                             [ngStyle]="{'color':'#fff'}" />-->
<!--                                                    </li>-->
                                                </ul>
                                            </div>
                                        </div>
                                        <a  class=""
                                            *ngIf="this.incomingForm.getResponses().data.length !== 0"
                                            (click)="getOldImg()"
                                            mat-mini-fab
                                            [ngStyle]="{'position': 'fixed','z-index': '9999999','bottom':'20px','left':'47%','background': '#fff','color':'#000'}">
                                            <i class="fa fa-chevron-down"></i>
                                        </a>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isUploading">
    <ng-container *ngIf="!service.empty(service.logo)">
        <img  class="logo img-circle"
              src="{{service.logo}}"
              alt=""/>
    </ng-container>
</ng-container>

    <input type="file"
           (change)="getImage($event)"
           [ngStyle]="{'display':'none'}"
           accept="image/*"
           #image>

    <div style="visibility: hidden; position: fixed"
         [style.left]="contextMenuPosition.x"
         [style.top]="contextMenuPosition.y"
         [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="inspector">
            <button mat-menu-item (click)="downloadImg()">Download</button>
        </ng-template>
    </mat-menu>

