import { Component, OnInit,AfterViewInit, Inject, ViewChild } from '@angular/core';
import {AppService} from '../../../app.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../../parameters';
import * as $ from 'jquery';
import {CarouselComponent} from 'angular-responsive-carousel';
@Component({
  selector: 'app-dialog-view-image',
  templateUrl: './dialog-view-image.component.html',
  styleUrls: ['./dialog-view-image.component.scss']
})
export class DialogViewImageComponent implements OnInit,AfterViewInit {
  @ViewChild("myCarousel") myCarousel : CarouselComponent;
  incomingResponseResult: any;
  showCarousel: any = false;
  selectedImgIndex = 0;
  nextSlide: any = null;
  responses: any = [];
  responses2: any = [];
  incomingResponse: any;
  position: any = 0;
  incremental: any = 0;
  selectedTabIndex : any = 0;
  carouselRef : any = 0;
  showImg = true;
  width: any = 0;
  height: any = 0;
  constructor(
    public  service: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogViewImageComponent>,) {
    this.incomingResponseResult = new Parameters();
    this.incomingResponse = new Parameters();
    this.incomingResponseResult.setAttributes(this.data.data);
  }

  ngOnInit(): void {
//  document.getElementsByTagName( 'body')[0].style.backgroundColor = '#000';
    setTimeout(()=>{
      this.showCarousel = true;
      if(this.data.hasOwnProperty('responses'))
       this.selectedTabIndex = this.service.findIntegerIndex(this.data.responses,'id',this.incomingResponseResult.getPostId());
       this.getCarouselArrowNext(()=>{
        this.moveUntilSelectedImg();
       });
    },100);
  }


  moveUntilSelectedImg(){
   this.showImg=false;
   this.incremental=this.incremental+1;
   setTimeout(()=>{
    if(this.selectedTabIndex >= this.incremental){
     this.nextSlide.click();
     this.moveUntilSelectedImg();
    }else this.showImg=true;
   },100);
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
       const matDialogContainers: any = document.getElementsByClassName( 'mat-dialog-container');
       for (var i = 0; i < matDialogContainers.length; i++) {
         const matDialogContainer: any = matDialogContainers[i];
         if (matDialogContainer instanceof HTMLElement) {
           matDialogContainer.style.backgroundColor = '#000';
           matDialogContainer.style.padding = '0px';
        }
       }
    });
  }

  getCarouselArrowNext(callback: any){
     setTimeout(()=>{
         try{
             const nextSlides = document.getElementsByClassName('carousel-arrow-next');
             for (var i = 0; i < nextSlides.length; i++) {
               this.nextSlide = nextSlides[i];
               if (this.nextSlide instanceof HTMLElement) {}
             }
         }catch(e){}
         if(this.nextSlide===null)this.getCarouselArrowNext(callback);
         callback(null);
     });
  }

}
