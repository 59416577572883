import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AppService} from "../app.service";
import {Parameters} from '../parameters';
@Injectable({
  providedIn: 'root'
})
export class ItemService {
  selected: any = {};
  items: any = {data:[]};
  outgoingRequest: any;
  constructor(
     private service: AppService,
     private dialog: MatDialog) {
     this.outgoingRequest = new Parameters();
  }

  getItemByItem(item_: any){
      var items: any = [] = this.service.app.data.items.data.filter((item: any) => {
        return parseFloat(item.id) === parseFloat(item_.id); });
      return items.length === 0 ? this.addItem(item_) : items[0];
  }

  getSelected(){
   return this.selected;
  }

  setSelected(item: any){
   this.selected  = item;
  }

  addItem(item: any){
      this.service.app.data.items.data.unshift(item);
      return item;
  }

  hasItemByCntrlNum(cntrlNum: any){
        var items: any = [] = this.service.app.data.items.data.filter((item: any) => {
          return item.cntrl_num === cntrlNum;});
        return items.length === 0 ? {status: 400} : {status: 200, item: items[0]};
  }

  getItemById(itemId: any){
      var items: any = [] = this.service.app.data.items.data.filter((item: any) => {
        return parseFloat(item.id) === parseFloat(itemId);});
      return items[0];
  }

  openDialog(item: any){
//       this.dialog.open(
//           DialogUserComponent, {
//             data  : {user: user},
//             width : '100%',
//             height: '100%',
//             disableClose: false,
//           },
//       );
  }

  search(searchText: any, callback: any){
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setSearchText(searchText);
      this.service.httpService('post', this.service.app.routes.items.search, this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
          (response: any) => {
            callback(response);
            setTimeout((result: any) =>{
              response.map((item: any) => {
                result = [];
                result = this.service.app.data.items.data.filter((itm: any) => {
                  return parseFloat(item.id) === parseFloat(itm.id);
                });if(result.length === 0){
                  this.service.app.data.items.data.push(item);
                }
              });
            });
          }, (error: any) => {
          });
  }
}
