import {Component, OnDestroy, OnInit, AfterViewInit} from '@angular/core';
import {AppComponent} from '../app.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../app.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
    splits: any;
    appsettingsUrl = '../assets/json/app.json';
    logoDisplayed: any = '';
    isloadingComplete: any = false;
    appRootImg: any;
    constructor(public appComponent: AppComponent,
                private router: Router,
                public service: AppService,
                private activatedRoute: ActivatedRoute){
                this.appRootImg = document.getElementById( 'appRootImg');
                this.appRootImg.style.display = "none";
                this.onComponentStart();
    }
    ngOnInit(): void {

    }

    ngOnDestroy(): void {
     this.service.waitStatus = null;
    }

    onErrorHandle(event: any){
    }

    doSomething(event: any){
     console.log(event);
     this.isloadingComplete = true;
    }

    onComponentStart(){
          this.splits = this.service.getNavigatedUrl().toString().split('?');
          this.appsettingsUrl = this.appsettingsUrl + '?version=' + this.service.random();
          this.logoDisplayed = this.service.empty(this.service.logo) ? '../../assets/images/coat.png' : this.service.logo;
          $('#mainStyle').attr('href','assets/css/custom.css?version=' + this.service.random());
          var pass: any  = null;
          if(!this.service.getNavigatedUrl().toString().includes('/feedback') &&
           !(this.service.getNavigatedUrl().toString().includes('/customers') && this.service.getNavigatedUrl().toString().includes('/orders')) &&
           !(this.service.getNavigatedUrl().toString().includes('/invitations') && this.service.getNavigatedUrl().toString().includes('/confirmation'))){
           document.getElementsByTagName( 'body')[0].style.backgroundColor = '#263238';
          }else{
            if(this.splits.length !== 1){
                  this.splits = this.splits[1].toString().split('&');
                  this.splits.map((split: any) => {
                       if(split.toString().toLowerCase().includes('extension=')){
                         document.getElementsByTagName( 'body')[0].style.backgroundColor =
                         split.toString().split('=')[1];
                         this.service.ngxLoadingBarColor = '#fff';
                         pass = undefined;
                       }else if(split.toString().toLowerCase().includes('access=')){
                           this.service.logo = 'logo';
                           pass = undefined;
                           if(!this.service.app.hasOwnProperty('settings')){
                               this.service.logo = this.service.clientImgUrl() + split.toString().split('=')[1];
                               this.getSettings((result: any) => {
                                  this.service.logo = this.service.getClientImgPart()  + split.toString().split('=')[1];});
                           }else this.service.logo = this.service.getClientImgPart()  + split.toString().split('=')[1];
                       }
                  });
            }
            if(pass = null){
              document.getElementsByTagName( 'body')[0].style.backgroundColor = '#04acec';
              this.service.ngxLoadingBarColor = '#fff';
              this.service.logo = this.service.getClientImgPart()  + '642ac4fe6f7a719';
            }
          }
    }


    ngAfterViewInit(){
    }



    getSettings(callback: any){
         this.service.httpService( 'get', this.appsettingsUrl, {}, {},
          (response: any) => {
              this.service.app = Object.assign(this.service.app, {settings: response});
              callback(null);
          },  (error: any) => {
          });
    }

}
