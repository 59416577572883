import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy, ViewChild} from '@angular/core';
import {FormService} from '../../../form.service';
import {AppService} from '../../../../app.service';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {Parameters} from "../../../../parameters";
import {StorageMap} from "@ngx-pwa/local-storage";
import {MatMenuTrigger} from "@angular/material/menu";
import {ResponseService} from '../../../responses/data/response.service';


@Component({
  selector: 'app-choice-preview-controls',
  templateUrl: './choice-preview-controls.component.html',
  styleUrls: ['./choice-preview-controls.component.scss']
})
export class ChoicePreviewControlsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() question: any;
  @Input() position: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  incomingSelectedOption: any;
  voiceSelectInterval: any;
  incomingResults: any;
  data: any;
  questions: any;
  form: any;
  label: any;
  incomingQuestion: any;
  incomingOption: any;
  incomingItem: any;
  incomingResult: any;
  incomingForm: any;
  incomingFormSettings: any;
  voiceSelect: any;
  isIntervalCleaned: any = false;
  incomingQuestionSettings: any;
  constructor(
      public service: AppService,
      public broadcastChannel: BroadcastChannelService,
      private responseService: ResponseService,
      private storage: StorageMap,
      public formService: FormService) {
    this.contextMenu = MatMenuTrigger;
    this.incomingQuestion = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingSelectedOption = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingItem = new Parameters();
    this.incomingResults = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingQuestionSettings = new Parameters();
    this.form = formService.selected;
    this.questions = this.form.controls;
    this.incomingQuestion.setAttributes(this.question);
  }

  ngOnInit(): void {
  }

  ngStart(){
    this.incomingQuestion.setAttributes(this.question);
  }

  ngOnChanges(changes: SimpleChanges): void {
   this.incomingForm.setAttributes(this.formService.getSelected());
   this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
   this.incomingFormSettings.setUnknown('lfd', this.incomingFormSettings.getAttributes().hasOwnProperty('lfd') ? this.incomingFormSettings.getUnknown('lfd') : 0);
   this.incomingQuestion.setAttributes(this.question);
   this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
   this.incomingQuestionSettings.setIsMenu(this.incomingQuestionSettings.getAttributes().hasOwnProperty('is_menu') ? this.incomingQuestionSettings.getIsMenu() : 0);
   if(parseFloat(this.incomingQuestionSettings.getIsMenu()) === 1)
       this.broadcastChannel.emitNavChangeEvent({action : 'nextButtonHidden'});
   this.incomingQuestion.setResult(this.incomingQuestion.getAttributes().hasOwnProperty('result') ?
       Object.assign({}, this.incomingQuestion.getResult()) : {});
   this.ngStart();
   if(window.location.href.toString().includes('/feedback'))
     if(this.service.empty(this.incomingQuestion.getData()))
       this.broadcastChannel.emitNavChangeEvent({action : 'hideNextButton'});
   if(this.incomingQuestion.getAttributes().hasOwnProperty('flag')){
    if(parseFloat(this.incomingQuestion.getFlag()) === 3){
     this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
     this.incomingQuestion.setFlag(0);
    }
   }
   if(parseFloat(this.incomingForm.getCategoryId()) ===5) {
      setTimeout(() => {
              this.responseService.film.pause();
              this.playSoundSelectSwahiliVersion(() => {
               this.responseService.film.play();
              });
      },this.service.timeout()*5);
   }
  }

  ngOnDestroy(): void {
   if(parseFloat(this.incomingForm.getCategoryId()) ===5){
       try {
           this.voiceSelect.pause();
           clearInterval(this.voiceSelectInterval);
       }catch (e) {
       }

   }
  }

  isChecked(element: any, optional: any){
    let l: any = false;
    this.incomingQuestion.setAttributes(this.question);
    if(!this.service.empty(this.incomingQuestion.getUnknown('data')) &&
        !this.service.empty(optional.label) &&
        this.incomingQuestion.getUnknown('data').toString() === optional.label.toString())
      l = true;
    return l;
  }

  playSoundSelectSwahiliVersion(callback: any){
     this.voiceSelect = new Audio('../assets/audio/selectSwahiliVersion.mp3');
     this.voiceSelect.play();
     this.voiceSelect.onended = () => {
         callback(null);
     };
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
    if(!this.incomingSelectedOption.getAttributes().hasOwnProperty('questionCntrlNum'))
    this.contextMenu.closeMenu();
  }

  removeOption(option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingSelectedOption.getAttributes().hasOwnProperty('questionCntrlNum')){
        this.formService.newOptional = this.incomingSelectedOption.getAttributes();
        this.incomingQuestion.getOptions().splice(this.service.findIndex(this.incomingQuestion.getOptions(), 'cntrl_num', this.incomingSelectedOption.getCntrlNum()), 1);
        this.formService.removeOther();
        if(!this.service.empty(this.incomingQuestion.getData())){
          if(this.incomingQuestion.getData().toString() === this.incomingSelectedOption.getLabel().toString())
          this.incomingQuestion.setData('');
        }
      }else this.contextMenu.closeMenu();
    });
  }

  handleUpdate(option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingSelectedOption.getAttributes().hasOwnProperty('questionCntrlNum')){
        this.label = prompt('', this.incomingSelectedOption.getLabel());
        if (this.label !== null) {
          this.incomingSelectedOption.attributes = Object.assign(this.incomingSelectedOption.attributes, {label: this.label});
          this.formService.newOptional = this.incomingSelectedOption.getAttributes();
          this.formService.updateOther(this.label);
          if(!this.service.empty(this.incomingQuestion.getData())){
            if(this.incomingQuestion.getData().toString() === this.incomingSelectedOption.getLabel().toString())
              this.incomingQuestion.setData(this.incomingSelectedOption.getLabel());
          }
        }
      }else this.contextMenu.closeMenu();
    });
  }

  addOptional(){
    this.incomingQuestion.setAttributes(this.question);
    this.label = prompt('', '');
    if (this.label !== null) {
      this.formService.addOptional(this.question.options, this.label);
      this.formService.newOptional = Object.assign(this.formService.newOptional, {questionCntrlNum: this.incomingQuestion.getCntrlNum()});
      this.formService.addOther();
    }
  }

  addOther(){this.storage.set('others', [this.formService.newOptional]).subscribe(() => {});}

  radioChange(event: any, optional){
     this.incomingItem.setAttributes(optional);
      this.question.data = optional.label;
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.getOptions().map((option: any) => {
        this.incomingOption.setAttributes(option);
        if(this.incomingOption.getLabel().toString() === this.incomingQuestion.getData()){
        }else this.formService.hideOptionalQuestions(this.incomingOption.getAttributes());
      });

      this.incomingOption.setAttributes(optional);
      this.incomingResults.setAttributes({});
      this.incomingResult.setAttributes({});
      this.incomingResult.setLabel(this.incomingOption.getLabel());
      this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
      this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
      this.incomingQuestion.setResult(Object.assign({}, this.incomingResults.getAttributes()));
      this.broadcastChannel.emitNavChangeEvent({action: 'next',  optionalId: optional.id, optionalCntrlNum: optional.cntrl_num, cntrlNum: optional.cntrl_num});
  }

  isnull(radionButton: any) {
    if(this.service.empty(this.question.data)) {
     radionButton.checked = false;
    }
  }

  hideAddOthers(){
    this.incomingQuestion.setAttributes(this.question);
    return this.service.parseFloat(this.incomingQuestion.getAddOthers()) === 0;
  }

  ngAfterViewInit(): void {
    this.incomingQuestion.setAttributes(this.question);
  }




}


