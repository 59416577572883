<mat-drawer-container class="example-container"
                      *ngIf="!progressShow"
                      [ngStyle]="{'position':'fixed','top':'0px','left':'0px','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}">
    <mat-drawer #drawer class="example-sidenav" mode="side"
                [ngStyle]="{'width':'100%','height':'100%','color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
        <app-register-invitee [placeholderFullName]="'Full name of pluse one ? '"></app-register-invitee>
    </mat-drawer>
    <div class="example-sidenav-content"
         [ngStyle]="{'width':this.service.getWidth()+'px','height':this.service.getHeight()+'px','color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
        <div class=""
             *ngIf="!progressShow">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
<!--                <center>-->
<!--                    <img class="img-circle mdl-shadow&#45;&#45;2dp"-->
<!--                         src="{{incomingClient.getUnknown('logo')}}"-->
<!--                         [ngStyle]="{'width':'60px','height':'60px','margin-bottom':'60px'}"/>-->
<!--                </center>-->
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 sawasdee20X2 cChoice"
                 [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
                <center>
                    <div  [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}"
                            [innerHTML]="!this.service.empty(this.incomingSettingsForm.getAfterAcceptLandingPageContents()) ?
                                       this.incomingSettingsForm.getAfterAcceptLandingPageContents() :
                                       this.service.upperStartingCharacter(this.service.app.strings.invitation.card.delivery,true)">
                    </div>
                    <ng-container *ngIf="showDeclinenote"><br><br>
                        <div [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
                            Please indicate below whether you will attend with a plus one.
                        </div>
                        <div class="mitra18" [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">
                            <mat-radio-group
                                    aria-labelledby="example-radio-group-label"
                                    class="example-radio-group">
                                <mat-radio-button
                                        class="example-radio-buttonn"
                                        #checked
                                        (click)="onRadioButtonChange(option)"
                                        *ngFor="let option of options"
                                        [value]="option">
                                    <a [ngStyle]="{'color': '#fff'}">{{option.label.toString()}}</a>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-container>
                </center>
            </div>
        </div>
    </div>
</mat-drawer-container>
<div>
    <div *ngIf="progressShow">
        <dv [ngStyle]="{'width':'100%'}" *ngIf="this.responseService.isConfirmed===false">
            <div ><mat-progress-bar mode="query" [ngStyle]="{'width':'100%'}"></mat-progress-bar></div>
            <div class="sawasdee20">
                {{this.service.upperStartingCharacter(this.service.app.strings.invitation.card.send, true)}}
            </div>
        </dv>
    </div>
<!--<div *ngIf="!progressShow">-->
<!--        <div class="sawasdee14" [ngStyle]="{'width':'330px'}">-->
<!--            {{this.service.app.strings.invitation.card.delivery }}-->
<!--            <i class="material-icons">done</i>-->
<!--        </div>-->
<!--</div>-->
    <div><br>
        <div  [ngStyle]="{'position':'absolute','left':'-3000px'}">
            <div class="" id="doc" >
                <div *ngIf="progressShow">
                    <div class="{{colWidth === 8 ? 'col-lg-2 col-md-2 ' : 'col-lg-0 col-md-0 '}}"></div>
                    <div class="{{colWidth === 8 ? 'col-lg-8 col-md-8 ' : 'col-lg-12 col-md-12 '}} col-sm-12 col-xs-12"
                         [ngStyle]="{'color':'#000','font-family':'Sawasdee','font-weight':'bold'}">
                        <center>
                            <div [innerHTML]="cardContents | safeUrl"></div>
                        </center>
                    </div>
                    <div class="{{colWidth === 8 ? 'col-lg-2 col-md-2 ' : 'col-lg-0 col-md-0 '}}"></div>
                </div>
            </div>
        </div>
<!--    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="!this.service.empty(cardContents)">-->
            <!--        <center><br>-->
            <!--            <a (click)="download()"-->
            <!--               style="background : {{incomingSettingsForm.getBackgroundColor()}};-->
            <!--                                                     color           : #fff;-->
            <!--                                                     font-size       : 14px;-->
            <!--                                                     border          : 0;-->
            <!--                                                     border-radius   : 4px;-->
            <!--                                                     display         : inline-block;-->
            <!--                                                     line-height     : 24px;-->
            <!--                                                     margin          : 8px 0;-->
            <!--                                                     min-height      : 20px;-->
            <!--                                                     outline         : 0;-->
            <!--                                                     cursor          : pointer;-->
            <!--                                                     outline-color   : currentcolor;-->
            <!--                                                     outline-style   : none;-->
            <!--                                                     outline-width   : 0px;-->
            <!--                                                     padding         : 8px 20px;-->
            <!--                                                     text-align      : center;-->
            <!--                                                     vertical-align  : middle;-->
            <!--                                                     white-space     : nowrap;-->
            <!--                                                     text-decoration : none;">Download</a>-->
            <!--        </center>-->
<!--    </div>-->
    </div>
</div>


<img class="img-circle mdl-shadow--2dp logo"
     *ngIf="progressShow"
     src="{{incomingClient.getUnknown('logo')}}"
     [ngStyle]="{'z-index':'9999999999999','width':'60px','height':'60px','margin-bottom':'60px'}"/>



<!--#4D6F93-->
<!--<a (click)="download()"-->
<!--   class="sawasdee20 cursor"-->
<!--   [ngStyle]="{'position': 'fixed','right': '5px','top':'-30px','color':'#fff','background-color': '#53659b','padding': '0px 15px 0px 15px','border-radius': '25px'}">-->
<!--    <br><br>Download invitation card<br>-->
<!--&lt;!&ndash;<center><i class="fa fa-chevron-down"></i></center>&ndash;&gt;-->
<!--</a>-->

<!--<mat-spinner class="progressCSS"-->
<!--             *ngIf="progressShow"-->
<!--             [diameter]="20">-->
<!--</mat-spinner>-->



