import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {FormService} from '../form.service';
import {Parameters} from '../../parameters';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
  selector: 'app-navigations-form',
  templateUrl: './navigations-form.component.html',
  styleUrls: ['./navigations-form.component.scss']
})
export class NavigationsFormComponent implements OnInit {

  lat: any = -6.8643365;
  lng: any = 39.2712821;
  app: any;
  appsettingsUrl = '../assets/json/app.json';
  origin: any = {};
  destination: any = {};
  elem: any;
  incomingSelectedForm: any;
  form: any;
  latlng: any;
  points: any = [];
  waypoints: any;
  markerOptions: any;
  renderOptions: any;
  letters: any;
  exhibitorControl = new FormControl();
  filteredExhibitorsOptions: any;
  exhibitors: any = [];
  labels: any;
  destinationPlaces: any = [];
  allPointNames: any;
  zoom: any;
  icon: any;
  searchBox: any;
  infoWindow: any = null;
  previousInfoWindow: any = null;
  currentInfoWindow: any = null;
  options = {
  //timeout: 80000,
  //maximumAge: 60000,
    enableHighAccuracy: true,
  };
  isOpen: any = false;
  img: any;
  constructor( @Inject(DOCUMENT) private document: any,
               private service: AppService,
               private formMetthods: FormService,
               private activatedRoute: ActivatedRoute,
               private router: Router) {
  }

  ngOnInit(): void {
    this.origin = {lat: this.lat, lng: this.lng};
    this.destination = {lat: this.lat, lng: this.lng};
    this.icon = {url: 'https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_my_location_48px-128.png', scaledSize: {height: 40, width: 40}};
    this.incomingSelectedForm = new Parameters();
    this.incomingSelectedForm.setAttributes(this.formMetthods.getSelected());
    this.form = this.incomingSelectedForm.attributes;
    this.elem = document.documentElement;
    this.app = this.service.app;
    this.searchBox = false;
    this.form.controls.map((point: any) => {
      this.points.push(this.getCoordinates(point));
    });

    this.img = {url: 'https://i.imgur.com/7teZKif.png', labelOrigin: { x: 25, y: 34 }, scaledSize: {height: 20, width: 20}};

    if(navigator){
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = parseFloat(pos.coords.latitude.toString());
        this.lng = parseFloat(pos.coords.longitude.toString());
      },(err: any) => {this.showError(err);}, this.options);
    }

    //
    // navigator.permissions.query({
    //  name: 'geolocation'
    // }).then((result: any) =>{
    //      if (result.state === 'granted') {
    //      } else if (result.state === 'prompt') {
    //          //navigator.geolocation.getCurrentPosition(revealPosition, positionDenied, geoSettings);
    //      } else if (result.state === 'denied') {
    //      }s
    //      // result.onchange = function() {
    //      // }
    // });
    //
    navigator.geolocation.watchPosition((pos: any) => {
      this.lat = parseFloat(pos.coords.latitude.toString());
      this.lng = parseFloat(pos.coords.longitude.toString());
    }, (err: any) => {this.showError(err);}, this.options);

    this.filteredExhibitorsOptions = this.exhibitorControl.valueChanges.pipe(
      startWith(),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filterExhibitor(name) : this.exhibitors.slice())
    );

    this.places((lat: any, lng: any) =>{
      this.lat = parseFloat(lat.toString());
      this.lng = parseFloat(lng.toString());
      if(this.destinationPlaces.length === 0){}else{
        this.destinationPlaces[0].origin.lat = parseFloat(this.lat);
        this.destinationPlaces[0].origin.lng = parseFloat(this.lng);
      }
    });

    setTimeout(() =>{
      this.zoom = 18;
    }, 1000);
  }

  onInfoWindowClose(gm: any, infoWIndow: any){
    this.searchBox = false;
  }



  handleCurrentPosition(){
    this.origin = {lat: parseFloat(this.lat), lng: parseFloat(this.lng), letter: 'HH', status: true};
    this.destination = {lat: parseFloat(this.lat), lng: parseFloat(this.lng), letter: 'HH', status: true};
    this.markerOptions = {
      origin: {
        icon: 'https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_my_location_48px-128.png',
        label: {color: 'white', text: this.origin.letter},
        draggable: false,
        infoWindow: this.infoWindowElements(this.origin.letter)
      },
      destination: {
        label: {color: 'white', text: this.destination.letter},
        draggable: false,
        icon: 'https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_my_location_48px-128.png',
        infoWindow: this.infoWindowElements(this.destination.letter)
      }
    };
    return {origin: this.origin, destination: this.destination, markerOptions: this.markerOptions, renderOptions: this.renderOptions};
  }


  onExhitorSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.destinationPlaces = [];
    // this.points.map((point: any) =>{
    //   if(point.letter.toString() < event.question.value.letter.toString()){
    //     this.destinationPlaces.push(point);
    //   }
    // });
    this.points.map((point: any, key: any) => {
      if(point.letter.toString() === event.option.value.letter.toString()){
        this.origin = {lat: parseFloat(this.lat), lng: parseFloat(this.lng), letter: '.'};
        this.destination = {lat: parseFloat(this.points[key].lat), lng: parseFloat(this.points[key].lng), letter: event.option.value.letter};
        this.renderOptions = {
          suppressMarkers: true,
          polylineOptions: {
            "strokeColor": "#1b8af3",
            "strokeWeight": 6
          },
        };
        this.markerOptions = {
          origin: {
            icon: this.img,
            label: {color: '#fff', text: 'STARTING'},
          },
          destination: {
             // label: {color: '#fff', text: this.destination.letter},
                draggable: false,
             // icon: this.img,
                infoWindow: this.infoWindowElements(this.points[key].exhibitors)
              }
        };
        this.destinationPlaces.push({origin: this.origin, destination: this.destination, markerOptions: this.markerOptions, renderOptions: this.renderOptions});
      }
    });
    this.infoWindow.close();
    this.zoom = this.zoom + 4;
    setTimeout(() =>{
      this.zoom = this.zoom - 4;
    },420);
  }

  displayFn(value: any){
    return value.name;
  }

  private _filterExhibitor(name: string) {
    const filterValue = name.toLowerCase();
    return this.exhibitors.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  searchCntrl(){}

  onClickMarker(infoWindow: any, gm: any){
    this.isOpen = false;
    if (gm.lastOpen !== null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
    this.infoWindow = gm.lastOpen;

    if(this.previousInfoWindow){
      this.currentInfoWindow = infoWindow;
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;

    setTimeout(() => {
      this.searchBox = true;
    },200);
  }

  markerOut(infoWindow: any, gm: any){
    this.isOpen = true;
    // if (gm.lastOpen !== null) {onMarkerOut
    //   gm.lastOpen.close();
    // }
    // gm.lastOpen = infoWindow;
  }

  onInfoWindowClicked(gm: any, infoWindow: any){
    alert(gm.lastOpen);
  }

  mapDblClick(infoWindow: any, gm: any){
    // setTimeout(() => {
    //   if(gm.lastOpen !== null){
    //     gm.lastOpen.close();
    //   }
    // }, 0);
  }

  onClickMap(gm: any){
    // if(this.previousInfoWindow){
    //   this.previousInfoWindow.close();
    // }
  }


  getCoordinates(point: any){
    this.letters = point.placeholder.toString().trim().split('*');
    this.latlng = this.letters[0].toString().trim().split(',');
    this.allPointNames = [];
    point.label.toString().trim().split(',').map((exhibitor: any) => {
      this.exhibitors.push({name : exhibitor.toString().toUpperCase(), letter: this.letters[1]});
      this.allPointNames.push({name : exhibitor.toString().toUpperCase(), letter: this.letters[1]});
    });
    // this.renderOptions = {
    //   suppressMarkers: true,
    //   polylineOptions: {
    //     "strokeColor": "#FFA02F",
    //     "strokeWeight": 6
    //   },
    // };
    // this.markerOptions = {
    //   origin: {
    //     // icon: 'https://i.imgur.com/7teZKif.png',
    //     label: {color: 'white', text: this.origin.letter},
    //     draggable: false,
    //     infoWindow: this.infoWIndow(this.origin.letter)
    //   },
    //   destination: {
    //     label: {color: 'white', text: this.destination.letter},
    //     draggable: false,
    //     // icon: 'https://i.imgur.com/7teZKif.png',
    //     infoWindow: this.infoWIndow(this.destination.letter)
    //   }
    // };
    return {lat: parseFloat(this.latlng[0]), lng: parseFloat(this.latlng[1]), exhibitors: this.allPointNames, letter: this.letters[1]};
  }

  onMapReady(map: any){
    const options: any = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon']
      },
      polygonOptions: {
        draggable: true,
        editable : true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };
    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);
  }

  infoWindowElements(names: any){
    let all: any = null;
    names.map((point: any) =>{
        all = (all === null) ? '<h1>' + point.name + '</h1>' : all + '<br><hr>' + '<h1>' + point.name + '</h1>';
    });
    return all;
  }

  setRenderOptions(){
    const renderOptions: any = {
      suppressMarkers: true,
      polylineOptions: {
         strokeColor : '#000',
         strokeWeight: 6
      },
    };
    return renderOptions;
  }


  renderOptionss(){
    const renderOptions: any = {
      suppressMarkers: true,
      polylineOptions: {
        strokeColor : '#1b8af3',
        strokeWeight: 4
      },
    };
    return renderOptions;
  }





  agmdirection(pointer: any, exhibitor: any){
    this.destinationPlaces = [];
    this.points.map((point: any, key: any) => {
      if(point.letter.toString() === pointer.letter.toString()){
        this.origin = {lat: parseFloat(this.lat), lng: parseFloat(this.lng), letter: '.'};
        this.destination = {lat: parseFloat(this.points[key].lat), lng: parseFloat(this.points[key].lng), letter: pointer.letter};
        this.renderOptions = {
          suppressMarkers: true,
          suppressPolylines: false,
          polylineOptions: {
            strokeColor : '#1b8af3',
            strokeWeight: 6
          },
        };
        this.markerOptions = {
          origin: {
            icon: this.img,
            label: {color: '#fff', text: 'STARTING'},
          },
          destination: {
         // label: {color: '#fff', text: this.destination.letter},
            draggable: false,
         // icon: this.img,
            infoWindow: this.infoWindowElements(this.points[key].exhibitors)
          }
        };
        this.destinationPlaces.push({origin: this.origin, destination: this.destination, markerOptions: this.markerOptions, renderOptions: this.renderOptions});
      }
    });
    this.infoWindow.close();
  }

  label(pos: any){ return {color: 'white', text: pos.letter}; }


  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  places(callback: any){
    setInterval(() =>{
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(pos => {
          //alert(pos.coords.latitude.toString());
          //alert(pos.coords.accuracy);
          //alert(pos.coords.altitudeAccuracy);
          //alert(pos.coords.speed);
          this.lat = parseFloat(pos.coords.latitude.toString());
          this.lng = parseFloat(pos.coords.longitude.toString());
          callback(this.lat, this.lng);
        },(err: any) => { this.showError(err);}, this.options);
      }else {
        alert('Geolocation is not supported by this browser.');
      }
    }, 10000);
  }

  getHeight(){return {height: window.innerHeight}; }



  showError(error) {
    switch(error.code) {
      case error.PERMISSION_DENIED:
     // alert('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        alert('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.');
        break;
    }
  }


}
