<h1 mat-dialog-title><center><span class="sawasdee20">{{title}}</span></center></h1>
<div mat-dialog-content [ngStyle]="{'margin-bottom':'100px'}">
    <center><p class="sawasdee18">Do you real want to {{params.action}} this record ?</p></center>
</div>
<div>
    <center>
        <a class="btn btn-success btn-md"   (click)="onAcceptable()">Yes</a>
        <a class="btn btn-success btn-md"   [mat-dialog-close]="">No</a>
    </center>
</div>
