import { Component, OnInit } from '@angular/core';
import {HeaderComponent} from "../header/header.component";

@Component({
  selector: 'app-ourservices',
  templateUrl: './ourservices.component.html',
  styleUrls: ['./ourservices.component.scss']
})
export class OurservicesComponent implements OnInit {

  constructor(private headerComponent: HeaderComponent) {
  }

  ngOnInit(): void {
    this.headerComponent.headerClass = 'header-4';
  }

}
