import {Component, Input, OnInit, ViewChild, OnDestroy, NgZone, AfterViewInit} from '@angular/core';
import {AppService} from '../../app.service';
import {ResponseService} from '../responses/data/response.service';
import {ClientService} from '../../clients/client.service';
import {FilterDashboardComponent} from '../../dashboard/filter-dashboard.component';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {FormService} from '../form.service';
import * as $ from 'jquery';
import {Parameters} from '../../parameters';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';
import {CampaignService} from "../../campaigns/campaign.service";
import {DashboardService} from "../../dashboard/dashboard.service";
import {DialogViewImageComponent} from '../../images/dialog/dialog-view-image/dialog-view-image.component';
import {Options, NgxQrcodeStylingComponent, NgxQrcodeStylingService } from 'ngx-qrcode-styling';
import Pusher from 'pusher-js';
@Component({
  selector: 'app-images-form',
  templateUrl: './images-form.component.html',
  styleUrls: ['./images-form.component.scss']
})
export class ImagesFormComponent implements OnInit, OnDestroy, AfterViewInit{
  @Input() flag: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  position: any;
  form: any;
  formId: any;
  incomingForm: any;
  loopAndDisplayImgInterval : any;
  subscription: any;
  searchCntrl: any;
  teams: any;
  isDialogOpen: any = false;
  campaign: any;
  incomingClient: any;
  incomingCampaign: any;
  interval: any;
  loopImgInterval: any;
  incomingResponse: any;
  incomingResponseResult: any;
  outgoingRequest: any;
  incomingQuestion: any;
  isImgDialogOpened: any = false;
  dialogRef: any=null;
  incomingFormSettings: any;
  outgoingResponse: any;
  incomingResponseResultSelected: any;
  public config: any = {
         width: 270,
         height: 270,
         data: '',
         image: '',
         margin: 0,
         dotsOptions: {
           color: "#000",
           type: "dots"
         },
         backgroundOptions: {
           color: "#ffffff",
         },
         imageOptions: {
           crossOrigin: "anonymous",
           margin: 0
         }
  };
  constructor(public service: AppService,
              public  dialog: MatDialog,
              public  zone: NgZone,
              private activatedRoute: ActivatedRoute,
              public  broadcastChannel: BroadcastChannelService,
              private qrcode: NgxQrcodeStylingService,
              private campaignService: CampaignService,
              private clientService: ClientService,
              private dashboardService: DashboardService,
              private router: Router,
              private formService: FormService,
              private responseService: ResponseService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.formId = this.activatedRoute.snapshot.params.id;
    this.formId = this.service.decrypt(this.formId);
    this.form = this.formService.getFormById(this.formId);
    this.incomingForm = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.outgoingResponse = new Parameters();
    this.incomingResponseResultSelected = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingResponse = new Parameters();
    this.incomingResponseResult = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingForm.setAttributes(this.form);
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.responseService.reportValues.attributes.form_id = this.incomingForm.attributes.id;
    this.campaign = this.campaignService.getCampaignById(this.campaignService.campaignSelectedId);
    this.service.header = this.form.name;
    this.incomingResponseResultSelected.setAttributes({});
    this.incomingCampaign.setAttributes(this.campaign);
    this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
  }

  ngOnInit(): void {
    this.incomingFormSettings.setBackgroundColor(this.incomingFormSettings.getAttributes().hasOwnProperty('background_color')?
     this.incomingFormSettings.getBackgroundColor():'');
    document.title = this.campaign.name;
    if(this.form.responses.data.length === 0)
      this.report();
    setTimeout(() => {
      this.emitter();
      if(parseFloat(this.incomingForm.getCategoryId())===6 && !window.location.href.toString().includes('live/photo/wall')){
       this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/live/photo/wall');return;
      }if(window.location.href.toString().includes('live/photo/wall')){
         document.getElementsByTagName( 'body')[0].style.backgroundColor = '#000';
         this.setIntervallatestResponses();
         Pusher.logToConsole = false;
         const pusher = new Pusher('7338988a96c85bb57c5a', {cluster: 'mt1'});
         const channel = pusher.subscribe('pusher');
         channel.bind('post', (data: any) => {
            this.zone.run(() =>{
              this.position = this.service.findIntegerIndex(this.responseService.broadCastedResponses,'id',data.message.id);
              if(this.position === -1) this.responseService.broadCastedResponses.push(data.message);
            });
         });

         setTimeout(()=>{
            this.service.setCrypt(this.incomingForm.getId(),(result: any)=>{
                 this.config.data = window.location.host +this.service.app.routes.forms.feedback.toString().replaceAll('xx',result)+'?q=';
                 this.config.data = this.config.data.toString().includes('https://') ? this.config.data : 'https://' + this.config.data;
                 if(!this.service.empty(this.incomingFormSettings.getBackgroundColor())){
                 this.config.data = this.config.data +'&extension='+this.incomingFormSettings.getBackgroundColor();
                 }if(!this.service.empty(this.incomingClient.getUnknown('logo'))){
                 this.config.data = this.config.data +'&access='+this.incomingClient.getUnknown('logo').split('clients/')[1];
                 }let element:any = document.getElementById('qrcode');
                 this.qrcode.create(this.config, element).subscribe((res) => {
                 });
            });
          });

      }
    });

  }

  setInterval(){
    this.interval = setTimeout(() => {
     if(this.responseService.broadCastedResponses.length !== 0) {
        if(!this.isImgDialogOpened){
          this.isImgDialogOpened = true;
          [this.responseService.broadCastedResponses[0]].map((response: any)=>{
           this.incomingResponse.setAttributes(response);
           this.position = this.service.findIntegerIndex(this.incomingForm.getResponses().data,'id',this.incomingResponse.getId());
           if(this.position === -1){
            this.incomingForm.getResponses().data.unshift(this.incomingResponse.getAttributes());
           }this.incomingResponse.getUnknown('datas').map((responseResult: any,incrementalResponseResult: any)=>{
            this.incomingResponseResult.setAttributes(responseResult);
            this.incomingQuestion.setAttributes(this.incomingResponseResult.getUnknown('formControl'));
            if(parseFloat(this.incomingQuestion.getControlId())===2 && incrementalResponseResult===0){
             if(!this.service.empty(this.incomingResponseResult.getUnknown('data'))){
              this.responseService.id = this.responseService.id + parseFloat('1');
              this.view(this.incomingResponseResult.getAttributes());
             }
            }
           });
          });
        }
     }else{
          if(!this.isImgDialogOpened){
               this.isImgDialogOpened = true;
               if(this.incomingForm.getResponses().data.length  === 1){
                  this.view(this.incomingForm.getResponses().data[0].datas[0]);
                  this.responseService.id = 0;
               }else if(this.responseService.id  === this.incomingForm.getResponses().data.length - 1){
                 this.view(this.incomingForm.getResponses().data[this.incomingForm.getResponses().data.length - 1].datas[0]);
                 this.responseService.id = parseFloat('0');
               }else if(this.responseService.id  < this.incomingForm.getResponses().data.length){
                 this.view(this.incomingForm.getResponses().data[this.responseService.id].datas[0]);
                 this.responseService.id += parseFloat('1');
               }else this.isImgDialogOpened = false;
          }
     }
    }, this.service.timeout()*3);
  }


  view(responseResult: any){
     this.incomingResponseResultSelected.setAttributes(responseResult);
     this.dialogRef  = this.dialog.open(DialogViewImageComponent, {
         data   : {data:responseResult},
         width  : '50%',
         height : '100%',
         hasBackdrop : true,
         disableClose: true,
     });
     this.dialogRef.afterClosed().subscribe(result => {
      this.isImgDialogOpened = false;
      this.setInterval();
     });
     this.dialogRef.afterOpened().subscribe(result => {
         setTimeout(()=>{
          this.isImgDialogOpened = true;
          this.dialogRef.close();
         },this.service.timeout()*parseFloat('12'));
     });
     try{this.responseService.broadCastedResponses.splice(this.service.findIndex(this.responseService.broadCastedResponses, 'id', responseResult.post_id), 1);}catch(e){}
  }


  emitter(){
    this.subscription = this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if (item instanceof Object) {
            if (item.hasOwnProperty('searchText') === true)
              this.searchCntrl = item.searchText;
          } else if (item.includes('extract')) {
            this.responseService.reportValues.setDisplay('excel');
            if(this.responseService.isDialogOpen === false) {
              this.responseService.isDialogOpen = true;
              this.dialog.open(FilterDashboardComponent, {
                data: {report: this.responseService.reportValues.getAttributes()},
                width: '55%',
                height: '100%'
              }).beforeClosed().subscribe(() =>{
                this.responseService.isDialogOpen = false;
              });
            }
          } else if (item.includes('search')) {
            this.handleSearch();
          } else if (item === 'openDateMenu') {
            this.openMenuDate();
          } else if (item === 'report') {
            this.report();
          //this.formService.setDate(this.responseService, this.dashboardService);
          }
        });
  }

  copy(){
    document.execCommand('copy');
  }


  report(){
    setTimeout((outgoingRequest: any = {}) => {
      this.outgoingRequest.setAttributes(Object.assign({},this.responseService.reportValues.getAttributes()));
      if(window.location.href.toString().includes('live/photo/wall'))
      this.outgoingRequest.setCategoryId(this.incomingForm.getCategoryId());
      this.outgoingRequest.setFormId(this.incomingForm.getId());
      delete this.outgoingRequest.attributes.data;
      this.service.httpService('post', '/reports', this.outgoingRequest.getAttributes(), {}, (responses: any) => {
        this.incomingForm.getResponses().data = responses;
        if(this.form.responses.data.length !== 0 && window.location.href.toString().includes('live/photo/wall')){
         this.getOldImgs();
         this.loopAndViewImg();
        }this.responseService.reportValues.attributes.data = 'values';
      }, (error: any) => {
      });
    });
  }

  getOldImgs(){
      this.outgoingRequest.setAttributes(Object.assign({},this.responseService.reportValues.getAttributes()));
      this.outgoingRequest.setFormId(this.incomingForm.getId());
      this.outgoingRequest.setCategoryId(this.incomingForm.getCategoryId());
      delete this.outgoingRequest.data;
      this.outgoingRequest.setDescendinglastAt(this.incomingForm.getResponses().data[this.incomingForm.getResponses().data.length - 1].id);
      this.service.httpService('post', '/reports', this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (responses: any) => {
        responses.map((response: any)=>{
           this.outgoingResponse.setAttributes(response);
           this.position = this.service.findIntegerIndex(this.incomingForm.getResponses().data,'id',this.outgoingResponse.getId());
           if(this.position === -1) this.incomingForm.getResponses().data.push(this.outgoingResponse.getAttributes());
        });if(responses.length !==0){
         this.getOldImgs();
        }
      }, (error: any) => {
      });
  }

  loopAndViewImg(){
   if(!this.responseService.isImgDialogOpened){
       this.responseService.isImgDialogOpened = true;
       setTimeout((response: any)=>{
           if(this.responseService.id === 0 && this.incomingForm.getResponses().data.length !== 0){
               if(!this.isImgDialogOpened){
                this.isImgDialogOpened = true;
                this.responseService.id += parseFloat('1');
                this.view(this.incomingForm.getResponses().data[0].datas[0]);
               }
           }
       });
   }
  }

  getlatestResponses(){
     this.outgoingRequest.setAttributes(Object.assign({},this.responseService.reportValues.getAttributes()));
     this.outgoingRequest.setFormId(this.incomingForm.getId());
     delete this.outgoingRequest.data;
     this.outgoingRequest.setAscendinglastAt(this.incomingForm.getResponses().data[0].id);
     this.service.httpService('post', this.service.app.routes.reports.fetch, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (responses: any) => {
       responses.map((response: any)=>{
          this.outgoingResponse.setAttributes(response);
          this.position = this.service.findIntegerIndex(this.responseService.broadCastedResponses,'id',this.outgoingResponse.getId());
          if(this.position === -1) {
           this.responseService.broadCastedResponses.push(this.outgoingResponse.getAttributes());
          }
       });
     }, (error: any) => {
     });
  }
  
  setIntervallatestResponses(){
        setInterval(() => {
          this.getlatestResponses();
        }, this.service.timeout()*parseFloat('11'));
  }



  openMenuDate(){
    if(this.responseService.isDialogOpen === false) {
      this.responseService.isDialogOpen = true;
      this.responseService.reportValues.setDisplay('dashboard');
      this.dialog.open(FilterDashboardComponent, {data: {report: this.responseService.reportValues.getAttributes()}, width: '55%', height: '100%'}).beforeClosed().subscribe(() => {
        this.responseService.isDialogOpen = false;
      });
    }
  }


  handleSearch(){
    setTimeout((outgoingRequest: any = {}) => {
      this.responseService.reportValues.setDisplay('dashboard');
      outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
      outgoingRequest.searchText = this.searchCntrl;
      delete outgoingRequest.data;
      this.service.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
        this.form.responses.data = response;
      }, (error: any) => {
      });
    });
  }




  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  handleDestroy(response: any){
    this.incomingForm = new Parameters();
    this.incomingForm.setAttributes(this.form);
    this.dialog.open(DeleteDialogComponent, {
      data    : {
        data  : this.form.responses,
        model : this.form,
        title : this.incomingForm.getName(),
        url   : '/forms/' + parseFloat(this.incomingForm.getId()) + '/delete',
        key   : 'id',
        id    : this.incomingForm.getId(),
      },
      width   : 'auto',
    });
  }

  getCampaignByForm(form: any){
    let campaign: any = this.service.only(this.app.data.campaigns.data.filter((campaign: any) => { return campaign.id === form.campaign_id;})[0]);
    let client: any = this.service.only(this.getClientByCampaign(campaign));
    campaign = Object.assign(campaign, {client: client});
    form = Object.assign(form, {campaign: campaign});
    return campaign;
  }


  getClientByCampaign(campaign: any){
    return this.app.data.clients.data.filter((client: any) => { return client.id === campaign.client_id; })[0];
  }

  handleUpdate(inspector: any){
    this.incomingForm = new Parameters();
    this.incomingForm.setAttributes(this.form);
    this.responseService.setSelected(this.incomingForm.getAttributes());
    // this.formsComponent.openDialog();
  }

  handleRestore(inspector: any){
    this.incomingForm.setAttributes(this.form);
    this.service.restore('/forms/' + parseFloat(this.incomingForm.getId()) + '/restore',
        this.form.responses, 'id', parseFloat(this.incomingForm.getId()), this.form);
  }


  handleResponses(form: any){
    this.subscription.unsubscribe();
    this.incomingForm.setAttributes(this.form);
    this.responseService.setSelected(this.form);
    this.router.navigateByUrl('/app/forms/' + this.form.id + '/responses');
  }

  openDialog(response: any){
    this.responseService.setSelected(this.incomingForm.getAttributes());
    // this.inspectorsComponent.openDialog();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
