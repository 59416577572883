import {AfterViewInit, Component, OnDestroy, OnInit,ViewChild,ElementRef} from '@angular/core';
import {AppService} from "../app.service";
import {Parameters} from "../parameters";
import { Geolocation, GeolocationPosition } from '@capacitor/geolocation';
import {MatMenuTrigger} from "@angular/material/menu";
import {MatDialog} from '@angular/material/dialog';
import {MessageDisplayComponent} from '../main/message/message-display.component';
import * as $ from 'jquery';


@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('addressElement', {static: false})  addressElement: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  lat: any = -6.8643365;
  lng: any = 39.2712821;
  zoom: any;
  zoom2: any;
  result: any;
  incomingResult: any;
  incomingRoute: any;
  incomingOrigin: any;
  incomingDestination: any;
  addRouteStatus: any = 0;
  map: any;
  mapClickListener: any;
  icon: any;
  addressValue: any;
  currentLocationLat: any  = 0;
  currentLocationLng: any = 0;
  currentLocationLat1: any  = 0;
  currentLocationLng1: any = 0;
  currentLocationLat2: any  = 0;
  currentLocationLng2: any = 0;
  currentLocationPositionIconUrl: any;
  currentLoationAddress: any = '';
  incomingAddress: any;
  incomingAddress1: any;
  incomingAddress2: any;
  incomingAddress3: any;
  incomingAddress4: any;
  outgoingRequest: any;
  outgoingWaypoint: any;
  incomingWaypoint: any;
  outgoingLocation: any;
   incomingLocation: any;
  positionOptions: any = {
    //timeout: 80000,
    //maximumAge: 60000,
      enableHighAccuracy: true,
  };
  constructor(public service: AppService,
              private dialog: MatDialog,) {
    this.incomingRoute = new Parameters();
    this.incomingOrigin = new Parameters();
    this.incomingDestination = new Parameters();
    this.incomingAddress = new Parameters();
    this.incomingAddress1 = new Parameters();
    this.incomingAddress2 = new Parameters();
    this.outgoingWaypoint = new Parameters();
    this.incomingAddress3 = new Parameters();
    this.incomingAddress4 = new Parameters();
    this.outgoingRequest = new Parameters();
    this.outgoingLocation = new Parameters();
    this.incomingWaypoint = new Parameters();
    this.incomingLocation = new Parameters();
    this.incomingRoute.setAttributes({});
    this.incomingOrigin.setAttributes({});
    this.incomingDestination.setAttributes({});
    this.addressElement = ElementRef;
    this.contextMenu = MatMenuTrigger;
  }

  ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

  markerUpdate(waypoint: any){
    this.incomingWaypoint.setAttributes(waypoint);
    this.incomingLocation.setAttributes(this.incomingWaypoint.getLocation());
    this.result = prompt('', this.incomingLocation.getTitle());
    if (this.result !== null){
     this.incomingLocation.setTitle(this.result);
     this.incomingWaypoint.setLocation(this.incomingLocation.getAttributes());
     this.outgoingRequest.setAttributes(Object.assign({},this.incomingWaypoint.getAttributes()));
     this.outgoingRequest.setLocation(JSON.stringify(this.incomingLocation.getAttributes()));
     this.outgoingRequest.setAlias(this.incomingLocation.getTitle());
     this.service.httpService('post', this.service.app.routes.waypoints.update,
             this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
           }, (error: any) => {
           });
    }
  }

  markerRemove(waypoint: any){
      this.incomingWaypoint.setAttributes(waypoint);
      this.incomingLocation.setAttributes(this.incomingWaypoint.getLocation());
      const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {
        data : {title: this.incomingLocation.getTitle(), action: 'delete'},
        width : 'auto',
      });
      dialogRef.afterClosed().subscribe((item: any) => {
        if (item instanceof Object){
          if (item.hasOwnProperty('response')){
            if (item.response.toString().includes('successfull')){
              this.outgoingRequest.setAttributes({});
              this.outgoingRequest.setId(this.incomingWaypoint.getId());
              this.service.httpService('post', this.service.app.routes.waypoints.delete,
                     this.outgoingRequest.getAttributes(), {}, (result: any) => {
                     this.incomingRoute.getWaypoints().splice(this.service.findIndex(this.incomingRoute.getWaypoints(), 'id', this.incomingWaypoint.getId()), 1);
                   }, (error: any) => {
                   });
            }
          }
        }
      });
  }


  ngOnInit(): void {
      this.currentLocationPositionIconUrl = {url: 'https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_my_location_48px-128.png', scaledSize: {height: 0, width: 0}};
       this.icon = {url: 'https://i.imgur.com/7teZKiff.png',  scaledSize: {height: 20, width: 20}};
       if(navigator){
              navigator.geolocation.getCurrentPosition(pos => {
                this.currentLocationLat = parseFloat(pos.coords.latitude.toString());
                this.currentLocationLng = parseFloat(pos.coords.longitude.toString());
              },(err: any) => {});
       }

       Geolocation.watchPosition(this.positionOptions, (pos: any, err: any) => {
           try{
               this.currentLocationLat = parseFloat(pos.coords.latitude.toString());
               this.currentLocationLng = parseFloat(pos.coords.longitude.toString());
           }catch (e) {}
       });
  }

  onContextMenu(event: MouseEvent, itm: any) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { item: itm };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
  }


  onMapReady(event: any){


  }

  onClickCurrentLocationPosition(event: any){
// setTimeout(()=>{
       if(this.incomingRoute.getWaypoints().length === 0){
        this.incomingOrigin.setLat(this.currentLocationLat1);
        this.incomingOrigin.setLng(this.currentLocationLng1);
        this.incomingDestination.setLat(this.currentLocationLat1);
        this.incomingDestination.setLng(this.currentLocationLng1);
      }else{
        this.incomingDestination.setLat(this.currentLocationLat1);
        this.incomingDestination.setLng(this.currentLocationLng1);
      }
      this.incomingRoute.setOrigin(this.incomingOrigin.getAttributes());
      this.incomingRoute.setDestination(this.incomingDestination.getAttributes());
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setLat(this.incomingDestination.getLat());
      this.outgoingRequest.setLng(this.incomingDestination.getLng());
      this.service.httpService('post', this.service.app.routes.home.address.get,
              this.outgoingRequest.getAttributes(), {}, (result: any) => {
              this.incomingAddress.setAttributes(result);
              this.incomingAddress1.setAttributes(this.incomingAddress.getAddress1());
              this.incomingAddress2.setAttributes(this.incomingAddress.getAddress2());
              this.incomingAddress3.setAttributes(this.incomingAddress.getAddress3());
              this.incomingAddress4.setAttributes(this.incomingAddress.getAddress4());
              this.incomingWaypoint.setAttributes({});
              this.incomingWaypoint.setCntrlNum(this.service.random());
              this.incomingWaypoint.setName(this.incomingAddress.getAddress());
              this.incomingWaypoint.setTitle(this.incomingWaypoint.getName());
              this.incomingWaypoint.setLat(this.incomingDestination.getLat());
              this.incomingWaypoint.setLng(this.incomingDestination.getLng());
              this.incomingWaypoint.setPlaceId(this.incomingAddress3.getPlaceId());
              this.incomingWaypoint.setRouteId(this.incomingRoute.getId());
              this.incomingWaypoint.setLocation({
                  lat: parseFloat(this.incomingDestination.getLat()),
                  lng: parseFloat(this.incomingDestination.getLng()),
                  cntrl_num: this.incomingWaypoint.getCntrlNum(),
                   title: this.incomingAddress.getAddress()
              });
              this.incomingRoute.getWaypoints().push(this.incomingWaypoint.getAttributes());
              this.incomingRoute.getWaypoints2().push({location:{
                lat: parseFloat(this.incomingDestination.getLat()),
                lng: parseFloat(this.incomingDestination.getLng()),
              }});
              this.addWaypoint(this.incomingWaypoint,(result: any)=> {
               this.incomingWaypoint.setId(result.id);
//             this.lat = this.currentLocationLat1;
//             this.lng = this.currentLocationLng1;
              });
            }, (error: any) => {
            });
//   });
  }

  getWaypointsByRoute(route: any){
   return route.waypoints.map((waypoint: any)=>{
    this.outgoingWaypoint.setAttributes(Object.assign({},waypoint));
    this.outgoingLocation.setAttributes(this.outgoingWaypoint.getLocation());
    return {location:{lat:parseFloat(this.outgoingLocation.getLat()),lng:parseFloat(this.outgoingLocation.getLng())}};
   });
  }

  addWaypoint(outgoingRequest: any,callback: any){
      this.service.httpService('post', this.service.app.routes.waypoints.add,
        outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
        callback(result);
      }, (error: any) => {
      });
  }

  onInputTextChange(event: any){
//       this.incomingQuestion.setData(event.target.value);
  }

  onGoogleMapAutocompleteSelected(result: any) {
      this.lat = parseFloat(result.geometry.location.lat());
      this.lng = parseFloat(result.geometry.location.lng());
      if(Object.keys(this.incomingRoute.getAttributes()).length > 0){
           this.incomingRoute.setTitle(this.addressElement.nativeElement.value);
           if(this.incomingRoute.getWaypoints().length === 0){
             this.incomingOrigin.setLat(parseFloat(result.geometry.location.lat()));
             this.incomingOrigin.setLng(parseFloat(result.geometry.location.lng()));
             this.incomingDestination.setLat(parseFloat(result.geometry.location.lat()));
             this.incomingDestination.setLng(parseFloat(result.geometry.location.lng()));
           }else{
             this.incomingDestination.setLat(parseFloat(result.geometry.location.lat()));
             this.incomingDestination.setLng(parseFloat(result.geometry.location.lng()));
           }
           this.incomingRoute.setOrigin(this.incomingOrigin.getAttributes());
           this.incomingRoute.setDestination(this.incomingDestination.getAttributes());
           this.incomingRoute.getWaypoints().push({location: {cntrl_num: this.service.random(),title: this.addressElement.nativeElement.value, lat: parseFloat(this.incomingDestination.getLat()), lng: parseFloat(this.incomingDestination.getLng()) }});
      } this.addressElement.nativeElement.value = '';
  }

  onLocationSelected(location: any) {
  }


  getWindow(){
    return{height: window.innerHeight};
  }

  addMarker(){
  this.onClickCurrentLocationPosition({});
  }

  mapReadyHandler(map: any){
    this.map = map;
    this.mapClickListener = this.map.addListener('contextmenu', (e: google.maps.MouseEvent) => {
     this.currentLocationLat1 = e.latLng.lat();
     this.currentLocationLng1 = e.latLng.lng();
//   this.onClickCurrentLocationPosition({});
//   contextmenu
//   if(this.incomingRoute.getWaypoints().length === 0){
//         this.incomingOrigin.setLat(e.latLng.lat());
//         this.incomingOrigin.setLng(e.latLng.lng());
//         this.incomingDestination.setLat(e.latLng.lat());
//         this.incomingDestination.setLng(e.latLng.lng());
//       }else{
//         this.incomingDestination.setLat(e.latLng.lat());
//         this.incomingDestination.setLng(e.latLng.lng());
//       }
//       this.incomingRoute.setOrigin(this.incomingOrigin.getAttributes());
//       this.incomingRoute.setDestination(this.incomingDestination.getAttributes());
//       this.incomingRoute.getWaypoints().push({location: { lat: parseFloat(this.incomingDestination.getLat()), lng: parseFloat(this.incomingDestination.getLng()) }});
    });
  }


  onClickMap(map:any){
   this.contextMenu.closeMenu();
  }

  addRoute(){
    this.result = prompt('route', '');
    if (this.result !== null){
      this.addRouteStatus = 1;
      this.incomingRoute.setAttributes({});
      this.incomingOrigin.setAttributes({});
      this.incomingOrigin.setLat(this.lat);
      this.incomingOrigin.setLng(this.lng);
      this.incomingDestination.setAttributes({});
      this.incomingDestination.setLat(this.lat);
      this.incomingDestination.setLng(this.lng);
      this.incomingRoute.setWaypoints([]);
      this.incomingRoute.setWaypoints2([]);
      this.incomingRoute.setTitle(this.result);
      this.incomingRoute.setName(this.result);
      this.incomingRoute.setOrigin(this.incomingOrigin.getAttributes());
      this.incomingRoute.setDestination(this.incomingDestination.getAttributes());
      this.incomingRoute.setCntrlNum(this.service.random());
      this.service.httpService('post', this.service.app.routes.routes.add,
            this.incomingRoute.getAttributes(), {}, (result: any) => {
            this.incomingRoute.attributes = Object.assign(this.incomingRoute.getAttributes(),result);
            this.service.app.data.routes.data.push(this.incomingRoute.getAttributes());
          }, (error: any) => {
          });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() =>{
      this.zoom = 18;
    });
  }

}
