import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from "../../../../app.service";
import {Parameters} from "../../../../parameters";
import {FormService} from "../../../form.service";

@Component({
    selector: 'app-number-preview-controls',
    templateUrl: './number-preview-controls.component.html',
    styleUrls: ['./number-preview-controls.component.scss']
})
export class NumberPreviewControlsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @ViewChild('numberElement', {static: false})  element: any;
    @Input() question: any;
    @Input() position: any;
    label: any;
    isInput: any = true;
    incomingQuestion: any;
    incomingAncestor: any;
    incomingOption: any;
    incomingSettings: any;
    incomingConditions: any;
    constructor(public service: AppService,
                public formService: FormService) {
        this.element = ElementRef;
        this.incomingQuestion = new Parameters();
        this.incomingAncestor = new Parameters();
        this.incomingOption   = new Parameters();
        this.incomingSettings = new Parameters();
        this.incomingConditions = new Parameters();
    }


    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.incomingQuestion.setAttributes(this.question);
        this.incomingSettings.setAttributes(
            this.incomingQuestion.attributes.hasOwnProperty('settings') ?
            this.incomingQuestion.getSettings() : {});
        this.incomingConditions.setAttributes(
            this.incomingQuestion.attributes.hasOwnProperty('conditions') ?
            this.incomingQuestion.getConditions() : {});
        this.incomingSettings.setDefault(this.incomingSettings.attributes.hasOwnProperty('_default') ?
            this.incomingSettings.getDefault() : '');
        this.ngAfterViewInit();
        setTimeout(() => {
            if(this.service.empty(this.incomingQuestion.getData())){
                if(this.element.nativeElement){
                    if(!this.service.empty(this.incomingSettings.getDefault())){
                        this.element.nativeElement.value = this.incomingSettings.getDefault();
                        this.incomingQuestion.setData(this.incomingSettings.getDefault());
                    }
                }
            }


        });
    }

    ngAfterViewInit(): void {
        setTimeout(() =>{
            if(this.element.nativeElement){
                this.element.nativeElement.focus();
                this.addComma(this.element.nativeElement);
            }
        });
    }

    ngOnDestroy(): void {
    }

    onNumberChanged(event: any){
        event.target.value = event.target.value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // this.numberElement.nativeElement.value = this.service.numberWithCommans(event.target.value.toString());
    }

    CommaFormatted(event) {
        // skip for arrow keys
        if(event.which >= 37 && event.which <= 40) return;

        // format number
        if (event.target.value) {
            event.target.value = event.target.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    onKeyUp(event: any){
        if(this.service.empty(this.element.nativeElement.value)){
        }else this.element.nativeElement.value = this.service.numberWithCommans(parseFloat(this.element.nativeElement.value.toString().replace(/,/g, '')));
    }
    numberCheck (args) {
        return !(args.key === 'e' || args.key === '+' || args.key === '-');
    }

    addComma(event: any){
      if(this.incomingConditions.attributes.hasOwnProperty('comma')){
         if(parseFloat(this.incomingConditions.getComma()) === 1)
                this.service.addComma(event);
      }
    }

    validateNumber(evt) {
        var e = evt || window.event;
        var key = e.keyCode || e.which;
        if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
            // numbers
            key >= 48 && key <= 57 ||
            // Numeric keypad
            key >= 96 && key <= 105 ||
            // Backspace and Tab and Enter
            key === 8 || key === 9 || key === 13 ||
            // Home and End
            key === 190 || key === 188 || key === 109 || key === 110 ||
            // Comma and Decimal
            key === 35 || key === 36 ||
            // left and right arrows
            key === 37 || key === 39 ||
            // Del and Ins
            key === 46 || key === 45) {
            // input is VALID
        }
        else {
            // input is INVALID
            e.returnValue = false;
            if (e.preventDefault)
                e.preventDefault();
        }
    }

    isAutoAggreted(){
        let result: any = false;
        this.incomingQuestion.setAttributes(this.question);
        if(this.incomingQuestion.attributes.hasOwnProperty('ancestor')) {
            this.incomingAncestor.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAncestor()));
            let options: any = this.service.getJSObjectByArrayAndLabel(this.incomingAncestor.getOptions(), 'cntrl_num');
            this.incomingOption.setAttributes(options[this.incomingQuestion.getOption().cntrlNum]);
            if(!this.service.empty(this.incomingOption.getAutoAggregatedField())){
                if(this.incomingOption.getAutoAggregatedField().toString() === this.incomingQuestion.getCntrlNum()) {
                    let tResults: any = 0;
                    this.incomingOption.getQuestions().map((q: any) => {
                        let incomingQ : any = new Parameters();
                        incomingQ.setAttributes(this.formService.getQuestionByCntrlNum(q.cntrl_num));
                        if(incomingQ.getCntrlNum().toString() === this.incomingQuestion.getCntrlNum()) {
                        }else{
                            if(!this.service.empty(incomingQ.getData())){
                             tResults += parseFloat(incomingQ.getData());
                            }
                        }
                    });
                    this.incomingQuestion.setData(this.service.numberWithCommans(tResults));
                    result = true;
                }
            }

        }
        return result;
    }



}
