<a class="pull-left"><h4>{{title}}</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<input type="hidden" class="nullInput"/>
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
    <mat-list>
        <mat-list-item>
            <span class="" mat-line>
               <mat-form-field  class="example-full-width" appearance="fill">
                    <mat-label>Item *</mat-label>
                    <input matInput
                           #itemElm
                           #itemMatAutocompleteTrigger="matAutocompleteTrigger"
                           autocomplete="off"
                           (input)="onChangeItemText($event)"
                           type="text"
                           [formControl] = "itemControl"
                           [matAutocomplete] = "optional">
                    <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}">keyboard_arrow_down</i>
                    <mat-autocomplete #optional = "matAutocomplete"
                                      (optionSelected)="onItemSelectionChanged($event)"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let item of filteredItems | async; let itemOf = index"
                                    [value]= "item">
                            {{item.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                          *ngIf="service.empty(itemElm.value) === false &&
                             ((filteredItems | async) | json).length === 2">
                             **---Not Found *---</h4>
                </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item>
            <span class="" mat-line>
              <mat-form-field  class="example-full-width">
                <mat-label>Qntity in or out *</mat-label>
                <input matInput
                       type="text"
                       autocomplete="off"
                       formControlName="in_out_qty">
              </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item>
          <span class="" mat-line>
               <mat-form-field class="example-full-width">
                  <mat-label>Description</mat-label>
                  <textarea matInput
                            placeholder=""
                            formControlName="description"
                            #message rows="1">
                  </textarea>
                  <mat-hint align="end">{{message.value.length}} / 255</mat-hint>
                </mat-form-field>
          </span>
        </mat-list-item>
    </mat-list>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <center>
                <div class="example-button-row">
                    <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{ this.keys.length === 0 ? 'Done' : 'Edit'}}"/>
                </div>
            </center>
        </div>
    </div>
</form>
