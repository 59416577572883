import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {Router} from '@angular/router';
import {Parameters} from '../parameters';
import {DeleteDialogComponent} from '../main/delete-dialog.component';
import {ClientService} from './client.service';
import {ClientsComponent} from './clients.component';

@Component({
  selector: 'app-table-clients',
  templateUrl: './table-clients.component.html',
  styleUrls: ['./table-clients.component.scss']
})
export class TableClientsComponent implements OnInit {
  @Input() public flag: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  selectedClient: any;
  clients: any;
  incomingSelectedClient: any;
  subscription: any;
  searchCntrl: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              private clientComponent: ClientsComponent,
              private router: Router,
              private clientService: ClientService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.clients = this.clientService.data;
    this.incomingSelectedClient = new Parameters();
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty('searchText') === true) {
            this.searchCntrl = item.searchText;
          }
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  handleCopy(){document.execCommand("copy");}

  onContextMenu(event: MouseEvent, client: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': client };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  getClient(client: any){
    client = Object.assign(client, this.clientService.getClientByClient(client));
    return client;
  }

  handleDestroy(inspector: any){
    this.incomingSelectedClient = new Parameters();
    this.incomingSelectedClient.setAttributes(this.selectedClient);
    this.dialog.open(DeleteDialogComponent, {
      data  : {
        data : this.clients,
        model: this.selectedClient,
        title: this.incomingSelectedClient.getName(),
        url  : '/clients/' + parseFloat(this.incomingSelectedClient.getId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedClient.getId(),
      },
      width : 'auto',
    });
  }

  handleUpdate(client: any){
    this.incomingSelectedClient = new Parameters();
    this.incomingSelectedClient.setAttributes(this.selectedClient);
    this.clientService.setSelected(this.incomingSelectedClient.getAttributes());
    this.clientComponent.openDialog();
  }
  handleRestore(inspector: any){
    this.incomingSelectedClient.setAttributes(this.selectedClient);
    this.service.restore('/clients/' + parseFloat(this.incomingSelectedClient.getId()) + '/restore',
      this.clients, 'id', parseFloat(this.incomingSelectedClient.getId()), this.selectedClient);
  }

  openDialog(inspector: any){
    this.clientService.setSelected(this.incomingSelectedClient.getAttributes());
    // this.clientComponent.openDialog();
  }


  showMessage(message: any) {
  }


}
