<div class="mdl-layout mdl-js-layout mdl-layout--fixed-header">
    <div class="android-header mdl-layout__header mdl-layout__header--waterfall"
     [ngStyle]="{'position':'fixed','top':'0px','right':'10px'}">
        <div class="mdl-layout__header-row">
          <span class="android-title mdl-layout-title">
            <img class="android-logo-image img-circle" src="assets/images/where-house-logo.jpg"
                 [ngStyle]="{'width':'40px','height':'40px'}">
          </span>
            <!-- Add spacer, to align navigation to the right in desktop -->
            <div class="android-header-spacer mdl-layout-spacer"></div>
            <div class="android-search-box mdl-textfield mdl-js-textfield mdl-textfield--expandable mdl-textfield--floating-label mdl-textfield--align-right mdl-textfield--full-width">
                <label class="mdl-button mdl-js-button mdl-button--icon" for="search-field">
                    <i class="material-icons">search</i>
                </label>
                <div class="mdl-textfield__expandable-holder">
                    <input class="mdl-textfield__input" type="text" id="search-field">
                </div>
            </div>
            <!-- Navigation -->
            <div class="android-navigation-container">
                <nav class="android-navigation mdl-navigation cursor" [ngStyle]="{'width':'1200px'}">
<!--                <a><i class="textWht glyphicon glyphicon-chevron-right"></i></a>-->
                    <a class="mdl-navigation__link mdl-typography--text-uppercasex cursor textWht sawasdee****18"
                       (click)="incomingMenuItemSelected.setAttributes(menu);scrollTo(menu);onContextMenu($event,menu)"
                       *ngFor="let menu of menus;let i = index">
                        {{service.upperStartingCharacter(menu.name,true).toUpperCase()}}
                        <i class="textWht glyphicon glyphicon-chevron-right cursor"></i>
                    </a>
<!--                <a class="mdl-navigation__link mdl-typography--text-uppercase cursor"-->
<!--                       id="planYourVisit">Plan your visit</a>-->
<!--                <a class="mdl-navigation__link mdl-typography--text-lowercase cursor sawasdee**20" (click)="scrollTo('venueInfo')">Venue info <i class="textWht glyphicon glyphicon-chevron-right"></i></a>-->
<!--                <a class="mdl-navigation__link mdl-typography--text-lowercase cursor sawasdee**20" (click)="scrollTo('contact')">Gallery <i class="textWht glyphicon glyphicon-chevron-right"></i></a>-->
<!--                <a class="mdl-navigation__link mdl-typography--text-lowercase cursor sawasdee**20" (click)="scrollTo('contact')">Hire venue <i class="textWht glyphicon glyphicon-chevron-right"></i></a>-->
<!--                <a class="mdl-navigation__link mdl-typography--text-lowercase cursor sawasdee**20" (click)="scrollTo('contact')">Contact <i class="textWht glyphicon glyphicon-chevron-right"></i></a>-->
                </nav>
            </div>
            <div style="visibility: hidden; position: fixed"
                 [style.left]="contextMenuPosition.x"
                 [style.top]="contextMenuPosition.y"
                 [matMenuTriggerFor]="contextMenu">
            </div>
            <mat-menu #contextMenu="matMenu">
                <ng-template matMenuContent let-item="inspector">
                    <ng-container *ngFor="let menu of menus">
                        <ng-container *ngIf="
                        incomingMenuItemSelected.getAttributes().hasOwnProperty('id') &&
                        incomingMenuItemSelected.getId()===menu.id">
                            <button mat-menu-item *ngFor="let child of menu.childs"
                                    (click)="scrollTo(child)">{{child.name}}</button>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </mat-menu>
            <span class="android-mobile-title mdl-layout-title">
            <img class="android-logo-image" src="images/android-logo.png">
          </span>
            <button class="android-more-button mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect" id="more-button">
                <i class="material-icons">more_vert</i>
            </button>
            <ul class="mdl-menu mdl-js-menu mdl-menu--bottom-right mdl-js-ripple-effect" for="more-button">
                <li class="mdl-menu__item">5.0 Lollipop</li>
                <li class="mdl-menu__item">4.4 KitKat</li>
                <li disabled class="mdl-menu__item">4.3 Jelly Bean</li>
                <li class="mdl-menu__item">Android History</li>
            </ul>
        </div>
    </div>

    <div class="android-drawer mdl-layout__drawer">
        <span class="mdl-layout-title">
          <img class="android-logo-image" src="images/android-logo-white.png">
        </span>
        <nav class="mdl-navigation">
            <a class="mdl-navigation__link" href="">Phones</a>
            <a class="mdl-navigation__link" href="">Tablets</a>
            <a class="mdl-navigation__link" href="">Wear</a>
            <a class="mdl-navigation__link" href="">TV</a>
            <a class="mdl-navigation__link" href="">Auto</a>
            <a class="mdl-navigation__link" href="">One</a>
            <a class="mdl-navigation__link" href="">Play</a>
            <div class="android-drawer-separator"></div>
            <span class="mdl-navigation__link" href="">Versions</span>
            <a class="mdl-navigation__link" href="">Lollipop 5.0</a>
            <a class="mdl-navigation__link" href="">KitKat 4.4</a>
            <a class="mdl-navigation__link" href="">Jelly Bean 4.3</a>
            <a class="mdl-navigation__link" href="">Android history</a>
            <div class="android-drawer-separator"></div>
            <span class="mdl-navigation__link" href="">Resources</span>
            <a class="mdl-navigation__link" href="">Official blog</a>
            <a class="mdl-navigation__link" href="">Android on Google+</a>
            <a class="mdl-navigation__link" href="">Android on Twitter</a>
            <div class="android-drawer-separator"></div>
            <span class="mdl-navigation__link" href="">For developers</span>
            <a class="mdl-navigation__link" href="">App developer resources</a>
            <a class="mdl-navigation__link" href="">Android Open Source Project</a>
            <a class="mdl-navigation__link" href="">Android SDK</a>
        </nav>
    </div>

    <div class="android-content mdl-layout__content">
<!--    <a name="top"></a>-->
        <router-outlet></router-outlet>
        <footer class="android-footer mdl-mega-footer">
            <div class="mdl-mega-footer--top-section">
                <div class="mdl-mega-footer--left-section">
                    <button class="mdl-mega-footer--social-btn"></button>
                    &nbsp;
                    <button class="mdl-mega-footer--social-btn"></button>
                    &nbsp;
                    <button class="mdl-mega-footer--social-btn"></button>
                </div>
                <div class="mdl-mega-footer--right-section">
                    <a class="mdl-typography--font-light" href="#top">
                        Back to Top
                        <i class="material-icons">expand_less</i>
                    </a>
                </div>
            </div>

            <div class="mdl-mega-footer--middle-section">
                <p class="mdl-typography--font-light">Satellite imagery: © 2014 Astrium, DigitalGlobe</p>
                <p class="mdl-typography--font-light">Some features and devices may not be available in all areas</p>
            </div>

            <div class="mdl-mega-footer--bottom-section">
                <a class="android-link android-link-menu mdl-typography--font-light" id="version-dropdown">
                    Versions
                    <i class="material-icons">arrow_drop_up</i>
                </a>
                <ul class="mdl-menu mdl-js-menu mdl-menu--top-left mdl-js-ripple-effect" for="version-dropdown">
                    <li class="mdl-menu__item">5.0 Lollipop</li>
                    <li class="mdl-menu__item">4.4 KitKat</li>
                    <li class="mdl-menu__item">4.3 Jelly Bean</li>
                    <li class="mdl-menu__item">Android History</li>
                </ul>
                <a class="android-link android-link-menu mdl-typography--font-light" id="developers-dropdown">
                    For Developers
                    <i class="material-icons">arrow_drop_up</i>
                </a>
                <ul class="mdl-menu mdl-js-menu mdl-menu--top-left mdl-js-ripple-effect" for="developers-dropdown">
                    <li class="mdl-menu__item">App developer resources</li>
                    <li class="mdl-menu__item">Android Open Source Project</li>
                    <li class="mdl-menu__item">Android SDK</li>
                    <li class="mdl-menu__item">Android for Work</li>
                </ul>
                <a class="android-link mdl-typography--font-light" href="">Blog</a>
                <a class="android-link mdl-typography--font-light" href="">Privacy Policy</a>
            </div>

        </footer>
    </div>
</div>
<!--<a href="https://github.com/google/material-design-lite/blob/mdl-1.x/templates/android-dot-com/" target="_blank" id="view-source" class="mdl-button mdl-js-button mdl-button&#45;&#45;raised mdl-js-ripple-effect mdl-color&#45;&#45;accent mdl-color-text&#45;&#45;accent-contrast">View Source</a>-->
<!--<script src="https://code.getmdl.io/1.3.0/material.min.js"></script>-->


<!--<header id="header" class="{{headerClass}}">-->
<!--    &lt;!&ndash;BURGER NAVIGATION SECTION START&ndash;&gt;-->
<!--    <div class="cp-burger-nav">-->
<!--        &lt;!&ndash;BURGER NAV BUTTON&ndash;&gt;-->
<!--        <div id="cp_side-menu-btn" class="cp_side-menu">-->
<!--            <a hrefx="" class=""> <img src="../../assets/images/menu-icon-2.png" alt="img"></a>-->
<!--            <img class="img-circle mdl-shadow&#45;&#45;2dp" src="../../assets/images/where-house-logo.jpg"-->
<!--                 alt="img" [ngStyle]="{'margin-left':'20px','width':'30px','height':'30px'}">-->
<!--&lt;!&ndash;            <a class="txtShadow hidden-md hidden-sm hidden-xs mitra"></a>&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;BURGER NAV BUTTON&ndash;&gt;-->
<!--        &lt;!&ndash;SIDEBAR MENU START&ndash;&gt;-->
<!--        <div id="cp_side-menu"> <a hrefx="#" id="cp-close-btn" class="crose"><i class="fa fa-close"></i></a>-->
<!--            &lt;!&ndash;<div class="cp-top-bar">&ndash;&gt;-->
<!--            &lt;!&ndash;<h4>For any Queries: +800 123 4567</h4>&ndash;&gt;-->
<!--            &lt;!&ndash;<div class="login-section"> <a hrefx="login.html" class="btn-login">Log in</a> <a hrefx="signup.html" class="btn-login">Signup</a> </div>&ndash;&gt;-->
<!--            &lt;!&ndash;</div>&ndash;&gt;-->
<!--            &lt;!&ndash;        <strong class="logo-2"><a hrefx="index.html"><img src="../../assets/images/sidebar-logo.png" alt="img"></a></strong>&ndash;&gt;-->
<!--            <div class="content mCustomScrollbar">-->
<!--                <div id="content-1" class="content">-->
<!--                    <div class="cp_side-navigation">-->
<!--                        <nav>-->
<!--                            <ul class="navbar-nav">-->
<!--                            <li class="active"><a hrefx="index.html" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Home<span class="caret"></span></a>-->
<!--                            <ul class="dropdown-menu" role="menu">-->
<!--                            <li><a hrefx="index-1.html">Home 1</a></li>-->
<!--                            <li><a hrefx="index-2.html">Home 2</a></li>-->
<!--                            <li><a hrefx="index-3.html">Home 3</a></li>-->
<!--                            <li><a [routerLink]="'/app/contact'">Contact</a></li>-->
<!--                            </ul>-->
<!--                            </li>-->
<!--                            </ul>-->
<!--                        </nav>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="cp-sidebar-social">-->
<!--                <ul>-->
<!--                    &lt;!&ndash;<li><a hrefx="#"><i class="fa fa-google-plus"></i></a></li>&ndash;&gt;-->
<!--                    &lt;!&ndash;<li><a hrefx="#"><i class="fa fa-twitter"></i></a></li>&ndash;&gt;-->
<!--                    &lt;!&ndash;<li><a hrefx="#"><i class="fa fa-linkedin"></i></a></li>&ndash;&gt;-->
<!--                    &lt;!&ndash;<li><a hrefx="#"><i class="fa fa-facebook-f"></i></a></li>&ndash;&gt;-->
<!--                </ul>-->
<!--            </div>-->
<!--            <strong class="copy">mashindano &copy; 2020, All Rights Reserved</strong> </div>-->
<!--        &lt;!&ndash;SIDEBAR MENU END&ndash;&gt;-->

<!--    </div>-->
<!--    &lt;!&ndash;BURGER NAVIGATION SECTION END&ndash;&gt;-->
<!--    <div class="container">-->
<!--        &lt;!&ndash;NAVIGATION START&ndash;&gt;-->
<!--        <div class="navigation-col">-->
<!--            <nav class="navbar navbar-inverse">-->
<!--                <div class="navbar-header">-->
<!--                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>-->
<!--                </div>-->
<!--                <div id="navbar" class="collapse navbar-collapse">-->
<!--                    <ul class="nav navbar-nav" id="nav">-->
<!--                        <li *ngFor="let menu of menus">-->
<!--                            <a class="sawasdee**18 sawasdee**X5 textWht"  [routerLink]="menu.path">-->
<!--                                {{service.upperStartingCharacter(menu.name, true).toUpperCase()}}-->
<!--                                <i class="glyphicon glyphicon-chevron-right"></i>-->
<!--                            </a>-->
<!--                            <ng-container *ngIf="!service.empty(menu.path)">-->
<!--                            </ng-container>-->
<!--                            <ng-container *ngIf="service.empty(menu.path)">-->
<!--                                <ul class="dropdown-menu mitra14X2" role="menu" >-->
<!--                                    <li><a [routerLink]="menu2.path" *ngFor="let menu2 of menu.childs">{{menu2.name.toString().toUpperCase()}}</a></li>-->
<!--                                </ul>-->
<!--                            </ng-container>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </nav>-->
<!--        </div>-->
<!--        &lt;!&ndash;NAVIGATION END&ndash;&gt;-->
<!--    </div>-->

<!--    &lt;!&ndash;USER OPTION COLUMN START&ndash;&gt;-->
<!--    <div class="user-option-col">-->
<!--        <div class="thumb">-->
<!--            <div class="dropdown">-->
<!--                <button  class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">-->
<!--                    <img class="img-circle" src="{{app.data.user.avatar ? app.data.user.avatar : 'assets/images/avatar.jpg'}}" alt="img" height="25" width="25" style="margin-top: 15px" *ngIf="app.data.status > 0">-->
<!--                </button>-->
<!--                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">-->
<!--                    <li><a hrefx="#">Manage Account</a></li>-->
<!--                    <li><a hrefx="#">Change Password</a></li>-->
<!--                    <li><a hrefx="#">Edit Profile</a></li>-->
<!--                    <li><a hrefx="#">Log off</a></li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="dropdown-box">-->
<!--            <div class="dropdown">-->
<!--                <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">-->
<!--                    <img src="../../assets/images/option-icon-2.png" alt="img">-->
<!--                </button>-->
<!--                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">-->
<!--                    <li> <a hrefx="#" class="login-round"><i class="fa fa-sign-in"></i></a> <a hrefx="#" class="btn-login">Log in with Jobinn</a> </li>-->
<!--                    <li> <a hrefx="#" class="login-round"><i class="fa fa-user-plus"></i></a> <a hrefx="#" class="btn-login">Log in with Jobinn</a> </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;USER OPTION COLUMN END&ndash;&gt;-->

<!--</header>-->
<!--<router-outlet></router-outlet>-->
