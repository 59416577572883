import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../app.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogProfileComponent} from '../users/dialog-profile/dialog-profile.component';
import {FormService} from '../forms/form.service';
import {Parameters} from "../parameters";
import {DashboardService} from "../dashboard/dashboard.service";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('menuTrigger') trigger: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  incomingMenuItemSelected: any;
  appmenusUrl = '../assets/json/menus.json';
  contextMenuPosition = { x: '0px', y: '0px' };
  headerClass = 'header-2';
  app: any;
  subscription: any;
  strings;
  menus: any = [];
  incomingMenuItem: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              public formMetthods: FormService,
              public dashboardService: DashboardService,
              private router: Router) {
    this.app = this.service.app;
    this.contextMenu = MatMenuTrigger;
    this.strings = new Parameters();
    this.incomingMenuItem = new Parameters();
    this.incomingMenuItemSelected = new Parameters();
    this.incomingMenuItemSelected.setAttributes({});
  }

  ngOnInit(): void {
    this.service.httpService( 'get', this.appmenusUrl, {}, {}, (menus: any) => {this.menus = menus;},  (error: any) => {});
  }

  scrollTo(menu: any) {
    this.incomingMenuItem.setAttributes(menu);
    this.strings.setAttributes(this.service.app.strings.params);
      let elementId = document.getElementById(this.incomingMenuItem.getId());
      if (elementId instanceof HTMLElement) {
        this.broadcastChannel.emitNavChangeEvent({navItem: this.incomingMenuItem.getId()});
      }
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = (event.clientX-50) + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


  logout(){
    localStorage.setItem(this.service.app.settings.tokenName, '');
    this.service.setToken('');
    this.router.navigateByUrl('login');
  }
}
