import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Inject } from '@angular/core';
import {map, startWith} from 'rxjs/internal/operators';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {FormControl} from '@angular/forms';
import {FormService} from "../forms/form.service";
import {Parameters} from "../parameters";
import {AppService} from "../app.service";
import {BroadcastChannelService} from '../broadcast-channel.service';
import {ActivatedRoute, Router} from '@angular/router';
@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit, AfterViewInit {
  @ViewChild('suggestions', {static: false}) elementSuggestions: any;
  @ViewChild('trigger', {static: false}) elementTrigger: any;
  suggestionControl = new FormControl();
  filteredSuggestions: any;
  outgoingRequest: any;
  incomingUser: any;
  formId: any;
  incomingForm: any;
  suggestions: any=[];
  constructor(public service: AppService,
              public broadcastChannel: BroadcastChannelService,
              public formService: FormService,
              public activateRouter: ActivatedRoute,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<PromptComponent>,
              private dialog: MatDialog) {
                  this.outgoingRequest = new Parameters(),
                  this.incomingUser = new Parameters(),
                  this.incomingForm = new Parameters(),
                  this.elementSuggestions = ElementRef;
                  this.elementTrigger = MatAutocompleteTrigger;
                  this.data = !this.data.hasOwnProperty('flag') ? Object.assign(this.data, {flag: null}) : this.data;
  }

  ngOnInit(): void {
   this.incomingUser.setAttributes(this.service.app.data.status === 0 ? {} : this.service.app.data.user);
   if(this.data.flag ===  'questions'){
//  this.formId = this.activateRouter.snapshot.params.id;
//  this.formId = this.service.decrypt(this.formId);
    this.formId = this.data.formId;
    this.incomingForm.setAttributes(this.formService.getFormById(this.formService.formSelectedId));
//  this.incomingForm.setAttributes(this.service.app.data.forms.data.filter((form: any)=> {return parseFloat(this.formId) === parseFloat(form.id);})[0]);
   }

   this.suggestions = this.service.app.data.suggestions;
   this.suggestions = this.data.flag ===  'questions' ? this.incomingForm.getControls() : this.suggestions;
   this.setSuggestionControl();
  }

  setSuggestionControl(){
      this.filteredSuggestions = this.suggestionControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.description),
          map(name => name ? this._filterSuggestion(name) : this.suggestions.slice())
      );
  }

  ngAfterViewInit(): void {
      setTimeout(()=> {
        if(this.elementSuggestions.nativeElement){
          this.elementSuggestions.nativeElement.focus();
          this.elementTrigger.closePanel();
        }
      });
  }

  add(text: any){
   this.broadcastChannel.emitNavChangeEvent({prompt: text});
   this.cancel();
  }
  cancel(){this.dialogRef.close();}

  private _filterSuggestion(name: string) {
      const filterValue: any = name.toString().toLowerCase();
      return this.suggestions.filter((option: any) => option.toString().toLowerCase().includes(filterValue));
  }

  displayFn(value: any){
      return value.hasOwnProperty('title')?
      value.title : value.description;
  }


  displayFnLabel(value: any){
        return value.label;
  }

  onInputTextChange(event: any){
    setTimeout(() => {
        if(this.data.hasOwnProperty('flag')){
            if(this.data.flag ===  'responses'){
               setTimeout(() => {
                  if(!this.service.empty(event.target.value)){
                   this.outgoingRequest.setAttributes({});
                   this.outgoingRequest.setFormId(this.data.form_id);
                   this.outgoingRequest.setUserId(
                    this.incomingUser.attributes.hasOwnProperty('id') ?
                    this.incomingUser.getId() : 1);
                   this.outgoingRequest.setSearchText(event.target.value);
                   this.outgoingRequest.setDisplay('dashboard');
                   this.outgoingRequest.setTarget('all');
                   this.service.httpService( 'post', this.service.app.settings.routes.responses.search, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
                      this.service.app.data.suggestions = response;
                      this.setSuggestionControl();
                   },  (error: any) => {});
                  }
               });
            }else if(this.data.flag ===  null){
               this.service.getSuggestions(event.target.value, () => {
                  if(!this.service.empty(event.target.value)){
                    this.setSuggestionControl();
                  }
               });
            }else this.setSuggestionControl();
        }
     });
  }

  onSuggestionSelectionChanged(event: MatAutocompleteSelectedEvent){
      this.suggestionControl.setValue(event.option.value);
      if(this.data.flag ===  'responses' || this.data.flag ===  'questions'){
       this.add(event.option.value);
      }
  }

}
