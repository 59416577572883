import {Component, Input, OnInit, ElementRef, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, NgZone,ViewChild} from '@angular/core';
import {AppService} from '../../../app.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatDrawer } from '@angular/material/sidenav';
import {Observable, Subject} from 'rxjs';
import {FormService} from '../../form.service';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {Parameters} from '../../../parameters';
import {ImageService} from '../../../images/image.service';
import {NotifierService} from 'angular-notifier';
import {ResponseService} from '../../responses/data/response.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DialogViewImageComponent} from '../../../images/dialog/dialog-view-image/dialog-view-image.component';
import * as moment from 'moment';
import Pusher from 'pusher-js';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import * as $ from 'jquery';
@Component({
  selector: 'app-image-preview-controls',
  templateUrl: './image-preview-controls.component.html',
  styleUrls: ['./image-preview-controls.component.scss']
})
export class ImagePreviewControlsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild(MatMenuTrigger) contextMenu: any;
  @ViewChild('drawer', {static: false})  drawer: any;
  @ViewChild('imgDrawer', {static: false})  imgDrawer: any;
  contextMenuPosition: any = { x: '0px', y: '0px' };
  @Input() question: any;
  @Input() position: any;
  data: any = '';
  selectedTabIndex : any = 0;
  lastTabIndex : any = 0;
  oldTabIndex : any = 0;
  nextTabIndex : any = 0;
  image: any = null;
  showCarousel: any = true;
  incomingQuestion: any;
  outgoingResponseRequest: any;
  video: any;
  canvas: any;
  picUrl: any;
  dialogRef: any = null;
  deviceId: string;
  incomingResponseResultSelected: any;
  context: any;
  incomingQuestionResults: any;
  incomingFormSettings: any;
  incomingForm: any;
  isCameraOpened: any = false;
  incomingResponse: any;
  selectedTabIndex2 : any = 1;
  posts: any;
  interval : any;
  outgoingQuestion: any;
  questions: any = [];
  outgoingImgRequest: any;
  isImgShownInSliderView: any = false;
  voiceCamera: any;
  interval2 : any;
  isImgCaptured: any = false;
  track: any; // the video track which is used to turn on/off the flashlight
  imageCapture: any;
  incomingReport: any;
  outgoingRequest: any;
  outgoingResponse: any;
  hasOldImg: any = true;
  private trigger: Subject<void> = new Subject<void>();
  public showWebcam = true;
  public allowCameraSwitch = true;
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public facingMode: any = 'environment';
  public multipleWebcamsAvailable = false;
  public webcamImage: any = null;
  showImg: any=false;
  isImgShownInSliderView2 : any=false;
  isUploading: any = false;
  constructor(public  service: AppService,
              public  router: Router,
              public dialog: MatDialog,
              private broadcastChannel: BroadcastChannelService,
              private notifier: NotifierService,
              private imgService: ImageService,
              public zone: NgZone,
              public responseService: ResponseService,
              private formService: FormService) {
    this.contextMenu = MatMenuTrigger;
    this.incomingQuestion = new Parameters();
    this.incomingQuestionResults = new Parameters();
    this.incomingForm = new Parameters();
    this.outgoingRequest = new Parameters();
    this.outgoingResponseRequest = new Parameters();
    this.incomingResponse = new Parameters();
    this.outgoingQuestion = new Parameters();
    this.outgoingImgRequest = new Parameters();
    this.incomingReport = new Parameters();
    this.outgoingResponse  = new Parameters();
    this.incomingResponseResultSelected = new Parameters();
    this.drawer = MatDrawer;
    this.imgDrawer = ElementRef;
  }

  ngOnInit(): void {
  }


  setIntervalOldResponses(){
            this.interval2 = setInterval(() => {
              this.getOldImg();
              if(this.incomingForm.getResponses().data.length>parseFloat('35'))
               clearInterval(this.interval2);
            }, this.service.timeout()*parseFloat('11'));
  }

  setInterval(){
          this.interval = setInterval(() => {
            this.getlatestResponses((result: any)=>{});
          }, this.service.timeout()*parseFloat('11'));
  }

  ngOnDestroy(): void {
   clearInterval(this.interval);
  }

  getlatestResponses(callback: any){
       this.outgoingResponseRequest.setAttributes(Object.assign({},this.responseService.reportValues.getAttributes()));
       this.outgoingResponseRequest.setFormId(this.incomingForm.getId());
       delete this.outgoingResponseRequest.data;
       this.outgoingResponseRequest.setAscendinglastAt(this.incomingForm.getResponses().data[0].id);
       this.service.httpService('post', this.service.app.routes.reports.get, this.outgoingResponseRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (responses: any) => {
         responses.map((response: any)=>{
            this.outgoingResponse.setAttributes(response);
            this.position = this.service.findIntegerIndex(this.incomingForm.getResponses().data,'id',this.outgoingResponse.getId());
            if(this.position === -1) {
             this.incomingForm.getResponses().data.unshift(this.outgoingResponse.getAttributes());
            }
         });
         callback(null);
       }, (error: any) => {
       callback(null);
       });
  }

  getImg(callback: any) {
     this.outgoingRequest.setAttributes({});
     this.outgoingRequest.setUrl(this.incomingResponseResultSelected.getUnknown('data'));
     this.service.httpService('post', this.service.app.routes.files.base64,
      this.outgoingRequest.getAttributes(), {},(result: any) => {
        callback('data:image/jpg;base64,'+result);
      }, (error: any) => {})
//    this.imgService
//      .getData(this.incomingResponseResultSelected.getUnknown('data'))
//       subscribe(imgData => (callback(imgData)), err => console.log(err));

  }





  downloadImg(){
   this.getImg((result: any)=>{
    this.imgService.download(result);
   });
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.playSoundCamera((result: any) => {});
    this.webcamImage = webcamImage;
    this.showImg= true;
    this.selectedTabIndex = 2;
    this.capturePic();
  }

 // webcamImage.imageAsDataUrl
  public triggerSnapshot(): void {
        this.isImgCaptured = false;
        this.trigger.next();
  }

  onContextMenu(event: MouseEvent, inspector: any) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { 'item': inspector };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
  }

  onSelectedTabChange(event: any){
    if(this.selectedTabIndex>this.lastTabIndex){
     this.nextTabIndex = this.selectedTabIndex;
     this.oldTabIndex = this.lastTabIndex;
//   this.nextTabIndex = this.selectedTabIndex <= this.nextTabIndex ? this.nextTabIndex : this.selectedTabIndex;
    }if(this.selectedTabIndex<this.lastTabIndex){
    this.nextTabIndex = this.lastTabIndex;
    this.oldTabIndex = this.selectedTabIndex;
    }this.showCarousel = false;
    setTimeout(()=>{this.showCarousel = true;});
    this.lastTabIndex = this.selectedTabIndex;
  }

  onErrorHandle(event: any){
  }


  public showNextWebcam(directionOrDeviceId: boolean|string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
  }

  onClickTabGroup(){
  }

  orderBy(responses: any){return this.service.orderBy(responses,'desc','id');}

  public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
  }


  private readAvailableVideoInputs() {
      WebcamUtil.getAvailableVideoInputs()
        .then((mediaDevices: MediaDeviceInfo[]) => {
          this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });
  }

  public handleInitError(error: WebcamInitError): void {
      if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      }
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
      return this.nextWebcam.asObservable();
  }

  public cameraWasSwitched(deviceId: string): void {
      this.deviceId = deviceId;
      this.readAvailableVideoInputs();
  }

  public get videoOptions(): MediaTrackConstraints {
      const result: MediaTrackConstraints = {};
      if (this.facingMode && this.facingMode !== '') {
        result.facingMode = { ideal: this.facingMode };
      }

      return result;
  }

  changeCameraFacingMode(){
   this.facingMode === 'enviroment' ? 'user' : 'enviroment';
  }

  public get triggerObservable(): Observable<void> {
      return this.trigger.asObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {
   this.incomingReport.setAttributes(this.responseService.reportValues.getAttributes());
   this.incomingResponse.setAttributes(this.responseService.selected);
   this.incomingForm.setAttributes(this.formService.getSelected());
   this.incomingReport.setFormId(this.incomingForm.getId());
   this.incomingQuestion.setAttributes(this.question);
   this.incomingQuestion.setResult(this.incomingQuestion.getAttributes().hasOwnProperty('result') &&
    !this.service.empty(this.incomingQuestion.getResult()) ? this.incomingQuestion.getResult() : {});
   this.incomingQuestion.setUnknown('pic', !this.incomingQuestion.getAttributes().hasOwnProperty('pic') ?
    this.incomingQuestion.getUnknown('data') : this.incomingQuestion.getUnknown('pic'));
   this.incomingQuestion.setUnknown('data', !this.service.empty(this.incomingQuestion.getUnknown('data')) ? this.incomingQuestion.getUnknown('data') : 'null');
   setTimeout(() => {
    if (parseFloat(this.incomingForm.getCategoryId()) === 6){
//   this.openCamera();
     this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.button.next.hide});
     this.isCameraOpened = true;
     Pusher.logToConsole = false;
     const pusher = new Pusher('7338988a96c85bb57c5a', {cluster: 'mt1'});
     const channel = pusher.subscribe('pusher');
     channel.bind('post', (data: any) => {
          this.zone.run(() => {
            this.responseService.broadCastedResponses.push(data.message);
            this.playSoundUpcomingmessage((result: any) => {
            });
          });
     });
     this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
     this.outgoingRequest.setCategoryId(this.incomingForm.getCategoryId());
     this.outgoingRequest.setFormId(this.incomingForm.getId());
     setTimeout(()=>{
     this.selectedTabIndex = this.service.isWaitScreen ? 3 : 0;
     this.service.isWaitScreen = false;
     });if(this.incomingForm.getResponses().data.length === 0){
     this.getResponses();
     }else this.setInterval();
     if(!this.responseService.isImgDialogOpened){
         this.responseService.isImgDialogOpened = true;
         window.addEventListener('keydown',(e: any) => {
           this.selectedTabIndex = (e.keyCode === 37 ||  e.keyCode === 100 || e.key === 'ArrowLeft') ? 0 : this.selectedTabIndex ;
           this.selectedTabIndex = (e.keyCode === 39  || e.key === 'ArrowRight') ? 3 : this.selectedTabIndex ;
         });
     }
    }
   });
  }

  getResponses(){
    this.service.httpService('post', this.service.app.routes.reports.get, this.outgoingRequest.getAttributes(), {}, (responses: any) => {
        responses.map((response: any) => {
         this.outgoingResponse.setAttributes(response);
         this.incomingForm.getResponses().data.push(this.outgoingResponse.getAttributes());
        });
        if(responses.length !== 0){
         this.setIntervalOldResponses();
         this.setInterval();
        }else this.selectedTabIndex = 3;
       }, (error: any) => {
       });
  }

  view(responseResult: any){
     if (this.dialogRef != null) {this.dialogRef.close(null); }
  //    setTimeout(()=>{
     this.dialogRef = this.dialog.open(DialogViewImageComponent, {
            data        : {data: responseResult,responses: this.incomingForm.getResponses().data},
            width       : this.service.getWidth() > 1000 ? '50%' : '99%',
            height      : '100%',
            hasBackdrop : true,
         });
  //    });
  }

  addBroadCastedResponses(){
    this.responseService.broadCastedResponses.map((response: any) => {
     this.outgoingResponse.setAttributes(response);
     this.position = this.service.findIndex(this.incomingForm.getResponses().data,'cntrl_num',this.outgoingResponse.getCntrlNum());
     if(this.position === -1)
     this.incomingForm.getResponses().data.unshift(this.outgoingResponse.getAttributes());
    }); this.responseService.broadCastedResponses = [];
  }

  accessFlashlight() {
        // Test browser support
        if (!('mediaDevices' in window.navigator)) {
            alert('Media Devices not available. Use HTTPS!');
            return;
        }

        // Get the environment camera (usually the second one)
        window.navigator.mediaDevices.enumerateDevices().then((devices) => {

            const cameras = devices.filter((device) => device.kind === 'videoinput');
            if (cameras.length === 0) {
                alert('No camera found. If your device has camera available, check permissions.');
                return;
            }

            const camera = cameras[cameras.length - 1];

            window.navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: camera.deviceId
                }
            }).then((stream) => {
                this.track = stream.getVideoTracks()[0];

//                 if (!(this.track.getCapabilities().torch)) {
//                     alert("No torch available.");
//                 };
            });
        });
  }

  openCamera(){
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
       this.isCameraOpened = true;
       navigator.mediaDevices.getUserMedia({
         video: {
          width: this.service.getWidth(),
          height: this.service.getHeight(),
          facingMode: 'enviroment'
         }
       }).then((stream) => {
             this.video.srcObject = stream;
             this.video.play();
             this.video.onloadeddata = () => {
             };
             this.track = stream.getVideoTracks()[0];
            // Create image capture object and get camera capabilities
             this.imageCapture = new (window as any).ImageCapture(this.track);
       });
    }
  }

  getOldImg(){
    this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
    this.outgoingRequest.setDescendinglastAt(this.incomingForm.getResponses().data[this.incomingForm.getResponses().data.length - 1].id);
    this.outgoingRequest.setCategoryId(this.incomingForm.getCategoryId());
    this.outgoingRequest.setFormId(this.incomingForm.getId());
    this.service.httpService('post', this.service.app.routes.reports.get, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (responses: any) => {
     if (responses.length === 0) {
      this.hasOldImg = false;
      clearInterval(this.interval2);
     }responses.map((response: any) => {
      this.outgoingResponse.setAttributes(response);
      this.incomingForm.getResponses().data.push(this.outgoingResponse.getAttributes());
     });
    }, (error: any) => {
    });
  }

  switchFlash(status: any){
    this.imageCapture = new (window as any).ImageCapture(this.track);
    const photoCapabilities: any = this.imageCapture.getPhotoCapabilities().then(() => {
      this.track.applyConstraints({
        advanced: [{torch: status}]
      });
    });
  }

  setFlashlightStatus(status) {
      this.accessFlashlight();
      this.track.applyConstraints({
          advanced: [{
              torch: status
          }]
      });
  }

  cancelCaptureCameraImg(){
   this.webcamImage = null;
   this.picUrl = null;
   this.showImg = false;
   this.selectedTabIndex = 3;
  }



  sendCapturedCameraImg(drawer: any){
      this.service.isWaitScreen = true;
      const outgoingPosts: any = JSON.stringify(this.posts);
      this.isUploading = true;
//    this.router.navigateByUrl('/wait..');
//    this.notifier.notify('success', this.service.app.strings.images.publish);
      this.service.httpService('post', this.service.app.routes.responses.post, {posts: outgoingPosts}, {}, (response: any) => {
            this.outgoingImgRequest.setAttributes({});
            this.outgoingImgRequest.setPostId(this.incomingResponse.getPostId());
            this.outgoingImgRequest.setFormControlId(this.incomingQuestion.attributes.id);
            this.outgoingImgRequest.setData('');
            this.outgoingImgRequest.setResult(this.incomingQuestion.getResult());
            this.outgoingImgRequest.setControlId(this.incomingQuestion.getControlId());
            this.outgoingImgRequest.setStatus(0);
            this.outgoingImgRequest.setUnknown('pic', '');
            this.outgoingImgRequest.setCntrlNum(this.incomingQuestion.getCntrlNum());
            this.outgoingImgRequest.setFile(this.incomingQuestion.getSrc());
            this.service.httpService( 'post', this.service.app.routes.responses.images.upload, this.outgoingImgRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
            this.getlatestResponses((result: any)=>{
            this.selectedTabIndex = 3;
            this.isUploading = false;
            this.webcamImage = null;
            this.picUrl = null;
            this.playSoundUpcomingmessage((result: any) => {});
            });
//          this.notifier.notify('success', this.service.app.strings.success.upload);
//          window.history.back();
            }, () => {});
         }, (error: any) => {});
  }


  capturePic(){
     if (!this.isImgCaptured){
          this.isImgCaptured = true;
//           this.playSoundCamera((result: any) => {
               this.incomingQuestion.setAttributes(this.question);
               this.picUrl = this.webcamImage.imageAsDataUrl;
      //       this.incomingQuestion.setUnknown('pic', this.picUrl);
               this.incomingQuestion.setSrc(this.service.dataURItoBlob(this.picUrl));
               this.posts = [];
               this.questions = [];
               this.formService.questions.map((question: any) => {
                   this.outgoingQuestion.setAttributes({});
                   this.outgoingQuestion.attributes = Object.assign(this.outgoingQuestion.attributes, question);
                   this.incomingQuestion.setResult(this.incomingQuestion.hasAttribute('result') &&
                      !this.service.empty(this.outgoingQuestion.getResult()) ?
                      JSON.stringify(this.outgoingQuestion.getResult()) : '');
                   this.outgoingQuestion.attributes.form_control_id = this.outgoingQuestion.attributes.control.id;
                   this.outgoingQuestion.attributes = Object.assign(this.outgoingQuestion.getAttributes(), {data: this.service.empty(this.outgoingQuestion.attributes.data) ? '' : this.outgoingQuestion.attributes.data});
                   delete this.outgoingQuestion.attributes.options;
                   this.outgoingQuestion.setUnknown('pic', '');
                   this.questions.push(this.outgoingQuestion.attributes);
               });
               this.incomingResponse.setEndingAt(moment(new Date()).format('HH:mm:ss'));
               this.incomingResponse.setTitle('');
               this.incomingResponse.setDepartmentId(1);
               this.incomingResponse.setOutletId(this.incomingResponse.getAttributes().hasOwnProperty('outlet_id') ?
               this.incomingResponse.getOutletId() :
               this.responseService.outletId);
               this.incomingResponse.setStatus(parseFloat('0'));
               this.incomingResponse.setPostId(this.service.random());
               this.incomingResponse.setLat(this.incomingResponse.attributes.hasOwnProperty('lat') ?
               this.incomingResponse.getLat() : 0);
               this.incomingResponse.setLng(this.incomingResponse.attributes.hasOwnProperty('lng') ?
               this.incomingResponse.getLng() : 0);
               this.incomingResponse.setAltitude(this.incomingResponse.attributes.hasOwnProperty('altitude') ?
               this.incomingResponse.getAltitude() : 0);
               if (this.incomingResponse.attributes.hasOwnProperty('datas'))
                delete this.incomingResponse.attributes.datas;
               if (this.incomingResponse.attributes.hasOwnProperty('id') &&
                this.incomingResponse.attributes.hasOwnProperty('form'))
                delete this.incomingResponse.attributes.form;
               if (this.incomingResponse.attributes.hasOwnProperty('department'))
                delete this.incomingResponse.attributes.department;
               if (this.incomingResponse.attributes.hasOwnProperty('posted_by'))
                delete this.incomingResponse.attributes.posted_by;
               this.incomingResponse.setControls(this.questions);
               this.posts.push(this.incomingResponse.getAttributes());
//           });
      }
//     if (!this.isImgCaptured){
//         this.isImgCaptured = true;
//         this.playSoundCamera((result: any) => {
//              this.incomingQuestion.setAttributes(this.question);
//              this.canvas.width  = this.service.getWidth();
//              this.canvas.height = this.service.getHeight();
//              this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.service.getWidth(), this.service.getHeight());
//              this.picUrl = this.canvas.toDataURL('image/png');
//     //       this.incomingQuestion.setUnknown('pic', this.picUrl);
//              this.incomingQuestion.setSrc(this.service.dataURItoBlob(this.picUrl));
//              this.posts = [];
//              this.questions = [];
//              this.formService.questions.map((question: any) => {
//                  this.outgoingQuestion.setAttributes({});
//                  this.outgoingQuestion.attributes = Object.assign(this.outgoingQuestion.attributes, question);
//                  this.incomingQuestion.setResult(this.incomingQuestion.hasAttribute('result') &&
//                     !this.service.empty(this.outgoingQuestion.getResult()) ?
//                     JSON.stringify(this.outgoingQuestion.getResult()) : '');
//                  this.outgoingQuestion.attributes.form_control_id = this.outgoingQuestion.attributes.control.id;
//                  this.outgoingQuestion.attributes = Object.assign(this.outgoingQuestion.getAttributes(), {data: this.service.empty(this.outgoingQuestion.attributes.data) ? '' : this.outgoingQuestion.attributes.data});
//                  delete this.outgoingQuestion.attributes.options;
//                  this.outgoingQuestion.setUnknown('pic', '');
//                  this.questions.push(this.outgoingQuestion.attributes);
//              });
//              this.incomingResponse.setEndingAt(moment(new Date()).format('HH:mm:ss'));
//              this.incomingResponse.setTitle('');
//              this.incomingResponse.setDepartmentId(1);
//              this.incomingResponse.setOutletId(this.incomingResponse.getAttributes().hasOwnProperty('outlet_id') ?
//              this.incomingResponse.getOutletId() :
//              this.responseService.outletId);
//              this.incomingResponse.setStatus(parseFloat('0'));
//              this.incomingResponse.setLat(this.incomingResponse.attributes.hasOwnProperty('lat') ?
//              this.incomingResponse.getLat() : 0);
//              this.incomingResponse.setLng(this.incomingResponse.attributes.hasOwnProperty('lng') ?
//              this.incomingResponse.getLng() : 0);
//              this.incomingResponse.setAltitude(this.incomingResponse.attributes.hasOwnProperty('altitude') ?
//              this.incomingResponse.getAltitude() : 0);
//              if (this.incomingResponse.attributes.hasOwnProperty('datas')) {
//              delete this.incomingResponse.attributes.datas;
//              }
//              if (this.incomingResponse.attributes.hasOwnProperty('id') &&
//              this.incomingResponse.attributes.hasOwnProperty('form')) {
//              delete this.incomingResponse.attributes.form;
//              }
//              if (this.incomingResponse.attributes.hasOwnProperty('department')) {
//              delete this.incomingResponse.attributes.department;
//              }
//              if (this.incomingResponse.attributes.hasOwnProperty('posted_by')) {
//              delete this.incomingResponse.attributes.posted_by;
//              }
//              this.incomingResponse.setControls(this.questions);
//              this.posts.push(this.incomingResponse.getAttributes());
//              const outgoingPosts: any = JSON.stringify(this.posts);
//              this.router.navigateByUrl('/wait..');
//              this.service.httpService('post', this.service.app.routes.responses.post, {posts: outgoingPosts}, {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
//                 this.outgoingImgRequest.setAttributes({});
//                 this.outgoingImgRequest.setPostId(this.incomingResponse.getPostId());
//                 this.outgoingImgRequest.setFormControlId(this.incomingQuestion.attributes.id);
//                 this.outgoingImgRequest.setData('');
//                 this.outgoingImgRequest.setResult(this.incomingQuestion.getResult());
//                 this.outgoingImgRequest.setControlId(this.incomingQuestion.getControlId());
//                 this.outgoingImgRequest.setStatus(0);
//                 this.outgoingImgRequest.setUnknown('pic', '');
//                 this.outgoingImgRequest.setCntrlNum(this.incomingQuestion.getCntrlNum());
//                 this.outgoingImgRequest.setFile(this.incomingQuestion.getSrc());
//                 this.service.httpService( 'post', this.service.app.routes.responses.images.upload, this.outgoingImgRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
//                 window.history.back();
//                 }, () => {});
//              }, (error: any) => {});
//         });
//     }
  }

  playSoundCamera(callback: any){
         this.voiceCamera = new Audio('../assets/audio/camera.wav');
         this.voiceCamera.play();
         this.voiceCamera.onended = () => {
             callback(null);
         };
  }

  playSoundUpcomingmessage(callback: any){
           const sound: any = new Audio('../assets/audio/message.mp3');
           sound.play();
           sound.onended = () => {
               callback(null);
           };
  }

  ngAfterViewInit(){
   this.video  = document.getElementById('video');
   this.canvas = document.getElementById('canvas');
   setTimeout(() => {
//     this.accessFlashlight();
   });
// this.openCamera();
  }

  openGallery(){
  }

  getImage(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    this.incomingQuestionResults.setAttributes({});
    reader.onload = () => {
      this.incomingQuestion.setSrc(this.service.dataURItoBlob(reader.result));
      this.incomingQuestion.setUnknown('pic', reader.result);
      this.incomingQuestion.setUnknown('imgSrc', reader.result);
      this.isImgCaptured = false;
      this.webcamImage = this.webcamImage instanceof Object ? this.webcamImage  :  {};
      this.webcamImage.imageAsDataUrl = reader.result;
      this.capturePic();
      this.zone.run(() => {
      this.selectedTabIndex = 2;
      });
//    this.sendCapturedCameraImg(null);
    };
  }

}
