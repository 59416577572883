import {Component, Input, OnInit} from '@angular/core';
import * as moment from "moment";
import {AppService} from "../../../app.service";

@Component({
  selector: 'app-date-controls',
  templateUrl: './date-controls.component.html',
  styleUrls: ['./date-controls.component.scss']
})
export class DateControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  date;
  constructor(private service: AppService) {
  }

  ngOnInit(): void {
    this.date = this.service.empty(this.question.data) ? new Date() : new Date(this.question.data);
  }

  onSelectDate(event: any){
    this.question.data = moment(new Date(event)).format('MM/DD/YYYY');
    this.date = event;
  }

}
