import { Component, OnInit } from '@angular/core';
import {HeaderComponent} from "../header/header.component";
import {AppService} from "../app.service";

@Component({
  selector: 'app-venue',
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.scss']
})
export class VenueComponent implements OnInit {

  appSetupurl: any  = '../assets/json/setup.json';
  setup: any;
  constructor(public service: AppService,
              private headerComponent: HeaderComponent) {
    this.headerComponent.headerClass = 'header-2';
  }

  ngOnInit(): void {
    this.service.httpService( 'get', this.appSetupurl, {}, {}, (setup: any) => {this.setup = setup;},  (error: any) => {});
  }

}
