import { Component, Input,OnInit } from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from "../../../../parameters";
import {FormService} from "../../../form.service";

@Component({
  selector: 'app-draft-ranks',
  templateUrl: './draft-ranks.component.html',
  styleUrls: ['./draft-ranks.component.scss']
})
export class DraftRanksComponent implements OnInit {

  @Input() question: any;
    @Input() position: any;
    controls: any;
    incomingQuestion: any;
    constructor(public service: AppService,
                private formService: FormService) {
      this.incomingQuestion = new Parameters();
    }
    ngOnInit(): void {
      this.incomingQuestion.setAttributes(this.question);
      this.controls = this.service.app.data.controls;
    }

  addOptional(){
    var text: any = prompt('', '');
    if (!this.service.empty(text)) {
      this.incomingQuestion.setAttributes(this.question);
      this.formService.addOptional(this.incomingQuestion.getOptions(), text);
    }
  }

}
