import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {AppService} from "../../../../app.service";
import {MatDialog} from "@angular/material/dialog";
import {FormService} from "../../../form.service";
import {DomSanitizer} from "@angular/platform-browser";
import {BroadcastChannelService} from "../../../../broadcast-channel.service";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatMenuTrigger} from "@angular/material/menu";
import {map, startWith} from "rxjs/operators";
import {Parameters} from "../../../../parameters";
import {AddControlsComponent} from "../../add/add-controls.component";

@Component({
  selector: 'app-draft-group-controls',
  templateUrl: './draft-group-controls.component.html',
  styleUrls: ['./draft-group-controls.component.scss']
})
export class DraftGroupControlsComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild('fruitInput') fruitInput: any;
  @ViewChild('auto') matAutocomplete: any;
  @Input() question: any;
  @Input() position: any;
  @Input() deletedOptions: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  questionCtrl = new FormControl();
  filteredOptions: Observable<any>;
  option: any;
  options: any = [];
  allOptions: any;
  label: any;
  controls: any;
  selectedOptional: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public formServce: FormService,
              private sanitizer: DomSanitizer,
              public broadcastChannel: BroadcastChannelService) {
    this.contextMenu = MatMenuTrigger;
    this.matAutocomplete = MatAutocomplete;
    this.fruitInput = ElementRef;
    this.allOptions = [];
    this.contextMenu = MatMenuTrigger;
    this.controls = this.service.app.data.controls;
    this.filteredOptions = this.questionCtrl.valueChanges.pipe(
        startWith(null),
        map((optional: string | null) => optional ? this._filter(optional) : this.allOptions.slice()));
  }

  ngOnInit(): void {
    this.options = this.question.options;
    this.formServce.question = this.question;
  }

  onContextMenu(event: MouseEvent, control: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': control };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  trustUrl(){
    return this.sanitizer.bypassSecurityTrustUrl('http://localhost/videos/I.Still.Believe.2020.720p.BluRay.x264.AAC-%5bYTS.MX%5d.mp4');
  }



  handleOption(option: any){
    this.label = prompt('', option.label);
    if (this.label !== null) option = Object.assign(option, {label: this.label});
  }

  getImage(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formServce.optionalFiles[this.selectedOptional.cntrl_num] = {
        media : this.service.dataURItoBlob(reader.result),
        pic: reader.result
      };
    };
  }

  handleAddQuestional(optional: any){
    const dialogRef = this.dialog.open(AddControlsComponent, {
      data  : {optional: optional, position: this.position},
      width : '60%',
      height: '70%',
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  addOptionalEvent(){
    var text: any = prompt('', 'Write optional title');
    if (!this.service.empty(text)) this.formServce.addOptional(this.question.options, text);
  }

  add(event: any): void {
    const value = (event.value || '').trim();
    // Add our filteredJudgmentCondition
    if (value) {
      this.option  = new Parameters();
      this.option.setAttributes({});
      this.option.setLabel(value);
      this.option.setUnknown('cntrl_num', this.service.random());
      this.option.setQuestions([]);
      this.options.push(JSON.parse(JSON.stringify(this.option.attributes)));
    }

    // Clear the input value
    // event.chipInput!.clear();
    this.fruitInput.nativeElement.value = '';
    this.questionCtrl.setValue(null);
  }

  remove(option: any): void {
    const index = this.options.indexOf(option);
    if (index === -1){} else {
      this.options.splice(index, 1);
      if(option.hasOwnProperty('id')) {
        this.deletedOptions.push(option);
      }
    }
  }

  addOptional(): void {
    this.option  = new Parameters();
    this.option.setAttributes({});
    this.option.setLabel('Untitled');
    this.option.setUnknown('cntrl_num', this.service.random());
    this.option.setQuestions([]);
    this.options.unshift(JSON.parse(JSON.stringify(this.option.attributes)));
    //this.handleOption(this.options[0]);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.options.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.questionCtrl.setValue(null);
  }

  private _filter(value: any): any {
    const filterValue = value.toLowerCase();
    return this.allOptions.filter(fruit => fruit.label.toString().toLowerCase().includes(filterValue));
  }

}
