<mat-drawer-container class="example-container" [hasBackdrop]="true">
    <mat-drawer #drawer
                [mode]="'push'"
                [ngStyle]="{'width': '100%','height': '100%','z-index': '999999999'}">
        <i (click)="onCloseDrawer(drawer);drawer.toggle()"
           class="material-icons"
           [ngStyle]="{'position': 'absolute','top': '5px','right': '5px','z-index': '999999999'}">cancel</i>
        <agm-map
                #gm
                [latitude]="currentLocationLat"
                [longitude]="currentLocationLng"
                [fitBounds]="true"
                [zoom]="zoom"
                [disableDefaultUI]="true"
                [styles]="service.app.settings.map.styles.style1"
                style="height : {{getWindow().height + 'px'}}">
<!--            <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>-->
                <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
            <ng-container *ngFor="let outlet of service.app.data.outlets.data">
                <ng-container *ngIf="!service.empty(outlet.lat)">
                    <agm-marker
                            [ngStyle]="{'background-color':'#000'}"
                            [latitude]="service.parseFloat(outlet.lat)"
                            [longitude]="service.parseFloat(outlet.lng)"
                            [agmFitBounds]="true"
                            [iconUrl]="icon"
                            [openInfoWindow]="true">
                    </agm-marker>
                </ng-container>
            </ng-container>
        </agm-map>
    </mat-drawer>
    <mat-drawer-content>
        <div  [ngStyle]="{'position': 'absolute','top': '20px','right': '20px','z-index': '999999999'}">
            <tabl>
                <td [ngStyle]="{'padding-right': '20px','color': '#fff'}"
                    class="cursor">
                    <div class="bubbleStyle">
                        <span class="sawasdee14">
                        {{this.outletService.incomingItemSelected.attributes.settings.item.name}}
                        </span>
                        <span matBadge="1"
                             *ngIf="!drawer.opened"
                             matBadgeColor="accent"
                             class="demo-section mitra">
                        </span>
                        <span class="bubbleStyle mitra17X6" [ngStyle]="{'margin-left': '30px','color': '#fff'}">press your order</span>
                    </div>
                </td>
                <td></td>
            </tabl>
        </div>
<!--        {{question.data | json}}-->
        <carousel [cellsToScroll]="1"
                  [cellsToShow]="1"
                  [height]="getWindow().height"
                  [autoplay]="false"
                  [arrows]="true"
                  [dots]="true"
                  [loop]="false">
            <div class="carousel-cell"
                 *ngFor="let option of incomingQuestion.getOptions(); let incremental = index">
                <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                     src="{{option.settings.item.avatar}}" #img />
            </div>
        </carousel>
<!--    freedns1.tanzaniawebhosting.com-->
        <!--<table [ngStyle]="{'width': '100%'}">-->
        <!--    <tr>-->
        <!--        <td>-->
        <!--            <mat-form-field class="example-full-width" appearance="outline">-->
        <!--                <input type="text"-->
        <!--                       class="sawasdee14"-->
        <!--                       autocomplete="off"-->
        <!--                       placeholder="Search for supplier.."-->
        <!--                       matInput-->
        <!--                       #elmCntrlSearch-->
        <!--                       [ngStyle]="{'text-align': 'center','width': '100%'}"-->
        <!--                       (input)="onInputTextChange($event)"-->
        <!--                       [formControl] = "itemControl"-->
        <!--                       [matAutocomplete] = "item">-->
        <!--                <i class="materials-icon pull-right cursor"-->
        <!--                   (click)="openGeolocation()">add</i>-->
        <!--                <mat-autocomplete #item = "matAutocomplete"-->
        <!--                                  (optionSelected)="onOptionSelected($event)"-->
        <!--                                  [displayWith]="displayFn">-->
        <!--                    <mat-option *ngFor="let item of filteredItemsOptions | async; let itemOf = index" [value]= "item" >-->
        <!--                        {{item.name}}-->
        <!--                    </mat-option>-->
        <!--                </mat-autocomplete>-->
        <!--                <h4 style="color: red; font-weight: normal"-->
        <!--                    *ngIf="service.empty(elmCntrlSearch.value) === false &&-->
        <!--                                         ((filteredItemsOptions | async) | json).length === 2">-->
        <!--                    **-&#45;&#45;Not Found *-&#45;&#45;-->
        <!--                </h4>-->
        <!--            </mat-form-field>-->
        <!--        </td>-->
        <!--    </tr>-->
        <!--</table>-->
    </mat-drawer-content>
</mat-drawer-container>