<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <mat-calendar [selected]="startingAt" (selectedChange)="onSelectStartingAt($event)" id="startingAt"></mat-calendar>
</div>
<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <mat-calendar [selected]="endingAt" (selectedChange)="onSelectEndingAt($event)"></mat-calendar>
</div>
<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <center>
            <ul class="list-inline">
                <li>
                    <a class="btn btn-xs btn-default defaultColor2" mat-fab
                       (click)="closeDialog()">
                        <i class="material-icons">close</i>
                    </a>
                </li>
                <li>
                    <a class="btn btn-xs btn-default defaultColor2" mat-fab
                       (click)="takeDateDiff()">
                        <i class="material-icons">done</i>
                    </a>
                </li>
            </ul>
        </center>
    </div>
</div>