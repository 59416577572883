import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {AppService} from '../app.service';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {MethodsEmployeesService} from '../employees/methods-employees.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    @Output() onOpenDialog = new EventEmitter();
    app: any;
    selectedIndexValue: any = 0;
    searchCntrl: any;
    constructor(public service: AppService,
                private location: Location,
                private employeeService: MethodsEmployeesService,
                private broadcastChannel: BroadcastChannelService) {
      this.app = this.service.app;
    }

    ngOnInit(): void {
    }

    iframeHeight(){
    return window.innerHeight;
    }

    openDialog(){
      this.employeeService.openDialog();
    }

    modules(){ return this.app.settings.modules.slice(0, 2);}


    load(){}

    back(){ this.location.back(); setTimeout(() => { if(window.location.href.toString().includes('wait..')) this.forward(); }, 900);}

    forward(){ this.location.forward();}


    onSearchTextChange(s: any){
      this.broadcastChannel.emitNavChangeEvent({searchText: s});
    }

    handleSearch(){
      this.broadcastChannel.emitNavChangeEvent('search');
    }

    enterKeyUp(s: any){
      this.broadcastChannel.emitNavChangeEvent({searchText: s, status: 'enter'});
    }

    getUrl(){return window.location.href;}

}
