import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from "../../../../app.service";
import {Parameters} from "../../../../parameters";
import {FormService} from '../../../form.service';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
@Component({
  selector: 'app-text-preview-controls',
  templateUrl: './text-preview-controls.component.html',
  styleUrls: ['./text-preview-controls.component.scss']
})
export class TextPreviewControlsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('element', {static: false})  element: ElementRef;
  @ViewChild('matAutocompleteTrigger', {static: false}) matAutocompleteTrigger: any;
  @Input() question: any;
  @Input() position: any;
  label: any;
  incomingSettings: any;
  incomingQuestion: any;
  result: any = true;
  suggestionControl = new FormControl();
  filteredSuggestions: any;
  constructor(public service: AppService,
    private broadcastChannel: BroadcastChannelService,
    public formService: FormService) {
    this.incomingQuestion = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingSettings.setAttributes({});
    this.matAutocompleteTrigger = MatAutocompleteTrigger;
  }

  ngOnInit(): void {
  }

  onSuggestionSelectionChanged(event: any){
      this.element.nativeElement.value  = event.option.value.description;
      this.incomingQuestion.setData(event.option.value.description);
      this.suggestionControl.setValue(event.option.value);
      this.broadcastChannel.emitNavChangeEvent({action:'nextQuestion'});
  }

  getSuggestions(event: any){
//       setTimeout(() => {
//         this.service.getSuggestions(event.target.value, () => {
//           if(this.service.empty(event.target.value)){
// //          this.startSuggestions();
//           }
//
//         });
//       });
  }

  addSuggestion(event: any){
    this.service.setSuggestion(event.target.value, (result: any) => {
    });
  }

  displayFn(value: any){
    return value.description;
  }

  startSuggestions(){
      this.filteredSuggestions = this.suggestionControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.description),
          map(name => name ? this._filterSuggestion(name) : this.service.app.data.suggestions.slice())
      );
  }

  private _filterSuggestion(name: string) {
      const filterValue: any = name.toString().toLowerCase();
      return this.service.app.data.suggestions.filter((option: any) => option.description.toString().toLowerCase().includes(filterValue));
  }

  ngOnChanges(changes: SimpleChanges): void {
     this.service.app.data = Object.assign(this.service.app.data, {suggestions: this.service.app.data.hasOwnProperty('suggestions') ?  this.service.app.data.suggestions : []});
     this.incomingQuestion.setAttributes(this.question);
     this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
     this.incomingQuestion.setData(this.service.empty(this.incomingQuestion.getData()) &&
     (this.incomingSettings.attributes.hasOwnProperty('_default') &&
     !this.service.empty(this.incomingSettings.getDefault())) ?
     this.incomingSettings.getDefault() :
     this.incomingQuestion.getData());
     this.startSuggestions();
     this.ngAfterViewInit();
     setTimeout((suggestions: any) => {
      this.element.nativeElement.value= this.incomingQuestion.getData();
      suggestions = this.service.app.data.suggestions.filter((suggestion: any)=>{return suggestion.description.toString().toLowerCase().includes(this.incomingQuestion.getData().toString().toLowerCase());});
//    if(suggestions.length !== 0) this.suggestionControl.setValue(suggestions[0]);

      this.matAutocompleteTrigger.closePanel();
     });
  }

  onInputTextChange(event: any){
    this.incomingQuestion.setAttributes(this.question);
    this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
    this.incomingQuestion.setData(event.target.value);
    if(this.service.empty(event.target.value)){
     if(this.incomingSettings.attributes.hasOwnProperty('_default')){
      if(!this.service.empty(this.incomingSettings.getDefault())){
       this.incomingQuestion.setData(this.incomingSettings.getDefault());
      }
     }
    }
  }

  validateTextLength(event: any){
    var e = event || window.event;
    var key = e.keyCode || e.which;
    if(key === 8){
    }else {
        if(!this.service.empty(event.target.value)){
          this.incomingQuestion.setAttributes(this.question);
          this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
          if(this.incomingSettings.attributes.hasOwnProperty('text_size')){
           if(!this.service.empty(this.incomingSettings.attributes.text_size)){
             if(parseFloat(this.incomingSettings.attributes.text_size) < event.target.value.toString().length){
              // input is INVALID
              e.returnValue = false;
              if (e.preventDefault)
                 e.preventDefault();
             }
           }
          }
       }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() =>{
      this.matAutocompleteTrigger.closePanel();
      if(this.element.nativeElement){
        this.element.nativeElement.focus();
      }
    });
  }

}

// if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
//     navigator.mediaDevices.getUserMedia({video: true})
// }

