import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';
import * as moment from 'moment';
import {BroadcastChannelService} from '../../../broadcast-channel.service';

@Component({
  selector: 'app-date-preview-controls',
  templateUrl: './date-preview-controls.component.html',
  styleUrls: ['./date-preview-controls.component.scss']
})
export class DatePreviewControlsComponent implements OnInit, OnDestroy {

  @Input() question: any;
  @Input() position: any;
  date: any;
  subscription: any;
  constructor(public service: AppService,
    private broadcastChannel: BroadcastChannelService) {
  }

  ngOnInit(): void {
    this.date = this.service.empty(this.question.data) ? null : new Date(this.question.data);
    this.subscription = this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('null') === true) {
              if(this.service.empty(this.question.data)){
                this.date = null;
              }
            }
          }
        });
  }

  onSelectDate(event: any){
    this.question.data = moment(new Date(event)).format('MM/DD/YYYY');
    this.date = event;
    this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
