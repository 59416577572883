<div [ngStyle]="{'position':'fixed','bottom':'20%','right':'5%','z-index':'9999999'}" >
    <center>
        <div [ngStyle]="{'padding':'50px','background-color':'#000'}">
            <div class="sawasdee18X2"
                 [ngStyle]="{'color':'#fff'}">
                {{service.upperStartingCharacter(incomingForm.getName(), true)}}
                <div>[scan to view or <br>add photos]</div>
            </div>
        </div>
        <div id="qrcode"></div>
       <span class="mitra" [ngStyle]="{'color':'#fff','font-size':'22px'}">
<!--            scan to view or add photo-->
       </span>
    </center>
</div>
<div class="page">
    <div class="clearfix"></div>
    <div class="row">f
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div>
                <div class="fixed">
                    <div class="roww">
                        <div class="col-md-12 col-sm-12 col-xs-12">
<!--                            <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="incomingCampaign.attributes.forms.data.length > 0">-->
<!--                                <div class="tile-stats" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;">-->
<!--                                    <div class="pull-right">-->
<!--                                        <table>-->
<!--                                            <td class='headline2 selectedMatTab2 cursor' (click)="matMenuStatus = 2; onContextMenu($event, {})"><i class="material-icons">view_comfy</i></td>-->
<!--                                            <td valign="top" class='headline2 selectedMatTab2'>{{incomingClient.getName().toString().toLowerCase()}}</td>-->
<!--                                            <td [hidden]='true'><img class ='img-thumbnailx' src="{{incomingClient.getLogo()}}" style="min-width: 60px; max-height: 25px; float: right;margin-left: 5px"/></td>-->
<!--                                        </table>-->
<!--                                    </div>-->
<!--                                    <div class="pull-left">-->
<!--                                        <table>-->
<!--                                            <td class="{{incomingForm.getId('') === form.id ? 'selectedMatTab': ''}} cursor"-->
<!--                                                style="margin-left: 10px; padding-right: 50px"-->
<!--                                                *ngFor="let form of incomingCampaign.attributes.forms.data"-->
<!--                                            >-->
<!--                                                <div class="headline">{{form.name.toString().toUpperCase()}}</div>-->
<!--                                            </td>-->
<!--                                        </table>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="row top_tiles" [ngStyle]="{'padding': '2px'}">
                                <div [ngStyle]="{'width':this.service.getWidth()+'px'}">
                                    <mat-grid-list cols="{{service.getWidth()/175}}" rowHeight="1:1">
                                        <ng-container *ngFor="let response of (form.responses.data | jsonFilter:searchCntrl);let incrementalResponse=index">
                                            <ng-container *ngFor="let responseResult of response.datas;let incrementalResponseResult=index">
                                                <ng-container *ngIf="incrementalResponseResult === 0">
                                                    <mat-grid-tile  *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">
                                                        <div class="cursor demo-card-image mdl-card mdl-shadow--2dp"
                                                             style="animation: deg20 5s infinite;background:url({{responseResult.data}}) center / cover"
                                                             (click)="view(responseResult)">
                                                        </div>
                                                    </mat-grid-tile>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </mat-grid-list>
                                </div>
                                <div *ngFor="let response of (form.responses.data | jsonFilter:searchCntrl)">
                                    <div *ngFor="let responseResult of response.datas">
<!--                                            <ng-container class=""-->
<!--                                                 *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">-->
<!--                                                <div class="responsiveImg">-->
<!--                                                    <div class="gallery">-->
<!--                                                        <a>-->
<!--                                                            <img src="{{responseResult.data}}"-->
<!--                                                                 alt=""-->
<!--                                                                 [ngStyle]="{'height':'175px'}"/>-->
<!--                                                        </a>-->
<!--                                                    </div>-->
<!--                                                </div>-->

                                                <!--                                                <div class="cursor demo-card-image mdl-card mdl-shadow&#45;&#45;2dp pull-left"-->
<!--                                                     style="background:url({{data.data}}) center / cover"-->
<!--                                                    (click)="view(data)">-->
<!--                                                   -->

<!--                                                  -->
<!--&lt;!&ndash;                                                    <div class="mdl-card__title mdl-card&#45;&#45;expand"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="mdl-card__actions">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <span class="demo-card-image__filename">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <table *ngIf="response.hasOwnProperty('outlet')" class="headline2">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <td [ngStyle]="{'color':'#fff'}">{{response.outlet.name + ',' + response.outlet.address}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                 <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <td [ngStyle]="{'color':'#fff'}">{{data.reference.date  + '(' + data.reference.time + ')'}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </table>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--                                                </div>-->
<!--                                            </ng-container>-->
<!--                                            <div class="tile-stats cursor col-lg-1 col-md-1 col-sm-2 col-xs-2"-->
<!--                                                 style="height:175px;background-repeat:no-repeat;background:url({{responseResult.data}}) center / cover"-->
<!--                                                 *ngIf="responseResult.formControl.control.id === 2 && !service.empty(responseResult.data)">-->
<!--                                            </div>-->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

