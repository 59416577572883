import {Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy, AfterContentInit} from '@angular/core';
import {AppService} from '../app.service';
import * as $ from 'jquery';
import Typewriter from '../../assets/plugin/typewriter-effect/dist/core';
import {CampanyService} from "../campanies/campany.service";
import {map, startWith} from "rxjs/internal/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {MatDialog} from "@angular/material/dialog";
import {DatediffComponent} from "../tools/datediff/datediff.component";
import {CategoryService} from "../categories/category.service";
import {BroadcastChannelService} from "../broadcast-channel.service";
import {Parameters} from "../parameters";
import {HeaderComponent} from "../header/header.component";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})



export class HomeComponent implements OnInit, AfterContentInit, OnDestroy {
  companyControl: any = new FormControl();
  categoryControl: any = new FormControl();
  formValues: FormGroup = new FormGroup({});
  appimgsUrl = '../assets/json/images.json';
  appeventsUrl = '../assets/json/events.json';
  appcategoriesUrl = '../assets/json/categories.json';
  appclientsUrl = '../assets/json/clients.json';
  appfaqUrl = '../assets/json/faq.json';
  apphomeUrl = '../assets/json/home.json';
  filteredCompanies: any;
  filteredCategories: any;
  dialogDateDiff: any;
  subscription: any;
  incomingItem: any;
  incomingResult: any;
  incomingStrings: any;
  images: any = [];
  events: any = [];
  categories: any = [];
  clients: any =[];
  lat: any = -6.8643365;
  lng: any = 39.2712821;
  faq: any = [];
  home: any = [];
  constructor(public service: AppService,
              public  formBuilder: FormBuilder,
              private dialog: MatDialog,
              public  broadcastChannel: BroadcastChannelService,
              private headerComponent: HeaderComponent,
              private categoryService: CategoryService,
              private companyService: CampanyService) {
    this.incomingItem = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingStrings = new Parameters();
    this.incomingItem.setAttributes({});
    this.incomingResult.setAttributes({});
    this.incomingStrings.setAttributes({});
  }

  ngOnInit(): void {
    this.resetCategory();
    this.loadImgs();
    this.service.httpService( 'get', this.apphomeUrl, {}, {}, (home: any) => {this.home = home;},  (error: any) => {});
    this.service.httpService( 'get', this.appeventsUrl, {}, {}, (events: any) => { this.events = events;},  (error: any) => {});
    this.service.httpService( 'get', this.appcategoriesUrl, {}, {}, (categories: any) => { this.categories = categories;new Typewriter('.categoryStarter', {strings: this.categories.map((category: any) => {return category.toLowerCase();}), loop: true, autoStart: true,});},  (error: any) => {});
    this.service.httpService( 'get', this.appclientsUrl, {}, {}, (clients: any) => {this.clients = clients;},  (error: any) => {});
    this.service.httpService( 'get', this.appfaqUrl, {}, {}, (faq: any) => {this.faq = faq;},  (error: any) => {});
    this.headerComponent.headerClass = 'header-2';
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          this.incomingStrings.setAttributes(this.service.app.strings.params);
          if(item instanceof Object){
            this.incomingItem.setAttributes(item);
            if(this.incomingItem.getAttributes().hasOwnProperty('dateDiff')){
              this.incomingResult.setAttributes(this.incomingItem.getUnknown('dateDiff'));
              alert(moment(new Date(this.incomingResult.getStartingAt())).format('MM/DD/YYYY'));
            }else if(this.incomingItem.getAttributes().hasOwnProperty('navItem')){
              let elementId: any = document.getElementById(this.incomingItem.getUnknown('navItem'));
              elementId.scrollIntoView({behavior:"smooth"});

            }
          }
        });

  }

  ngOnDestroy(): void {
    this.headerComponent.headerClass = 'header-4';
  }

  loadImgs(){
    this.service.httpService( 'get', this.appimgsUrl, {}, {}, (images: any) => {
          this.images = images;
        },  (error: any) => {
        });
  }

  onCarouselSelectionChange(event: any){
    alert(77);
    console.log(event);
  }
  resetCategory(){
    this.filteredCategories = this.categoryControl.valueChanges.pipe(
        startWith(''),
        map((
            value: any) => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterCategories(name) : this.service.app.data.categories.data.slice())
    );
  }

  openDateDiffDialog(){
    this.dialog.open(DatediffComponent, {
      data  : {params: {}},
      width : '55%',
      height: '100%',
      disableClose: false,
    });
  }


  reset(){
    setTimeout(() => {
      this.filteredCompanies = this.companyControl.valueChanges.pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value.name),
          map(name => name ? this.filterCompany(name) : this.service.app.data.companies.data.slice())
      );

    });
  }

  onCompanyInputTextChange(event: any){
    this.companyService.search(event.target.value, (response: any) => {
      this.reset();
    });
  }

  onCategoryInputTextChange(event: any){
    this.categoryService.search(event.target.value, (response: any) => {this.resetCategory();});
  }

  private filterCompany(name: any) {
    const args: string = name.toLowerCase();
    return this.service.app.data.companies.data.filter((company: any) => {return JSON.stringify(company).toString().toLowerCase().includes(args); });
  }

  private filterCategories(name: any) {
    const args: string = name.toLowerCase();
    return this.service.app.data.categories.data.filter((category: any) => {return JSON.stringify(category).toString().toLowerCase().includes(args); });
  }

  displayFn(value: any){
    return value.name;
  }

  onCompanySelectionChanged(event: MatAutocompleteSelectedEvent){

  }

  onCategorySelectionChanged(event: MatAutocompleteSelectedEvent){

  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }


  ngAfterContentInit(): void {
    setTimeout(() => {
   // document.getElementsByTagName( 'body')[0].scrollTo(0, 0);
   // $.getScript( '../assets/js/calender.js?version=' + this.service.random(), ( data, textStatus, jqxhr ) => {});
   document.getElementsByTagName( 'body')[0].style.overflowY ='hidden';
      const mdlClasses = document.getElementsByClassName('android-header');
      for (var i = 0; i < mdlClasses.length; i++) {
        const mdlClass = mdlClasses[i];
        if (mdlClass instanceof HTMLElement) {
          // mdlClass.style.background = '#000';
          mdlClass.style.color = '#fff';
          mdlClass.style.background = 'rgba(30, 18, 18, 0.67)';
          // mdlClass.style.background = 'url(assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg)';
        }
      }
      new Typewriter('.startTyper', {
        strings: ['Discount up to 10%'],
        loop: true,
        autoStart: true,
      });
    });
  }

  getDateSelected(){
    return new Date();
  }

}
