<mat-tab-group>
  <mat-tab label="All({{provider.app.data.campanies.data.length}})">
    <br><br><app-search ></app-search><br><br>
    <app-table-campanies [flag]="'all'"></app-table-campanies>
  </mat-tab>
  <mat-tab label="Deleted({{provider.app.data.campanies.trashed.data.length}})">
    <br><br><app-search></app-search><br><br>
    <app-table-campanies [flag]="'restore'"></app-table-campanies>
  </mat-tab>
</mat-tab-group>
