import { Component, OnInit } from '@angular/core';
import {HeaderComponent} from "../header/header.component";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor(private headerComponent: HeaderComponent) {
  }

  ngOnInit(): void {
    this.headerComponent.headerClass = 'header-2';
  }

}
