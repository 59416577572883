<ng-container *ngIf="service.empty(service.logo)">
    <img  class="logo img-circle"
          src="../../assets/images/coat.png"
          (error)="onErrorHandle($event)"
          alt=""/>
</ng-container>

<ng-container *ngIf="!service.empty(service.logo)">
    <img  class="logo img-circle"
          src="{{service.logo}}"
          (error)="onErrorHandle($event)"
          alt=""/>
</ng-container>

<!--<img  class="logo img-circle"-->
<!--      *ngIf="isloadingComplete"-->
<!--      (load)="doSomething($event)"-->
<!--      src="{{logoDisplayed}}"-->
<!--      alt="logo"/>-->

<!--<mat-progress-bar-->
<!--        *ngIf="service.progress"-->
<!--        class="progressCSS"-->
<!--        mode="query">-->
<!--</mat-progress-bar>-->