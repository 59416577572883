<!--MAIN START-->
<div id="main">
    <div class="banner-outer banner-2 home">
        <div class="banner-2" [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()/2+'px'}">
            <!--            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
            <!--                 src="assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg" />-->
            <div class="img" [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()/2 + 'px','z-index':'0'}">
                <carousel [cellsToScroll]="1"
                          [cellsToShow]="1"
                          [autoplay]="true"
                          [arrows]="false"
                          [height]="service.getHeight()/2"
                          [dots]="false"
                          [loop]="true"
                          *ngIf="meetings.length!==0">
                    <div class="carousel-cell"
                         [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()/2+'px'}"
                         *ngFor="let meeting of meetings">
                        <img src="{{meeting}}"
                             class="mdl-shadow--2dp"
                             [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"/>
                    </div>
                </carousel>
            </div>
        </div>
    </div>
    <!--        &lt;!&ndash;INNER BANNER START&ndash;&gt;-->
<!--        <section id="inner-banner">-->
<!--            <div class="container">-->
<!--                <h1 class="mitra" [ngStyle]="{'color':'#fff'}">&nbsp;</h1>-->
<!--            </div>-->
<!--        </section>-->
<!--&lt;!&ndash;    INNER BANNER END&ndash;&gt;-->
<!--&lt;!&ndash;    RECENT JOB SECTION START&ndash;&gt;-->
    <section class="resumes-section padd-tb" [ngStyle]="{'color':'#888'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="resumes-content">
                        <div class="summary-box mitra">
                            <div>
                                <div class="android-more-section">
                                    <div class="android-section-title mdl-typography--display-1-color-contrast mitra">Offer</div>
                                    <div class="android-card-container mdl-grid">
                                        <div class="mdl-cell mdl-cell--5-col mdl-cell--6-col-tablet mdl-cell--6-col-phone mdl-card mdl-shadow--3dp">
                                            <div class="mdl-card__media">
                                                <img src="assets/images/where-house-logo.jpg">
                                            </div>
                                            <div class="mdl-card__title">
                                                <h4 class="mdl-card__title-text mitra">Discount 10 %</h4>
                                            </div>
                                            <div class="mdl-card__supporting-text">
                                                <span class="mdl-typography--font-light mdl-typography--subhead mitra">
                                                    Generator,
                                                    valid until 3 Dec 2023
                                                </span>
                                            </div>
                                            <div class="mdl-card__actions">
                                                <center>
                                                    <a class="btn btn-danger btn-lg mitra">
                                                        Reserve venue
    <!--                                                    <i class="material-icons">chevron_right</i>-->
                                                    </a>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--RECENT JOB SECTION END-->
</div>
<!--MAIN END-->