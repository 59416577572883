import { Injectable } from '@angular/core';
import {Parameters} from '../../../parameters';
import {AppService} from '../../../app.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  film: any;
     /**
       * @param mixed
       */

  id: any = 0;
    /**
      * @param mixed
      */
  filmUrl: any = 'assets/audio/filmmusic2.mp3';
     /**
       * @param mixed
       */

  isConfirmed: any = false;
    /**
      * @param mixed
      */
 isImgDialogOpened: any = false;
  /**
    * @param mixed
    */


  broadCastedResponses: any = [];
  /**
         * @param mixed
         */
  responseSelectedCntrlNum: any = null;
   /**
     * @param mixed
     */
  incomingResponse: any;
   /**
     * @param mixed
     */
  incomingUser: any;
   /**
     * @param mixed
     */
  incomingForm; any;
   /**
     * @param mixed
     */
  searchText: any = '';
   /**
     * @param mixed
     */
  outletId: any = 0;
   /**
     * @param mixed
     */
  yScrollingPart: any = 0;
   /**
     * @param mixed
     */
  xScrollingPart: any = 0;
  /**
   * @param mixed
   */

  reportValues: any;

  /**
   * @param mixed
   */

  userId: any = null;

  /**
   * @param mixed
   */

  getUserId(): void {
    return this.userId;
  }

  /**
   * @param mixed
   */

  setUserId(userId: any): void {
    this.userId = userId;
  }

  /**

   */

  data: any = [];

  /**
   * @param mixed Data
   */

  isDialogOpen: any = false;

  /**
   * @param mixed Data
   */


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  /**
   * @param mixed Data
   */

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }



  constructor(private service: AppService) {

    this.incomingForm = new Parameters();
    /**
         * @param mixed report
         */
    this.incomingUser = new Parameters();
    /**
         * @param mixed report
         */
    this.incomingResponse = new Parameters();
    /**
         * @param mixed report
         */

    this.reportValues = new Parameters();
    /**
     * @param mixed report
     */
    this.reportValues.setAttributes({});

    /**
     * @param mixed report
     */

    this.reportValues.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.reportValues.setTarget('all');

    /**
     * @param mixed report
     */

    this.reportValues.setType('responses');

    /**
     * @param mixed report
     */

    this.reportValues.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.reportValues.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.reportValues.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.reportValues.setData(null);
  }

  setPost(form: any, callback: any){
          setTimeout(() => {
              this.incomingForm.setAttributes(Object.assign({},form));
              this.incomingResponse.setAttributes({});
              this.incomingResponse.setFormId(this.incomingForm.getId());
              this.incomingResponse.setStartingAt(moment(new Date()).format('HH:mm:ss'));
              this.incomingResponse.setAltitude(0);
              this.incomingResponse.setDeviceUsage(this.service.getBrowserName());
              this.incomingResponse.setDepartmentId(1);
              this.incomingResponse.setAddress('');
              this.incomingResponse.setTitle('');
              this.incomingResponse.setStatus(0);
              this.incomingResponse.setLat(0);
              this.incomingResponse.setLng(0);
              this.incomingResponse.setPostId(this.service.random());
              this.incomingResponse.setCntrlNum(this.incomingResponse.getPostId());
              this.incomingResponse.setForm(Object.assign({}, this.incomingForm.getAttributes()));
              this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY'));
              this.incomingUser.setAttributes(this.service.app.data.status === 0 ? {} : this.service.app.data.user);
              if(Object.keys(this.incomingUser.attributes).length === 0){
               window.localStorage.setItem(this.incomingForm.getId(), JSON.stringify(this.incomingForm.getAttributes()));
              }else {
                  this.incomingResponse.setPostedBy(this.incomingUser.getAttributes());
                  this.incomingResponse.setUserId(this.incomingUser.getId());
              }this.responseSelectedCntrlNum = this.incomingResponse.getCntrlNum();
              callback(this.incomingResponse.getAttributes());
          });
  }

  playFilm(callback: any){
      this.film = new Audio(this.filmUrl);
   // this.film.volume = 0.5;
      this.film.play();
      this.film.onended = () => {
       callback(null);
       this.playFilm(callback);
      };
  }
}
