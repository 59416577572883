import { Component, OnInit } from '@angular/core';
import {HeaderComponent} from "../header/header.component";
import {AppService} from "../app.service";

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent implements OnInit {

  meetings: any = ["http://localhost/warehouse/warehouse-cover-2.jpg"];
  constructor(private headerComponent: HeaderComponent,
              public  service: AppService) {
  }

  ngOnInit(): void {
    this.headerComponent.headerClass = 'header-2';
  }

}
