import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";
import PlaceResult = google.maps.places.PlaceResult;
import {Parameters} from '../../../../parameters';
@Component({
  selector: 'app-coordinate-respond-controls',
  templateUrl: './coordinate-respond-controls.component.html',
  styleUrls: ['./coordinate-respond-controls.component.scss']
})
export class CoordinateRespondControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  @Input() item: any;
  @Input() status: any
  addressValue: any;
  incomingResult: any;
  incomingQuestion: any;
  incomingItem: any;
  constructor(public service: AppService) {
    this.incomingResult = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingItem = new Parameters();
  }

  ngOnInit(): void {
    this.incomingQuestion.setAttributes(this.question);
    this.addressValue = this.service.empty(this.incomingQuestion.getData()) ? '' : this.incomingQuestion.getData();
  }

  onAddressTextChange(event: any){
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.setData(event.target.value);
    this.incomingQuestion.setResult({});
    this.setValue();
  }

  setValue(){
      if(this.status  !== undefined){
        this.incomingQuestion.setAttributes(this.question);
        this.incomingItem.setAttributes(this.item);
        this.incomingItem.setData(this.incomingQuestion.getData());
        this.incomingItem.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') ? this.incomingQuestion.getResult() : {});
      }
  }

  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.setData(result.formatted_address);
    this.setValue();
  }

  onLocationSelected(location: any) {
   this.incomingQuestion.setAttributes(this.question);
   this.incomingResult.setAttributes({});
   this.incomingResult.setLat(location.latitude);
   this.incomingResult.setLng(location.longitude);
   this.incomingQuestion.setResult(this.incomingResult.getAttributes());
   this.setValue();
  }

}
