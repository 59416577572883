<div class="android-customized-section" [ngStyle]="{'background-color':'transparent'}">
    <div class="android-customized-section-text">
        <div class="mdl-typography--font-light mdl-typography--display-1-color-contrast textWht">For any ticket-related matters, please contact us at</div>
        <div class="mdl-typography--font-light textWht">
            <ul class="textWht text-left">
                <li>
                    Telephone: +255 744 07 20 75
                </li>
                <li>
                    Saturday - Thursday from 11:00 am until 7:00 pm
                </li>
                <li>
                    Email:tak-tickets@warehouse.com
                </li>
                <li>
                    <h4 class="textWht">Ticketing terms & conditions</h4>
                    <p class="textWht text-left">
                        A. General

                        The sold ticket (the “Ticket”) allows the holder to attend the event associated with the Ticket (the “Event”) in accordance with the following terms and conditions:

                        The Arena Kuwait is the exclusive and sole authorized provider of ticket-selling services for all events at the Arena Kuwait unless otherwise notified officially by The Arena Kuwait.

                        Tickets purchased through any other sources are invalid and will immediately be deemed canceled upon detection. So, it is highly recommended to buy the Tickets from The Arena Kuwait being the official ticket seller for the Events.

                        A Ticket is only valid for the date, time and Event specified and should be presented at the time of admission and kept until the end of the Event.
                        Tickets are scanned at the entrance for validity and only the first scan of the bar code on the Ticket will grant entry. If there are multiple copies of the same Ticket, the scanner at the door will detect that the Ticket has already been scanned and no further entry will be allowed. The buyer's name is indicated on the Ticket.
                        The Ticket is not resalable, waivable, or transferable to others.
                        Ticket(s) that are duplicated, resold, waived transferred to others, or used for advertising, promotion or other commercial purposes will be deemed invalid and subject to immediate cancellation.
                        In case a Ticket was subject to any change alteration or defacing, the Ticket will immediately be invalid, it could only be exchanged with another valid Ticket if submitted within a sufficient period of time prior to the Event date in accordance with the terms and conditions for exchanging tickets as detailed in our website.
                        If the Ticket has been purchased at the Arena Kuwait Box Office, the buyer may receive a re-printed duplicate ticket by visiting the Box Office at the 360 Mall and providing the necessary proof of purchase.
                        The Ticket refund and exchange are limited to certain occurrences based on the Ticket Refund and Exchange Policy.

                        B. Ticket Refund & Exchange Policy

                        Due to the special nature of the activities related to the Event, all Ticket sales are, in general, final and non-refundable.

                        However, Tickets could be canceled and the amounts paid for will be refunded in any of the following exceptional instances:

                        Cancellation of the Event.
                        Reschedule the Event if the Ticket holder refuses to attend the Event at the rescheduled time.
                        Material Change in the Nature of the Event: if the nature of the Event is changed significantly, the Ticket holder will have the option of either confirming his/her acceptance of the change to the nature of the Event or request for a refund in response to the Arena Kuwait specified conditions/deadline for such an option. If the Ticket holder fails to inform the Arena Kuwait of his/her request for a refund by this deadline, it will be considered a confirmation from the Ticket holder for the modified Event and he/her will not be entitled to request a refund. It is not considered to be a material change in the nature of the Event, any change of (i) a part of the content of the Event, (ii) the members of the group/team performing the Event, and/or (iii) the line-up of any multi-artist Event (for example a festival).

                        The Arena Kuwait will refrain from issuing exchanges or refunds after a Ticket purchase has been made in the following instances:

                        Ticket is lost, stolen, damaged, or destroyed tickets
                        The ticket has been resold, waived, or transferred
                        The ticket has been changed, altered, or defaced

                        If a refund or exchange is to be issued, then it will be the Arena Kuwait management who will process the refund or exchange for the Ticket sold through authorized online channels, box offices, call center, or retail outlets.

                        A full refund for Tickets purchased prior to the original date of the Event will be given up to the face value of this Ticket. Refund of any transaction amount against any purchase through this website would be refunded to the original buyer and with the same mode of payment. Delivery and order processing fees, if any, will not be refunded.

                        In order to make a refund for the canceled or postponed Events, the physically purchased tickets should be physically delivered to the Arena Kuwait Box Office located at the 360 Mall.

                        C. Making A Claim For A Ticket

                        All claims for a Ticket refund must be made at the Arena Kuwait Box Office at the 360 Mall or by emailing:
                        tak-tickets@thearenakuwait.com

                        The claimer will be given/sent a form to complete.

                        The claimer should fill in the form and send it to The Arena Kuwait as soon as possible with all the information and documents asked for. It is essential that the Claimer provide as much detail as possible to enable handling his/her claim quickly. It is recommended to keep photocopies of all information the claimer sends to The Arena Kuwait.

                        Below is a list of the documents needed in order to deal with a claim:

                        • Claimer’s full personal details

                        • Ticket purchase details

                        • Original unused Ticket

                        • Ticket purchase receipt

                        • As much evidence as possible to support the claim.

                        Making A Complaint

                        The Arena Kuwait aims to provide first-class policies and services. However, there may be times when you feel we have not done so. If this is the case, please tell us about it so that we can do our best to solve the problem by, among others, raising the matter to the organizer whenever it is required. If you make a complaint your legal rights will not be affected.

                        In the first instance, please email: wecanhelp@thearenakuwait.com
                    </p>

                </li>
            </ul>
        </div>
    </div>
</div>
