<div id="main">
    <!--<div [ngStyle]="{'position':'fixed','top':'0px','background-color':'red','z-index':'9999999','height':'100%','width':'100%'}">dsfkdsf</div>-->
    <div class="banner-outer banner-2 home">
        <div class="banner-2" [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()+'px'}">
<!--            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
<!--                 src="assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg" />-->
                        <div class="img" [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()+'px','z-index':'0'}">
                            <carousel [cellsToScroll]="1"
                                      [cellsToShow]="1"
                                      [height]="service.getHeight()"
                                      [autoplay]="true"
                                      [arrows]="false"
                                      [dots]="false"
                                      [loop]="true"
                                      *ngIf="weddings.length!==0">
                                <div class="carousel-cell"
                                     [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()+'px'}"
                                     *ngFor="let wedding of weddings">
                                    <img src="{{wedding}}"
                                         class="mdl-shadow--2dp"
                                         [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"/>
                                </div>
                            </carousel>
                        </div>
        </div>
    </div>
</div>



<div ngClass="sawasdee"
     [ngStyle]="{'position':'absolute','top':'0%','height':this.service.getWidth()+'px','width':this.service.getHeight()+'px','margin-top':'10px','margin-bottom':'10px'}">
    <div class="vertical-center"
         [ngStyle]="{'padding-top':'50px','padding-bottom':'50px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)', 'display':'block', 'object-fit': 'cover','background-image': 'url(assets/images/parrten-bg.png)'}">
        <div ngClass="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
        <div ngClass="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <center>
                <table>
                    <tr>
                        <td class="mitra14X2">
                            <center>
                                welcome to warehouse Arena
                            </center>
                        </td>
                    </tr>
                    <tr>
                        <td class="mitra20X2" [ngStyle]="{'padding-top':'40px'}">
                            <div>
                                {{service.upperStartingCharacter("Warehouse Arena is the perfect location to celebrate your special wedding day with loved ones",true)}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="mitra14X2" [ngStyle]="{'padding-top':'40px'}">
                            <div>
                                {{
                                  service.upperStartingCharacter(" Warehouse is the perfect location to celebrate your special day with loved ones, thanks\n" +
                                      "                                to our outstanding wedding venues in Dar es salaam,\n" +
                                      "                                5-star luxury accommodation, world-class\n" +
                                      "                                cuisine and city and harbour views, that\n" +
                                      "                                together all enhance the romantic\n" +
                                      "                                atmosphere.\n" +
                                      "\n" +
                                      "                                Our spectacular Almasi Ballroom, the largest hotel\n" +
                                      "                                ballroom in Tanzania, is ideal for staging a\n" +
                                      "                                memorable wedding ceremony and reception, while\n" +
                                      "                                the Bridal Suite provides an intimate space for the\n" +
                                      "                                bride and her party to get ready, enabling them\n" +
                                      "                                to make last-minute preparations.\n" +
                                      "\n" +
                                      "                                Let our dedicated wedding team take care of every details on your behalf,\n" +
                                      "                                from decor to tailor-made menus, ensuring your\n" +
                                      "                                day is stress-free and filled with love and cherished\n" +
                                      "                                memories.", true)
                                }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td  [ngStyle]="{'padding-top':'40px'}">
                            <center>
                                <div [ngStyle]="{'width':'350px','padding':'10px'}" class="mdl-shadow--24dp">
                                    <div class="mitra20X2">
                                        Talk to us<br><br>
                                    </div>
                                    <div class="mitra16X2 text-left">
                                        Tell us about your wedding requirements and then let us prepare a detailed proposal excusively for you<br><br>
                                    </div>
                                    <div>
                                        <a class="btn btn-lg btn-danger mitra14X2">Request proposal</a>
                                        <a class="btn btn-lg btn-danger mitra14X2">Book venue</a>

                                    </div>
                                </div>

                            </center>
                        </td>
                    </tr>
                </table>
            </center>
        </div>
        <div ngClass="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
    </div>
</div>