import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from "../../../../parameters";
import {MatMenuTrigger} from "@angular/material/menu";
import {FormService} from "../../../form.service";

@Component({
  selector: 'app-check-preview-controls',
  templateUrl: './check-preview-controls.component.html',
  styleUrls: ['./check-preview-controls.component.scss']
})
export class CheckPreviewControlsComponent implements OnInit, OnChanges {

  @Input() question: any;
  @Input() position: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = {x: '0px', y: '0px'};
  incomingSelectedOption: any;
  incomingResults: any;
  incomingQuestion: any;
  incomingOption: any;
  label: any;
  incomingForm: any;
  incomingResult: any;
  incomingOptional: any;
  incomingSettings: any;
  constructor(public service: AppService,
              public formService: FormService) {
    this.incomingQuestion = new Parameters();
    this.incomingResults = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingSelectedOption = new Parameters();
    this.incomingOptional = new Parameters();
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOption.setAttributes({});
    this.contextMenu = MatMenuTrigger;

  }

  ngStart(){
    this.incomingQuestion.setAttributes(this.question);
  }

  ngOnInit(){
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.incomingForm.setAttributes(Object.assign({}, this.formService.getSelected()));
    this.incomingSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingSettings.setLargeDisplay(this.incomingSettings.attributes.hasOwnProperty('lfd') ? this.incomingSettings.getLargeDisplay() : 0);
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') ? Object.assign({}, this.incomingQuestion.getResult()) : {});
    this.ngStart();
  }


  onOptionSelected(option: any){
   this.incomingOption.setAttributes(option);
   this.onClick({}, this.incomingOption.getAttributes());
  }

  set(){
    let output: any = '';
    if(!this.service.empty(this.incomingQuestion.getData())) {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.getOptions().map((option: any) => {
        this.incomingOption.setAttributes(option);
        if (this.incomingQuestion.getData().toString().includes(this.incomingOption.getLabel().toString())) {
          output = this.service.empty(output) ?
              this.incomingOption.getLabel() :
              output + this.service.separator + this.incomingOption.getLabel();
        }
      });
    }
    this.incomingQuestion.setData(output);
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = {'item': inspector};
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
    if(!this.incomingSelectedOption.attributes.hasOwnProperty('questionCntrlNum'))
    this.contextMenu.closeMenu();
  }


  removeOption(option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingSelectedOption.attributes.hasOwnProperty('questionCntrlNum')){
        this.formService.newOptional = this.incomingSelectedOption.getAttributes();
        this.incomingQuestion.getOptions().splice(this.service.findIndex(this.incomingQuestion.getOptions(), 'cntrl_num', this.incomingSelectedOption.getCntrlNum()), 1);
        this.formService.removeOther(); this.set();
      }else this.contextMenu.closeMenu();
    });
  }

  handleUpdate(option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingSelectedOption.attributes.hasOwnProperty('questionCntrlNum')){
        this.label = prompt('', this.incomingSelectedOption.getLabel());
        if (this.label !== null) {
          this.incomingSelectedOption.attributes = Object.assign(this.incomingSelectedOption.attributes, {label: this.label});
          this.formService.newOptional = this.incomingSelectedOption.getAttributes();
          this.formService.updateOther(this.label); this.set();
        }
      }else this.contextMenu.closeMenu();
    });
  }

  onClick(checked: any, option: any) {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingOption.setAttributes(option);
      if (!this.incomingQuestion.getData().toString().includes(this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum())) {
        this.incomingQuestion.setData(this.service.empty(this.incomingQuestion.getData()) ?
            this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum() : this.incomingQuestion.getData() + this.service.separator + this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum());
      } else {
        this.incomingQuestion.setData(
            this.incomingQuestion.getData().toString().includes(this.service.separator + this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum()) ?
                this.incomingQuestion.getData().toString().replace(this.service.separator + this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum(), '') :
                (this.incomingQuestion.getData().toString().includes(this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum() + this.service.separator) ?
                    this.incomingQuestion.getData().toString().replace(this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum() + this.service.separator, '') :
                    this.incomingQuestion.getData().toString().replace(this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum(), ''))
        );
      }
      this.incomingResults.setAttributes({});
      this.incomingResult.setAttributes({});
      this.incomingQuestion.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') ? Object.assign({}, this.incomingQuestion.getResult()) : {});
      this.incomingResult.setLabel(this.incomingOption.getLabel());
      this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
      this.incomingResults.setAttributes(this.incomingQuestion.getResult());
      if(this.incomingResults.attributes.hasOwnProperty(this.incomingOption.getCntrlNum())){
       delete this.incomingResults.attributes[this.incomingOption.getCntrlNum()];
      }else this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
  }

  isChecked(option: any, checked: any) {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingOptional.setAttributes(option);
      this.incomingQuestion.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') ? Object.assign({}, this.incomingQuestion.getResult()) : {});
      const l: any = this.incomingQuestion.getResult().hasOwnProperty(this.incomingOptional.getCntrlNum());
      return l;
  }

  onClickSpan(option: any){
    setTimeout(() => {
      this.incomingOption.setAttributes(option);
      if(this.incomingOption.getAttributes().hasOwnProperty('questionCntrlNum')){
        alert(this.incomingOption.getLabel());
      }
    });
  }

}











