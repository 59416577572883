import {Component, AfterViewInit, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {FormControl} from '@angular/forms';
import {AppService} from '../../../../app.service';
import * as moment from 'moment';
import {orderBy } from 'lodash';
import {StorageMap } from '@ngx-pwa/local-storage';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {Parameters} from '../../../../parameters';
import {OutletService} from '../../../../outlets/outlet.service';
import {ResponseService} from '../../../responses/data/response.service';
import {FormService} from '../../../form.service';
import {MapsAPILoader} from '@agm/core';
@Component({
  selector: 'app-outlet-preview-controls',
  templateUrl: './outlet-preview-controls.component.html',
  styleUrls: ['./outlet-preview-controls.component.scss']
})
export class OutletPreviewControlsComponent implements OnChanges, AfterViewInit {
  @ViewChild('outlet')  outletElementRef: any;
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) outletTypeElement: any;
  @Input() question: any;
  @Input() position: any;
  label: any;
  outletTypeControl = new FormControl();
  outletControl = new FormControl();
  outletSegmentControl = new FormControl();
  controls: any;
  searchCntrl: any;
  addressValue: any;
  landmarkValue: any;
  filteredOutletTypeOptions: any;
  filteredOutletSegmentOptions: any;
  filteredOutletOptions: any;
  outgoingRequest: any;
  spinner: any = false;
  incomingResult: any;
  incomingQuestion: any;
  incomingResponseStorage: any;
  incomingOutlet: any;
  incomingResponse: any;
  incomingRoutePlan: any;
  incomingForm: any;
  incomingFormSettings: any;
  geocoder: any;
  options: any = [];
  incomingOption: any;
  responses: any = [];
  constructor(private responseService: ResponseService,
              public  formService: FormService,
              public  broadcastChannel: BroadcastChannelService,
              private storage: StorageMap,
              private outletService:OutletService,
              private mapsAPILoader: MapsAPILoader,
              public service: AppService) {
   this.outgoingRequest   = new Parameters();
   this.incomingResult    = new Parameters();
   this.incomingQuestion  = new Parameters();
   this.incomingResponse  = new Parameters();
   this.incomingOutlet    = new Parameters();
   this.incomingRoutePlan = new Parameters();
   this.incomingOption    = new Parameters();
   this.incomingForm      = new Parameters();
   this.incomingResponseStorage = new Parameters();
   this.incomingFormSettings = new Parameters();
   this.outletElementRef     = ElementRef;
   this.service.app.data = Object.assign(this.service.app.data, {outlets: this.service.app.data.hasOwnProperty('outlets') ?  this.service.app.data.outlets : {data: []}});
  }
 
  ngOnChanges(changes: any): void {
    this.options = [];
    this.incomingOption.setAttributes({});
    this.incomingOption.setLabel(this.service.app.strings.yes);
    this.options.push(this.incomingOption.getAttributes());
    this.incomingOption.setAttributes({});
    this.incomingOption.setLabel(this.service.app.strings.no);
    this.options.push(this.incomingOption.getAttributes());
    this.service.app.data.outlet_types =
      this.service.app.data.hasOwnProperty('outlet_types') ?
      this.service.app.data.outlet_types : {data:[]};
    if(this.service.app.data.outlet_types.data.length === 0) this.outletService.requestOutletCategories();
    this.incomingResponse.setAttributes(this.responseService.selected);
    this.incomingForm.setAttributes(this.formService.selected);
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingFormSettings.setAddress(this.incomingFormSettings.attributes.hasOwnProperty('address') ? this.incomingFormSettings.getAddress() : '');
    this.question = Object.assign(this.question, {options: []});
    this.incomingQuestion.setAttributes(this.question);
    this.incomingResult.setAttributes(this.incomingQuestion.attributes.hasOwnProperty('result') &&
      !this.service.empty(this.incomingQuestion.getResult())  ?
      this.incomingQuestion.getResult() : {});
    this.incomingQuestion.setResult(this.incomingResult.getAttributes());
    this.incomingResult.setCntrlNum(this.incomingResult.attributes.hasOwnProperty('cntrl_num') ? this.incomingResult.getCntrlNum() : this.service.random());
    this.incomingResult.setAddress(this.incomingResult.attributes.hasOwnProperty('address') ? this.incomingResult.getAddress() : '');
    this.incomingResult.setLandmark(this.incomingResult.attributes.hasOwnProperty('landmark') ? this.incomingResult.getLandmark() : '');
    this.incomingResult.setName(this.incomingResult.attributes.hasOwnProperty('name') ? this.incomingResult.getName() : '');
  //this.outletElementRef.nativeElement.value = this.incomingResult.getName();
    this.addressValue = this.incomingResult.getAddress();
    this.landmarkValue = this.incomingResult.getLandmark();
    this.filteredOutletTypeOptions = this.outletTypeControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterOutletType(name) : this.service.app.data.outlet_types.data.filter((segment: any) =>{return parseFloat(segment.status) === 0;}).slice())
    );

    this.filteredOutletSegmentOptions = this.outletSegmentControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterOutletSegment(name) : this.service.app.data.outlet_types.data.filter((segment: any) =>{return parseFloat(segment.status) === 1;}).slice())
    );

    this.filteredOutletOptions = this.outletControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterOutlet(name) : [])
    );
//  this.service.app.data.outlets.data.slice()
    if(this.incomingResult.attributes.hasOwnProperty('outlet_type_id')){
      this.outletTypeControl.setValue(this.service.app.data.outlet_types.data.filter((type: any) => { return type.id === parseFloat(this.incomingResult.getOutletTypeId());})[0]);
    }

    if(this.incomingResult.attributes.hasOwnProperty('outlet_segment_id')){
     if(parseFloat(this.incomingResult.getOutletSegmentId()) !== 0){
      this.outletSegmentControl.setValue(this.service.app.data.outlet_types.data.filter((type: any) => { return type.id === parseFloat(this.incomingResult.getOutletSegmentId());})[0]);
     }
    }


    this.storage.get('responses').subscribe((responses: any) => {
       if(responses) {
        this.responses = responses;
       }
    });

    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setFormId(this.incomingForm.getId());
    this.outgoingRequest.setDay(moment(new Date()).format('dddd'));
    this.outgoingRequest.setUserId(this.service.app.data.hasOwnProperty('user')?this.service.app.data.user.id:0);
         this.service.app.data = this.service.app.data.hasOwnProperty('routes') ? this.service.app.data : Object.assign(this.service.app.data,{routes:{data:[]}});
    this.service.app.data.routes = this.service.app.data.routes.hasOwnProperty('plans') ? this.service.app.data.routes : Object.assign(this.service.app.data.routes,{plans:{data:[]}});
    this.service.app.data.routes.plans.data = orderBy(this.service.app.data.routes.plans.data, 'id', 'desc');
    if(this.service.app.data.routes.plans.data.length !==0)
    this.outgoingRequest.setAscendinglastAt(this.service.app.data.routes.plans.data[0].id);
    this.service.httpService( 'post',
        this.service.app.routes.routes.plans.get,
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true},(result: any) => {
        this.service.app.data.routes.plans.data = Array.prototype.concat(this.service.app.data.routes.plans.data, result);
        this.service.app.data.routes.plans.data = this.service.app.data.routes.plans.data.flat(1);
        this.service.app.data.routes.plans.data = orderBy(this.service.app.data.routes.plans.data, 'id', 'desc');
    },  (error: any) => {
    });

      //const loader = new Loader({
   //        apiKey   : 'AIzaSyCsnJeT35rbnOM-ei4fd15eXkNx8smK2nQ',
   //        version  : 'weekly',
   //        libraries: ['places']
   //    });
   //
   //    const mapOptions = {
   //        center: {
   //            lat: 0,
   //            lng: 0
   //        },
   //        zoom: 4
   //    };
   //
   // loader.load()
   //        .then((google) => {
   //            alert(988);
   //        })
   //        .catch(e => {
   //            // do something
   //        });


    //var geocoder = new google.maps.Geocoder();
      // geocoder.geocode({address: window.localStorage.getItem('address')
      // }, (results, status) => {
      //     if (status === google.maps.GeocoderStatus.OK){
      //     }
    // });




  }

  isRoutePlan(outlet: any){
       this.position = this.service.app.data.routes.plans.data.findIndex((routePlan: any)=>{
       this.incomingOutlet.setAttributes(routePlan.outlet);
       return parseFloat(this.incomingOutlet.getId()) ===  parseFloat(outlet.id);
   }); return this.position === -1;
  }


  getColor(routePlan: any, color: any, status: any){
   this.incomingRoutePlan.setAttributes(routePlan);
   let position = this.responses.findIndex((response: any)=>{
    this.incomingResponseStorage.setAttributes(response);
    return this.incomingResponseStorage.getAttributes().hasOwnProperty('outlet_id') &&
     parseFloat(this.incomingResponseStorage.getOutletId()) ===  parseFloat(routePlan.outlet.id);
   });return position !== -1 ? (status === 0 &&
     parseFloat(this.responses[position].status) === 0 ?
     (this.incomingResponse.getAttributes().hasOwnProperty('outlet_id') &&
     parseFloat(this.incomingResponse.getOutletId()) ===  parseFloat(routePlan.outlet.id) ? '#263238':'gold') : color) : '';
  }

  onRoutePlanSelected(routePlan:any){
    this.incomingResult.setAttributes(Object.assign({}, routePlan.outlet));
    this.incomingQuestion.setData(this.incomingResult.getId());
    this.incomingResponse.setOutletTypeId(this.incomingResult.getOutletTypeId());
    this.incomingResponse.setOutletId(this.incomingResult.getId());
    delete this.incomingResult.attributes.id;
    delete this.incomingResult.attributes.cntrl_num;
    this.incomingQuestion.setResult(this.incomingResult.getAttributes());
    this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
    let position: any = this.service.findIndex(this.responses,'post_id',this.incomingResponse.getPostId());
    this.responses[position] = Object.assign(this.responses[position], this.incomingResponse.getAttributes());
     this.storage.set('responses', this.responses).subscribe(() => {});
  }



  ngAfterViewInit(){
   setTimeout(() => {
       this.outletElementRef.nativeElement.value = this.incomingResult.getName();
       if(this.incomingResult.attributes.hasOwnProperty('id')){
        this.position = this.service.app.data.outlets.data.findIndex((outlet: any) => parseFloat(outlet.id) === parseFloat(this.incomingResult.getId()));
        if(this.position === -1){
           this.outgoingRequest.setAttributes({});
           this.outgoingRequest.setId(this.incomingResult.getId());
           this.service.httpService( 'post',
            this.service.app.routes.outlets.get,
            this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true},(response: any) => {
            this.service.app.data.outlets.data.unshift(response);
            this.incomingResult.attributes = Object.assign(this.incomingResult.attributes, response);
            this.ngOnChanges(null);
            },  (error: any) => {
            });
        }
      }
   });
  }

  onInputCategory(event: any){
      // this.incomingQuestion.setData('');
      // this.incomingResult.setOutletTypeId('');
  }


  onChangeOutletSegmentText(event: any){
  }

  addOutlet(){
   this.service.addOutlet = undefined;
// window.history.back();
  }


  add(){
      setTimeout(()  => {
        this.outgoingRequest.setAttributes(Object.assign({}, this.incomingResult.getAttributes()));
        this.outgoingRequest.setCntrlNum(this.service.random());
        if(this.outgoingRequest.attributes.hasOwnProperty('id')) delete this.outgoingRequest.attributes.id;
         this.service.httpService( 'post',
            this.service.app.routes.outlets.add,
            this.outgoingRequest.getAttributes(), {},(response: any) => {
             this.service.app.data.outlets.data.unshift(response);
             this.incomingResult.attributes = Object.assign({}, response);
             this.incomingQuestion.setData(this.incomingResult.getId());
             this.incomingQuestion.setResult(this.incomingResult.getAttributes());
             this.incomingResponse.setAddress(this.incomingResult.getAddress());
             this.incomingResponse.setLat(this.incomingResult.getLat());
             this.incomingResponse.setLng(this.incomingResult.getLng());
             this.ngOnChanges(null);
            },  (error: any) => {
            });

      });
  }

  resetResult(){
      this.incomingQuestion.setData('');
      this.incomingResult.setLat('');
      this.incomingResult.setLng('');
      this.incomingResult.setAddress('');
      this.incomingResult.setAltitude('');
      this.incomingResponse.setLat('');
      this.incomingResponse.setLng('');
      this.incomingResponse.setAddress('')
      this.incomingResponse.setAltitude('');
  }

  onInputOutletText(event: any){
        setTimeout(() =>{
            this.spinner = true;
            this.outgoingRequest.setAttributes({});
            this.incomingResult.setName(event.target.value);
            this.incomingQuestion.setData('');
            this.outgoingRequest.setSearchText(event.target.value);
            if(this.service.app.data.hasOwnProperty('user'))
            this.outgoingRequest.setUserId(this.service.app.data.user.id);
            this.service.httpService( 'post',
                this.service.app.routes.outlets.search,
                this.outgoingRequest.getAttributes(), {
                    ignoreLoadingBar: 'true', notify: true},(response: any) => {
                    this.spinner = false;
                    response.map((outlet: any) => {
                        this.incomingOutlet.setAttributes(outlet);
                        this.position = this.service.findIndex(this.service.app.data.outlets.data, 'cntrl_num', this.incomingOutlet.getCntrlNum());
                        if(parseFloat(this.position) === -1) this.service.app.data.outlets.data.unshift(this.incomingOutlet.getAttributes());});
                }, (error: any) => {this.spinner = false;
                });
        });
  }

  onInputPhoneTextChange(event: any){
      this.incomingQuestion.setData('');
      this.incomingResult.setPhone(event.target.value);
  }

  private _filterOutletType(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.outlet_types.data.filter((option: any) => (option.name.toLowerCase().includes(filterValue) && parseFloat(option.status) === 0));
  }

 tmout(){return 112;}
  private _filterOutlet(name: string) {
      const filterValue = name.toLowerCase();
      return this.service.app.data.outlets.data.filter((outlet: any) => JSON.stringify(outlet).toLowerCase().includes(filterValue));
  }

  onOutletTypeSelectionChanged(event: MatAutocompleteSelectedEvent){
   this.incomingResult.setOutletTypeId(event.option.value.id);
   this.incomingResponse.setOutletTypeId(this.incomingResult.getOutletTypeId());
  }

  displayFn(value: any){
    return value.name;
  }

  private _filterOutletSegment(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.outlet_types.data.filter((option: any) => (option.name.toLowerCase().includes(filterValue) && parseFloat(option.status) === 1));
  }

  onInputAddressText(event: any){
      if(this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAddress()){
            this.incomingResult.setAddress('');
            this.incomingResult.setLat('');
            this.incomingResult.setLng('');
            this.incomingResult.setAltitude('');
            this.incomingResponse.setAddress('');
            this.incomingResponse.setLat('');
            this.incomingResponse.setLng('');
            this.incomingResponse.setAltitude('');
            this.mapsAPILoader.load().then(() => {
                // this.geocoder = new google.maps.Geocoder();
                // this.geocoder.geocoder({ address:  event.target.value}, (results, status) => {
                //     if (status === google.maps.GeocoderStatus.OK) {
                //         var latitude  = results[0].geometry.location.lat();
                //         var longitude = results[0].geometry.location.lng();
                //         alert(latitude);
                //     }
                // });
            });
      }else{
            this.incomingResult.setAddress(event.target.value);
            this.incomingResult.setLat(0);
            this.incomingResult.setLng(0);
            this.incomingResult.setAltitude(0);
            this.incomingResponse.setAddress(event.target.value);
            this.incomingResponse.setLat(0);
            this.incomingResponse.setLng(0);
            this.incomingResponse.setAltitude(0);
      }
      this.incomingQuestion.setData('');
  }

  onChangeLandmarkText(event: any){
      this.incomingResult.setLandmark(event.target.value);
  }

  onOutletSegmentSelectionChanged(event: MatAutocompleteSelectedEvent){
     this.incomingResult.setOutletSegmentId(event.option.value.id);
     this.incomingResponse.setOutletSegmentId(this.incomingResult.getOutletSegmentId());
  }

  openPanel(event: any){
    event.stopPropagation();
    this.outletTypeElement.openPanel();
  }

  onMapReady(event:any){
  }

  onGoogleMapAutocompleteSelected(result: any, address: any) {
  //result.formatted_address
      this.incomingQuestion.setAttributes(this.question);
      this.incomingResult.setAttributes(this.incomingQuestion.attributes.hasOwnProperty('result') ?
          this.incomingQuestion.getResult() : {});
      this.incomingResult.setAddress(address);
      this.incomingResult.setLat(result.geometry.location.lat());
      this.incomingResult.setLng(result.geometry.location.lng());
      this.incomingResult.setAltitude(0);
      this.incomingQuestion.setResult(this.incomingResult.getAttributes());
      this.incomingResponse.setAddress4((result.address_components.length - 4) > 0 ? result.address_components[result.address_components.length - 4].long_name : '');
      this.incomingResponse.setAddress3((result.address_components.length - 3) > 0 ? result.address_components[result.address_components.length - 3].long_name : '');
      this.incomingResponse.setAddress2((result.address_components.length - 2) > 0 ? result.address_components[result.address_components.length - 2].long_name : '');
      this.incomingResponse.setAddress1((result.address_components.length - 1) > 0 ? result.address_components[result.address_components.length - 1].long_name : '');
      this.incomingResponse.setAddress(this.incomingResult.getAddress());
      this.incomingResponse.setLat(this.incomingResult.getLat());
      this.incomingResponse.setLng(this.incomingResult.getLng());
      this.incomingResponse.setAltitude(this.incomingResult.getAltitude());
      window.localStorage.setItem('address', address);
  }

  onPlaceChange(event: any){

  }

  onOutletSelectionChanged(event: any){
      this.incomingResult.setAttributes(Object.assign({}, event.hasOwnProperty('option') ? event.option.value : event));
      this.incomingQuestion.setData(this.incomingResult.getId());
      this.incomingResponse.setLat(this.incomingResult.getLat());
      this.incomingResponse.setOutletTypeId(this.incomingResult.getOutletTypeId());
      this.incomingResponse.setOutletId(this.incomingResult.getId());
      this.incomingResponse.setLng(this.incomingResult.getLng());
      this.incomingResponse.setAddress(this.incomingResult.getAddress());
      this.incomingResponse.setAltitude(0);
      delete this.incomingResult.attributes.id;
      delete this.incomingResult.attributes.cntrl_num;
      this.incomingQuestion.setResult(this.incomingResult.getAttributes());
      this.ngOnChanges(null);
      this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
  }



}
