import { Component, OnInit,Input, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/internal/operators';
import {Parameters} from "../../../../parameters";
import {AppService} from "../../../../app.service";
import {FormService} from '../../../form.service';
import {orderBy} from 'lodash';
@Component({
  selector: 'app-waypoints-draft',
  templateUrl: './waypoints-draft.component.html',
  styleUrls: ['./waypoints-draft.component.scss']
})
export class WaypointsDraftComponent implements OnInit,OnDestroy {
    @Input() question: any;
    @Input() position: any;
    @Input() deletedOptions: any;

    typeControl = new FormControl();
    filteredTypesOptions: any;
    incomingQuestion: any;
    incomingResult: any;
    incomingOption: any;
    lat: any = -28.48322;
    lng: any = 24.676997;
    map: any;
    zoom: any = 15;
    currentLng: any;
    currentLat: any;
    mapClickListener: any;
    currentPositionIcon: any;
    icon: any;
    incomingSettings: any;
    incomingItem: any;
    incomingSelectedOption: any;
    incomingImg: any;
    incomingImages: any;
    outgoingRequest: any;
    waypoints: any = [];
    origin: any;
    destination: any;
    prevWindow: any = null;
    openedWindow: any = null;
    result: any;
  constructor(public service: AppService,
    public formService: FormService) {
    this.incomingQuestion = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingItem = new Parameters();
    this.incomingSelectedOption = new Parameters();
    this.incomingImg = new Parameters();
    this.incomingImages = new Parameters();
    this.outgoingRequest = new Parameters();
    this.origin = new Parameters();
    this.destination = new Parameters();
    this.currentPositionIcon = {url: 'https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_my_location_48px-128.png', scaledSize: {height: 25, width: 25}};
    this.icon = {url: 'https://i.imgur.com/7teZKif.png', scaledSize: {height: 20, width: 20}};
    this.currentLat = this.lat;
    this.currentLng = this.lng;
    this.origin.setAttributes({});
    this.destination.setAttributes({});
    this.origin.setLat(0);
    this.origin.setLng(this.origin.getLat());
    this.destination.setLat(this.origin.getLat());
    this.destination.setLng(this.origin.getLat());
  }

  ngOnDestroy(): void {
      if (this.mapClickListener) {
        this.mapClickListener.remove();
      }
  }

  getImg(event: any){
      const files: FileList = event.target.files;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.incomingImg.setAttributes({});
        this.incomingImg.setSrc(reader.result);
        this.incomingImg.setFlag(0);
        this.incomingImg.setCaption('');
        this.incomingImg.setOrderBy('');
        this.incomingImg.setStatus(1);
        this.incomingImg.setUrl('');
        this.incomingImg.setPath('');
        this.incomingSettings.setAttributes(this.incomingSelectedOption.getSettings());
        this.incomingSettings.setImages(this.incomingSettings.attributes.hasOwnProperty('images') ? this.incomingSettings.getImages() : []);
        this.incomingSettings.getImages().push(this.incomingImg.getAttributes());
        if(this.incomingSelectedOption.attributes.hasOwnProperty('id')){
         this.incomingImg.setAvatar(this.service.dataURItoBlob(reader.result));
         setTimeout((params: any) => {
           this.service.httpService('post', '/images',
           this.incomingImg.getAttributes(), {},(response: any) => {
           this.incomingImg.attributes = Object.assign(this.incomingImg.attributes, response);
           this.outgoingRequest.setAttributes({});
           this.outgoingRequest.setEntity1(params.entity1);
           this.outgoingRequest.setEntity2(this.incomingImg.getId());
           this.outgoingRequest.setDescription('');
           this.outgoingRequest.setSample('option.images');
           this.sampling(this.outgoingRequest);
           }, (error: any) => {
           });
         },0, {entity1 : this.incomingSelectedOption.getId()});
        }
      };
  }

  onmarkerClick(infoWindow: any, map: any, marker: any){
      this.incomingQuestion.setAttributes(this.question);
      this.incomingOption.setAttributes(marker);
      if(this.prevWindow === null){ this.prevWindow = infoWindow; }else{
        this.openedWindow = infoWindow;
        this.prevWindow.close();
       } this.prevWindow = infoWindow;
        setTimeout(() => {
          const elements = document.getElementsByClassName('gm-ui-hover-effect');
          for (var i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element instanceof HTMLElement) {
              element.addEventListener('click', () => {
               this.prevWindow = null;
               this.position = this.service.findIndex(this.incomingQuestion.getOptions(), 'cntrl_num', this.incomingOption.getCntrlNum());
               this.incomingQuestion.getOptions().splice(this.position, 1);
               if(this.incomingOption.attributes.hasOwnProperty('id')) {
                this.deletedOptions.push(this.incomingOption.getAttributes());
               }
              });
           }
          }
       });
  }

  addPoint(){
   this.formService.addQuestion(
    this.formService.questions,
    this.service.app.data.controls.filter((control: any) => {return parseFloat(control.id) === 28;})[0],
    this.formService.questions.length);
    this.question = this.formService.newQuestion;
    this.lat = this.currentLat;
    this.lng = this.currentLng;
    this.valueChanges();
    this.typeControl.setValue(this.formService.newQuestion);
    this.setWaypoints();
  }

  setSettings(event: any, column: any){this.formService.questions.length
        this.incomingQuestion.setAttributes(this.question);
        this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
        if(!this.service.empty(event.target.value)){
          this.incomingSettings.setUnknown(column, event.target.value);
        }else {
          if(this.incomingSettings.hasAttribute(column)){
            delete this.incomingSettings.getAttributes()[column];
          }
        }
  }


  setWaypoints(){
   this.waypoints = [];
   this.lat = this.currentLat;
   this.lng = this.currentLng;
   this.incomingQuestion.setAttributes(this.question);
   this.incomingQuestion.getOptions().map((option: any) => {
    this.incomingOption.setAttributes(option);
    this.incomingSettings.setAttributes(this.incomingOption.getSettings());
    this.incomingSettings.setPosition(this.incomingSettings.attributes.hasOwnProperty('position') ? this.incomingSettings.getPosition() : 0);
    if(this.incomingSettings.attributes.hasOwnProperty('start') && parseFloat(this.incomingSettings.getStart()) === 1){
     this.origin.setLat(this.incomingSettings.getLat());
     this.origin.setLng(this.incomingSettings.getLng());
    }else if(this.incomingSettings.attributes.hasOwnProperty('end') && parseFloat(this.incomingSettings.getEnd()) === 1){
     this.destination.setLat(this.incomingSettings.getLat());
     this.destination.setLng(this.incomingSettings.getLng());
     this.incomingQuestion.setLabel(this.incomingOption.getLabel());
    }else this.waypoints.push({location: { lat: parseFloat(this.incomingSettings.getLat()), lng: parseFloat(this.incomingSettings.getLng()) }});
   });
  }

  sampling(outgoingRequest: any){
   this.service.httpService('post', '/entities/sampling',
     outgoingRequest.getAttributes(), {},(response: any) => {
     alert(4565656);
     }, (error: any) => {
     });
  }

  updateGeoSettings(point: any, column: any){
    this.incomingQuestion.setAttributes(this.question);
    this.incomingItem.setAttributes(point);
    this.incomingQuestion.getOptions().map((option: any) => {
      this.incomingOption.setAttributes(option);
      this.incomingSettings.setAttributes(this.incomingOption.getSettings());
      if(!this.incomingOption.getCntrlNum().toString().includes(this.incomingItem.getCntrlNum()))
       this.incomingSettings.setUnknown(column, 0);
    });this.setWaypoints();
  }

  onClickCurrentPosition(event: any){
    this.incomingQuestion.setAttributes(this.question);
    this.position = this.incomingQuestion.getOptions().findIndex((option: any) => {return parseFloat(option.lat) === this.currentLat && parseFloat(option.lng) === this.currentLng;});
    if(this.position === -1){
        this.formService.addOptional(this.incomingQuestion.getOptions(), '&nbsp;');
        this.incomingOption.setAttributes(this.formService.newOptional);
        this.incomingSettings.setAttributes(this.incomingOption.getSettings());
        this.incomingSettings.setLat(this.currentLat);
        this.incomingSettings.setLng(this.currentLng);
    }
  }

  onTypeSelectionChanged(event: MatAutocompleteSelectedEvent){
   this.question = event.option.value;
   this.setWaypoints();
  }

  getHeight(){ return {height: window.innerHeight}; }

  private _filterType(name: string) {
      const filterValue = name.toLowerCase();
      return this.formService.questions.filter((question: any) => JSON.stringify(question).toLowerCase().includes(filterValue));
  }

  displayFn(value: any){
      return value.label;
  }

  setQuestion(){
   this.incomingQuestion.setAttributes(this.question);
   this.incomingQuestion.setOptions(this.incomingQuestion.attributes.hasOwnProperty('options') ? this.incomingQuestion.getOptions() : []);
    setTimeout(() => {
    this.setWaypoints();
   });
  }

  hasEnding(){
    this.result = false;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.getOptions().map((option: any) => {
     this.incomingOption.setAttributes(option);
     this.incomingSettings.setAttributes(this.incomingOption.getSettings());
     if(this.incomingSettings.attributes.hasOwnProperty('end') && parseFloat(this.incomingSettings.getEnd()) === 1){
      this.result = true;
     }
    }); return this.result;
  }


  ngOnInit(): void {
      if(navigator){
           navigator.geolocation.getCurrentPosition(pos => {
             this.currentLat = parseFloat(pos.coords.latitude.toString());
             this.currentLng = parseFloat(pos.coords.longitude.toString());
             this.lat = parseFloat(pos.coords.latitude.toString());
             this.lng = parseFloat(pos.coords.longitude.toString());
           },(err: any) => {});
       }
       this.valueChanges();
       this.question = null;
         if(this.formService.questions.length !== 0){
         this.question = this.formService.questions[0];
         this.typeControl.setValue(this.formService.questions[0]);
         this.setWaypoints();
       }

  }

  valueChanges(){
    setTimeout(() => {
       this.filteredTypesOptions = this.typeControl.valueChanges
       .pipe(
           startWith(''),
           map(value => typeof value === 'string' ? value : value.name),
           map(name => name ? this._filterType(name) : this.formService.questions.slice())
       );
    });
   }

  hasStarting(){
      this.result = false;
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.getOptions().map((option: any) => {
       this.incomingOption.setAttributes(option);
       this.incomingSettings.setAttributes(this.incomingOption.getSettings());
       if(this.incomingSettings.attributes.hasOwnProperty('start') && parseFloat(this.incomingSettings.getStart()) === 1){
        this.result = true;
       }
      }); return this.result;
  }

   mapReadyHandler(map: any){
      this.map = map;
      this.incomingQuestion.setAttributes(this.question);
      this.mapClickListener = this.map.addListener('dblclick', (e: google.maps.MouseEvent) => {
          this.formService.addOptional(this.incomingQuestion.getOptions(), '&nbsp;');
          this.incomingOption.setAttributes(this.formService.newOptional);
          this.incomingSettings.setAttributes(this.incomingOption.getSettings());
          this.incomingSettings.setLat(e.latLng.lat());
          this.incomingSettings.setLng(e.latLng.lng());
          if(!this.hasStarting())this.incomingSettings.setStart(1);
          else if(!this.hasEnding())this.incomingSettings.setEnd(1);
          this.setWaypoints();
      });
    }

}
