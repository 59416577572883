<div [ngStyle]="{'position':'fixed','top':'0px','left':'0px','width': this.service.getWidth()+'px','height': this.service.getHeight()+'px','color': this.incomingSettings.getColor(),'background-color': this.incomingSettings.getBackgroundColor()}"
     class="containerRelative">
    <br>
    <span class="pull-right cursor mitra"
          (click)="goB()"
          *ngIf="!this.service.empty(this.incomingSettings.getAutoNumber())"
          [ngStyle]="{'margin-right':'20px','font-size':'46px'}">
        {{this.incomingAutoNumber.getData()}}
    </span>
    <img src="{{incomingSettings.getLogoUrl()}}"
         class="img-circle pull-right"
         *ngIf="this.service.empty(this.incomingSettings.getAutoNumber())"
         [ngStyle]="{'margin-right':'20px','scale': '1 1','animation': 'mymove 5s infinite','width': '60px', 'height': '60px'}"/>
    <div class="centered">
<!--        <h1 id="startTyper" style=""></h1>-->
<!--        <img src="assets/images/avatar.jpg"-->
<!--             [ngStyle]="{'width': '100%', 'height': 'auto'}"/>-->
<!--        <ng-container *ngIf="incomingBannerQuestion.getAttributes().hasOwnProperty('id')">-->
<!--            <div [innerHTML]="incomingBannerQuestion.getUnknown('data')|safeUrl"></div>-->
<!--        </ng-container>-->
        <div class="mitra"  [ngStyle]="{'font-size': '26px'}">{{ this.incomingInvitee.getName().toLowerCase()}}</div>
    </div>
</div>


