<!--MAIN START-->
<div id="main">
    <div class="banner-outer banner-2 home">
        <div class="banner-2" [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()/2+'px'}">
            <!--            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
            <!--                 src="assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg" />-->
            <div class="img" [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()/2+'px','z-index':'0'}">
                <carousel [cellsToScroll]="1"
                          [cellsToShow]="1"
                          [height]="service.getHeight()/2"
                          [autoplay]="true"
                          [arrows]="false"
                          [dots]="false"
                          [loop]="true"
                          *ngIf="meetings.length!==0">
                    <div class="carousel-cell"
                         [ngStyle]="{'width': service.getWidth()+'px', 'height': service.getHeight()/2+'px'}"
                         *ngFor="let meeting of meetings">
                        <img src="{{meeting}}"
                             class="mdl-shadow--2dp"
                             [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"/>
                    </div>
                </carousel>
            </div>
        </div>
    </div>
<!--    &lt;!&ndash;INNER BANNER START&ndash;&gt;-->
<!--    <section id="inner-banner">-->
<!--        <div class="container">-->
<!--            <h1 class="mitra" [ngStyle]="{'color':'#fff'}">Accessibility </h1>-->
<!--        </div>-->
<!--    </section>-->
    <!--INNER BANNER END-->
    <!--RECENT JOB SECTION START-->
    <section class="resumes-section padd-tb" [ngStyle]="{'color':'#888'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="resumes-content">
                        <div class="summary-box mitra">
                            <h4 class="mitra">
                                Accessibility: It is our priority to ensure that the venue is welcoming and accessible to as many people as possible. Our facilities are equipped to suit the needs of people with mobility difficulties and specific accessibility needs.
                            </h4><br>
                            <ul class="sawasdeeX4 font11 textBlack">
                                <li>
                                    Designated disabled parking spaces with ease of access
                                </li>
                                <li>
                                    40-wheel chair platforms with 40 aider seats located on the Mezzanine Floor
                                </li>
                                <li>
                                    An accessible elevator for visitors with physical disabilities (weight limit of 2000kg)
                                </li>
                                <li>
                                    Wheelchair accessible restrooms
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--RECENT JOB SECTION END-->
</div>
<!--MAIN END-->