import { Component, OnInit } from '@angular/core';
import {HeaderComponent} from "../header/header.component";
import {AppService} from "../app.service";

@Component({
  selector: 'app-weddings',
  templateUrl: './weddings.component.html',
  styleUrls: ['./weddings.component.scss']
})
export class WeddingsComponent implements OnInit {

  weddings: any = [];
  appweddingsUrl = '../assets/json/weddings.json';
  constructor(public service: AppService,
              private headerComponent: HeaderComponent) {
  }

  ngOnInit(): void {
    this.headerComponent.headerClass = 'header-2';
    this.service.httpService( 'get', this.appweddingsUrl, {}, {}, (weddings: any) => {this.weddings = weddings;},  (error: any) => {});

  }

}
