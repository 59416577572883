import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {AppService} from '../../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {FormService} from '../../form.service';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {Parameters} from '../../../parameters';

@Component({
  selector: 'app-sheet-controls',
  templateUrl: './sheet-controls.component.html',
  styleUrls: ['./sheet-controls.component.scss']
})
export class SheetControlsComponent implements OnInit {

  @ViewChild('optionalInput') fruitInput: any;
  @ViewChild('auto') matAutocomplete: any;
  @Input() question: any;
  @Input() position: any;
  @Input() deletedOptions: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  questionCtrl = new FormControl();
  filteredOptions: Observable<any>;
  option: any;
  options: any = [];
  allOptions: any;
  label: any;
  controls: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public formMetthods: FormService,
              public broadcastChannel: BroadcastChannelService) {
    this.matAutocomplete = MatAutocomplete;
    this.fruitInput = ElementRef;
    this.allOptions = [];
    this.controls = this.service.app.data.controls;
    this.filteredOptions = this.questionCtrl.valueChanges.pipe(
        startWith(null),
        map((fruit: string | null) => fruit ? this._filter(fruit) : this.allOptions.slice()));
  }

  ngOnInit(): void {
    this.options = this.question.options;
    this.formMetthods.question = this.question;
    this.question.options.map((optional: any) => {
      const incomingOptional = new Parameters();
      incomingOptional.setAttributes(optional);
      optional.source = optional.hasOwnProperty('source') === true ? optional.source : Object.assign({l: 'null'}, optional);
      if(this.service.empty(incomingOptional.getSource()) === true) {
        optional = Object.assign(optional, {source: Object.assign({}, optional)});
      }
    });
  }

  handleOption(option: any){
    this.label = prompt('', option.label);
    if (this.label !== null) option = Object.assign(option, {label: this.label});
  }

  add(event: any): void {
    const value = (event.value || '').trim();
    // Add our filteredJudgmentCondition
    if (value) {
      this.option  = new Parameters();
      this.option.setAttributes({});
      this.option.setLabel(value);
      this.option.setSource({l: 'null'});
      this.option.setUnknown('cntrl_num', this.service.random());
      this.options.push(JSON.parse(JSON.stringify(this.option.attributes)));
    }

    // Clear the input value
    // event.chipInput!.clear();
    this.fruitInput.nativeElement.value = '';
    this.questionCtrl.setValue(null);
  }

  remove(option: any): void {
    const index = this.options.indexOf(option);
    if (index === -1){} else {
      this.options.splice(index, 1);
      if(option.hasOwnProperty('id')) {
        this.deletedOptions.push(option);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.options.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.questionCtrl.setValue(null);
  }

  private _filter(value: any): any {
    const filterValue = value.toLowerCase();
    return this.allOptions.filter(fruit => fruit.label.toLowerCase().includes(filterValue));
  }

}
