import {Component, OnInit, AfterViewInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {AppService} from './app.service';
import {Parameters} from './parameters';
import {FormService} from './forms/form.service';
import * as CryptoJS from 'crypto-js';
import {MatDialog} from '@angular/material/dialog';
import {StorageMap } from '@ngx-pwa/local-storage';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
    title = 'Pattern';
    appsettingsUrl = '../assets/json/app.json';
    routesUrl = '../assets/json/routes.json';
    stringsUrl = '../assets/json/strings.json';
    app: any = {settings: null};
    outgoingRequest: any = {};
    formId: any;
    loginForm: any;
    form: any = {};
    incomingUser: any;
    incomingForm: any;
    splits: any;
    appRootImg: any;
    campaignId: any = 4;
    constructor(
                private router: Router,
                public dialog: MatDialog,
                public service: AppService,
                private storage: StorageMap) {
        this.incomingUser = new Parameters();
        this.incomingForm = new Parameters();
        this.outgoingRequest = new Parameters();
        this.incomingUser.setAttributes({});
        this.incomingForm.setAttributes({});
        this.service.setNavigatedUrl(window.location.href);
        this.splits = this.service.getNavigatedUrl().toString().split('?');
        this.appRootImg = document.getElementById( 'appRootImg');
    }
    ngOnInit(): void {
        this.service.app = Object.assign(this.service.app, {data: {}});
        this.service.app.data = Object.assign(this.service.app.data, {companies: {data: []}});
        this.service.app.data = Object.assign(this.service.app.data, {categories: {data: []}});
        this.service.httpService( 'get', this.appsettingsUrl, {}, {},
            (settings: any) => {
                this.app.settings = settings;
                this.service.app = Object.assign(this.service.app, {settings: settings});
                this.service.httpService( 'get', this.routesUrl, {}, {ignoreLoadingBar: 'true'},(routes: any) => {
                    this.service.app = Object.assign(this.service.app, {routes: routes});
                    this.service.httpService( 'get', this.stringsUrl, {}, {ignoreLoadingBar: 'true'},(strings: any) => {
                        this.service.app = Object.assign(this.service.app, {strings: strings});
                        setTimeout(() =>{
                            this.getFormsByCampaignId(this.campaignId,(forms: any)=>{
                                this.service.app.data = Object.assign(this.service.app.data, {forms: {data: forms}});
                                this.router.navigateByUrl('/app/home');
                            });
                        });
                    },(error: any) => {
                    });
                },  (error: any) => {
                });
            },  (error: any) => {
            });
    }

    ngAfterViewInit(){
        document.getElementsByTagName( 'body')[0].style.backgroundColor = 'transparent';
        document.getElementsByTagName( 'body')[0].style.backgroundColor = 'rgba(30, 18, 18, 0.67)';
    }

    getFormsByCampaignId(cntrlNum: any, callback: any) {
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setCampaignId(this.campaignId);
        this.service.httpService('post', this.service.app.routes.campaigns.forms.app.get,
            this.outgoingRequest.getAttributes(),
            {ignoreLoadingBar: 'true', notify: false},
            (result: any) => {
            callback(result);
        },  (error: any) => { });
    }

}
