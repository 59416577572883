<agm-map
  #gm
  (mapReady)="onMapReady($event)"
  (mapClick)="onClickMap(gm)"
  [latitude]="lat"
  [longitude]="lng"
  [fitBounds]="true"
  [zoom]="zoom"
  [disableDefaultUI]="false"
  [styles]="app.settings.map.styles.style1"
  style="height : {{getHeight().height + 'px'}}">
  <agm-circle
    [latitude]="lat"
    [longitude]="lng"
    [fillColor]="'#ff8000'"
    [radius]="900"
    >
  </agm-circle>
<!--  <agm-marker-->
<!--    [latitude]="lat"-->
<!--    [longitude]="lng"-->
<!--    [agmFitBounds]="false"-->
<!--    [iconUrl]="icon">-->
<!--  </agm-marker>-->
  <agm-marker
    *ngFor="let point of points"
    [latitude]="point.lat"
    [longitude]="point.lng"
    [agmFitBounds]="true"
    [label]="{color: '#fff', text: point.letter.length === 1 ? point.letter : point.letter.toLowerCase(), fontSize: '13px'}"
    [iconUrl]="img"
    [openInfoWindow]="true"
    (markerClick)="onClickMarker(infoWindow,gm)"
    (mouseOut)="markerOut(infoWindow,gm)"
    (dblclick)="mapDblClick(infoWindow,gm)"
  >
    <agm-info-window
      (infoWindowClose)="onInfoWindowClose(gm,infoWindow)"
      (click)="onInfoWindowClicked(gm,infoWindow)"
      #infoWindow>
      <center><h3>{{point.letter}}</h3></center>
      <hr>
      <div *ngFor="let exhibitor of point.exhibitors" style="cursor: pointer" (click)="agmdirection(point,exhibitor)">
        <h1>{{exhibitor.name}}</h1>
        <hr>
      </div>
<!--  <p [innerHTML]="infoWindowElements(point.exhibitors)"></p>-->
<!--     , fontSize: '10px'-->
    </agm-info-window>
  </agm-marker>
  <agm-direction
    *ngFor="let point of destinationPlaces"
    [origin] = "point.origin"
    [destination]="point.destination"
    [renderOptions]="{suppressMarkers: true}" >
  </agm-direction>
<!--  <agm-polyline [editable]="true" [strokeColor]="'#1b8af3'" [strokeWeight]="6" *ngIf="destinationPlaces.length > 0">-->
<!--    <agm-polyline-point-->
<!--                        [latitude]="destinationPlaces[0].origin.lat"-->
<!--                        [longitude]="destinationPlaces[0].origin.lng">-->
<!--    </agm-polyline-point>-->
<!--    <agm-polyline-point-->
<!--    <agm-polyline-point-->
<!--      [latitude]="destinationPlaces[0].destination.lat"-->
<!--      [longitude]="destinationPlaces[0].destination.lng">-->
<!--    </agm-polyline-point>-->
<!--  </agm-polyline>-->
  <agm-direction
    *ngFor="let point of points; let key = index"
    [provideRouteAlternatives]="true"
    [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : '#ff8000'}}"
    [visible]="true"
    [origin] = "{lat: points[0].lat , lng: points[0].lng}"
    [destination] = "{lat: point.lat, lng: point.lng}">
  </agm-direction>
<!--  <agm-polyline [editable]="true" [strokeColor]="'#f44336'">-->
<!--    <agm-polyline-point *ngFor="let point of points; let key = index"-->
<!--                        [latitude]="point.lat"-->
<!--                        [longitude]="point.lng">-->
<!--    </agm-polyline-point>-->
<!--  </agm-polyline>-->
</agm-map>
<div class="child" [hidden]="searchBox">
  <div class="bar">
    <input class="searchbar"
           [ngStyle]="{'text-align': 'center'}"
           [placeholder]="'Where to ?'"
           type="text"
           title="Search"
           [formControl] = "exhibitorControl"
           [matAutocomplete] = "exhibitor">
    <mat-autocomplete #exhibitor = "matAutocomplete" (optionSelected)="onExhitorSelectionChanged($event)" [displayWith]="displayFn">
      <mat-option *ngFor="let exhibitor of filteredExhibitorsOptions | async; let exhibitorOf = index" [value]= "exhibitor" >
        {{exhibitor.name}}
      </mat-option>
    </mat-autocomplete>
    <a> <i class="material-icons voice">search</i></a>
  </div>
</div>


<!--[latitude]="(destinationPlaces.length  === 0 ? points[0].origin.lat : destinationPlaces[0].origin.lat)"-->
<!--[longitude]="(destinationPlaces.length === 0 ? points[0].origin.lng : destinationPlaces[0].origin.lng)"-->
