import { Component, OnInit,Input, OnChanges, SimpleChanges } from '@angular/core';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-view-submit-control',
  templateUrl: './view-submit-control.component.html',
  styleUrls: ['./view-submit-control.component.scss']
})
export class ViewSubmitControlComponent implements OnInit, OnChanges {

  @Input() question: any;
  @Input() position: any;
  constructor(private broadcastChannel: BroadcastChannelService,
              public  service: AppService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.button.next.hide});
  }

}
