import {Component, Inject, OnInit, AfterContentInit, AfterViewInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AppService} from '../app.service';
import {Parameters} from '../parameters';
import {orderBy} from 'lodash';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormService} from './form.service';
import * as moment from "moment";
import {BroadcastChannelService} from "../broadcast-channel.service";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {CampaignService} from '../campaigns/campaign.service';
import {TreeviewFormComponent} from "./treeview/treeview-form.component";
import {interval} from "rxjs";
import {DialogTeamsComponent} from "../teams/dialog/dialog-teams.component";
import {MessageDisplayComponent} from "../main/message/message-display.component";
import {ProgressDialogComponent} from "../main/progress-dialog/progress-dialog.component";

@Component({
  selector: 'app-dialog-forms',
  templateUrl: './dialog-forms.component.html',
  styleUrls: ['./dialog-forms.component.scss']
})
export class DialogFormsComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();
  keys: any;
  questions: any;
  forms: any;
  selectedForm: any = {};
  incomingSelectedForm: any;
  incomingSettings: any;
  configAngularEditor: any;
  url: string;
  searchCntrl: any;
  formValues: FormGroup = new FormGroup({});
  filteredClientsOptions: any;
  filteredCampaignsOptions: any;
  filteredTypesOptions: any;
  clientControl = new FormControl();
  campaignControl = new FormControl();
  typeControl = new FormControl();
  incomingControl: any;
  incomingReference2: any;
  controls: any;
  hidden: any = false;
  pos: any = 0;
  removedQuestions = [];
  removedOptions = [];
  options: any = [];
  outgoingQuestion: any = {};
  flag: any = 0;
  position: any = 0;
  parameters: any;
  removedOptionalQuestions: any;
  incomingCampaign: any;
  subscription: any;
  incomingQuestion: any;
  incomingOption: any;
  incomingQ: any;
  incomingOpt: any;
  incomingResult: any;
  incomingAncestor: any;
  submittedFormValues: any;
  incomingResponse: any;
  interval: any;
  wait: any = null;
  lg: any = 1;
  peddingOpts: any;
  incomingItem: any;
  finalQuestions: any = [];
  constructor(public formService: FormService,
              public campaignService: CampaignService,
              public formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dialog: MatDialog,
              private broadcastChannel: BroadcastChannelService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogFormsComponent>,
              public service: AppService) {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#ebebeb';
    this.incomingCampaign = new Parameters();
    this.incomingSelectedForm = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingReference2 = new Parameters();
    this.incomingItem = new Parameters();
    this.incomingQ = new Parameters();
    this.incomingOpt = new Parameters();
    this.incomingAncestor = new Parameters();
    this.submittedFormValues = new Parameters();
    this.incomingResponse = new Parameters();
    this.peddingOpts = new Parameters();
    this.formService.isViewOpened = {};
    this.incomingCampaign.setAttributes({});
    this.selectedForm = this.formService.selected;
    this.incomingSelectedForm.setAttributes(this.selectedForm);
    this.keys = Object.keys(this.selectedForm).length;
    this.formService.optionalFiles = {};
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.url = this.keys === 0 ? '/forms' : '/forms/' + parseFloat(this.incomingSelectedForm.getId()) + '/update';
    this.questions = [];
    this.removedOptions = [];
    this.incomingControl = new Parameters();
    this.incomingControl.setAttributes({});
    this.controls = this.service.app.data.controls;
    this.hidden = false;
    this.formService.questions = this.questions;
    this.formService.deletedOptions  = this.removedOptions;
    this.formService.deletedQuestions = [];
    this.formService.deletedOptions  = [];
    this.parameters = new Parameters();
    this.formService.deletedOptionQuestions = [];
    this.peddingOpts.setAttributes({});
  }


  ngOnInit(): void {
    this.formValues = this.formBuilder.group({
      name:        ['', Validators.compose([])],
      target:      ['', Validators.compose([])],
      cntrl_num:   ['', Validators.compose([])],
      controls:    ['', Validators.compose([])],
      source:      ['', Validators.compose([])],
      status:      ['1' , Validators.compose([])],
      campaign_id: ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      category_id: ['5' , Validators.compose([])],
      deletedOptions: ['', Validators.compose([])],
    });
    this.flag = this.formService.categoryId;
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('scroll') === true) {
             if(item.scroll.toString().includes('down')){
              // document.getElementsByClassName( 'mat-dialog-container')[0].scrollTo(0, window.innerHeight * 10000);
             }
            }else if (item.hasOwnProperty('campaign') === true) {
              this.campaignControl.setValue(item.campaign);
              this.formValues.controls['campaign_id'].setValue(item.campaign.id);
            }
          }

        });

    this.removedOptionalQuestions = this.formService.deletedOptionQuestions;

    setTimeout(() => {
      this.filteredClientsOptions = this.clientControl.valueChanges
          .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.name),
              map(name => name ? this._filterClient(name) : this.service.app.data.clients.data.slice())
          );

      this.filteredCampaignsOptions = this.campaignControl.valueChanges
          .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.name),
              map(name => name ? this._filterCampaign(name) : this.service.app.data.campaigns.data.slice())
          );

      this.filteredTypesOptions = this.typeControl.valueChanges
          .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.name),
              map(name => name ? this._filterType(name) : this.service.app.data.projects.slice())
          );

      this.typeControl.setValue(    this.keys === 0 ? {} : this.getTypeByForm(this.selectedForm));
    }, 490);

    if(this.keys === 0){
      this.incomingControl.setControlId(1);
      this.incomingControl.setOptions([]);
      this.incomingControl.setControl({id: 1, name: 'Text'});
      this.incomingControl.setPlaceholder('');
      this.incomingControl.setUnknown('cntrl_num', this.service.random());
      this.incomingControl.setRequired(0);
      this.incomingControl.setLabel('Untitled Question');
      this.incomingControl.setUnknown('visible', 1);
      this.incomingControl.setIndex(0);
      this.incomingControl.setName('Text');
      this.questions.unshift(this.incomingControl.getAttributes());
      this.formValues.controls['cntrl_num'].setValue(this.service.random());
      if(Object.keys(this.campaignService.selected).length === 0){
        this.formValues.controls['campaign_id'].setValue(this.campaignService.campaignSelectedId === undefined ? '' : this.campaignService.campaignSelectedId);
     // if(this.campaignService.hasUnkownCampaign()){
        //   this.campaignService.setSelected(this.campaignService.getCampaignByCntrlNum(this.systemService.app.settings.unknownCampaignCntrlNum));
        //   this.formValues.controls['campaign_id'].setValue(this.campaignService.onSelectProduct.id);
        //   this.campaignControl.setValue(this.campaignService.onSelectProduct);
        // }else {
        //   this.campaignService.setUnknown((response, status) => {
        //     if(status === 200){
        //       this.systemService.app.data.campaigns.data.push(response);
        //       this.formValues.controls['campaign_id'].setValue(response.id);
        //       this.campaignControl.setValue(response);
        //       this.campaignService.setSelected(response);
        //     }
        //   });
        // }
      }
    }else{
    //this.questions = this.incomingSelectedForm.attributes.controls;
      this.questions = this.incomingSelectedForm.attributes.controls.map((question: any) => {
          this.outgoingQuestion = {};
          this.outgoingQuestion = this.service.empty(question.cntrl_num) ? Object.assign(question, {cntrl_num: this.service.random()}) : question;
          this.options = this.outgoingQuestion.options.map((option: any) => {return this.service.empty(option.cntrl_num) ? Object.assign(option, {cntrl_num: this.service.random()}) : option;});
          this.outgoingQuestion = Object.assign(this.outgoingQuestion, {options: this.options});
          return this.outgoingQuestion;
      });
      this.formService.questions = this.questions;
      this.formValues.controls['name'].setValue(this.incomingSelectedForm.getName());
      this.formValues.controls['description'].setValue(this.incomingSelectedForm.getDescription());
      this.formValues.addControl('id', new FormControl(parseFloat(this.incomingSelectedForm.getId()), Validators.compose([])));
      for(var key in this.incomingSelectedForm.getAttributes()){
        if(this.incomingSelectedForm.attributes[key] instanceof Object || this.incomingSelectedForm.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key))
          this.formValues.controls[key].setValue(this.incomingSelectedForm.attributes[key]);
          else this.formValues.addControl(key, new FormControl(this.incomingSelectedForm.attributes[key], Validators.compose([])));
        }
      }
      this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingSelectedForm.getCampaignId()));
      this.campaignControl.setValue(this.incomingCampaign.getAttributes());
      this.campaignService.setSelected(this.incomingCampaign.getAttributes());
      this.broadcastChannel.emitNavChangeEvent({campaignId: this.incomingCampaign.getId()});
      this.formValues.controls['cntrl_num'].setValue((this.service.empty(this.incomingSelectedForm.getCntrlNum())) ? this.service.random() : this.incomingSelectedForm.getCntrlNum());
    }
    this.dialogRef.afterClosed().subscribe(result => {this.formService.setSelected({}); });
    this.setAllQuestions();
    this.loadFiles();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setValue(item, column, result){ item[column] = result;}
  removeSkip(questions: any , question: any){
    this.incomingQuestion.setAttributes(question);
    this.position = questions.indexOf(this.incomingQuestion.getAttributes());
    if (this.position !== -1){
      questions.splice(this.position, 1);
      this.position = this.formService.questions.findIndex((questional: any) => {return parseFloat(questional.cntrl_num) === parseFloat(question.cntrl_num);});
      this.incomingQuestion.setVisible(1);
      if(this.position === -1) {this.formService.questions.push(this.incomingQuestion.getAttributes()); }else {this.formService.questions[this.position].visible = 1;};
      if(this.incomingQuestion.attributes.hasOwnProperty('option_question_id')) {this.formService.deletedOptionQuestions.push(this.incomingQuestion.getAttributes()); }
    }
  }

  loadFiles(){
    this.questions.map((question: any) => {
      question.options.map((optional: any) => {
        if(this.service.empty(optional.media) === false){
          this.formService.optionalFiles[optional.cntrl_num] = {media: null, pic: optional.media};
        }
      });
    });
  }

  scrollDown(){
    document.getElementsByClassName( 'mat-dialog-container')[0].scrollTo(0, window.innerHeight + document.getElementsByClassName( 'mat-dialog-container')[0].scrollHeight);
  }

  scrollUp(){
    document.getElementsByClassName( 'mat-dialog-container')[0].scrollTo(0, 0);
  }

  ngAfterContentInit(): void {
  }

  setAllQuestions(){
    this.formService.questions.map((question: any) => {
      if(question.hasOwnProperty('options')){
        question.options.map((opt: any) => {
          if(opt.label.toString().includes('null')){
            this.formService.showQuestions(opt);
          } else this.formService.hideOptionalQuestions(opt);
        });
      }
    });
    this.formService.loadHiddenQuestions();
  }


  ngAfterViewInit(): void {
   if(this.keys === 0){}else{
     setTimeout(() =>{ this.broadcastChannel.emitNavChangeEvent({campaignId: this.incomingCampaign.getId()});});
   }
  }

  closeDialog(){
    this.formService.isOpenDialog = false;
    this.dialogRef.close(null);
  }

  onClientSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['client_id'].setValue(event.option.value.id);
  }

  onSelectedTabChange(event: MatTabChangeEvent){
    if(event.index === 2){
      this.broadcastChannel.emitNavChangeEvent('treenodes');
      // (new TreeviewFormComponent(this.formService, this.broadcastChannel)).nodes();
    }
  }





  handle(pos: any, questionOf: any, hidden: any){
    setTimeout(() => {
      this.pos = questionOf;
      this.hidden = false;
    }, 920);
  }

  onTypeSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['category_id'].setValue(event.option.value.id);
  }

  onCampaignSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['campaign_id'].setValue(event.option.value.id);
    this.broadcastChannel.emitNavChangeEvent({campaignId: event.option.value.id});
  }

  displayFn(value: any){
    return value.hasOwnProperty('name')?name:'';
  }


  getCampaignByForm(form: any){
    return this.service.app.data.campaigns.data.filter((campaign: any) => { return campaign.id === form.campaign_id;})[0];
  }

  getTypeByForm(form: any){
    return this.service.app.data.projects.filter((formType: any) => { return parseFloat(formType.id) === parseFloat(form.category_id);})[0];
  }

  private _filterClient(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.clients.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  hasQuestionnar(){
    let l: boolean = true;if(this.questions.length === 0){ this.notifier.notify('success', 'please nothing any questinnar on set'); l = false;}return l;
  }

  private _filterCampaign(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.campaigns.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  timeout(){return parseFloat('3112');};
  private _filterType(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.projects.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  shouldSubmitted(callback: any){
    this.interval = setInterval(() => {
      if(this.wait === null){
        this.wait = undefined;
      }else callback(null);
    }, this.timeout());
  }

  onSubmit(postValues: any) {
    this.questions = this.questions.map((question: any)=>{
      this.incomingQuestion.setAttributes(question);
      this.incomingResult.setAttributes(this.incomingQuestion.attributes.hasOwnProperty('result')?
       this.incomingQuestion.getResult():{});
//       this.incomingSettings.setAttributes(this.incomingQuestion.attributes.hasOwnProperty('settings')?
//       this.incomingQuestion.getSettings():{});
      this.incomingReference2.setAttributes(this.incomingQuestion.attributes.hasOwnProperty('reference2')?
       this.incomingQuestion.getReference2():{});
      this.incomingQuestion.setResult(JSON.stringify(this.incomingResult.getAttributes()));
//       this.incomingQuestion.setSettings(JSON.stringify(this.incomingSettings.getAttributes()));
      this.incomingQuestion.setReference2(JSON.stringify(this.incomingReference2.getAttributes()));
      return this.incomingQuestion.getAttributes();
    });
    this.formValues.controls['controls'].setValue(JSON.stringify(this.questions));
    this.formValues.controls['source'].setValue(JSON.stringify({}));
    this.submittedFormValues.setAttributes(postValues);
    this.submittedFormValues.attributes = Object.assign(this.submittedFormValues.attributes, {controls: JSON.stringify(this.questions)});
    this.submittedFormValues.attributes = Object.assign(this.submittedFormValues.attributes, {trashed: JSON.stringify(this.formService.deletedQuestions)});
    this.submittedFormValues.attributes = Object.assign(this.submittedFormValues.attributes, {source: JSON.stringify({})});
    this.submittedFormValues.attributes = Object.assign(this.submittedFormValues.attributes, {settings: JSON.stringify(Object.keys(this.incomingSelectedForm.getAttributes()).length === 0 ? {} : this.incomingSelectedForm.getSettings())});
    this.submittedFormValues.attributes = Object.assign(this.submittedFormValues.attributes, {deletedOptions: JSON.stringify(this.removedOptions)});
    this.submittedFormValues.attributes = Object.assign(this.submittedFormValues.attributes, {removedOptionalQestions: JSON.stringify(this.removedOptionalQuestions)});
    this.service.httpService('post', this.url, this.submittedFormValues.attributes, {},
        (response: any) => {
          this.incomingResponse.setAttributes(response);
          if (this.keys === 0) this.service.app.data.forms.data.unshift(this.incomingResponse.attributes);
          else {
           this.incomingSelectedForm.setControls(this.incomingResponse.getControls());
           this.questions=this.incomingResponse.getControls();
           this.incomingSelectedForm.attributes = Object.assign(this.incomingSelectedForm.getAttributes(), this.incomingResponse.getAttributes());
            this.formService.setSelected(this.incomingSelectedForm.getAttributes());
          }this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'published' : 'updated'));
          this.broadcastChannel.emitNavChangeEvent({form: this.keys === 0  ? this.incomingResponse.getAttributes() : this.incomingSelectedForm.getAttributes()});
          this.closeDialog();
          this.uploadFiles(this.incomingResponse.attributes.id);
        }, (error: any) => {
        });
  }
  
  setOptionalQustionsPosition(option: any, callback: any){
    let l: any = 1;
    this.incomingOption.setAttributes(option);
    option.questions.map((question: any, i: any) => {
      question = this.formService.getQuestionByCntrlNum(question.cntrl_num);
      this.incomingQuestion.setAttributes(question);
      if(this.incomingQuestion.attributes.control.id === 12){}else{
        if(this.incomingQuestion.attributes.control.id === 18){
          this.incomingQuestion.attributes = Object.assign(
              this.incomingQuestion.attributes, { position: this.incomingOption.attributes.position});
          this.incomingQuestion.attributes.options.map((opt: any, j: any) => {
            this.incomingOpt.setAttributes(opt);
            this.incomingOpt.attributes = Object.assign(this.incomingOpt.attributes, {position: this.incomingOption.attributes.position + '.' + (parseFloat(l.toString()))});
            l+= parseFloat('1');
            this.peddingOpts.setUnknown(this.incomingOpt.attributes.cntrl_num, this.incomingOpt.attributes);
            // this.setOptionalQustionsPosition(this.incomingOpt.attributes, this.incomingOpt.attributes.cntrl_num , callback);
          });
        }else {
          this.incomingQuestion.attributes = Object.assign(this.incomingQuestion.attributes, {
            position: this.incomingOption.attributes.position + '.' + (parseFloat(l.toString()))});
          l+= parseFloat('1');
          this.incomingQuestion.attributes.options.map((opt: any, j: any) => {
            this.incomingOpt.setAttributes(opt);
            this.incomingOpt.attributes = Object.assign(this.incomingOption.attributes, {position: this.incomingQuestion.attributes.position + '.' + (parseFloat(j.toString()) + 1)});
            this.peddingOpts.setUnknown(this.incomingOpt.attributes.cntrl_num, this.incomingOpt.attributes);
          });
        }
      }
    });
    callback(this.incomingOption.attributes);
  }



  // setOptionalQustionsPositionl(question: any, callback: any){
  //   callback(null);
  //   this.incomingOption.setAttributes(question);
  //   question.questions.map((question: any, i: any) => {
  //     question = this.formService.getQuestionByCntrlNum(question.cntrl_num);
  //     this.incomingQuestion.setAttributes(question);
  //     if(this.incomingQuestion.attributes.control.id === 12){
  //       i-= parseFloat('1');
  //     }else{
  //       if(this.incomingQuestion.attributes.control.id === 18){
  //         this.incomingQuestion.attributes.options.map((opt: any, l: any) => {
  //           this.incomingOpt.setAttributes(opt);
  //           this.incomingOpt.setPosition(this.incomingOption.getPosition() + '.' + (l+1));
  //           this.setOptionalQustionsPosition(this.incomingOpt.attributes, callback);
  //         });i-=1;
  //       }else {
  //         this.incomingQuestion.setPosition(this.incomingOption.getPosition() + '.' + (i+1));
  //         this.incomingQuestion.attributes.options.map((opt: any, l: any) => {
  //           this.incomingOpt.setAttributes(opt);
  //           this.incomingOpt.setPosition(this.incomingQuestion.getPosition() + '.' + (l+1));
  //           this.setOptionalQustionsPosition(this.incomingOpt.attributes, callback);
  //         });
  //       }
  //     }
  //     alert(this.incomingQuestion.attributes.hasOwnProperty('position') ? this.incomingQuestion.getPosition() : '///');
  //   });
  // }


  // setPosition(callback: any){
  //   this.showAllQuestions((rs: any) => {
  //     this.hideAllChildQuestions((rs: any) => {
  //       this.getAllVisibleQuestions((questions: any) => {
  //         questions.map((question: any) => {
  //           this.incomingQuestion.setAttributes(question);
  //           if(this.incomingQuestion.attributes.control.id === 12){
  //           }else{
  //             if(this.incomingQuestion.attributes.control.id === 18){
  //               this.incomingQuestion.attributes.options.map((question: any) => {
  //                 this.incomingOption.setAttributes(question);
  //                 this.incomingOption.setPosition(this.lg);
  //                 this.lg += 1;
  //                 this.setOptionalQustionsPosition(this.incomingOption.attributes, callback);
  //               });
  //             }else{
  //               this.incomingQuestion.attributes = Object.assign(this.incomingQuestion.attributes, {position: this.lg.toString()});
  //               this.incomingQuestion.attributes.options.map((question: any, l: any) => {
  //                 this.incomingOption.setAttributes(question);
  //                 this.incomingOption.setPosition(this.lg.toString() + '.' + (l+1));
  //                 this.setOptionalQustionsPosition(this.incomingOption.attributes, callback);
  //               });
  //               this.lg+=1;
  //             }
  //           }
  //         });callback(null);
  //       });
  //     });
  //   });
  // }

  setPosition(callback: any){
    this.showAllQuestions((rs: any) => {
      this.hideAllChildQuestions((rs: any) => {
        this.getAllVisibleQuestions((questions: any) => {
          questions.map((question: any) => {
            this.incomingQuestion.setAttributes(question);
            if(this.incomingQuestion.attributes.control.id === 12){}else{
              this.incomingQuestion.attributes.options.map((option: any, l: any) => {
                this.incomingOption.setAttributes(option);
                this.incomingOption.setAncestor({position: this.incomingQuestion.attributes.position,
                  controlId: this.incomingQuestion.attributes.control.id});
                  this.setOptionalQustionsPosition(option, () => {
                    callback(null);
                    this.incomingOpt.setAttributes(option);
                    if(this.peddingOpts.attributes.hasOwnProperty(this.incomingOpt.attributes.cntrl_num))
                       delete this.peddingOpts[this.incomingOpt.attributes.cntrl_num];
                    if(Object.keys(this.peddingOpts.attributes).length === 0){}else{
                      // tslint:disable-next-line:radix
                      let l: any = parseInt('0');
                      for(const key in this.peddingOpts.attributes){
                        if(l === 0)this.setOptionalQustionsPosition(this.peddingOpts.attributes[key], callback);
                        else l+= parseFloat('1');
                      }
                    }
                  });
              });
            }
          });callback(null);
        });
      });
    });
  }

  uploadFiles(formId){
    let formValues: any = new Parameters();
    formValues.setAttributes({});
    for(var key in this.formService.optionalFiles) {
      formValues.attributes.cntrl_num = key;
      formValues.attributes.form_id = formId;
      formValues.attributes.media = this.formService.optionalFiles[key].media;
      this.service.httpService('post', '/options/addFile',
          formValues.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
            let form: any = this.formService.getFormById(formId);
            form = Object.assign(form, {controls: response.controls});
          }, (error: any) => {
          });


    }
  }

  addCampaign(){
      this.campaignService.setSelected({});
      this.campaignService.openDialog({});
      this.notifier.notify('success', 'Add Project');
  }

  showAllQuestions(callback: any){
    this.formService.questions.map((question: any) => {
      this.incomingQuestion.setAttributes(question);
      this.incomingQuestion.attributes.visible = 1;
      if(this.incomingQuestion.attributes.hasOwnProperty('position'))
      delete this.incomingQuestion.attributes.position;
    });
    callback({});
  }

  hideAllChildQuestions(callback: any){
    setTimeout(() => {
      this.formService.questions.map((question: any) => {
        this.incomingQuestion.setAttributes(question);
        if(this.incomingQuestion.attributes.hasOwnProperty('options')){
          this.incomingQuestion.attributes.options.map((option: any) =>{
            this.incomingOption.setAttributes(option);
            if(this.incomingOption.attributes.hasOwnProperty('questions')){
              this.incomingOption.attributes.questions.map((q: any) =>{
                this.incomingQ.setAttributes(this.formService.getQuestionByCntrlNum(q.cntrl_num));
                this.incomingQ.attributes = Object.assign(this.incomingQ.attributes, {visible: 0});
                this.incomingQ.attributes = Object.assign(this.incomingQ.attributes, {ancestor: this.incomingQuestion.attributes.cntrl_num});
                this.incomingQ.attributes = Object.assign(this.incomingQ.attributes, {option: {label: this.incomingOption.attributes.label, cntrlNum: this.incomingOption.attributes.cntrl_num}});
              });
            }
          });
        }
      });
      callback({});
    });
  }

  getAllVisibleQuestions(callback: any){
    let l: any = 1;
    let questions: any = this.formService.questions.filter((question: any) => {
      this.incomingQuestion.setAttributes(question);
      if(this.incomingQuestion.attributes.visible === 1){
        if(this.incomingQuestion.attributes.control.id === 12){}else{
          this.incomingQuestion.attributes = Object.assign(this.incomingQuestion.attributes, {position: l});
          this.incomingQuestion.attributes = Object.assign(this.incomingQuestion.attributes, {main: 'true'});
          l+= parseFloat('1');
          this.incomingQuestion.attributes.options.map((option: any, r: any) => {
            this.incomingOption.setAttributes(option);
            this.incomingOption.setPosition(
                this.incomingQuestion.attributes.control.id === 18 ? (parseFloat(r.toString()) + 1) : (parseFloat(r.toString()) + 1));
          });
        }
      }
      return this.incomingQuestion.attributes.visible === 1;
    });callback(questions);
  }

  handleDestroy(carcass: any){
  }
}
