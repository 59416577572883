<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input  matInput
                                #emailElement
                                class="sawasdee26"
                                type="email"
                                autocomplete="off"
                                placeholder="{{question.placeholder}}"
                                [(ngModel)]="service.empty(question.data) === false && question.hasOwnProperty('default') ? question.default : question.data"
                                [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                                #message />
                        <!--        <i class="material-icons pull-right">insert_emoticon</i>-->
                    </mat-form-field>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 row">
                    <center>
                        <app-next-button></app-next-button>
                    </center>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
