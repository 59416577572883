import { Component, OnInit } from '@angular/core';
import {CampanyService} from './campany.service';
import {AppService} from '../app.service';

@Component({
  selector: 'app-tabs-campanies',
  templateUrl: './tabs-campanies.component.html',
  styleUrls: ['./tabs-campanies.component.scss']
})
export class TabsCampaniesComponent implements OnInit {

  campanies: any;
  constructor(public campanyService: CampanyService,
              public provider: AppService) {
  }

  ngOnInit(): void {
  }

}
