import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {AppService} from "../../../../app.service";
import {BroadcastChannelService} from "../../../../broadcast-channel.service";
import {FormService} from "../../../form.service";
import {Parameters} from "../../../../parameters";

@Component({
  selector: 'app-groups-preview-controls',
  templateUrl: './groups-preview-controls.component.html',
  styleUrls: ['./groups-preview-controls.component.scss']
})
export class GroupsPreviewControlsComponent implements OnInit, AfterViewInit, AfterContentChecked, OnChanges, AfterContentInit, OnDestroy {
  @Input() question: any;
  @Input() position: any;
  data: any;
  questions: any;
  form: any;
  incomingOption: any;
  incomingQuestion: any;
  subscription: any;
  constructor(
      public service: AppService,
      public broadcastChannel: BroadcastChannelService,
      public formService: FormService) {
    this.form = formService.selected;
    this.questions = this.form.controls;
    this.incomingOption = new Parameters();
    this.incomingQuestion = new Parameters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngStart();
  }

  ngStart(){
    this.scrollY();
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('scroll')) {
              this.scrollY();
            }
          }
        });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterContentChecked(): void {
  }


  optionalProgress(optional: any){
    optional.written = 0;
    optional.all = 0;
    this.formService.getOptionalProgress(optional, (rs: any) => {
      optional.all += rs.hasOwnProperty('all')     ? parseFloat(rs.all.toString()) : 0 ;
      optional.written += rs.hasOwnProperty('written') ? parseFloat(rs.written.toString()) : 0 ;
      optional.progress = this.formService.progress(optional.written, optional.all);
    });
    // tslint:disable-next-line:radi
    return parseInt(optional.progress.toString());
  }


  onPanelClicked(optional: any){
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOption.setAttributes(optional);
    this.question.data = optional.label;
    this.broadcastChannel.emitNavChangeEvent({action: 'next', controlId: this.question.id, optionalId: optional.id, optionalCntrlNum: optional.cntrl_num});
    this.incomingQuestion.setUnknown('pageYOffset',  window.scrollY);
  }

  scrollY(){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingQuestion.getAttributes().hasOwnProperty('pageYOffset')){
        setTimeout((page: any) => {
          window.scrollTo(0, parseFloat(page.yOffSet));
        }, 0, {yOffSet: this.incomingQuestion.getUnknown('pageYOffset')});
      }
    });
  }


  reset(radionButton: any) {
    if (this.service.empty(this.question.data)) radionButton.checked = false;
  }

  ngAfterViewInit(): void {
    this.scrollY();
  }

  ngAfterContentInit(): void {
  }
}
